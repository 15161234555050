define('app/modules/evaluation/views/views.lightboxView',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/modules/evaluation/templates/evaluation.templates.lightbox.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.home.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.individuals.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.pre.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.post.tmpl','app/modules/evaluation/views/views.summaryView','app/modules/evaluation/models/evaluation.models.summary','backbone.crudder','app/app'],function (require) {
	'use strict';

	var Marionette 			    = require('marionette'),
		Vent 				    = require('app/app.vent'),
		Commands 			    = require('app/commands/commands.vent'),
		Requests 			    = require('app/requests/requests.vent'),
		LightboxTemplate 	    = require('tpl!app/modules/evaluation/templates/evaluation.templates.lightbox.tmpl'),
		HomeTemplate 	        = require('tpl!app/modules/evaluation/templates/evaluation.templates.home.tmpl'),
        IndividualsTemplate     = require('tpl!app/modules/evaluation/templates/evaluation.templates.individuals.tmpl'),
        PreassessmentTemplate 	= require('tpl!app/modules/evaluation/templates/evaluation.templates.pre.tmpl'),
        PostassessmentTemplate 	= require('tpl!app/modules/evaluation/templates/evaluation.templates.post.tmpl'),
        ReportView              = require('app/modules/evaluation/views/views.summaryView'),
        ReportModel 	        = require('app/modules/evaluation/models/evaluation.models.summary'),
        Crudder                 = require('backbone.crudder');

		return Marionette.ItemView.extend({

            /**
             * Main template
             */
			template: LightboxTemplate,

            /**
             * Starry plugin instance
             */
            starry: null,

            /**
             * UI hooks
             */
            ui: {
                overlayclick : '.overlay',
                exitclick : '.exitform'
            },

            /**
             * Backbone events
             */
            events: {
                'click @ui.overlayclick' : 'closelightbox',
                'click @ui.exitclick' : 'closelightbox'
            },

            /**
             * Rules for validating pre and post assessment forms.
             */
            validationRules: {
                pre: {
                    serviceUserId: {
                        validator: "required",
                        message: "You must fill in the serviceUserId."
                    },
                    referralTeam: {
                        message: "Please choose at least one item or fill in the 'other' box.",
                        validator: function(elements) {
                            var oneSelected = false;
                            $.each(elements, function(index, element) {
                                if (element.checked) {
                                    oneSelected = true;
                                }
                            });

                            if (!oneSelected) {
                                // Has the 'other' box been filled in?
                                oneSelected = $('[name="referralTeam-other"]').val() != "";
                            }

                            return oneSelected;
                        }
                    },
                    currentDayTimeCareHours: {
                        messages: [
                            "Please fill in your current day time care hours per week.",
                            "Your hours must be a number less than 400."
                        ],
                        validator: [
                            "required",
                            "hours"
                        ]
                    },
                    currentNightTimeSupport: {
                        messages: [
                            "Please select your current night time support type.",
                            function(elements) {
                                var message = "";
                                $.each(elements, function(index, element) {
                                    if (element.checked) {
                                        // sleepingNight -> sleeping night
                                        message = "Please fill in your " + element.value.replace("N", " n") + " hours per week.";
                                    }
                                });
                                return message;
                            }
                        ],
                        validator: [
                            "requireOne",
                            function(elements) {
                                var result = true,
                                    regex = new RegExp(/\d+(\.\d+)?/);;
                                $.each(elements, function(index, element){
                                    var input = $("#" + $(element).data('input'));
                                    if (element.checked && input.val() == "") {
                                        result = false;
                                    }
                                    if (element.checked && !regex.test(input.val())) {
                                        result = false;
                                    }

                                    // Pass validation for 'none'
                                    if (element.checked && element.value == "none") {
                                        result = true;
                                    }
                                });

                                return result;
                            }
                        ]
                    },
                    assessingResidentialPlacement: {
                        message: "Please tell us if you're installing this to assess the need for a residential placement.",
                        validator: "requireOne"
                    },
                    reasonsForInstalling: {
                        message: "You must select at least one item or fill in the 'other' box.",
                        validator: function(elements) {
                            var oneSelected = false;
                            $.each(elements, function(index, element) {
                                if (element.checked) {
                                    oneSelected = true;
                                }
                            });

                            if (!oneSelected) {
                                // Has the 'other' box been filled in?
                                oneSelected = $('[name="reasonsForInstalling-other"]').val() != "";
                            }

                            return oneSelected;
                        }
                    }
                },
                post: {
                    assessmentEndDate: {
                        validator: [
                            "required",
                            "date"
                        ],
                        messages: [
                            "Please fill in your assessment end date.",
                            "Must be in the format dd/mm/yyyy"
                        ]
                    },
                    revisedDayTimeCareHours: {
                        messages: [
                            "Please fill in your post assessment day time care hours per week.",
                            "Your hours must be a number less than 400."
                        ],
                        validator: [
                            "required",
                            "hours"
                        ]
                    },
                    revisedNightTimeSupport: {
                        messages: [
                            "Please select your post assessment night time support type.",
                            function(elements) {
                                var message = "";
                                $.each(elements, function(index, element) {
                                    if (element.checked) {
                                        // sleepingNight -> sleeping night
                                        message = "Please fill in your " + element.value.replace("N", " n") + " hours per week.";
                                    }
                                });
                                return message;
                            }
                        ],
                        validator: [
                            "requireOne",
                            function(elements) {
                                var result = true,
                                    regex = new RegExp(/\d+(\.\d+)?/);;
                                $.each(elements, function(index, element){
                                    var input = $("#" + $(element).data('input'));
                                    if (element.checked && input.val() == "") {
                                        result = false;
                                    }
                                    if (element.checked && !regex.test(input.val())) {
                                        result = false;
                                    }

                                    // Pass validation for 'none'
                                    if (element.checked && element.value == "none") {
                                        result = true;
                                    }
                                });

                                return result;
                            }
                        ]
                    },
                    residentialCareRequired: {
                        message: "Please tell us if the individual has gone into residential care.",
                        validator: "requireOne"
                    },
                    supportedAchievements: {
                        message: "You must select at least one item or fill in the 'other' box.",
                        validator: function(elements) {
                            var oneSelected = false;
                            $.each(elements, function(index, element) {
                                if (element.checked) {
                                    oneSelected = true;
                                }
                            });

                            if (!oneSelected) {
                                // Has the 'other' box been filled in?
                                oneSelected = $('[name="supportedAchievements-other"]').val() != "";
                            }

                            return oneSelected;
                        }
                    },
                    usefulnessOfService: {
                        message: "Please rate us between 1 and 5.",
                        validator: function(element) {
                            var value = parseInt(element.val());

                            return (value > 0 && value < 6);
                        }
                    }
                }
            },

            /**
             * Close the lightbox
             */
			closelightbox:function() {
				$('.overlay').hide();
				$('#lightboxcontainer').hide();
			},

            /**
             * When the page has rendered, this runs automatically.
             */
            onRender: function() {
                var disableClose = false;
                    //greyAssessmentEnd = false;
                $('#lightboxcontainer').html(LightboxTemplate).show();

                if (store.get('disableClose')) {
                    disableClose = true;
                }
                //if (store.get('greyAssessmentEnd')) {
                //    greyAssessmentEnd = true;
                //}
                var oAuthBearerObject = store.get('oAuth_bearer_object')
                store.clear();
                setTimeout(function(){
                    store.set('oAuth_bearer_object', oAuthBearerObject)
                },200)
				store.set('fakeId', 1);
				store.set('disableClose', disableClose);
                //store.set('greyAssessmentEnd', greyAssessmentEnd);
            },

            /**
             * When the page is visible, this runs automatically.
             */
            onShow:function() {
                $('.printcontainer').hide();
                this.loadHome(true);

                if (store.get('disableClose')) {
                    $('.exitform,.overlay').unbind();
                    $('.exitform').hide();
                } else {
                    $('.exitform,.overlay').unbind().click(this.closelightbox);
                }
            },

            /**
             * Load the data for the homepage.
             * @param fetch (boolean) whether to fetch from server or not.
             */
            loadHome: function(fetch) {
                var evaluationContainer = $('#evaluationcontainer');

                evaluationContainer.html('<div class="loader-container"></div>');
                var loaderContainer = evaluationContainer.find('.loader-container');
                $('.overlay').show();
                loaderContainer.spin();

                if (fetch == true) {
                    this.model.fetch({
                        success: function() {
                            loaderContainer.spin(false);
                            evaluationContainer.html(HomeTemplate);

                            this.renderHome();

                            if (store.get('greyAssessmentEnd')) {
                                $('#assessmentEndDate').prop('disabled', 'disabled');
                            }
                        }.bind(this)
                    });
                } else {
                    loaderContainer.spin(false);
                    evaluationContainer.html(HomeTemplate);

                    this.renderHome();
                }
            },

            /**
             * Load Report view.
             * @param e JavaScript event object.
             */
            loadReport: function (e) {
                var App = require('app/app'),
                    individualId = $(e.target).data('id');

                App.lightbox.show(new ReportView({
                    model: new ReportModel({
                        id: individualId,
                        scope: 'individual',
                        installationId: this.model.get('_id')
                    })
                }));
            },

            /**
             * Save the homepage.
             * @param callback Callback to call once saved.
             */
            saveHome: function (callback) {
                function dateTest(value) {
                    var regex = new RegExp(/[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/);

                    return regex.test(value) || value == "";
                }

                function emailTest(value) {
                    return (value.indexOf("@") > -1 && value.indexOf(".") > -1);
                }

                var emailInput = $('input#emailAddress'),
                    emailError = $('#emailError'),
                    emailChangeFunc = function (e) {
                        var elem = $(e.target);

                        if (elem.val() != "") {
                            emailError.hide();
                        } else {
                            elem.one('change', emailChangeFunc);
                        }
                    }.bind(this),
                    dateChangeFunc = function (e) {
                        var elem = $(e.target);

                        if (dateTest(elem.val())) {
                            dateError.hide();
                        } else {
                            elem.one('change', dateChangeFunc);
                        }
                    }.bind(this),
                    emailValue = emailInput.val(),
                    dateError = $('#dateError'),
                    startDateInput = $('input#assessmentStartDate'),
                    startDateValue = startDateInput.val(),
                    endDateInput = $('input#assessmentEndDate'),
                    endDateValue = endDateInput.val(),
                    emailErrorText = "",
                    dateErrorText = "";

                // Check for blanks
                if (emailValue == "") {
                    emailErrorText = "Please fill in your email address.";
                }

                // Pattern matching
                if (!emailTest(emailValue)) {
                    emailErrorText = "Your email address is invalid.";
                }
                if (!dateTest(startDateValue)) {
                    dateErrorText = "Your assessment start date is invalid.";
                }
                if (!dateTest(endDateValue)) {
                    if (dateErrorText == "") {
                        dateErrorText = "Your assessment end date is invalid.";
                    } else {
                        dateErrorText = "Your assessment start and end date are invalid.";
                    }
                }

                if (emailErrorText == "" && dateErrorText == "") {
                    // Passed!
                    if (callback) {
                        this.persist(callback.bind(this));
                    } else {
                        this.persist();
                    }
                } else {
                    if (emailErrorText != "") {
                        emailError.text(emailErrorText).show();
                        emailInput.one('change', emailChangeFunc);
                    }
                    if (dateErrorText != "") {
                        dateError.text(dateErrorText).show();
                        startDateInput.one('change', dateChangeFunc);
                        endDateInput.one('change', dateChangeFunc);
                    }
                }
            },

            /**
             * Render the elements for the home page.
             */
            renderHome: function() {
                $('.charthighlights > h3').text("Evaluation Form Home");

                $.each(this.model.attributes, function(key, value) {
                    switch (key) {
                        case "individuals":
                            // Do nothing.
                            break;

                        default:
                            $('input[name="' + key + '"]').val(value);
                            break;
                    }
                });

                $('#individuals').find('tbody').html(IndividualsTemplate({
                    ended: this.model.get('ended'),
                    individuals: this.model.get('individuals')
                }));

                // Register events
                if (!store.get('disableClose')) {
                    $('.exitform').click(this.closelightbox);
                }
                $('.openPreAssessment').click(function(e) {
                    this.loadPre(e);
                }.bind(this)).css('cursor', 'pointer');
                $('.openPostAssessment').click(function(e) {
                    this.loadPost(e);
                }.bind(this)).css('cursor', 'pointer');
                $('#add-new-individual').click(function(e) {
                    this.saveHome(function() {
                        this.renderForm('pre');
                    });
                }.bind(this));
                $('#save-and-close').click(function(e) {
                    this.saveHome(function() {
                        this.closelightbox();
                    });
                }.bind(this));
                $('.openReports').css('cursor', 'pointer').click(this.loadReport.bind(this));
                $('.deleteIndividual').click(function(e) {
                    var individualId = $(e.target).data('id');

                    // Load modal
                    $('#deleteOverlay').show();
                    $('#deleteModal').show();
                    $('#deleteIndividual').click(function(e) {
                        // Delete individual
                        var individuals = this.model.get('individuals'),
                            newIndividuals = [];
                        $.each(individuals, function(index, individual) {
                            if (individual.id != individualId) {
                                newIndividuals.push(individual);
                            }
                        });

                        this.model.set({
                            individuals: newIndividuals
                        });

                        this.persist(function() {
                            // Reload home
                            $('#deleteOverlay').hide();
                            $('#deleteModal').hide();
                            this.loadHome(true);
                        }.bind(this));

                        e.preventDefault();
                    }.bind(this));
                    $('#doNotDeleteIndividual').click(function(e) {
                        // Close modal
                        $('#deleteOverlay').hide();
                        $('#deleteModal').hide();

                        e.preventDefault();
                    }.bind(this));

                    e.preventDefault();
                }.bind(this));

                // Register form events
                $('#assessmentStartDate').keyup(function(e){
                    if (e && e.target && e.target.value) {
                        this.model.set('assessmentStartDate', e.target.value);
                    }
                }.bind(this));
                $('#assessmentEndDate').keyup(function(e){
                    if (e && e.target && e.target.value) {
                        this.model.set('assessmentEndDate', e.target.value);
                    }
                }.bind(this));
                $('#personCompletingAssessment').keyup(function(e){
                    if (e && e.target && e.target.value) {
                        this.model.set('personCompletingAssessment', e.target.value);
                    }
                }.bind(this));
                $('#emailAddress').keyup(function(e){
                    if (e && e.target && e.target.value) {
                        this.model.set('emailAddress', e.target.value);
                    }
                }.bind(this));
            },

            /**
             * Load the pre-assessment form.
             * @param e JavaScript Event object.
             */
            loadPre: function(e) {
                var individualId = $(e.target).data('id');

                this.renderForm('pre', individualId);
            },

            /**
             * Load the post-assessment form.
             * @param e JavaScript Event object.
             */
            loadPost: function(e) {
                var individualId = $(e.target).data('id');

                this.renderForm('post', individualId);
            },

            /**
             * Render the elements for a form.
             * @param formType "pre" or "post".
             * @param individualId ID of the individual to load (null for a blank form).
             */
            renderForm: function(formType, individualId) {
                // Show loader
                $('#lightboxcontainer').html(LightboxTemplate).show();
                $('.overlay').show();
                $('.loader-container').spin();

                if (!store.get('disableClose')) {
                    $('.exitform').click(this.closelightbox);
                } else {
                    $('.exitform').unbind().hide();
                }

                // Find individual
                var individual,
                    individuals = this.model.get('individuals');
                for(var i = 0; i < individuals.length; i++) {
                    individual = individuals[i];
                    if (individual.id == individualId) {
                        // This is the one.
                        break;
                    } else {
                        individual = null;
                    }
                }

                var $evaluationContainer = $('#evaluationcontainer'),
                    $charthighlights = $('.charthighlights > h3');
                switch (formType) {
                    case "pre":
                        $evaluationContainer.html(PreassessmentTemplate);
                        $charthighlights.text("Pre assessment form");
                        break;

                    case "post":
                        $evaluationContainer.html(PostassessmentTemplate);
                        $charthighlights.text("Post assessment form");

                        $.getScript('assets/js/starry/starry.js', function(){
                            this.starry = new Starry('#usefulnessOfService');
                            this.starry.init({
                                stars: 5,
                                multiple: true,
                                startValue: 0,
                                readOnly: false,
                                tooltips: false,
                                success: function(rating) {
                                    $('input[name=usefulnessOfService]').val(rating);
                                }
                            });

                            if(individual.postAssessmentData != null) {
                                this.starry.setRating(individual.postAssessmentData.usefulnessOfService);
                            }
                        }.bind(this));
                        break;

                    default:
                        // No form? What do I do?
                        alert('No form!');
                        return;
                }

                this.setupForm(formType, individual);
                this.setTriggers(formType, individual);
            },

            /**
             * Setup the form.
             * @param formType "pre" or "post".
             * @param individual Individual object (null for no individual).
             */
            setupForm: function(formType, individual) {
                /**
                 * Process the form input item.
                 * @param key   Form input name.
                 * @param value Value from the object.
                 * @param view  The view object.
                 */
                function process(key, value, view) {
                    if (value == null) {
                        return;
                    }

                    switch (key) {
                        case "referralTeam":
                        case "reasonsForInstalling":
                        case "supportedAchievements":
                            if (value.other) {
                                $('input[name="' + key + '-other"]').val(value.other);
                            }

                            if (!$.isArray(value)) {
                                value = value.options;
                            }

                            $.each(value, function (index, option) {
                                $('input[name="' + key + '"][value="' + option + '"]').prop('checked', true);
                            });
                            break;

                        // Auto-populated assessment start & end.
                        case "assessmentStartDate":
                        case "assessmentEndDate":
                            // Pre-populated from main data.
                            if (value == "") {
                                value = view.model.get(key);
                            }

                            $('input[name="' + key + '"]').val(value);
                            break;

                        // Star rating
                        //case "usefulnessOfService":
                        //    $('input[name="' + key + '"]').val(value).trigger('change');
                        //    break;

                        // Radio
                        case "currentNightTimeSupport":
                        case "revisedNightTimeSupport":
                            if (value.option != "") {
                                var $input = $('input[name="' + key + '"][value="' + value.option + '"]'),
                                    $hoursInput = $('#' + $input.data('input'));

                                $input.prop('checked', true);

                                $hoursInput.val(value.hoursPerWeek).prop('disabled', false);
                            }
                            break;

                        // Boolean (Yes/No)
                        case "assessingResidentialPlacement":
                        case "residentialCareRequired":
                            if (value == true) {
                                $('input[name="' + key + '"][value="Yes"]').prop('checked', true);
                            } else {
                                $('input[name="' + key + '"][value="No"]').prop('checked', true);
                            }
                            break;

                        // Anything else
                        default:
                            $('[name="' + key + '"]').val(value);
                            break;
                    }
                }

                switch (formType) {
                    case "pre":
                        if (individual != null) {
                            $.each(individual.preAssessmentData, function(key, value) {
                                process(key, value, this);
                            }.bind(this));
                        }
                        //process('assessmentStartDate', true, this);
                        break;

                    case "post":
                        if (individual != null && individual.postAssessmentData != null) {
                            $.each(individual.postAssessmentData, function(key, value) {
                                process(key, value, this);
                            }.bind(this));
                        }
                        if (individual.postAssessmentData == null) {
                            process('assessmentEndDate', "", this);
                        }
                        break;
                }
            },

            /**
             * Set the triggers for the form.
             * @param formType      "pre" or "post".
             * @param individual    Individual object (null for no individual).
             */
            setTriggers: function(formType, individual) {
                $('.toggleInput').change(function(e) {
                    var elem = $(this),
                        inputs = [
                            $('#sleepingNightHoursPerWeek'),
                            $('#wakingNightHoursPerWeek'),
                            $('#visitingNightHoursPerWeek')
                        ];

                    $.each(inputs, function(index, input) {
                        input.prop('disabled', 'disabled').val("");
                    });

                    switch(elem.val()) {
                        case "sleepingNight":
                        case "wakingNight":
                        case "visitingNight":
                            $('#' + elem.data('input')).prop('disabled', false);
                            break;
                    }
                });
                $('#goback').click(function(e) {
                    this.formCancel();

                    e.preventDefault();
                }.bind(this));
                $('#save').click(function(e) {
                    this.validate(formType, function() {
                        this.formSave(formType, individual);
                    }.bind(this));

                    e.preventDefault();
                }.bind(this));
            },

            /**
             * Handler for the "Go Back" button on the form.
             */
            formCancel: function() {
                this.loadHome(false);
                // TODO: Alert for changes that haven't been saved.
            },

            /**
             * Validate the form input.
             * @param formType  "pre" or "post"
             * @param callback  Callback to call if validation passes.
             */
            validate: function(formType, callback) {
                function validationFailed(element, message) {
                    var errorText = $('p.errorText[data-input="' + element.prop('name') + '"]'),
                        changeFunc = function(e){
                            var elem = $(e.target);

                            if (elem.val() != "") {
                                element.css('border-color', '#ccc');
                                errorText.hide();
                            } else {
                                elem.one('change', changeFunc);
                            }
                        }.bind(this);

                    element.css('border-color', 'red');
                    element.one('change', changeFunc);
                    errorText.text(message).show();
                }

                var rules = this.validationRules[formType],
                    predefinedRules = {
                        required: function(element) {
                            return element.val() != "";
                        },
                        hours: function(element) {
                            var value = element.val();
                            var int = parseInt(value);
                            var regex = new RegExp(/\d+(\.\d+)?/);

                            // Check if it is a number
                            if (!regex.test(value)) {
                                return false;
                            }

                            // Return whether its less than 400.
                            return int >= 0 && int < 400;
                        },
                        requireOne: function(elements) {
                            var oneSelected = false;
                            $.each(elements, function(index, element) {
                                if (element.checked) {
                                    oneSelected = true;
                                }
                            });

                            return oneSelected;
                        },
                        date: function(element) {
                            var value = element.val(),
                                regex = new RegExp(/[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/);

                            return regex.test(value);
                        }
                    },
                    validator,
                    hasErrors = false;

                if (typeof rules != "object") {
                    // No validation rules!
                    return console.error('There are no validation rules!');
                }

                $.each(rules, function(element, config){
                    element = $('[name="' + element + '"]');

                    if (config.message && typeof config.message == "string" && config.validator) {
                        // Singular validation
                        // Just 1 rule is defined to check.

                        if (typeof config.validator == "string") {
                            // Resolve string into a function.
                            config.validator = predefinedRules[config.validator];
                        }

                        if (!config.validator(element)) {
                            // Validation error on this field.
                            hasErrors = true;
                            if (typeof config.message == "function") {
                                config.message = config.message(element);
                            }
                            validationFailed(element, config.message);
                        }
                    } else {
                        // Multiple validation
                        // More than 1 rule is defined to check.

                        var failed = false;
                        $.each(config.messages, function(index, message) {
                            if (!failed) {
                                validator = config.validator[index];

                                if (typeof validator == "string") {
                                    // Resolve string into a function.
                                    validator = predefinedRules[validator];
                                }

                                if (!validator(element)) {
                                    // Validation error on this field.
                                    hasErrors = true;
                                    failed = true;
                                    if (typeof message == "function") {
                                        message = message(element);
                                    }
                                    validationFailed(element, message);
                                }
                            }
                        });
                    }
                });

                if (!hasErrors && callback) {
                    // Allow form submission
                    callback();
                }
            },

            /**
             * Called when "Save" is clicked on a form.
             * @param formType      "pre" or "post".
             * @param individual    Individual object (null for no individual).
             */
            formSave: function(formType, individual) {
                /**
                 * Get the selected item for an input.
                 * @param type  Input type ("radio" or "checkbox")
                 * @param key   Input key
                 * @returns {*|jQuery} Selected Input.
                 */
                function getChecked(type, key) {
                    return $('input[type=' + type + '][name="' + key + '"]').map(function() {
                        return {
                            key: $(this).prop('value'),
                            value: $(this).prop('checked')
                        };
                    }).get();
                }

                /**
                 * Save the form in the model.
                 * @param formType      "pre" or "post"
                 * @param individual    Individual object (null for no individual).
                 * @param callback      Callback to call when saved to model.
                 */
                function saveForm(formType, individual, callback) {
                    var form = {},
                        checked,
                        preQuestions = [
                            "serviceUserId",
                            "referralTeam",
                            "currentDayTimeCareHours",
                            "currentNightTimeSupport",
                            "assessingResidentialPlacement",
                            "reasonsForInstalling",
                            "preAssessmentComments"
                        ],
                        postQuestions = [
                            "assessmentEndDate",
                            "revisedDayTimeCareHours",
                            "revisedNightTimeSupport",
                            "residentialCareRequired",
                            "supportedAchievements",
                            "usefulnessOfService",
                            "postAssessmentComments"
                        ];

                    if (formType == "pre") {
                        $.each(preQuestions, function (index, key) {
                            var newValue = {
                                options: []
                            };

                            switch (key) {
                                case "referralTeam":
                                case "reasonsForInstalling":
                                    checked = getChecked("checkbox", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue.options.push(value['key']);
                                        }
                                    });

                                    newValue['other'] = $('input[type=text][name="' + key + '-other"]').val();
                                    break;

                                case "currentNightTimeSupport":
                                    checked = getChecked("radio", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue = value['key'];
                                        }
                                    });

                                    newValue = {
                                        option: newValue
                                    };

                                    switch(newValue.option) {
                                        case "sleepingNight":
                                            newValue['hoursPerWeek'] = $('input#sleepingNightHoursPerWeek').val();
                                            break;

                                        case "wakingNight":
                                            newValue['hoursPerWeek'] = $('input#wakingNightHoursPerWeek').val();
                                            break;

                                        case "visitingNight":
                                            newValue['hoursPerWeek'] = $('input#visitingNightHoursPerWeek').val();
                                            break;

                                        default:
                                            newValue['hoursPerWeek'] = '';
                                            break;
                                    }
                                    break;

                                case "assessingResidentialPlacement":
                                    checked = getChecked("radio", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue = value['key'] == "Yes";
                                        }
                                    });
                                    break;

                                //case "reasonsForInstalling":
                                //    newValue = [];
                                //
                                //    checked = $('input[type=checkbox][name="' + key + '"]').map(function() {
                                //        return {
                                //            key: $(this).prop('value'),
                                //            value: $(this).prop('checked')
                                //        };
                                //    }).get();
                                //    $.each(checked, function(key, value){
                                //        if (value['value'] == true) {
                                //            newValue.push(value['key']);
                                //        }
                                //    });
                                //    break;

                                default:
                                    newValue = $('[name=' + key + ']').val();
                                    break;
                            }

                            form[key] = newValue;
                        }.bind(this));
                    } else if(formType == "post") {
                        $.each(postQuestions, function (index, key) {
                            var newValue = {
                                options: []
                            };

                            switch (key) {
                                case "revisedNightTimeSupport":
                                    checked = getChecked("radio", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue = value['key'];
                                        }
                                    });

                                    newValue = {
                                        option: newValue
                                    };

                                    switch(newValue.option) {
                                        case "sleepingNight":
                                            newValue['hoursPerWeek'] = $('input#sleepingNightHoursPerWeek').val();
                                            break;

                                        case "wakingNight":
                                            newValue['hoursPerWeek'] = $('input#wakingNightHoursPerWeek').val();
                                            break;

                                        case "visitingNight":
                                            newValue['hoursPerWeek'] = $('input#visitingNightHoursPerWeek').val();
                                            break;

                                        default:
                                            newValue['hoursPerWeek'] = '';
                                            break;
                                    }
                                    break;

                                case "residentialCareRequired":
                                    checked = getChecked("radio", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue = value['key'] == "Yes";
                                        }
                                    });
                                    break;

                                case "supportedAchievements":
                                    //newValue = [];
                                    //
                                    //checked = $('input[type=checkbox][name="' + key + '"]').map(function() {
                                    //    return {
                                    //        key: $(this).prop('value'),
                                    //        value: $(this).prop('checked')
                                    //    };
                                    //}).get();
                                    //$.each(checked, function(key, value){
                                    //    if (value['value'] == true) {
                                    //        newValue.push(value['key']);
                                    //    }
                                    //});
                                    //break;

                                    checked = getChecked("checkbox", key);
                                    $.each(checked, function(key, value){
                                        if (value['value'] == true) {
                                            newValue.options.push(value['key']);
                                        }
                                    });

                                    newValue['other'] = $('input[type=text][name="' + key + '-other"]').val();
                                    break;

                                default:
                                    newValue = $('[name=' + key + ']').val();
                                    break;
                            }

                            form[key] = newValue;
                        }.bind(this));
                    }

                    if (callback) {
                        callback(formType, form, individual);
                    }
                }

                /**
                 * Store form in model.
                 * @param formType      "pre" or "post"
                 * @param form          Form object.
                 * @param individual    Individual object.
                 */
                function storeFormLocally(formType, form, individual) {
                    var individuals = this.model.get('individuals'),
                        oldIndividuals = [];

                    $.each(individuals, function(index, individual){
                        oldIndividuals.push(individual);
                    });

                    switch(formType) {
                        case "pre":
                            if (individual != null) {
                                // Update existing.
                                // Update Individual passed in.
                                individual.preAssessmentStatus = true;
                                individual.preAssessmentData = form;
                                individual.name = individual.preAssessmentData.serviceUserId;

                                // Set the new individual on the model.
                                $.each(individuals, function(index, oldIndividual){
                                    if (oldIndividual.id == individual.id) {
                                        // Its this one! Update it.
                                        individuals[index] = individual;
                                    }
                                }.bind(this));
                            } else {
                                // Create new.
                                individual = {
                                    id: parseInt(store.get('fakeId')) * -1,
                                    preAssessmentStatus: true,
                                    postAssessmentStatus: false,
                                    evaluationReport: false,
                                    preAssessmentData: form,
                                    postAssessmentData: null
                                };
                                individual.name = individual.preAssessmentData.serviceUserId;

                                individuals.push(individual);
                            }
                            break;

                        case "post":
                            if (individual != null) {
                                // Update existing.
                                // Update Individual passed in.
                                individual.postAssessmentStatus = true;
                                individual.postAssessmentData = form;

                                // Set the new individual on the model.
                                $.each(individuals, function(index, oldIndividual){
                                    if (oldIndividual.id == individual.id) {
                                        // Its this one! Update it.
                                        individuals[index] = individual;
                                    }
                                }.bind(this));
                            } else {
                                // Create new.
                                individual = {
                                    id: parseInt(store.get('fakeId')) * -1,
                                    preAssessmentStatus: false,
                                    postAssessmentStatus: true,
                                    evaluationReport: false,
                                    preAssessmentData: null,
                                    postAssessmentData: form
                                };

                                individuals.push(individual);
                            }
                            break;
                    }

                    this.model.set({
                        individuals: individuals
                    });

					store.set('fakeId', parseInt(store.get('fakeId')) + 1);

                    this.persist(function() {
                        this.loadHome(true);
                    }.bind(this), function() {
                        this.model.set({
                            individuals: oldIndividuals
                        });
                    }.bind(this));
                }

                // Update Model with form.
                saveForm(formType, individual, storeFormLocally.bind(this));
            },

            /**
             * Persist the model.
             * @param successCallback   Callback to call if successful.
             * @param errorCallback     Callback to call if unsuccessful.
             */
            persist: function (successCallback, errorCallback) {
                Crudder.creatingUpdating({
                    entity: this.model,
                    successMessage: "Evaluation forms saved successfully.",
                    errorMessage: "Unable to save evaluation forms."
                }, function(response) {
                    if (response.success && successCallback) {
                        successCallback(response);
                    } else if(!response.success && errorCallback) {
                        errorCallback(response);
                    }
                });
            }

		});

});

