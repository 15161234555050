define('app/modules/newcharts/features/events/collections/sensors.collection',[
	'backbone',
	'app/modules/newcharts/features/events/models/sensor.model'
], function(Backbone, Model) {
	'use strict';

	return Backbone.Collection.extend({

		model: Model
	});

});
