/**
Chart Application - Timeline Model
@module Chart
@submodule Chart.Models.Timeline
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Timeline Model
    * defaults
Author(s):
  * Just Checking
*/

/*
Example Data:
  "axisData": "2014-01-07 00:00:00",
  "axisLabel": "2014-01-07 00:00:00",
  "timeOfDay": "Midnight"
*/

define('app/modules/charts/models/charts.models.timeline.item',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Timeline Model
  @class Chart.Models.Timeline
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property defaults
    @type Object
    */
    defaults: {

      axisLabel: undefined

    }

  });

});
