/**
Account Application - Controller
@module Account
@submodule Account.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Account App Controller
    * initialize
      * Events
      * Fetch
      * Show
    * getFormView
      * Events
    * saveChanges
Author(s):
  * Just Checking
*/

define('app/modules/account/controllers/account.controllers.form',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/account/models/users.models.user',
  'app/modules/account/views/account.views.form',
  'backbone.validation',
  'backbone.crudder'

], function(Marionette, Commands, Requests, Model, FormView, Validation, Crudder) {

  'use strict';

  /**
  Account Controller
  @class Account.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

      var
      that = this,
      App = require('app/app');

      // Define our system model
      this.model = new Model();

      // Define the main layout
      this.view = this.getFormView();

      // Define the main region
      this.region = App.main.currentView.content;

      /*
      Events
      */

      // Listen for the main layout's 'show' event
      this.view.on('show', function() {

        // Update the topbar title with the chart title
        Commands.execute('update:topbar', {

          title: {

            text: 'My Account'

          }

        });

        /*
        Header view
        */

        // Show the section header
        that.showHeader({

          title: 'My Account'

        });

      });


       // TEMP
      this.model.set({

        userId: 0,
        emailAddress: ""

      },
      {

        silent: true

      });

      /*
      Fetch
      */

      // Fetch the model data
      this.model.fetch({

         userId: 0

      });


      // TEMP

      /*
      Show
      */

      // Call the controller 'show' method
      this.show({

        loader: true,
        debug: false

      });
    },

    /**
    @method getFormView
    */
    getFormView: function() {

      var
      formView = new FormView({

        model: this.model

      }),
      formWrapperView = Requests.request('get:form:wrapper', formView);

      /*
      Events
      */

      // Listen for the view render event
      formView.on('render', function() {

        // Bind validation to the form view
        Backbone.Validation.bind(this);
        Commands.execute('set:change:true', {view:this});

      });

      // Listen for the view render event
      formView.on('save:account', this.saveChanges);

      return formWrapperView;

    },

    /**
    @method saveChanges
    */
    saveChanges: function(callback) {

      var that = this;

      // Save this model via crudder
      Crudder.creatingUpdating({

        entity: this.model,
        patch: false,
        successMessage: 'Your details have been saved',
        errorMessage: 'Unable to save'

      }, function(response) {

        // Has the request been successful?
        if (response.success) {

          // Set the passwords back to nowt
          that.model.set({

            password: '',
            passwordConfirm: ''

          });

        }
        else {

          console.log('update failed');

        }

        // Callback for unit test
        if (callback) { callback(response.success); }

      });

    }

	});

});

