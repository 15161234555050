/**
Base Marionette Composite View
@module Base
@submodule Base.Marionette.CompositeView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  *
Author(s):
  * Just Checking
*/

define('app/base/views/marionette/base.views.marionette.compositeView',[

  'marionette'

], function(Marionette) {

  'use strict';

  return _.extend(Marionette.CompositeView.prototype, {

	/**
    @property isEmpty
    @type Function
    */
    isEmpty: function(collection) {

      var isEmpty = false;

      // Is the collection empty?
      if (collection.length === 0) {

        isEmpty = true;

        // Remove the table
        this.$el.find('.view-container').remove();

        // Set a new item view container
        this.itemViewContainer = '#empty-view-container';

      }
      else {

        // Remove the empty view container
        this.$el.find('#empty-view-container').remove();

      }

      return isEmpty;

    }

	});

});
