/**
Application Requests - Get Form Change Status
@module Requests
@submodule Requests.GetChangeStatus
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get form change status
Author(s):
  * Just Checking
*/

define('app/requests/requests.getChangeStatus',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get form change status
			@event get:change:status
			@return {Boolean} Has a form change been recorded?
			*/
			return Requests.setHandler('get:change:status', function() {

				var App = require('app/app');
				
				return App.state.changeDetected;

			});

    }

  };

});

