/**
Systems Installations Collection
@module Systems
@submodule Systems.Collections.Installations
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Systems Installations Collection
    * model
Author(s):
  * Just Checking  
*/

define('app/modules/systems/collections/systems.collections.installations',[

  'backbone',
  'app/modules/systems/models/systems.models.installation'

], function(Backbone, Model) {

  'use strict';

  /**
  Systems Installations Collection
  @class Systems.Collections.Installations
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property model
    @type Object
    */
    model: Model,

    /**
    @method comparator
    */ 
    comparator: function(model) {

      // Order the installations by 'installationNumber' DESC
      return -model.get('installationNumber');

    }

  });

});
