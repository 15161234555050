define('app/modules/newcharts/layouts/body.layout',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'app/commands/commands.vent',
    'tpl!app/modules/newcharts/templates/body.template.tmpl',
    'd3',
    'moment',
    'app/modules/newcharts/views/main-menu.view',
    'app/modules/newcharts/views/chart-menu.view',
    'app/modules/newcharts/views/chart.view',
    'app/modules/newcharts/collections/chart-rows.collection',
    'app/modules/newcharts/views/multi-menu.view',
    'app/modules/newcharts/views/multi.view',
    'app/modules/newcharts/layouts/multi.layout',
    'app/modules/newcharts/layouts/bathroom.layout',
    'app/modules/newcharts/views/bathroom-menu.view',
    'app/modules/newcharts/models/bathroom.model',
    'app/modules/newcharts/state',
    'app/tracking',

	// Global Spinner View
	'app/modules/newcharts/views/spinner.view',

	// Non-Occupancy
    'app/modules/newcharts/features/doorActivity/models/nonOccupancy.model',
    'app/modules/newcharts/features/doorActivity/views/nonOccupancy-menu.view',
    'app/modules/newcharts/features/doorActivity/layouts/nonOccupancy.layout',

	// Door Activity
    'app/modules/newcharts/features/doorActivity/models/doorActivity.model',
    'app/modules/newcharts/features/doorActivity/views/doorActivity-menu.view',
    'app/modules/newcharts/features/doorActivity/layouts/doorActivity.layout',

	// Feature Preview
	'app/modules/newcharts/models/feature-preview.model',
	'backbone.crudder',

	// Notifications
	'app/modules/newcharts/features/notifications/collections/notifications.collection',
	'app/modules/newcharts/features/notifications/views/notifications.view',
	'app/modules/newcharts/features/notifications/views/notification-form.view',
	'app/modules/newcharts/features/notifications/views/notification-menu.view',
	'app/modules/newcharts/features/notifications/collections/notification-history.collection',
	'app/modules/newcharts/features/notifications/models/notification-history.model',
	'app/modules/newcharts/features/notifications/views/notification-history.view',
	'app/modules/newcharts/features/notifications/views/notification-history-menu.view',

	// Notifications Family
	'app/modules/newcharts/features/notifications_family/collections/notifications.collection',
	'app/modules/newcharts/features/notifications_family/views/notifications.view',
	'app/modules/newcharts/features/notifications_family/views/notification-form.view',
	'app/modules/newcharts/features/notifications_family/views/notification-menu.view',
	'app/modules/newcharts/features/notifications_family/collections/notification-history.collection',
	'app/modules/newcharts/features/notifications_family/models/notification-history.model',
	'app/modules/newcharts/features/notifications_family/views/notification-history.view',
	'app/modules/newcharts/features/notifications_family/views/notification-history-menu.view',

	// Moonshot
	'app/modules/newcharts/features/moonshot/models/moonshot.model',
	'app/modules/newcharts/features/moonshot/views/moonshot.view',

	// Events
	'app/modules/newcharts/features/events/collections/events.collection',
	'app/modules/newcharts/features/events/views/events.view',
	'app/modules/newcharts/features/events/views/event-form.view',
	'app/modules/newcharts/features/events/collections/sensors.collection',
	'app/modules/newcharts/features/events/views/event-chart.view',

	// Carer Visits
	'app/modules/newcharts/features/carerVisits/collections/carerVisits.collection',
	'app/modules/newcharts/features/carerVisits/views/carerVisits.view',
	'app/modules/newcharts/features/carerVisits/models/carerVisits.model',

    // Heat & Light
    'app/modules/newcharts/features/heatLight/models/heatLight.model',
    'app/modules/newcharts/features/heatLight/models/heatLightSettings.model',
    'app/modules/newcharts/features/heatLight/views/heatLight.view',

], function(
    Marionette,
    Vent,
    Requests,
    Commands,
    Template,
    D3,
    Moment,
    MainMenuView,
    ChartMenu,
    ChartView,
    RowCollection,
    MultiMenu,
    MultiView,
    MultiLayout,
    BathroomLayout,
    BathroomMenu,
    BathroomModel,
    State,
    Tracking,

	Spinner,

    NonOccupancyModel,
    NonOccupancyMenu,
    NonOccupancyLayout,

    DoorActivityModel,
    DoorActivityMenu,
    DoorActivityLayout,

	FeaturePreviewModel,
	Crudder,

	NotificationsCollection,
	NotificationsView,
	NotificationForm,
	NotificationListMenu,
	NotificationHistory,
	NotificationHistoryMeta,
	NotificationHistoryView,
	NotificationHistoryMenu,

	NotificationsCollectionFamily,
	NotificationsViewFamily,
	NotificationFormFamily,
	NotificationListMenuFamily,
	NotificationHistoryFamily,
	NotificationHistoryMetaFamily,
	NotificationHistoryViewFamily,
	NotificationHistoryMenuFamily,

	MoonshotModel,
	MoonshotView,

	EventsCollection,
	EventsView,
	EventForm,
	EventSensorsCollection,
	EventChart,

	CarerVisitsCollection,
	CarerVisitsView,
	CarerVisitsMeta,

    HeatLightModel,
    HeatLightSettingsModel,
    HeatView
) {
    'use strict';

    return Marionette.Layout.extend({

        template: Template,

        regions: {
            leftMenu: '#left-menu',
            rightMenu: '#right-menu',
            body: '#body'
        },

		templateHelpers: {
			doorActivityPreview: function() {
				return this.chartMenu.doorActivityPreview != null && new Moment(this.chartMenu.doorActivityPreview).isAfter(new Moment());
			},

			doorActivityPreviewDays: function() {
				var date = moment.utc(this.chartMenu.doorActivityPreview).local(),
					days = date.diff(new Moment(), 'days'),
					text = 'days';

				if (days == 1) {
					text = 'day';
				} else if(days == 0) {
					text = '<span class="emphasis">until ' + date.format('ha') + '</span>';
					return text;
				}

                return 'for the next <span class="emphasis">' + days + ' ' + text + '</span>';
			},

			notificationsPreview: function() {
				return this.chartMenu.notificationsPreview != null && new Moment(this.chartMenu.notificationsPreview).isAfter(new Moment());
			},

			notificationsPreviewDays: function() {
				var date = moment.utc(this.chartMenu.notificationsPreview).local(),
					days = date.diff(new Moment(), 'days'),
					text = 'days';

				if (days == 1) {
					text = 'day';
				} else if(days == 0) {
					text = '<span class="emphasis">until ' + date.format('ha') + '</span>';
					return text;
				}

				return 'for the next <span class="emphasis">' + days + ' ' + text + '</span>';
			}
		},

		notificationsHistoryMetaModel: null,

		carerVisitsMetaModel: null,

        initialize: function() {
            this.render();

            Vent.on('newchart:datepicker', function(options) {
                var newDate = options.value,
                    oldDate = new Moment(this.model.get('chartMeta').chartDate);

                newDate = new Moment(newDate.format('YYYY-MM-DD') + ' 00:00');

                this.chartNavigate({
                    date: newDate,
                    zoom: 120
                });
            }.bind(this));
            Vent.on('newchart:multi-change-dates', function(options) {
                var $switchNightView = $('#switchNightView');

                if ($switchNightView.is(':checked')) {
                    options.from.add(12, 'hours');
                    options.to.add(12, 'hours');
                }

                var noteBoxes = this.$el.find('.notebox'),
                    chart,
                    totalCharts = noteBoxes.length,
                    successfulCharts = 0;

                if (this.model.get('chartMenu').canAccessNote) {
                    for (var i = 0; i < noteBoxes.length; i++) {
                        chart = noteBoxes[i].id.replace('notebox-', '');

                        Vent.trigger('newchart:multi-check-note-' + chart, function () {
                            successfulCharts++;

                            if (totalCharts == successfulCharts) {
                                this.model.set('startDate', new Moment(options.from));
                                this.model.set('endDate', new Moment(options.to));

                                this.body.show(new MultiLayout({
                                    model: this.model,
                                    dates: _.extend({
                                        zoom: 120 // Reset Zoom
                                    }, options),
                                    installId: this.model.get('chartMeta').installationId,
                                    nightView: $switchNightView.is(':checked')
                                }));

                                State.save(this.model);
                            }
                        }.bind(this));
                    }
                } else {
                    this.model.set('startDate', new Moment(options.from));
                    this.model.set('endDate', new Moment(options.to));

                    this.body.show(new MultiLayout({
                        model: this.model,
                        dates: _.extend({
                            zoom: 120 // Reset Zoom
                        }, options),
                        installId: this.model.get('chartMeta').installationId,
                        nightView: $switchNightView.is(':checked')
                    }));

                    State.save(this.model);
                }
            }.bind(this));
			Vent.on('newchart:notification-edit', function(options) {
				setTimeout(function() {
					try {
						this.body.show(new NotificationForm(options));
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);
			Vent.on('newchart:notification-edit-family', function(options) {
				setTimeout(function() {
					try {
						this.body.show(new NotificationFormFamily(options));
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);
			Vent.on('newchart:notification-list', function() {
				setTimeout(function() {
					this.leftMenu.close();
					this.onBeforeShow();
				}.bind(this));
			}, this);
			Vent.on('newchart:notification-history', function(options) {
				setTimeout(function() {
					try {
						this.loadNotificationHistory(options.offset, options.limit, options.sort, options.dir);
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);

			Vent.on('newchart:carer-visits', function(options) {
				setTimeout(function() {
					try {
						this.loadCarerVisits(options.offset, options.limit, options.sort, options.dir);
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);

			Vent.on('newchart:event-form', function(options) {
				setTimeout(function() {
					try {
						var items = options.model.get('sensors');
						items = _.filter(items, function(item) {
							return item['order'] != 99;
						});
						items = _.sortBy(items, function(item) {
							return item['order'];
						});

						if (items.length == 0) {
							items.push({
								label: null,
								order: 0
							});
						}

						options.collection = new EventSensorsCollection(items);
						this.body.show(new EventForm(options));
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);
			Vent.on('newchart:event-list', function(options) {
				setTimeout(function() {
					try {
						this.onBeforeShow();
					} catch(e) {
						// Do nothing.
						console.error(e);
					}
				}.bind(this));
			}, this);
			Vent.on('newchart:event-chart', function(options) {
				setTimeout(function() {
					try {
						this.body.show(new EventChart(options));
					} catch(e) {
						console.error(e);
					}
				}.bind(this));
			}, this);
			Vent.on('newchart:heatLight-reload', function(options) {
                setTimeout(function() {
                    try {
                        this.renderHeatLight(options.model);
                    } catch(e) {
                        // Do nothing.
                        console.error(e);
                    }
                }.bind(this));
            }, this);
        },

        onBeforeShow: function() {
            this.model.set('multi', false);
            var model;

            switch(this.model.get('type')) {
                case 'multi':
                	var slots = 14;
                	if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                	    slots = 70;
                	}

                    this.model.set('multi', true);

                    if (!this.model.get('startDate') && !this.model.get('endDate')) {
						var startDate = new Moment(this.model.get('chartMeta').installationStart).startOf('day'),
							endDate = this.model.get('chartMeta').installationEnd,
							now = moment();

						if (endDate == null) {
							endDate = new Moment().startOf('day');
						} else {
							endDate = new Moment(endDate).startOf('day');
						}

						if (new Moment(endDate).subtract(slots-1, 'days').isBefore(startDate)) {
							this.model.set('startDate', startDate);
						} else {
							if (new Moment(endDate).subtract(slots-1, 'days').isAfter(now)) {
								this.model.set('startDate', now.subtract(slots-1, 'days').startOf('day'));
							} else {
								this.model.set('startDate', new Moment(endDate).subtract(slots-1, 'days'));
							}
						}

						if (endDate.isAfter(new Moment())) {
							this.model.set('endDate', new Moment().startOf('day'));
						} else {
							this.model.set('endDate', endDate);
						}
                    }

                    this.leftMenu.show(new MultiMenu({
                        model: this.model,
                        dates: {
                            from: this.model.get('startDate'),
                            to: this.model.get('endDate')
                        }
                    }));
                    this.body.show(new MultiLayout({
                        model: this.model,
                        dates: {
                            from: this.model.get('startDate'),
                            to: this.model.get('endDate')
                        },
                        zoom: this.model.get('chartMeta').zoom,
                        installId: this.model.get('chartMeta').installationId
                    }));
                    break;

                case 'bathroom':
                    Tracking.track('openBathroom', this.model.get('chartMeta').installationId);
                    model = new BathroomModel({
                        _id: this.model.id,
                        type: 'bathroom'
                    });
                    model.set(State.load(model));
                    model.fetch({
                        success: function() {
                            this.leftMenu.show(new BathroomMenu({
                                model: model
                            }));
                            this.body.show(new BathroomLayout({
                                model: model
                            }));
                        }.bind(this)
                    });
                    break;

                case 'nonOccupancy':
                    if (!this.model.get('chartMeta').nonOccupancy) {
                        // They don't have nonOccupancy enabled, let's redirect them to doorActivity.
                        setTimeout(function() {
                            Commands.trigger('app:navigate', {
                                route: Backbone.history.getFragment().replace('nonOccupancy', 'doorActivity')
                            });
                        }.bind(this));
                        return false;
                    }

                    Tracking.track('openNonOccupancy', this.model.get('chartMeta').installationId);
                    model = new NonOccupancyModel({
                        _id: this.model.id,
                        type: 'nonOccupancy',
                        nonOccupancy: this.model.get('chartMeta').nonOccupancy == true
                    });
                    model.set(State.load(model));
                    model.fetch({
                        success: function() {
                            this.leftMenu.show(new NonOccupancyMenu({
                                model: model
                            }));
                            this.body.show(new NonOccupancyLayout({
                                model: model
                            }));
                        }.bind(this)
                    });
                    break;

                case 'doorActivity':
                    Tracking.track('openDoorActivity', this.model.get('chartMeta').installationId);
                    model = new DoorActivityModel({
                        _id: this.model.id,
                        type: 'doorActivity',
                        nonOccupancy: this.model.get('chartMeta').nonOccupancy == true
                    });
                    model.set(State.load(model));
                    model.fetch({
                        success: function() {
                            this.leftMenu.show(new DoorActivityMenu({
                                model: model
                            }));
                            this.body.show(new DoorActivityLayout({
                                model: model
                            }));
                        }.bind(this)
                    });
                    break;

				case 'notifications':
					Tracking.track('openNotifications', this.model.get('chartMeta').installationId);

					this.notificationsHistoryMetaModel = new NotificationHistoryMeta({
						_id: this.model.get('chartMeta').installationId
					});
					var collection = new NotificationsCollection({
						_id: this.model.id,
						type: 'notifications'
					});
					this.body.show(new Spinner());
					collection.fetch({
						success: function() {
							this.leftMenu.show(new NotificationListMenu());
							this.body.show(new NotificationsView({
								collection: collection
							}))
						}.bind(this)
					});
					break;

				case 'notifications_family':
					Tracking.track('openNotificationsFamily', this.model.get('chartMeta').installationId);

					this.notificationsHistoryMetaModel = new NotificationHistoryMetaFamily({
						_id: this.model.get('chartMeta').installationId
					});
					var collection = new NotificationsCollectionFamily({
						_id: this.model.id,
						type: 'notifications_family'
					});
					this.body.show(new Spinner());
					collection.fetch({
						success: function() {
							this.leftMenu.show(new NotificationListMenuFamily());
							this.body.show(new NotificationsViewFamily({
								collection: collection
							}))
						}.bind(this)
					});
					break;

				case 'postassessment':
					model = new MoonshotModel({
						_id: this.model.id,
						type: 'postassessment'
					});
					model.fetch({
						success: function() {
							this.body.show(new MoonshotView({
								model: model
							}));
						}.bind(this)
					});
					break;

				case 'events':
					Tracking.track('openEvents', this.model.get('chartMeta').installationId);

					// this.notificationsHistoryMetaModel = new NotificationHistoryMeta({
					// 	_id: this.model.get('chartMeta').installationId
					// });
					var collection = new EventsCollection({
						_id: this.model.id,
						type: 'events'
					});
					this.body.show(new Spinner());
					collection.fetch({
						success: function() {
							// this.leftMenu.show(new NotificationListMenu());
							this.body.show(new EventsView({
								collection: collection,
								install_id: this.model.id
							}))
						}.bind(this)
					});
					break;

				case 'carerVisits':
					Tracking.track('carerVisits', this.model.get('chartMeta').installationId);

					this.carerVisitsMetaModel = new CarerVisitsMeta({
						_id: this.model.get('chartMeta').installationId
					});
					this.loadCarerVisits();
					break;

                case 'heatLight':
                    this.renderHeatLight();
                    break;

                default:
                    this.leftMenu.show(new ChartMenu({
                        model: this.model
                    }));
                    this.body.show(new ChartView({
                        model: this.model,
                        collection: this.getChartRows()
                    }));

                    Vent.on('newchart:navigate', this.chartNavigate.bind(this));
                    break;
            }

            if (this.model.get('type') !== 'postassessment' && this.model.get('type') !== 'carerVisits' && this.model.get('type') !== 'heatLight') {
				this.rightMenu.show(new MainMenuView({
					model: this.model
				}));
			}
        },

		onShow: function() {
			if (this.model.get('type') == 'nonOccupancy' || this.model.get('type') == 'doorActivity' || this.model.get('type') == 'notifications') {
				$.getScript('assets/js/starry/starry.js', function(){
					this.starry = new Starry('#rating');
					this.starry.init({
						stars: 5,
						multiple: true,
						startValue: 0,
						readOnly: false,
						tooltips: false,
						success: function(rating) {
							$('input[name=rating]').val(rating);
						}
					});

					var $featurePreviewBtn = $('#featurePreviewBtn');
					$featurePreviewBtn.click(function(e) {
						e.preventDefault();

						var container = $('.featurePreview'),
							parentModel = this.model,
							model = new FeaturePreviewModel({
								_id: parentModel.id
							}),
							$comments = container.find('#additional-comments').val();

						container.find('.error').hide();

						if (!$comments || $comments == '') {
							container.find('.error').show();
							return;
						}

						container.find('button').attr('disabled', 'disabled');

						model.set('feature', this.model.get('type'));
						model.set('rating', container.find('form').find('input[name=rating]').val());
                        model.set('comments', $comments);

						Crudder.creatingUpdating({
							entity: model,
							patch: false,
							successMessage: 'Your feedback has been saved',
							errorMessage: 'Unable to save'
						}, function(response) {
							// Has the request been successful?
							if (response.success) {
								this.starry.setRating(0);
								container.find('#additional-comments').val('');
							}

							container.find('button').removeAttr('disabled');
						}.bind(this));
					}.bind(this));
					$('.featurePreview').slideDown(500);
				}.bind(this));
			}
		},

        chartNavigate: function(options) {
            Vent.off('newchart:navigate');

            if (!options.zoom) {
                options.zoom = 120;
            }

            this.model.fetch({
                data: {
                    date: options.date.format('YYYY-MM-DDTHH:mm:ss'),
                    zoom: options.zoom,
                    minimal: 1
                },
                success: function() {
                    State.save(this.model);

                    this.onBeforeShow();

                    if (options.note) {
                        // Rearm the note!
                        setTimeout(function() {
                            $('#note-subject').val(options.note.subject);
                            $('#note-message').val(options.note.message);
                        });
                    }
                }.bind(this)
            });
        },

		loadNotificationHistory: function(offset, limit, sort, dir) {
			this.leftMenu.close();
			this.body.show(new Spinner());

			// Fetch count and pages
			if (offset) {
				this.notificationsHistoryMetaModel.set('offset', offset);
			}
			if (limit) {
				this.notificationsHistoryMetaModel.set('limit', limit);
			}
			if (sort) {
				this.notificationsHistoryMetaModel.set('sort', sort);
			}
			if (dir) {
				this.notificationsHistoryMetaModel.set('dir', dir);
			}
			this.notificationsHistoryMetaModel.fetch({
				success: function() {
					// Fetch rows
					var collection = new NotificationHistory({
						_id: this.model.get('chartMeta').installationId
					});
					collection.fetch({
						data: {
							offset: this.notificationsHistoryMetaModel.get('offset'),
							limit: this.notificationsHistoryMetaModel.get('limit'),
							sort: this.notificationsHistoryMetaModel.get('sort'),
							dir: this.notificationsHistoryMetaModel.get('dir')
						},
						success: function() {
							this.leftMenu.show(new NotificationHistoryMenu({
								model: this.notificationsHistoryMetaModel
							}));
							this.body.show(new NotificationHistoryView({
								collection: collection,
								model: this.notificationsHistoryMetaModel
							}));
						}.bind(this)
					});
				}.bind(this)
			});
		},

		loadCarerVisits: function(offset, limit, sort, dir) {
			this.body.show(new Spinner());

			// Fetch count and pages
			if (!isNaN(offset)) {
				this.carerVisitsMetaModel.set('offset', offset);
			}
			if (limit) {
				this.carerVisitsMetaModel.set('limit', limit);
			}
			if (sort) {
				this.carerVisitsMetaModel.set('sort', sort);
			}
			if (dir) {
				this.carerVisitsMetaModel.set('dir', dir);
			}
			this.carerVisitsMetaModel.fetch({
				success: function() {
					// Fetch rows
					var collection = new CarerVisitsCollection({
						_id: this.model.get('chartMeta').installationId
					});
					collection.fetch({
						data: {
							offset: this.carerVisitsMetaModel.get('offset'),
							limit: this.carerVisitsMetaModel.get('limit'),
							sort: this.carerVisitsMetaModel.get('sort'),
							dir: this.carerVisitsMetaModel.get('dir')
						},
						success: function() {
							this.body.show(new CarerVisitsView({
								collection: collection,
								model: this.carerVisitsMetaModel
							}));
						}.bind(this)
					});
				}.bind(this)
			});
		},

		getChartRows: function() {
			var rows = this.model.get('chartRows'),
				accelerometers = this.model.get('chartRows').clone(),
				filterRows = rows.filter(function(row) {
					return row.get('chartType') != 'accelerometer';
				}),
				filterAccelerometers = accelerometers.filter(function(row) {
					return row.get('chartType') == 'accelerometer' && row.get('parent') != null;
				});

			rows.reset(filterRows);
			accelerometers.reset(filterAccelerometers);

			// Make a new collection with child accelerometers in the right places.
			var newRows = new RowCollection(), temp;
			rows.each(function(row) {
				newRows.add(row);

				if (
					temp = accelerometers.where({
						parent: row.get('chartLineId')
					})
				) {
					_.each(temp, function(item) {
						newRows.add(item);
					});
				}
			});

			return newRows;
		},

        renderHeatLight: function(model) {
            var settingsModel = new HeatLightSettingsModel({
                _id: this.model.id
            });

            this.body.show(new Spinner);
            settingsModel.fetch({
                success: function() {
                    if (!model) {
                        model = new HeatLightModel({
                            _id: this.model.id,
                            type: 'heatLight',
                            mode: 'heat'
                        });
                        var state = State.load(model);
                        if (state && state.mode) {
                            model.set('mode', state.mode);
                        }
						if (state && state.sensorId) {
							model.set('sensorId', state.sensorId);
						}

                        // Ensure Heat + Light date is synced with the chart
                        var state = State.getTypeManual('chart', this.model.id);
                        if (state && state.date) {
                            var date = new Moment(state.date);

                            model.set('dateFrom', date.format('YYYY-MM-DD'));
                            model.set('dateTo', date.add(1, 'days').format('YYYY-MM-DD'));
                        }
                    }

                    // Constrain model to install dates
                    var installStart = new Moment(this.model.get('chartMeta').installationStart).startOf('day'),
                        installEnd = new Moment(this.model.get('chartMeta').installationEnd).startOf('day'),
                        checkDate = new Moment(model.get('dateFrom'));

                    if (checkDate.isBefore(installStart)) {
                        checkDate = installStart;
                    }
                    if (checkDate.isAfter(installEnd)) {
                        checkDate = installEnd;
                    }
                    model.set('dateFrom', checkDate.format('YYYY-MM-DD'));
                    model.set('dateTo', checkDate.add(1, 'days').format('YYYY-MM-DD'));

					if (null === model.get('sensorId')) {
						// Set sensorId to the first supported sensor
						var fields, field;
						for (var sensorId in settingsModel.attributes) {
							if (null === sensorId || isNaN(sensorId)) {
								continue;
							}

							for (field in settingsModel.get(sensorId).fields) {
								field = settingsModel.get(sensorId).fields[field];
								if (
									(
										model.get('mode') === 'heat'
										&& field.FieldTypeName === 'Temperature'
									)
									||
									(
										model.get('mode') === 'light'
										&& field.FieldTypeName === 'Light'
									)
								) {
									model.set('sensorId', sensorId);
									break;
								}
							}

							if (null !== model.get('sensorId')) {
								break;
							}
						}
					}

					if (null === model.get('sensorId')) {
                        var attempts = State.getHeatLightAttempts(this.model.id);
                        if (attempts < 5) {
                            State.setHeatLightAttempts(this.model.id, ++attempts);

                            // Kit doesn't support this mode, try again with the other mode?
                            if (model.get('mode') === 'heat') {
                                model.set('mode', 'light');
                            } else {
                                model.set('mode', 'heat');
                            }
                            this.renderHeatLight(model);
                        } else {
                            alert('Could not load Heat and Light, no sensors appear to be supported on this system.');
                            State.setHeatLightAttempts(this.model.id, 0);
                        }
						return;
					}

                    State.save(model);
                    model.fetch({
                        success: function() {
                            this.body.show(new HeatView({
                                chartMeta: this.model.get('chartMeta'),
                                model: model,
                                settingsModel: settingsModel
                            }));
                        }.bind(this)
                    })
                }.bind(this)
            });
        }
    });

});

