/**
System List Controller
@module Systems
@submodule Systems.Controllers.List
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * System List Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/modules/systems/controllers/systems.controllers.list',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/systems/collections/systems.collections.systems',
  'app/modules/systems/views/systems.views.list',
  'app/tracking'

], function(Marionette, Commands, Requests, Collection, ListView, Tracking) {

  'use strict';

  /**
  System List Controller
  @class Systems.Controllers.List
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

      var
      that = this,
      App = require('app/app');

      // Define our system collection
      this.collection = new Collection();

      // Define the main region
      this.region = App.main.currentView.content;

      // Define the main view
      this.view = new ListView({

        collection: this.collection

      });

      /*
      Update the top bar
      */

      // Listen for the main layout's 'show' event
      this.view.on('show', function() {
		  if (
			  this.collection.length > 0
			  && this.collection.findWhere({
				  chartVersion: 1
			  })
		  ) {
			  $('#portrait-overlay').remove();
		  }
		  
		  Tracking.track('openMySystems');

        /*
        Top bar
        */

        // Update the topbar title with the chart title
        Commands.execute('update:topbar', {

          title: {

            text: 'My Systems'

          }

        });

        /*
        Header view
        */

        // Show the section header
        that.showHeader({

          title: 'My Systems'

        });


        /* On-boarding tour */
        Commands.execute('init:onBoard', {

          page: 'systems'

        });


      });

      /*
      Fetch
      */

      // Fetch the model data
      this.collection.fetch();

      /*
      Show
      */

      // Call the controller 'show' method
      this.show({

        loader: true
        // debug: true

      });

    }

	});

});

