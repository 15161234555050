/**
Application Components - Pagination ItemView
@module Components
@submodule Components.Pagination.ItemView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
  * Utils
Contents:
  * Paging View
		* template
		* ui
		* events
		* onRender
		* navigate
		* buildUrl
Author(s):
  * Just Checking
*/

define('app/components/paging/components.paging.view',[

  'backbone',
  'marionette',
  'tpl!app/components/paging/templates/pagination.tmpl',
  'utils'

], function(Backbone, Marionette, Template, Utils) {

  'use strict';

  /**
  Paging View
  @class Paging.View
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      nextButton: '#paging-next',
      previousButton: '#paging-previous'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click button': 'navigate'

    },

    /**
    @method onRender
    */
    onRender: function() {

      var
      pageCount = this.model.get('pageCount'),
      pageNumber = this.model.get('pageNumber'),
      category = this.model.get('category'),
      currentFragment = Backbone.history.fragment;

      // UI Settings
      // -----------

      // If the number of pages are greater than 1
      if (pageCount > 1) {

        // TODO: set the correct data-hash
        this.ui.nextButton.attr('data-hash', this.buildUrl(currentFragment, pageNumber, 1));

        // show the next button
        this.ui.nextButton.show();

      }

      // If the current page number is greater than 1
      if (pageNumber > 1) {

        // TODO: set the correct data-hash
        this.ui.previousButton.attr('data-hash', this.buildUrl(currentFragment, pageNumber, 0));

        // show the previous button
        this.ui.previousButton.show();

      }

      // If we are on the last page of results
      if (pageNumber >= pageCount) {

        // hide the next button
        this.ui.nextButton.hide();

      }

    },

    /**
    @method navigate
    @param e {Object} Event information
    */
    navigate: function(e) {
      var
      target = $(e.target),
      targetTagName = target.prop('tagName');



      // Check for a click on the button
      if (targetTagName === 'BUTTON') {

        // Trigger the paging:navigate event
        this.trigger('paging:navigate', target);

      }
      else {

        // Assuming an icon inside the button has been clicked
        this.trigger('paging:navigate', target.parent());

      }

    },

    /**
    @method buildUrl
    @param currentFragment {String} The current Backbone history fragment
    @param pageNumber {Integer} The page number sent in via the collection
    @param target {Integer} Are we building the next or previous button?
    */
    buildUrl: function(currentFragment, pageNumber, target) {

      var
      fragmentSections = currentFragment.split('/'),
      href = '#',
      targetPage,
      currentPageNumber,
      pageIncluded = (new RegExp('page')).test(currentFragment);

      // Loop through the sections of the current fragment
      _.each(fragmentSections, function(section) {

        // Is there is a page setting in the fragment?
        if ((new RegExp('page')).test(section)) {

          // Strip the current page number from the fragment section
          currentPageNumber = section.replace('page', '');

          // Is current page number a valid number?
          if (Utils.isNumber(currentPageNumber)) {

            // Turn the current page string into an int
            currentPageNumber = parseInt(currentPageNumber, 10);

            // Are we building the 'next' button?
            if (target === 1) {

              // We need to target the next page, current + 1
              targetPage = currentPageNumber + 1;

            }
            else {

              // We need to target the previous page, current - 1
              targetPage = currentPageNumber - 1;

            }

            // Remove the current page number from the href string
            // and add the new target number
            href += (section.substring(0, section.length - currentPageNumber.length) + 'page' + targetPage + '/');

          }

        }
        else {

          // This isn't the page section so just add it to the href
          href += (section + '/');

        }

      });

      // Is this the first page with no page set in routing?
      if (pageNumber === 1 && !pageIncluded) {

        // We only need to set the 'next' button
        href += ('page2/');

      }

      return href.substring(0, href.length - 1);

    }

  });

});

