/**
Application Components - Form Controller
@module Components
@submodule Components.Form.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Backbone Syphon
  * Requests
Contents:
  * Form App Controller
		* initialize
		* formCancel
		* formSubmit
		* formContentRegion
		* getFormLayout
		* getDefaultConfig
		* getButtons
Author(s):
  * Just Checking
Usage:
The following options can be added to any view requesting the form wrapper and will overwrite any defaults.

	form: {

		footer: false|true, (Is the footer visible? (default: true))
		focusFirstInput: false|true, (Focus on the first form element in the view (default: false))
		buttons: {
			primary: String|false (String = Button text, false = hidden (default: 'Submit'))
			cancel: String|false, (String = Button text, false = hidden (default: false))
			placement: right|left (default: left),
			className: String, (String = Classes assigned to buttons)
			reference: String (String = Text added after button text)
		}

	}

*/

define('app/components/form/components.form.controller',[

  'marionette',
  'app/commands/commands.vent',
  'backbone.syphon',
  'app/requests/requests.vent',
  'app/components/form/components.form.layout'

], function(Marionette, Commands, Syphon, Requests, FormLayout) {

  'use strict';

  /**
  Form Controller
  @class Components.Form.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var that = this;

      // Do we have options?
      if (!options) {
        
        // Create an empty object
        options = {};
      
      }

      // Set the content view
      this.contentView = options.view;

      // Set the form layout
      this.formLayout = this.getFormLayout(options.config);

      // Listen to the form layout show event
      this.listenTo(this.formLayout, 'show', this.formContentRegion);

      // Listen to the form layout submit event
      this.listenTo(this.formLayout, 'form:submit', this.formSubmit);
       
      // Listen to the form layout cancel event
      this.listenTo(this.formLayout, 'form:cancel', this.formCancel);

    },

    /**
    @method formCancel
    */
    formCancel: function() {
      // Is there a cancel method of the content view?
			if (_.isFunction(this.contentView.cancel)) {
				// Call the cancel method
				this.contentView.cancel();
				// Check if the layout is still open
				if (!this.formLayout.isClosed) {
					// Remove any error messages
					this.formLayout.ui.errorMessage.addClass('hide');
				}				
			}
      else {
        // Go back to previous view as default
        Commands.execute('app:navigate:back');
        Commands.execute('set:change:false', {view:this});
      } 
    },

    /**
    @method formSubmit
    */
    formSubmit: function() {

			var that = this;

			// Is there a save method of the content view?
			if (_.isFunction(this.contentView.save)) {

				// Use Backbone Syphon to get the form data
				var data = Syphon.serialize(this.formLayout);
				
				// Save the data
				this.contentView.save(data, function(isValid) {

					// Is the form valid?
					if (isValid) {

						that.formLayout.ui.errorMessage.addClass('hide');

					}
					else {

						that.formLayout.ui.errorMessage.removeClass('hide');

					}

				});

			}
    
    },

    /**
    @method formContentRegion
    */
    formContentRegion: function() {

			// Show the content view within the form layout region
      this.formLayout.formContentRegion.show(this.contentView);

    },

    /**
    @method getFormLayout
    */
    getFormLayout: function(options) {

			var
			buttons,
			config;

			// Get any configuration set in the content view 
			config = this.getDefaultConfig(_.result(this.contentView, 'form'));

			// Copy all the options into the config object
			_.extend(config, options);

			// Request the buttons
      buttons = this.getButtons(config.buttons);

      // Return the form layout
      return new FormLayout({

				config: config,
				model: this.contentView.model,
        buttons: buttons

      });

    },

    /**
    @method getDefaultConfig
    */
    getDefaultConfig: function(config) {

			// Has a config not been supplied?
			if (!config) {
				
				// Create an empty object
				config = {};
			
			}

			// Return any defaults if they are not set
			return _.defaults(config, {

				footer: true,
				focusFirstInput: false

			});

    },

    /**
    @method getButtons
    */
    getButtons: function(buttons) {

			// Do we require buttons?
      if (buttons !== false) {

				// Return the requested buttons
        return Requests.request('form:button:entities', buttons, this.contentView.model);

      }
    
    }

	});

});

