/**
Application Commands - Show Current Location
@module Commands
@submodule Commands.ShowCurrentLocation
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
	* Requests
Contents:
	* Module Initialize
		* Show Current Location
Author(s):
  * Just Checking
*/

define('app/commands/commands.showCurrentLocation',[

	'backbone',
  'app/commands/commands.vent',
  'app/requests/requests.vent'

], function(Backbone, Commands, Requests) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Show Current Location
			@event show:current:location
			*/
			return Commands.setHandler('show:current:location', function() {

				var
				currentFragment = Backbone.history.fragment,
				currentSection = '',
				targetEl = $('#top-bar-centered');

				// Request the current section
				currentSection = Requests.request('get:fragment:item', {

					fragment: currentFragment,
					index: 2

				});

				// Remove the current 'active' class
				targetEl.find('li').removeClass('active');

				// Add the active class to the right menu item by id
				targetEl.find('#top-bar-' + currentSection).addClass('active');

				return false;

			});

    }

  };

});

