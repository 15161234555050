/**
Users Application - List View
@module UsersApp
@submodule UsersApp.Views.List
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Template
	* Vent
	* ItemView
Contents:
  * User Item View
		* className
		* template
		* itemView
		* itemViewContainer
		* events
		* onRender (Disabled)
		* newUser
Author(s):
  * Just Checking
*/

define('app/modules/users/views/menu/settings.users.views.menu.list',[

  'marionette',
  'tpl!app/modules/users/templates/usersMenu.tmpl',
  'app/app.vent',
  'app/modules/users/views/menu/settings.users.views.menu.item',
  'app/modules/users/views/menu/settings.users.views.menu.empty'

], function(Marionette, Template, Vent, ItemView, EmptyView) {

  'use strict';

  /**
  User Item View
  @class UsersApp.View
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property className
    @type String
    */
    className: 'large-4 columns no-padding menu-panel',

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    emptyView: EmptyView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#side-menu',

    /**
    @property events
    @type Object
    */
    events: {

      'click .new-user': 'newUser'

    },

    isEmpty: function() {
		return this.collection.length === 0;
	},

    /*
    onRender: function() {

      var that = this;

      // Pluck the SMS enabled settings from the collection
      _.each(this.collection.pluck('enableSmsAlerts'), function(state) {

        // Are sms alerts enabled for this user?
        if (state) {

          // Add 1 to the current number of sms enabled users
          Vent.trigger('edit:users:sms', true);

        }

      });

    },

    */

    /**
    @method newUser
    */
    newUser: function() {

      // Trigger the new:user event in the controller
      Vent.trigger('new:user');

    }

  });

});

