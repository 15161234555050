define('app/modules/newcharts/features/events/models/sensor.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({


	});

});
