define('app/views/views.enhancedServiceQuestionsLightbox',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/templates/enhancedServiceQuestionsLightbox.template.tmpl','backbone.crudder'],function (require) {
	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		LightboxTemplate 	= require('tpl!app/templates/enhancedServiceQuestionsLightbox.template.tmpl'),
		Crudder				= require('backbone.crudder');

	return Marionette.ItemView.extend({

		template: LightboxTemplate,

		ui: {
			exitclick : '.exitform',
			submitBtn: '#submit',
			nothingCheck: '#nothing',
			whatDidJCShowYou: '#whatDidJCShowYou',
			howWouldYouGainInfo: '#howWouldYouGainInfo'
		},

		events: {
			'click @ui.exitclick' : 'closelightbox',
			'click @ui.submitBtn': 'submit',
			'change @ui.nothingCheck': 'setNothing'
		},

		initialize:function () {
			$('.overlay').unbind().click(this.closelightbox.bind(this));
		},

		closelightbox:function() {
			$('.overlay').hide();
			$('#lightboxcontainer').hide();

			$('.emailPopup').unbind();

			if (typeof this.options.closeCallback === 'function') {
				this.options.closeCallback();
			}
		},

		onRender:function () {
			$('#lightboxcontainer').html(LightboxTemplate(this.model.toJSON()));
			$('.overlay').show();
			$('#lightboxcontainer').show();
		},

		onShow: function() {
			$('.emailPopup').animate({scrollTop: 0}, 600);
		},

		submit: function(e) {
			e.preventDefault();

			this.model.set({
				whatDidJCShowYou: this.ui.whatDidJCShowYou.val(),
				howWouldYouGainInfo: this.ui.howWouldYouGainInfo.val()
			});

			// Crudder.
			Crudder.creatingUpdating({
				entity: this.model,
				patch: false,
				successMessage: 'Questions saved successfully.'
			}, function(response) {
				// Do nothing.
				if (response.success) {
					this.closelightbox();
				}
			}.bind(this));
		},

		setNothing: function(e) {
			var target = $(e.target);

			if (target.is(':checked')) {
				this.ui.whatDidJCShowYou.attr('disabled', 'disabled').val('');
			} else {
				this.ui.whatDidJCShowYou.removeAttr('disabled');
			}
		}

	});

});

