/**
Base Marionette View
@module Base
@submodule Base.Marionette.View
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * templateHelpers
    * formatDate
    * formatDateTime
    * fuzzyTime
  * addOpacityWrapper
Author(s):
  * Just Checking
*/

define('app/base/views/marionette/base.views.marionette.view',[

  'marionette',
  'app/requests/requests.vent',
  'moment'

], function(Marionette, Requests, Moment) {

  'use strict';

  return _.extend(Marionette.View.prototype, {

    /**
    @property templateHelpers
    @type Object
    */
    templateHelpers: function() {
      
      return {

        /**
        @method formatDate
        @param date {Date} The date to be formatted
        @param format {Date} The format of the date to be formatted
        */
        formatDate: function(date, format) {


          var formattedDate;

          // Validate date value
          if (new Moment(date).isValid() && (typeof format != 'undefined')) {

            // Request a formatted date
            formattedDate = Requests.request('get:formatted:date', {

              date: date,
              format: format

            });



          }
          else if ((typeof format != 'undefined') && new Moment(format).isValid()) {

            /*
            Backbone ModelBinder

            If backbone modelbinder makes the request, the params will be:

            * direction - either ModelToView or ViewToModel
            * value - the model's attribute value or the view element's value
            * attribute Name
            * model - this is more useful when you're dealing with calculated attributes
            * els - an array of the els that were bound to the converter
            */

            // Request a formatted date
            formattedDate = Requests.request('get:formatted:date', {

              date: format

            });

          }
          else {
            //console.log('invalid format:'+ format);
            return '';

          }

          return formattedDate;

        },

        /**
        @method formatDateTime
        @param date {Date} The date to be formatted
        @param format {Date} The format of the date to be formatted
        */
        formatDateTime: function(date, format) {

          // Request a formatted date
          var formattedDateTime = Requests.request('get:formatted:date', {

            date: date,
            format: Requests.request('get:state:data').get('dateTimeFormat')

          });

          return formattedDateTime;

        },

        /**
        @method fuzzyTime
        @param date {Date} The date to be formatted
        */
        fuzzyTime: function(date) {

          // Request a formatted date
          var fuzzyTime = Requests.request('get:fuzzy:time', {

            date: date

          });

          return fuzzyTime;

        },

        /* What dis???
        linkTo: function(name, url, options) {

          if (options === null) {

            options = {};

          }

          _.defaults(options, {
            
            external: false
          
          });
          
          if (!options.external) {
            
            url = "#" + url;
          
          }
          
          return "<a href='" + url + "'>" + (this.escape(name)) + "</a>";
        
        },*/

      };

    },

    /**
    @method templateHelpers
    @param add {Boolean} Add or remove the wrapper
    */
    addOpacityWrapper: function(options) {

      options = options || {};

      // We require a true or false for 'add'
      if (_.isBoolean(options.add)) {
        
        // Add a toggle wrapper to this view
        this.$el.toggleWrapper({
        
          className: 'opacity'
        
        }, options.add);
      
      }
      else {

        console.warn('Opacity wrapper requires an add parameter');

        return;

      }
    
    }

	});

});
