/**
Chart Application - Row Model
@module Chart
@submodule Chart.Models.Row
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Row Model
    * idAttribute
    * urlRoot
Author(s):
  * Just Checking
*/

/*
Example Data:
  "rowPosition": 1,
  "rowCurrentPosition": 1,
  "rowShow": false,
  "rowInUse": true,
  "chartLineId": 252058,
  "chartLineLabel": "Bedroom",
  "chartLineName": "Bedroom",
  "sensorData": [
    {
      "dataType": "movement",
      "tooltip": {
        "headline": "",
        "text": ""
      },
      "dataItems": [
        {
          "x": 10,
          "w": 7
        }
        ...
*/

define('app/modules/charts/models/charts.models.row',[

  'backbone',
  'moment'

], function(Backbone, Moment) {

  'use strict';

  /**
  Row Model
  @class Chart.Models.Row
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property idAttribute
    @type Integer
    */
    idAttribute: 'chartLineId',

    /**
    @property urlRoot
    @type String
    */
    urlRoot: 'Charts'

  });

});

