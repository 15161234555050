/**
Application Requests - Get Form Wrapper
@module Requests
@submodule Requests.GetFormWrapper
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get form wrapper
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFormWrapper',[

	'app/requests/requests.vent',
	'app/components/form/components.form.controller'

], function(Requests, FormController) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get form wrapper
			@event get:change:status
			@return {Object} The form layout to display
			*/
			return Requests.setHandler('get:form:wrapper', function(contentView, options) {

				// Do we have options?
				if (options === null) {

					options = {};
				
				}

				// Is there a model in the view?
				if (!contentView.model) {
        
					throw new Error('No model found inside of form\'s contentView');

				}

				// Create our form controller
				var formController = new FormController({

					view: contentView,
					config: options

				});
				
				// Return the form layout
				return formController.formLayout;

			});

    }

  };

});

