define('app/modules/newcharts/7day/views/chart-mini.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'tpl!app/modules/newcharts/7day/templates/chart-mini.template.tmpl',
    'moment',
    'app/modules/newcharts/7day/views/chart-row-mini.view'

], function (Marionette, Vent, Commands, Template, Moment, ChartRowMini) {
    'use strict';

    return Marionette.CompositeView.extend({

        template: Template,

        itemView: ChartRowMini,

        itemViewContainer: '.chart-wrapper',

		itemViewOptions: function() {
			var chartDate = new Moment(this.model.get('requestedDate')).startOf('day'),
				installEnd = new Moment(this.model.get('chartMeta').installationEnd).startOf('day');

			return {
				renderData: !chartDate.isAfter(installEnd)
			};
		},

        initialize: function() {
            this.model.set('requestedDate', new Moment(this.model.get('requestedDate')));
            this.model.set('chartDate', new Moment(this.model.get('chartMeta').chartDate));
        },

        onShow: function() {
            this.renderTimeline();
        },

        renderTimeline: function() {
            var container = this.$el.find('#chart-timeline-hours'),
                offset = this.model.get('chartTimeline')[0].axisOffset;

            _.each(this.model.get('chartTimeline'), function(item) {
                var html = '<a data-date="' + new Moment(item.axisData).toISOString() + '">';

                var classNames = [],
                    hour;

                if (this.model.get('chartMeta').zoom == 120) {
                    hour = new Moment(item.axisLabel).format('HH');
                } else {
                    hour = new Moment(item.axisLabel).format('HH:mm');
                }

                if (hour === '00' || hour === '00:00') {
                    classNames.push('chart-timeline-label-midnight');
                } else if (hour === '12' || hour === '12:00') {
                    classNames.push('chart-timeline-label-noon');
                }

                html += hour;
                html += '</a>';

                container
                    .append(
                        html
                    )
                    .addClass(classNames)
                    .css({
                        left: offset
                    });
            }.bind(this));
        }

    });

});
