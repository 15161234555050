define('app/modules/newcharts/features/events/views/event-sensor.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/events/templates/event-sensor.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder) {
	'use strict';

	return Marionette.ItemView.extend({

		tagName: 'li',

		template: Template,

		ui: {
			removeButton: '.chart-edit-row-remove-button'
		},

		events: {
			'click @ui.removeButton': 'removeSensor'
		},

		attributes: function() {
			return {
				'data-code': this.model.get('code')
			};
		},

		removeSensor: function(e) {
			e.preventDefault();

			Vent.trigger('events:remove-sensor', this.model);
		}

	});

});
