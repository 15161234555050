/**
Help Controller
@module Help
@submodule Help.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Help App Controller
    * initialize
    * getById
    * showHelp
    * showHeader
    * updateModuleState
Author(s):
  * Just Checking
*/

define('app/modules/help/help.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/help/help.view'

], function(Marionette, Commands, Requests, ItemView) {

  'use strict';

  /**
  Help Controller
  @class Help.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

      var
      that = this,
      App = require('app/app');

      // Define the main layout
      this.view = new ItemView();

      // Define the main region
      this.region = App.main.currentView.content;

      // Define our system model
      // this.model = new Model();

      /*
      Update the top bar
      */

      // Listen for the main layout's 'show' event
      this.view.on('show', function() {

        // Update the topbar title with the chart title
        Commands.execute('update:topbar', {

          title: {
          
            text: 'Help &amp; Resources'

          }

        });

        /*
        Header view
        */

        // Show the section header
        that.showHeader({

          title: 'Help &amp; Resources'

        });

      });

      // Call the controller 'show' method
      this.show();

    }

	});

});
