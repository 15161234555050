/**
Application Components - Panels Controller
@module Components
@submodule Components.Panels.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main App Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/components/panels/panels.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/components/panels/layouts/panels.layouts.panels',
  'app/components/panels/layouts/panels.layouts.panel'

], function(Marionette, Commands, PanelsLayout, PanelLayout) {

  'use strict';

  /**
  Main App Controller
  @class MainApp.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    Start the app by showing the appropriate views
    @method initialize
    */
    initialize: function(options) {

      var
      that = this,
      App = require('app/app');

      // Define the main panel region
      this.region = App.panels;
      
      // New panels layout
      this.layout = new PanelsLayout();

      // When the panels layout is shown
      this.layout.on('show', function() {

        /*
        Add panel layouts
        */

        // Add a new panel layout to the top panel
        this.panelTop.show(new PanelLayout());

        // Add a new panel layout to the right panel
        this.panelRight.show(new PanelLayout());

        // Add a new panel layout to the bottom panel
        this.panelBottom.show(new PanelLayout());

        // Add a new panel layout to the left panel
        this.panelLeft.show(new PanelLayout());

      });

      // Show the panels layout in the main panels region
      this.show();

    }

  });

});
