/**
Application Logins - Model
@module Logins
@submodule Logins.Model
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Logins Model
		* defaults
Author(s):
  * Just Checking
*/

define('app/modules/logins/logins.model',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Logins Model
  @class LoginsModel
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

		/**
    @property defaults
    @type Object
    
    defaults: {

			alignment: "left"

    }*/

  });

});
