define('app/modules/newcharts/features/notifications_family/collections/notifications.collection',[
	'app/modules/newcharts/features/notifications/collections/notifications.collection'
], function(ParentCollection) {
	'use strict';

	return ParentCollection.extend({

		url: function() {
			return 'api/v1/Alerts/' + this.models[0].id + '?family=true';
		}
	});

});
