define('app/modules/newcharts/views/chart-row.view',[
    'backbone',
    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
	'app/modules/newcharts/state',
    'tpl!app/modules/newcharts/templates/chart-row.template.tmpl',
    'moment',
    'd3',
	'app/modules/newcharts/rectMap',

], function(Backbone, Marionette, Vent, Commands, Requests, State, Template, Moment, D3, RectMap) {
    'use strict';


	var printCSSClasses = [];
    return Marionette.ItemView.extend({

        template: Template,

		serializeData: function() {
        	var data = this.model.toJSON();
			data.meta = this.options.meta;
			data.menu = this.options.menu;
			return data;
		},

		ui: {
			doorLink: '.door-click',
			rowCollapse: '.row-collapse',
			chartRowViewOptionsWrapper: '#chartRowViewOptionsWrapper',
            chartRowViewOptions: '#chartRowViewOptions',
            chartRowViewOptionsDropdown: "#chartRowViewOptionsDropdown",
            subViewContainerEl: ".sub-view-container",
            chartRowViewOptionsUpdate: "#updateChartOptions"
		},

		events: {
			'click @ui.doorLink': 'clickDoor',
			'click @ui.rowCollapse': 'clickExpand',
			'click @ui.chartRowViewOptions': 'handleChartRowOptions',
            //'click @ui.chartRowViewOptionsUpdate': 'handleUpdateChartOptions',
			'click @ui.chartRowViewOptionsUpdate': 'triggerChartRowUpdate',
            'click #chartOptionsList li': 'handleChartOptionClick'
		},

		initialize: function() {
			this.showChartViewOptions = false;
            // set chartViewOptionsList to === with stores from localStorage or set an empty array if missing
            this.chartViewOptionsList = [];//State.getChartLineOptionsListFromId(this.model.get('chartLineId'))
            this.dropdownOpen = false;
            $(document).on('click', this.handleDocumentClick.bind(this));
            // Marionette.CompositeView.prototype.initialize.apply(that, arguments);
          },

        handleChartRowOptions: function() {
            if(!this.dropdownOpen){
                this.ui.chartRowViewOptionsDropdown.toggleClass('hidden', false);
				this.ui.chartRowViewOptions.toggleClass('selected', true);
                this.ui.chartRowViewOptionsUpdate.attr("disabled", true);
                this.renderChartOptions();
                var that = this;
                setTimeout(function(){
                    that.dropdownOpen = true;
                }, 100)
            }else{
                this.closeChartRowDropdown();
            }
        },

        handleDocumentClick: function(event) {
            if (this.dropdownOpen) {
              var target = event.target;
              var dropdown = this.ui.chartRowViewOptionsDropdown;
            // Check if the click occurred outside the dropdown element
              if (!dropdown.is(target) && dropdown.has(target).length === 0) {
                this.closeChartRowDropdown();
              }
            }
        },

		triggerChartRowUpdate: function() {
            // sync chartViewOptionsList to the same as localStorage
            State.syncChartLinesOptions(this.model.get('chartLineId'), this.chartViewOptionsList)
            Vent.trigger('newchart:update-row-options', {chartLineId:this.model.get('chartLineId')});
        },

        handleUpdateChartOptions: function() {
            // Handle the update button click event
            this.updateChartSVGs();
            this.closeChartRowDropdown();
          },

          handleChartOptionClick: function(event) {
            event.stopPropagation();
            event.preventDefault();
            var selectedIndex = $(event.currentTarget).index();
            var selectedChart = this.chartViewOptionsList[selectedIndex];
            selectedChart.visible = !selectedChart.visible;
            this.ui.chartRowViewOptionsUpdate.attr("disabled", false);
            this.renderChartOptions();
          },

          renderChartOptions: function() {
            var chartOptionsList = this.$('#chartOptionsList');
            chartOptionsList.empty();

            for (var i = 0; i < this.chartViewOptionsList.length; i++) {
              var chartOption = this.chartViewOptionsList[i];
              var listItem = $('<li></li>');
              var checkbox = $('<input type="checkbox">');
              checkbox.prop('checked', chartOption.visible);
              checkbox.attr('id', chartOption.chartID)
              listItem.append(checkbox);
              listItem.append(chartOption.chartLabel);
              chartOptionsList.append(listItem);
            }
          },

		  updateChartSVGs:function(){
            // sync chartViewOptionsList to the same as localStorage
            this.chartViewOptionsList = State.getChartLineOptionsListFromId(this.model.get('chartLineId'))
            //
            var chartLineId = this.model.get('chartLineId');
            var rowDataWrapper = this.$el.find('#chartRow' + chartLineId).find('.chart-data');
            //
            for (var i = 0; i < this.chartViewOptionsList.length; i++) {
                var id = this.chartViewOptionsList[i].chartID,
                    vis = this.chartViewOptionsList[i].visible,
                    type = this.chartViewOptionsList[i].type,
                    svgItems = D3.select(rowDataWrapper[0]).selectAll(this.chartViewOptionsList[i].type+"."+id);
                // Iterate over the selected elements
                svgItems.each(function() {
                    // Access each <rect> element
                    var svgItemElement = D3.select(this);
                    // Perform operations on each <rect> element
                    if(!vis){
                        svgItemElement.style("display", "none");
                        if(type === "path"){
                            svgItemElement.style("stroke-width", "0px");
							svgItemElement.style("opacity", "0");
                        }
						svgItemElement.classed("hidden-from-chart", true);
                    }else{
                        svgItemElement.style("display", null);
                        if(type === "path"){
                            svgItemElement.style("stroke-width", "2px");
							svgItemElement.style("opacity", "1");
                        }
						svgItemElement.classed("hidden-from-chart", false);
                    }
                });
            }

        },

        closeChartRowDropdown:function(){
            // reset/sync chartViewOptionsList to the same as localStorage
            this.chartOptionsList = State.getChartLineOptionsListFromId(this.model.get('chartLineId'));
            this.dropdownOpen = false;
            this.ui.chartRowViewOptionsDropdown.toggleClass('hidden', true);
			this.ui.chartRowViewOptions.toggleClass('selected', false);
        },

        doesChartOptionsListContains:function(id){
			if(id == "currentTime"){
				return true;
			}
             // Check if chartViewOptionsList already contains an item with given
			var containsID = this.chartViewOptionsList.some(function(obj) {
				return obj.chartID === id;
			});
            return containsID;
        },
        isChartVisible:function(chartLineId, id){
            var vis = State.getVisibleByChartId(chartLineId, id)
            return vis
        },
        syncChartOptionsList:function(){
            if(this.showChartViewOptions){
				State.syncChartLinesOptions(this.model.get('chartLineId'), this.chartViewOptionsList)
                this.showChartRowOptionsWrapper();
                this.updateChartSVGs()
            }
        },
        showChartRowOptionsWrapper:function(){
            if(!this.chartViewOptionsList.length){
                this.ui.chartRowViewOptionsWrapper.toggleClass('hidden', true);
            }else{
                this.ui.chartRowViewOptionsWrapper.toggleClass('hidden', false);
            }
        },
        hideChartRowOptionsWrapper:function(){
            this.ui.chartRowViewOptionsWrapper.toggleClass('hidden', true);
        },

		templateHelpers: function() {
			var rows = this.options.rows,
				model = this.model;

			return {
				isParent: function() {
					return rows.where({
						parent: model.get('chartLineId'),
						rowInUse: true
					}).length > 0;
				},
				isChild: function() {
					return model.get('parent') != null;
				}
			};
		},

        onRender: function() {
			var acceleratorItems = [];
            var id = this.model.get('chartLineId'),
                rowData = this.model.get('chartLineData'),
                rowDataWrapper = this.$el.find('#chartRow' + id).find('.chart-data'),
                svgElement,
                container = this.$el,
				rowId = this.getRowId()
				that = this;

            D3.select(rowDataWrapper[0])
                .append('svg')
                .attr('id', 'rowData' + id)
                .attr('width', '100%')
                .append('defs')
                .append('pattern')
                .attr('id', 'diagonalHatch-' + id)
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', '10')
                .attr('height', '10')
                .append('g')
                .attr('class', 'offline-hatch')
                .append('path')
                .attr('d', 'M0,10 l10,-10');

            svgElement = rowDataWrapper.find('#rowData' + id);

            var maxX = 0
            _.each(rowData, function(element, index) {
                // Loop through each data item for this alert type
                _.each(element.dataItems, function(value, key) {
                    // Append the svg element with a rect element
                    if(value.x > maxX){
                        maxX = value.x
                    }

					 // ChartViewOptions DropdownList
					 if(!this.doesChartOptionsListContains(element.dataType)){
                        this.chartViewOptionsList.push({
                            chartID: element.dataType,
                            visible: this.isChartVisible(id, element.dataType),
                            chartLabel: element.dataType,
                            type:"rect"
                        });
                    }

					if (element.dataType == 'event' || element.dataType == 'event_ajar') {
						if (element.dataType == 'event') {
							value.w = 2;
						}
						D3.select(svgElement[0])
							.append('rect')
							.attr('class', element.dataType)
							.attr('x', function () {
								return value.x;
							})
							.attr('y', function () {
								return element.dataType == 'event_ajar' ? 22 : 10;
							})
							.attr('width', function () {
								return value.w;
							})
							.attr('height', function () {
								return element.dataType == 'event_ajar' ? 8 : 20;
							})
							.attr('fill', '#ccc')
							.each(function () {
								// Do we have a tooltip to show?
								if (element.tooltip) {
									// Initialise a popover for this element
									var start = moment.utc(value.start),
										end = moment.utc(value.end),
										diff = end.diff(start, 'minutes');

									Commands.execute('init:popover', {
										container: container,
										element: $(this),
										data: that.getTooltipText( element.tooltip.text + ': ' + diff + '&nbsp;minute' + (diff == 1 ? '' : 's'), "1"),// {
											//headline: '',
											//text: element.tooltip.text + ': ' + diff + '&nbsp;minute' + (diff == 1 ? '' : 's')
										//},
										html: true
									});
								}
							});

						// Build-up map of tooltips
						RectMap.addItem(rowId, value.x, value.w, $(svgElement[0]).children('rect').last());
					} else if (element.isNotification) {
						D3.select(svgElement[0])
							.append('rect')
							.attr('class', element.dataType)
							.attr('x', function () {
								return value.x;
							})
							.attr('y', function () {
								return 10;
							})
							.attr('width', function () {
								return 2;
							})
							.attr('height', function () {
								return 20;
							})
							.attr('fill', '#ccc')
							.each(function () {
								// Do we have a tooltip to show?
								if (element.tooltip) {
									// Initialise a popover for this element
									Commands.execute('init:popover', {
										container: container,
										element: $(this),
										data: that.getTooltipText(element.tooltip.text, "2")//element.tooltip
									});
								}
							});

						// Build-up map of tooltips
						RectMap.addItem(rowId, value.x, 2, $(svgElement[0]).children('rect').last());
					} else {
						var className = element.dataType;

						if (value.child) {
							className += ' child';
						}

                        D3.select(svgElement[0])
							.append('rect')
							.attr('class', className)
							.attr('style', function() {
								if (element.dataType === 'offline' || element.dataType === 'dataLoss') {
									return 'fill:url(#diagonalHatch-' + id + ')';
								} else {
									return '';
								}
							})
							.attr('x', function() {
								// Return the data x position value
								return value.x;
							})
							.attr('y', function() {
								// Set the correct y position depending on data type
								switch(element.dataType) {
									case 'movement':
									case 'carer':
										return 4;

									case 'doorAjar':
									case 'event_ajar':
									case 'carerAjar':
										return 22;

									case 'interpretedAjar':
										return 22;

									case 'doorEvent':
										return 10;

									case 'accelerometer':
										// return 17
										return 25;

									default:
										return 0;
								}
							})
							.attr('width', function() {
								// Return the data width value
								return value.w > 2 ? value.w + 1 : value.w;
							})
							.attr('height', function() {
								// Set the correct y position depending on data type
								switch(element.dataType) {
									case 'motion':
									case 'carer':
										return 26;

									case 'doorAjar':
									case 'event_ajar':
									case 'carerAjar':
										return 8;

									case 'interpretedAjar':
										return 8;

									case 'doorEvent':
										return 20;

									case 'accelerometer':
										acceleratorItems.push({
                                            x:value.x,
                                            el:this,
                                        })
										// return 13;
                                        return 5;

									default:
										return 30;
								}
							})
							.each(function() {
								// Do we have a tooltip to show?
								if (element.tooltip) {
									// Initialise a popover for this element
									Commands.execute('init:popover', {
										container: container,
										element: $(this),
										data: that.getTooltipText(element.tooltip.text, "3")//element.tooltip
									});
								}
							});

						// Build-up map of tooltips
						RectMap.addItem(rowId, value.x, value.w, $(svgElement[0]).children('rect').last());
					}
                }.bind(this));
            }.bind(this));

			setTimeout(function() {
				if (this.model.get('rowInUse')) {
					RectMap.addRow(rowId, rowDataWrapper);
				}
			}.bind(this));
            //

            var that = this;
            // console.log(maxX)
            // var w = rowDataWrapper.innerWidth()
            setTimeout(function(){
				that.loadECG(
					rowDataWrapper.innerWidth(),
					rowDataWrapper.innerHeight(),
					acceleratorItems
				);
            }, 100)
        },

		getRowId: function() {
			var date = new Moment(this.options.meta.chartDate);

			return date.format('YYYYMMDDHHmmss') + '-' + this.model.get('chartLineId');
		},

		clickDoor: function(e) {
			e.preventDefault();

			var target = $(e.target),
				chartLineId = target.data('id');

			// Set cookie with a given door line
			$.cookie('doorActivity_' + this.options.meta.installationId, chartLineId, { expires: 1 });

			// Launch Door Summary at the given chart line id.
			Commands.execute('app:navigate', {
				route: 'systems/' +
				this.options.meta.systemNo +
				'/newcharts/' +
				this.options.meta.installationId +
				'/doorActivity'
			});
		},

		clickExpand: function(e) {
			if (this.ui.rowCollapse.hasClass('icon-plus')) {
				this.expandChildren();
			} else {
				this.collapseChildren();
			}
		},

		collapseChildren: function() {
			$('.chartRow' + this.model.get('chartLineId'))
				.find('.row-collapse')
				.removeClass('icon-minus')
				.addClass('icon-plus');

			Vent.trigger('newchart:collapse-children', {
				parent: this.model.get('chartLineId')
			});
		},

		expandChildren: function() {
			$('.chartRow' + this.model.get('chartLineId'))
				.find('.row-collapse')
				.removeClass('icon-plus')
				.addClass('icon-minus');

			Vent.trigger('newchart:expand-children', {
				parent: this.model.get('chartLineId')
			});
		},




        // GUY 2023 New ECG Chart!
		getTooltipText: function(text, id){
			return {
				headline: '', 
				text:text
			}
        },

		initAcceleratorData: function(data, acceleratorItems){
            _.each(data, function(d, index) {
                var item = _.filter(acceleratorItems, function(item){ return item.x === d.x1; })[0];
                if(item){
					var h = d.y1;
					if (h < 5){
						h = 5;
					}
                    item.el.setAttribute("height", h)
                    item.el.setAttribute("y", 30 - h)
                }
            })
        },

        initChart: function(data, type, num, width, height){
            var scaleLimits = {
                xMin:0,
                xMax: D3.max(data, function(d) { return d.x2; }),
                yMin:0,
                yMax:D3.max(data, function(d) { return d.y2; })
            }
            if(num === "2"){
                // temperature
                // scaleLimits.yMin = -10
                scaleLimits.yMax = scaleLimits.yMax > 35 ? scaleLimits.yMax : 35 // 35 degress or as high as the highest value from data
            }
            if(num !== "16"){
                this.drawChart(data, type, num, '#chartAll', width, height, scaleLimits);
            }
        },

        drawChart: function(data, type, num, elId, width, height, scaleLimits){

			var initialDataItem = {
                "x1":data[0].x1,"y1":data[0].y1,
                "x2":data[0].x1,"y2":data[0].y1,
                "color":data[0].color,
				"label":data[0].label
            }
            data.unshift(initialDataItem)

            const chartWrapper = this.$el.find(elId)

			var container = this.$el,
			 	rowId = this.getRowId(),
			 	that = this;
			//
			var id = this.model.get('chartLineId');
			var rowDataWrapper = this.$el.find('#chartRow' + id).find('.chart-data');
			var svgElement = rowDataWrapper.find('#rowData' + id);
			//
            // Define chart dimensions
            var margin = {top: 2, right: 0, bottom: 2, left: 0},
            width = width - margin.left - margin.right,
            height = height - margin.top - margin.bottom;

            // Define scales
            var xScale = D3.scale
                .linear()
                .domain([scaleLimits.xMin, scaleLimits.xMax])
                .range([0, scaleLimits.xMax]);

            var yScale = D3.scale
                .linear()
                .domain([scaleLimits.yMin, scaleLimits.yMax])
                .range([height, 0]);

            // Define line function
            var line = D3.svg
                .line()
                .x(function(d) { return xScale(d.x2); })
                .y(function(d) { return yScale(d.y2); })
                .interpolate("natural");

            
            // Add SVG element
            var svg = D3.select(svgElement[0])/*chartWrapper[0])
                .append('svg')
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
*/

			// init gradient
			var defs = svg.append("defs");

			var gradID = "grad-" + id + "-" + num;
			var gradient = defs.append("linearGradient")
				.attr("id", gradID)
				.attr("x1", "0%")
				.attr("x2", "100%")
				.attr("y1", "0%")
				.attr("y2", "0%");

			gradient.selectAll("stop")
				.data(data)
				.enter().append("stop")
				.attr("offset", function(d, i) {
					var percentage = ((d.x2 - scaleLimits.xMin) / (scaleLimits.xMax - scaleLimits.xMin)) * 100;
					return percentage + "%";//i/(data.length-1);
				})
				.attr("stop-color", function(d) { return d.color; })
				.attr("stop-opacity", 1);

			var lineColour = data[0].color
			var lineColourClass = "colour-" + lineColour;
			if(lineColour.charAt(0) === '#')
			{
				lineColourClass = "colour-" + lineColour.substring(1);
			}

			// Check if printCSSClasses already contains an object with className equal to lineColourClass
			var containsClass = printCSSClasses.some(function(obj) {
				return obj.className === lineColourClass;
			});

			if (!containsClass) {
				// Add the object to printCSSClasses if the className is not already present
				printCSSClasses.push({
				  className: lineColourClass,
				  class: "path.line." + lineColourClass + "{\n \t stroke:" + lineColour + "!important; \n \t fill:transparent!important; \n}"
				});
			}
            // Add path element for line
            svg.append("path")
                .datum(data)
                .attr("class", type + " line line-ecg " + lineColourClass)
                .attr("d", line)
				.style("stroke", function(dt) {
                    var arr = [D3.max(dt, function(d) { return d.y1; }),
                        D3.max(dt, function(d) { return d.y2; }),
                        D3.min(dt, function(d) { return d.y1; }),
                        D3.min(dt, function(d) { return d.y2; })]
                    var allEqual = _.every(arr, function(item) {
                        return item === arr[0];
                    });
                    if(allEqual){
                        return dt[0].color;
                    }else{
                        return "url(#" + gradID +")"
                    }

                })
                .style("stroke-width", "2px")
                .style("fill", "transparent");

				 // ChartViewOptions DropdownList
				 if(!this.doesChartOptionsListContains(type)){
					this.chartViewOptionsList.push({
						chartID: type,
						visible: this.isChartVisible(id, type),
						chartLabel:type,// data[1].chart_line_label,
						type:"path"
					});
				}

/*
			svg.selectAll("circle")
				.data(data)
				.enter()
				.append("circle")
				.attr("cx", function(d) { return xScale(d.x2); })
				.attr("cy", function(d) { return yScale(d.y2); })
				.attr("r", 2)
				.attr("fill","none")
				.each(function (d) {
					// Do we have a tooltip to show?
					// if (element.tooltip) {
						// console.log(" >>>>>>>>>>>>>>>>>>>>>>>> ", this)
						// Initialise a popover for this element
						Commands.execute('init:popover', {
							container: container,
							element: $(this),
							data: { headline:'', text:d.label }
						});
						RectMap.addItem(rowId, 0, width, this)
					// }
						
					}
					
				);
			*/
			svg.selectAll("circle")
				.data(data)
				.enter().append('circle')
				.attr('class', "ecg-rect-tt")
				.attr("cx", function(d) { return xScale(d.x2); })
				.attr("cy", function(d) { return yScale(d.y2); })
				.attr("r", 2)
				.attr('fill', 'none')
				.each(function(d, i) {

					// Initialise a popover for this element
					Commands.execute('init:popover', {
						container: container,
						element: $(this),
						data: that.getTooltipText(type + ": " + d.label, "5")//element.tooltip
					});
					RectMap.addItem(rowId,  xScale(d.x2),  yScale(d.y2), $(this));
				});

			// Build-up map of tooltips $(svgElement[0]).children('rect').last()
			
			

			setTimeout(function() {
				RectMap.addRow(rowId, chartWrapper);
			}.bind(this));
        },

        loadECG: function(width, height, acceleratorItems) {


           // var fromDate = new Moment(this.options.meta.chartDate).format('YYYY-MM-DD');


            var ecgURL =  'https://api.justchecking.com/chart/v4/lines/' + this.model.get('chartLineId') + '?zoom=' + this.options.meta.zoom + '&from=' + this.options.meta.chartDate + '&lineWidth=' + width + '&lineHeight=' + height;

            var that = this;

            var xhr = new XMLHttpRequest();

			var token = State.getBearerObject().access_token;

            xhr.open("GET", ecgURL, true); // true for asynchronous request

			xhr.setRequestHeader('Authorization', 'Bearer ' + token)

            xhr.onreadystatechange = function() {
				if (xhr.readyState === XMLHttpRequest.DONE) { // readyState 4 means request is complete
					if (xhr.status === 200) { // status 200 means success
					var data = JSON.parse(xhr.responseText);
					// Run code on success here
					// Data loaded, empty div ready to redraw
					that.$el.find('chartAll').html('&nbsp;')
					if(data["2"] && data["2"].length){
						// temperature
						that.initChart(data["2"], "temperature", "2", width, height);
					}
					if(data["3"] && data["3"].length){
						// light
						that.initChart(data["3"], "light", "3", width, height);
					}
					if(data["5"] && data["5"].length){
						// sound
						that.initChart(data["5"], "sound", "5", width, height);
					}
					if(data["11"] && data["11"].length){
						// weight
						that.initChart(data["11"], "weight", "11", width, height);
					}
					if(data["16"] && data["16"].length){
						// acceleration
						//that.initChart(data["16"], "acceleration", "16", width, height);
						that.showChartViewOptions = true;
						that.initAcceleratorData(data["16"], acceleratorItems)
					}
					that.showChartViewOptions = true;
					setTimeout(function(){
						that.syncChartOptionsList();
					}, 100);
					}
					else if(xhr.status === 403) {
						// User doesn't have ECG API access
						that.showChartViewOptions = false;
						return null;
					} else {
					//console.error("OLD SCHOOL >>>> Error:", xhr.statusText);
						// Run code on error here
						// ChartViewOptions DropdownList
						that.showChartViewOptions = true;
						that.syncChartOptionsList();
					}
					// store printCSSClasses to session storage
					sessionStorage.setItem('printCSSClasses', JSON.stringify(printCSSClasses));
				}
            };

            xhr.send();


        },	


    });

});
