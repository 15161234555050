/**
Chart Application - Controls Button View
@module Chart
@submodule Chart.Views.Controls.Button
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
Contents:
  * Controls Button View
    * template
    * tagName
    * className
    * events
    * serializeData
    * setFormat
    * navigate
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.controls.item',[

  'marionette',
  'app/app.vent',
  'tpl!app/modules/charts/templates/charts.templates.controls.item.tmpl',
  'moment'

], function(Marionette, Vent, Template, Moment) {

  'use strict';

  /**
  Controls Button View
  @class Chart.Views.Controls.Button
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property tagName
    @type String
    */
    tagName: 'li',

    /**
    @property className
    @type String
    */
    className: function() {

      var
      className = (this.options.zoom === 10) ? 'chart-day-menu-hour' : '';

      // Is this the selected button?
      if (this.model.get('selected')) {

        className += ' active';

      }
      // Is the button disabled?
      else if (!this.model.get('enabled')) {

        className += ' disabled';

      }

      return className;

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click': 'navigate'

    },

    /**
    @method serializeData
    */
    serializeData: function() {

      return {

        'navLabel': this.model.get('navLabel'),
        'topFormat': this.setFormat().topFormat,
        'bottomFormat': this.setFormat().bottomFormat,
        'zoom': (this.options.zoom === 10)
      
      };
    
    },

    /**
    @method setFormat
    @return The format required for the timeline values
    */
    setFormat: function() {

      return {

        topFormat: (this.options.zoom === 10) ? 'HH' : 'dd',
        bottomFormat: (this.options.zoom === 10) ? 'a' : 'D'

      };

    },

    /**
    @method onRender
    */
    navigate: function(e) {

      // Is the target already active?
      if ($(e.currentTarget).hasClass('active')) {

        console.log('no clicky');

      }
      else {

        // Navigate the chart app
        Vent.trigger('chart:navigate', {

          date: this.model.get('navData')

        });

      }

      e.preventDefault();

    }

  });

});

