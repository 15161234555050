/**
Application Layout - Topbar Layout
@module Layout
@submodule Layout.Layouts.Topbar
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
	* Commands
Contents:
  * Topbar Layout
		* template
		* regions
Author(s):
  * Just Checking
*/

define('app/layout/layouts/layout.layouts.header.menu.topbar',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.header.menu.topbar.tmpl',
	'app/commands/commands.vent'

], function(Marionette, Template, Commands) {

  'use strict';

  /**
  Topbar Layout
  @class Layout.Layouts.Topbar
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

      title: '#page-header-title',
      buttonLeft: '#page-header-button-left',
      buttonOptional: '#page-header-button-optional',
      buttonRight: '#page-header-button-right'

    }

  });

});

