define('app/commands/commands.sequencesReportLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/views/views.sequencesReportLightbox','app/models/sequencesReportLightbox.model','app/app'],function (require) {
	'use strict';

	var Commands            = require('app/commands/commands.vent'),
		Requests            = require('app/requests/requests.vent'),
		Lightbox            = require('app/views/views.sequencesReportLightbox'),
		Model 	            = require('app/models/sequencesReportLightbox.model');

		return {
			initialize: function() {
				return Commands.setHandler('app:sequencesReportLightbox', function(options) {
					var App = require('app/app'),
                        cacheinstall = options.installationId,
                        model = new Model({
                            _id: cacheinstall,
							eventId: options.eventId
                        });

					model.fetch({
						success: function(model) {
							App.lightbox.show(new Lightbox({
								model: model
							}));
						}
					});
				});
			}
		};
});




