/**
Application Logins - Logins Item View
@module Logins
@submodule Logins.Views.Item
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Logins Item View
    * tagName
    * template
Author(s):
  * Just Checking
*/

define('app/modules/logins/views/logins.views.item',[

  'marionette',
  'tpl!app/modules/logins/templates/logins.templates.item.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Logins Item View
  @class Logins.Views.Item
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property tagName
    @type String
    */
    tagName: 'tr',

    /**
    @property template
    @type Template
    */
    template: Template

  });

});

