define('app/modules/newcharts/7day/layouts/main.layout',[
    'marionette',
    'app/app.vent',
    'tpl!app/modules/newcharts/7day/templates/main.template.tmpl',
    'moment',
    'switcher',
    'app/modules/newcharts/models/chart.model',
    'app/modules/newcharts/7day/views/chart-mini.view',
    'tpl!app/modules/newcharts/7day/templates/main.pdf.template.tmpl'
], function (Marionette, Vent, Template, Moment, Switcher, ChartModel, ChartMiniView, SevenDayPDF) {
    'use strict';

    return Marionette.Layout.extend({

        template: Template,

        regions: {
            'chart1': '#chart1',
            'chart2': '#chart2',
            'chart3': '#chart3',
            'chart4': '#chart4',
            'chart5': '#chart5',
            'chart6': '#chart6',
            'chart7': '#chart7'
        },

        ui: {
            closeBtn: '.exitform',
            page: '#page',
            loader: '.loader-container',
            previousBtn: '#previousBtn',
            nextBtn: '#nextBtn',
            printBtn: '.printcontainer',
            switchNightViewWrapper: '#switchSevenDayNightView-wrapper',
            switchNightView: '#switchSevenDayNightView'
        },

        events: {
            'click @ui.closeBtn': 'close',
            'click @ui.previousBtn': 'back',
            'click @ui.nextBtn': 'forward',
            'click @ui.printBtn': 'print',
            'change @ui.switchNightView': 'toggleNightView'
        },

        initialize: function () {
            this.render();
        },

        onShow: function() {
            $('.overlay').show();
            Switcher.init({
                view: this.ui.switchNightViewWrapper
            });

            this.loadCharts();
        },

        loadCharts: function(nightView) {
            if (nightView) {
                nightView = nightView == true;
            }

            this.ui.page.hide();
            this.ui.loader.show().spin();

            var startDate = new Moment(this.model.get('startDate')),
                fetched = 0,
                model,
                models = [],
                slot = 1;

            if (nightView) {
                startDate.add(12, 'hours');
                $('#modal-title').text('7 Night View');
            } else {
                $('#modal-title').text('7 Day View');
            }

            // Go back to the previous Monday if its not Monday.
            if (startDate.day() != 1) {
                startDate.day("Monday");
            }

            // Check if the user can go back or forward
            if (startDate.isAfter(new Moment(this.model.get('chartMeta').installationStart))) {
                this.ui.previousBtn.removeAttr('disabled');
            } else {
                this.ui.previousBtn.attr('disabled', 'disabled');
            }
            if (this.canGoNext()) {
                this.ui.nextBtn.removeAttr('disabled');
            } else {
                this.ui.nextBtn.attr('disabled', 'disabled');
            }

            while (slot < 8) {
                model = new ChartModel({
                    _id: this.model.get('chartMeta').installationId,
                    slot: slot,
                    requestedDate: startDate.format('YYYY-MM-DDTHH:mm:ss')
                });
                model.fetch({
                    data: {
                        date: startDate.format('YYYY-MM-DDTHH:mm:ss'),
                        zoom: 120,
                        minimal: 1
                    },
                    success: function (model) {
                        fetched++;
                        this['chart' + model.get('slot')].show(new ChartMiniView({
                            model: model,
                            collection: model.get('chartRows')
                        }));
                        if (fetched == models.length) {
                            fetched = 0;
                            this.ui.loader.hide().spin(false);
                            this.ui.page.show();
                        }
                    }.bind(this)
                });
                models.push(model);
                slot++;
                startDate.add(1, 'days');
            }
        },

        toggleNightView: function(e) {
            this.loadCharts(!$(e.target).is(':checked'));
        },

        close: function() {
            this.$el.find('.lightboxevaluation').remove();
            $('.overlay').hide();
        },

        back: function() {
            this.model.set('startDate', this.model.get('startDate').subtract(7, 'days'));

            this.loadCharts(!this.ui.switchNightView.is(':checked'));
        },

        forward: function() {
            this.model.set('startDate', this.model.get('startDate').add(7, 'days'));

            this.loadCharts(!this.ui.switchNightView.is(':checked'));
        },

        print: function() {
            function generateForm(params) {
                var url = '/api/v1/Generate/PDF',
                    html = "<form action='" + url + "' method='post' style='display: none;'>";

                _.each(params, function(value, key) {
                    if (key == 'html') {
                        value = value.replace(/'/g, "\'").replace(/'/g, "&apos;");
                        value = encodeURI(value);
                    }
                    html += "<input type='hidden' name='" + key + "' value='" + value + "' />";
                });

                html += "</form>";

                $(html).appendTo('body').submit().remove();
            }

            generateForm({
                html: SevenDayPDF({
                    mainCSS: document.querySelector("link[href*='.css']").href,
                    html: this.ui.page.html(),
                    generated: new Moment().format('LLL'),
                    chartTitle: this.model.get('chartMeta').chartTitle,
                    systemNum: this.model.get('chartMeta').systemNo
                })
            });
        },

		canGoNext: function() {
			var installationEnd = this.model.get('chartMeta').installationEnd ? new Moment(this.model.get('chartMeta').installationEnd).startOf('day') : null,
				start = new Moment(this.model.get('startDate')).day('Monday'),
				end = start.clone().add(6, 'days'),
				today = moment.utc();

			if (installationEnd) {
				return !(installationEnd.isSame(end) || installationEnd.isBefore(end));
			}

			return end.isBefore(today);
		}
    })

});
