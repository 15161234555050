/**
Main Application
*/

/*
Requires:
  * Require
  * App
Contents:
  * Require config
  * Start the application
Author(s):
  * Just Checking
*/

/*
Require config
*/

var version = '3.9.30';


require.config({
  waitSeconds: 9999999999,
  paths: {
    bugsnag: 'vendor/components/bugsnag/src/bugsnag',
    jquery: 'vendor/components/jquery/dist/jquery.min',
    'jquery.ui': 'vendor/jquery/jquery-ui-1.11.4.custom',
    underscore: 'vendor/components/underscore/underscore',
    backbone: 'vendor/components/backbone/backbone',
    marionette: 'vendor/components/marionette/lib/core/amd/backbone.marionette',
    'backbone.wreqr': 'vendor/components/backbone.wreqr/lib/backbone.wreqr',
    'backbone.babysitter': 'vendor/components/backbone.babysitter/lib/backbone.babysitter',
    tpl: 'vendor/components/requirejs-tpl/tpl',
    'backbone.crudder': 'vendor/plugins/backbone.crudder-amd',
    'backbone.crudder.settings': 'app/config/backbone/config.backbone.crudder',
    'backbone.apiRoot': 'vendor/plugins/backbone.apiRoot',
    'backbone.relational': 'vendor/components/Backbone-relational/backbone-relational',
    'backbone.memento': 'vendor/components/backbone.memento/backbone.memento',
    'backbone.syphon': 'vendor/components/backbone.syphon/lib/amd/backbone.syphon',
    'backbone.mutators': 'vendor/components/backbone.mutators/backbone.mutators',
    'backbone.modelbinder': 'vendor/components/backbone.modelbinder/Backbone.ModelBinder',
    'backbone.historytracker': 'vendor/components/backbone-historytracker/backbone.historytracker',
    'backbone.validation': 'vendor/components/backbone.validation/dist/backbone-validation-amd',
    'backbone.routefilter': 'vendor/plugins/backbone.routefilter',
    detect: 'lib/detect',
    'ajax.init': 'app/config/ajax/config.ajax.init',
    'ajax.loader': 'app/config/ajax/config.ajax.loader',
    phonenumber: 'lib/phonenumber',
    switcher: 'lib/switch',
    hammer: 'vendor/components/hammerjs/hammer',
    'jquery.hammer': 'vendor/components/jquery-hammerjs/jquery.hammer-standalone',
    'jquery.cookie': 'vendor/components/jquery.cookie/jquery.cookie',
    'jquery.resize': 'vendor/components/jquery-resize/jquery.ba-resize',
    'jquery.toggleWrapper': 'vendor/plugins/jquery.toggleWrapper',
    placeholder: 'vendor/plugins/jquery.placeholder',
    alertify: 'vendor/components/alertify.js/lib/alertify',
    mobiscroll: 'vendor/mobiscroll/mobiscroll.custom-2.9.5.min',
    'mobiscroll.integration': 'vendor/mobiscroll/mobiscroll.integration.bb-2.9.5',
    'mobiscroll.integration.calendar': 'vendor/mobiscroll/mobiscroll.integration.bb.calendar-2.9.5',
    validation: 'lib/validation',
    utils: 'lib/utils',
    resize: 'lib/resize',
    d3: 'vendor/components/d3/d3',
    'jquery.panels': 'vendor/plugins/jquery.panels',
    'bootstrap.dropdown': 'vendor/components/bootstrap/js/dropdown',
    'bootstrap.tooltip': 'vendor/components/bootstrap/js/tooltip',
    'bootstrap.popover': 'vendor/components/bootstrap/js/popover',
    moment: 'vendor/components/momentjs/moment',
    spin: 'vendor/components/spinjs/spin',
    'jquery.spin': 'vendor/components/spinjs/jquery.spin',
    'jquery.okeyDokey': 'vendor/plugins/jquery.okeyDokey',
    parsley: 'vendor/components/parsleyjs/dist/parsley.min',
    chained: 'vendor/plugins/jquery.chained.min',
    grumble: 'vendor/plugins/jquery.grumble.min',
    crumble: 'vendor/plugins/jquery.crumble.min',
    'jquery.timepicker': 'vendor/components/jt.timepicker/jquery.timepicker',
    chartjs: 'vendor/components/chart.js/dist/Chart.min',
    store2: 'vendor/components/store2/src/store2'
  },
  shim: {
    jquery: {
      exports: 'jQuery'
    },
    'jquery.ui': {
      deps: ['jquery']
    },
    underscore: {
      exports: '_'
    },
    backbone: {
      deps: ['jquery', 'underscore'],
      exports: 'Backbone'
    },
    'backbone.wreqr': {
      deps: ['backbone']
    },
    'backbone.babysitter': {
      deps: ['backbone']
    },
    marionette: {
      deps: ['backbone', 'backbone.wreqr'],
      exports: 'Marionette'
    },
    'backbone.crudder': {
      deps: ['backbone'],
      exports: 'Backbone.Crudder'
    },
    'backbone.crudder.settings': {
      deps: ['backbone', 'backbone.crudder']
    },
    'backbone.apiRoot': {
      deps: ['backbone']
    },
    'backbone.relational': {
      deps: ['backbone']
    },
    'backbone.memento': {
      deps: ['backbone']
    },
    'backbone.syphon': {
      deps: ['backbone']
    },
    'backbone.mutators': {
      deps: ['backbone']
    },
    'backbone.modelbinder': {
      deps: ['backbone']
    },
    'backbone.historytracker': {
      deps: ['backbone']
    },
    'backbone.routefilter': {
      deps: ['backbone']
    },
    'backbone.validation': {
      deps: ['backbone']
    },
    'ajax.init': {
			deps: ['jquery']
    },
    'ajax.loader': {
			deps: ['jquery']
    },
    placeholder: {
			deps: ['jquery']
    },
    validation: {
			deps: ['jquery']
    },
    phonenumber: {
			deps: ['jquery']
    },
    'jquery.hammer': {
			deps: ['jquery', 'hammer']
    },
    'jquery.resize': {
      deps: ['jquery']
    },
    'jquery.toggleWrapper': {
      deps: ['jquery']
    },
    'jquery.cookie': {
      deps: ['jquery']
    },
    mobiscroll: {
			deps: ['jquery']
    },
    utils: {
			deps: ['jquery']
    },
    'jquery.panels': {
      deps: ['jquery', 'jquery.hammer']
    },
    'bootstrap.dropdown': {
      deps: ['jquery']
    },
    'bootstrap.tooltip': {
      deps: ['jquery']
    },
    'bootstrap.popover': {
      deps: ['jquery', 'bootstrap.tooltip']
    },
    'jquery.okeyDokey': {
      deps: ['jquery']
    },
    parsley:{
      deps: ['jquery']
    },
    chained:{
      deps: ['jquery']
    },
    grumble:{
      deps: ['jquery']
    },
    crumble:{
      deps: ['jquery', 'grumble']
    },
    'jquery.timepicker': {
      deps: ['jquery']
    }
  }
});

require.config({
	urlArgs: 'v=' + window.location.hostname === 'app.justchecking.com' ? version : (new Date()).getTime()
});

// Set whether you want the application to build for another country (Set to false to stick with english templates).
country = {
    name: "israel",
    lang: "he",
    pageTitle: "שגרתא",
    dir: 'rtl',
    pngDir: 'assets/images/png/shigrata',
    favicon: 'assets/images/png/shigrata/favicon.ico',
    stylesheets: [
        'israel'
    ]
};
country = false;

/*
Require the main application module
*/
require([
  'jquery',
  'bugsnag',
  'app/app',
  'app/modules/newcharts/state',
  'moment',
  'store2',
  'jquery.cookie',
  'jquery.timepicker'
], function(jQuery, Bugsnag, App, State, Moment) {
  'use strict';

  Bugsnag.apiKey = "f0b271968b72df473be150b8dc7ddf09";
  Bugsnag.notifyReleaseStages = ["production"];
  Bugsnag.appVersion = version;
  Bugsnag.releaseStage = function() {
	  if(window.location.hostname.toString().indexOf('.jc001.co.uk') > -1) {
		  return 'staging';
	  }

	  switch(window.location.hostname.toString()) {
		  case 'app.justchecking.com':
		  case 'justchecking.co.il':
			  return 'production';

		  default:
			  return 'local';
	  }
  }();
  console.log(" >>>>>>>>> ")
  console.log(Bugsnag.releaseStage)
  jQuery.propHooks.disabled = {
		set: function (el, value) {
			if (el.disabled !== value) {
				el.disabled = value;
				value && $(el).trigger('disabledSet');
				!value && $(el).trigger('enabledSet');
    	}
  	}
  };

  // Record app sessions via smartlook
  if (Bugsnag.releaseStage == 'production') {
	  window.smartlook||(function(d) {
		  var o=window.smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
		  var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
		  c.charset='utf-8';c.src='//rec.smartlook.com/recorder.js';h.appendChild(c);
	  })(document);
	  window.smartlook('init', '0a5ab74feb69a8c6d7894b48e398ea4d5f570ce3'); // Start recording in SmartLook.
  }

  /*
    HTML resets for foreign countries
   */
  if (typeof country == "object") {
      if (country.pageTitle) {
          $('title').text(country.pageTitle);
          $('meta[name="apple-mobile-web-app-title"]').attr('content', country.pageTitle);
      }
      if (country.lang) {
          $('head').append('<meta http-equiv="Content-Language" content="' + country.lang + '" />');
          $('html').prop('lang', country.lang);
      }
      if (country.dir) {
          //$('html').prop('dir', country.dir);
      }
      if (country.pngDir) {
          $('link[rel=apple-touch-icon]').each(function () {
              var originalSrc = $(this).attr('href');

              $(this).attr('href', originalSrc.replace('assets/images/png', country.pngDir));
          });
          $('link[rel="shortcut icon"]').attr('href', country.favicon);
      }
      if (country.stylesheets.length > 0) {
          var head = $('head');

          for (var i = 0; i < country.stylesheets.length; i++) {
              head.append('<link rel="stylesheet" href="stylesheets/i18n/' + country.stylesheets[i] + '.css" type="text/css">');
          }
      }
  } else {
      $('title').text("Just Checking");
      $('html').prop('lang', "en");
      $('head').append('<meta http-equiv="Content-Language" content="en" />');
  }

  if (!State.isMouse()) {
      var moves = 0,
          mouseHandler = function(e) {
              moves++;

              setTimeout(function() {
                  $(window).unbind('mousemove', mouseHandler);
              }.bind(this), 0);

              if (moves > 1) {
                  $('html').addClass('mouse');
                  State.rememberMouse();
              }
          }.bind(this);

      $(window).mousemove(mouseHandler);
  } else {
      $('html').addClass('mouse');
  }

  $('#portrait-overlay').on('touchmove', function(e) {
      e.preventDefault();
      return false;
  });

  try {
  	if (window.self !== window.top) {
		$('#portrait-overlay').remove();
	}
  } catch(e) {
  	// Do nothing.
  }

  // Newer iPhone - Stop pinch to zoom
  document.documentElement.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
	  event.preventDefault();
	}
  }, false);

  // Fix iFrame bug with Chrome 92
  function inIFrame() {
	try {
	  return window.self !== window.top
	} catch (e) {
	  return true;
	}
  }
  if (inIFrame()) {
	// Disable alerts
	window.alert = function() {};

	// Confirms always return true
	window.confirm = function() { return true; };
  }

  google.charts.load('current', {'packages':['corechart', 'table']});

  // Moment polyfill for global moment
  window['moment'] = Moment;


  

  App.start();
});

define("main", function(){});

