/**
Application Commands - When Fetched
@module Commands
@submodule Commands.WhenFetched
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
Author(s):
  * Just Checking
*/

define('app/commands/commands.whenFetched',[

  'jquery',
	'backbone',
	'app/commands/commands.vent'

], function($, Backbone, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Toggle Content Class
			@event when:fetched
      @param entities {Object} The entities to fetch
      @param callback {Function} Callback function
			@param debug {Boolean} Option to debug the code
			*/
			return Commands.setHandler('when:fetched', function(entities, callback, debug) {

        var xhrs;
        
        // Combine the entities and then pluck the _fetch object
        xhrs = _.chain([entities]).flatten().pluck('_fetch').value();

        // Debug
        if (debug) {

          console.log('xhr:');
          console.log(xhrs);

        }
        
        // When the AJAX request is done call the callback
        return $.when.apply($.ajax, xhrs).done(callback).fail(function() {

          // Debug
          if (debug) {

            console.warn('AJAX call failed');

          }

        });
      
      });

    }

  };

});

