/**
Chart Application - Row View
@module Chart
@submodule Chart.Views.Row
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * D3
Contents:
  * Row View
    * template
    * tagName
    * className
    * attributes
    * ui
    * events
    * onRender
    * hideRow
    * showRow
    * toggleHideButtons
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.rows.item',[

  'marionette',
  'app/app.vent',
  'tpl!app/modules/charts/templates/charts.templates.row.tmpl',
  'app/commands/commands.vent',
  'd3',
  'bootstrap.popover'

], function(Marionette, Vent, Template, Commands, D3, Popover) {

  'use strict';

  /**
  Row View
  @class Chart.Views.Row
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property tagName
    @type String
    */
    tagName: 'li',

    /**
    @property className
    @type Function
    */
    className: function() {

      var classes = 'clearfix';

      // Is there inline editing?
      if (this.options.inlineEditing) {

        // add the move cursor class
      //  classes += ' cursor-move';

      }

      return classes;

    },

    /**
    @property attributes
    @type Object
    */
    attributes: function() {

      // Add the target date to the button as a data-* attribute
      return {

        'id': 'chartRow' + this.model.get('chartLineId'),
        'data-id': this.model.get('chartLineId')

      };

    },

    /**
    @property ui
    @type Object
    */
    ui: {

      chartLineLabel: '.chart-row-label',
      chartLabel: '.chart-label',
      chartDataWrapper: '.chart-data-wrapper',
      hideRowButton: '#chart-row-hide-button',
      showRowButton: '#chart-row-show-button'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click @ui.hideRowButton': 'hideRow',
      'click @ui.showRowButton': 'showRow'

    },

    /**
    @method onRender
    */
    onRender: function() {

      var that = this;

      /*
      Initial visibility
      */

      // Is this row to be hidden?
      if (!this.model.get('rowShow')) {

        // Hide the row
        this.hideRow();

      }

      /*
      Display the chart data
      */

      var
      uniqueId = this.model.id,
      rowData = this.model.get('chartLineData'),
      rowDataWrapper = this.$el.find('.chart-data'),
      svgElement;

      //console.log(rowData);

      // Add the svg element with the pattern for offline alerts
      D3.select(rowDataWrapper[0])
        .append('svg')
        .attr('id', 'rowData' + uniqueId)
        .attr('width', '100%')
        .append('defs')
        .append('pattern')
        .attr('id', 'diagonalHatch')
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', '10')
        .attr('height', '10')
        .append('g')
        .attr('class', 'offline-hatch')
        .append('path')
        .attr('d', 'M0,10 l10,-10')
      ;

      // Select the newly created SVG element
      svgElement = rowDataWrapper.find('#rowData' + uniqueId);
      //console.log(svgElement);

      // Loop through each alert type
      _.each(rowData, function(element, index) {

        // Loop through each data item for this alert type
        _.each(element.dataItems, function(value, key) {

          /*
          'movement', 'doorEvent', 'doorAjar', 'interpretedAjar', 'nonOcc', 'nonOccRolling', 'dataLoss', 'offline', 'powerOff', 'powerOffRecorded', 'currentTime'
          */

          // Append the svg element with a rect element
          D3.select(svgElement[0])
            .append('rect')
            .attr('class', element.dataType)
            .attr('style', function() {

              if (element.dataType === 'offline' || element.dataType === 'dataLoss') {

                return 'fill:url(#diagonalHatch)';

              }
              else {

                return '';

              }

            })
            .attr('x', function() {

              // Return the data x position value
              return value.x;

            })
            .attr('y', function() {

              // Set the correct y position depending on data type
              switch(element.dataType) {

                case 'movement':

                  return 4;

                case 'doorAjar':

                  return 22;

                case 'interpretedAjar':

                  return 22;

                case 'doorEvent':

                  return 10;

                default:

                  return 0;

              }

            })
            .attr('width', function() {

              // Return the data width value
              return value.w > 2 ? value.w + 1 : value.w;

            })
            .attr('height', function() {

              // Set the correct y position depending on data type
              switch(element.dataType) {

                case 'motion':

                  return 26;

                case 'doorAjar':

                  return 8;

                case 'interpretedAjar':

                  return 8;

                case 'doorEvent':

                  return 20;

                default:

                  return 30;

              }

            })
            .each(function() {

              // Do we have a tooltip to show?
              if (element.tooltip) {

                // Initialise a popover for this element
                Commands.execute('init:popover', {

                  container: that.$el,
                  element: $(this),
                  data: element.tooltip

                });

              }

            });

        });

      });

    },

    /**
    @method hideRow
    */
    hideRow: function(e) {

      // Fade out the row elements
      this.ui.chartLabel.addClass('half-opacity');
      this.ui.chartDataWrapper.addClass('half-opacity');

      // Toggle the eye icons
      this.toggleHideButtons();

      // Update the model
      this.model.set({

        rowShow: false

      },
      {

        silent: true

      });

      e.preventDefault();

    },

    /**
    @method showRow
    */
    showRow: function(e) {

      // Fade in the row elements
      this.ui.chartLabel.removeClass('half-opacity');
      this.ui.chartDataWrapper.removeClass('half-opacity');

      // Toggle the eye icons
      this.toggleHideButtons();

      // Update the model
      this.model.set({

        rowShow: true

      },
      {

        silent: true

      });

      e.preventDefault();

    },

    /**
    @method toggleHideButtons
    */
    toggleHideButtons: function(e) {

      // Toggle the eye icons
      this.ui.hideRowButton.toggle();
      this.ui.showRowButton.toggle();

      // Store the changes
      Vent.trigger('chart:store:rows');

    }

  });

});

