define('app/commands/commands.outcomePredictionLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/views/views.outcomePredictionLightbox','app/models/chaining.model','app/app'],function (require) {
	'use strict';

	var Commands            = require('app/commands/commands.vent'),
		Requests            = require('app/requests/requests.vent'),
		Lightbox            = require('app/views/views.outcomePredictionLightbox'),
		Model 	            = require('app/models/chaining.model');

		return {
			initialize: function() {
				return Commands.setHandler('app:outcomePredictionLightbox', function(options) {
					var App = require('app/app'),
                        cacheinstall = options.installationId,
                        model = new Model({
                            _id: cacheinstall,
							outcomes: [
								'No Change',
								'Reduced Dependency',
								'Discharged',
								'Increased Care',
								'Re-admission to hospital'
							]
                        });

					model.fetch({
						success: function(model) {
							var view = new Lightbox({
								model: model,
								closeCallback: options.callback
							});

							App.lightbox.show(view);
						}
					});
				});
			}
		};
});




