define('app/modules/newcharts/features/doorActivity/models/doorActivity.model',['require','backbone','moment'],function (require) {
    'use strict';

    var Backbone = require('backbone'),
        Moment = require('moment');

    return Backbone.Model.extend({
        idAttribute: '_id',

        url: function() {
            var params = {
                    type: 'doorUsage',
                    previousDate: this.get('week1') ? this.get('week1').format('YYYY-MM-DD') : null,
                    recentDate: this.get('week2') ? this.get('week2').format('YYYY-MM-DD') : null,
                    timeRangeStart: this.get('timeFrom'),
                    timeRangeEnd: this.get('timeTo')
                },
                query = [];

            if (this.get('sensorId') != '') {
                params.chartId = this.get('sensorId');
            }

            for (var key in params) {
                query.push(key + '=' + encodeURIComponent(params[key]));
            }

            return 'api/v1/Insights/' + this.id + '?' + query.join('&');
        },

        defaults: {
            week1: new Moment().subtract(6, 'days'),
            week2: new Moment().subtract(13, 'days'),
            timeFrom: '00:00',
            timeTo: '00:00',
            sensorId: ''
        }
    });

});
