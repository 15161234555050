/**
Application Commands - App Navigate Top
@module Commands
@submodule Commands.AppNavigateTop
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
Contents:
  * Module Initialize
		* Back to the top
Author(s):
  * Just Checking
*/

define('app/commands/commands.appNavigateTop',[

  'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Back to the top
			@event app:navigate:top
			*/
			return Commands.setHandler('app:navigate:top', function() {

				// Jump to the top of the page
				$('html, body').animate({ scrollTop: window }, 0);

			});

    }

  };

});

