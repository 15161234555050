/**
Application Central Commands Vent
@module Commands
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
Contents:
  * Return a new command object
Author(s): 
  * Just Checking
*/

define('app/commands/commands.vent',[

	'backbone',
  'marionette'

], function(Backbone, Marionette) {

  // Return a new command object
  return new Backbone.Wreqr.Commands();

});
