define('app/commands/commands.onBoarding',[

	'backbone',
	'app/commands/commands.vent',
	'grumble',
	'jquery.cookie'

], function(Backbone, Commands, grumble, cookie) {

  'use strict';


return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {


      		/**
			Initialise a controller
			@event init:controller
			@param element {Object} data passed in when firing the command.
			*/
			return Commands.setHandler('init:onBoard', function(details) {

				//check if the user hasn't already seen the tour
				if($.cookie('showTour') !== 'false'){

					var turnOffTour = document.createElement('div');
					turnOffTour.innerHTML = "Turn off help bubbles";
					turnOffTour.id = "closeTour";

					turnOffTour.onclick = function(){
						//set the show tour cookie to hide the tour for 10 years
						$.cookie('showTour','false', { expires: 365*10, path: '/' });
						$('#closeTour').fadeOut('slow');
					};

					document.getElementsByTagName('body')[0].appendChild(turnOffTour);


					switch(details.page) {
                        case 'systems':
					    	//Hide all the grumble bubbles!
					    	$('.grumble').css('display', 'none');
					    	$('.grumble-text').css('display', 'none');


					        // Note: Loading help bubbles moved to systems.view.list@renderFeaturePromotion
						break;

					    case 'installations':

					    	//Hide all the grumble bubbles!
					    	$('.grumble').css('display', 'none');
					    	$('.grumble-text').css('display', 'none');


				    		$('#system-detail button:not(.button-group button):eq(0)').grumble({
								text: 'Create or end an installation',
								angle: 180
							});

							$('.pure-button[title=Chart]').first().grumble({
								text: 'View the chart',
								angle: 315
							});

							$('.pure-button[title="Chart Setup"]').first().grumble({
								text: 'Change sensor labels &amp; chart details',
								angle: 0
							});

							$('.pure-button[title="Chart Users"]').first().grumble({
								text: 'Add or remove users',
								angle: 180
							});

							$('#system-installations h2').grumble({
								text: 'Historical charts',
								angle: 45
							});
					        break;

					    case 'chartUsers':

					    	//Hide all the grumble bubbles!
					    	$('.grumble').css('display', 'none');
					    	$('.grumble-text').css('display', 'none');

					    	$('.new-user').first().grumble({
								text: 'Give someone access',
								angle: 45
							});

							$('.menu-secondary').first().grumble({
								text: 'Click on a user to see their access level',
								angle: 90
							});

					    	break;

					    case 'chartSetup':

					    	//Hide all the grumble bubbles!
					    	$('.grumble').css('display', 'none');
					    	$('.grumble-text').css('display', 'none');

					    	$('button[title=Installations]').grumble({
								text: 'Manage installations and see historical charts',
								angle: 180
							});

							$('#chart-edit-title').grumble({
								text: 'Update the chart title',
								angle: 315
							});

							$('#switch-on').grumble({
								text: 'Turn Non-Occupancy on/off on the chart',
								angle: 0
							});


					    	$('#chart-sensor-list').grumble({
								text: 'Re-add previously removed sensors',
								angle: 180
							});

					    	$('#chart-edit-rows-header').grumble({
								text: 'Drag &amp; drop to re-order, re-label &amp; remove sensor rows',
								angle: 45,
								distance: -160
							});

					    	break;


					    case 'chart':

					    	//Hide all the grumble bubbles!
					    	$('.grumble').css('display', 'none');
					    	$('.grumble-text').css('display', 'none');



					    	$('#chart-menu-main li:eq(0)').grumble({
								text: 'Select a date to view',
								angle: 270
							});

					    	$('#chart-logins-button').grumble({
								text: 'View logins to this installation',
								angle: 0
							});

					    	// View menu gets hidden on zoom and not removed from the page so this is a workaround to not show the bubble on the page when zoomed
					    	if($('#views').css('display') !== 'none'){
						    	$('#views').grumble({
									text: 'View 7 days or switch to Night View',
									angle: 180
								});
					    	}


					    	$('#drop-settings').grumble({
								text: 'Setup users, change chart details &amp; manage installations',
								angle: 0
							});

							$('.chart-row-hide a').first().grumble({
								text: 'Fade out chart rows to help look at activities',
								angle: 180
							});


					    	$('.chart-timeline-hours-wrapper').first().grumble({
								text: 'Click on a time to zoom-in, zoom-out using the magnifying glass',
								angle: 225
							});


					    	break;

					    	default:
					    		break;

					} //end of case statements

					/* Hide all help buttons on the page when you click or scroll */
					$(document.body).mousedown(function(){
						//Hide all the grumble bubbles!
				    	$('.grumble').fadeOut('slow');
				    	$('.grumble-text').fadeOut('slow');
					});


					$('#page-main').scroll(function(){
						//on hide if the user scrolls by a minimum amount otherwise when scrollbars are added to divs on page render this would hide all the bubbles :(
						if($(this).scrollTop() > 0){
							//Hide all the grumble bubbles!
					    	$('.grumble').fadeOut('slow');
				    		$('.grumble-text').fadeOut('slow');
				    	}
					});

				} //end of if

			}); //end of handler

    } //end of initailize

  }; //end of return

}); //end of define;
