define('app/modules/newcharts/features/notifications_family/views/notification.view',[
	'app/app.vent',
	'app/modules/newcharts/features/notifications/views/notification.view'
], function(Vent, ParentView) {
	'use strict';

	return ParentView.extend({

		loadEditForm: function() {
			if (this.model.get('disabled') == false) {
				Vent.trigger('newchart:notification-edit-family', {model: this.model});
			}
		}

	});

});
