define('app/modules/newcharts/features/events/views/event.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/events/templates/event.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder',
	'app/modules/newcharts/features/events/models/event_chart.model',
	'bugsnag'

], function(Marionette, Vent, Commands, Requests, Template, Moment, Switcher, Crudder, ChartModel, Bugsnag) {
	'use strict';

	return Marionette.ItemView.extend({

		tagName: 'tr',

		template: Template,

		ui: {
			editBtn: '.edit-btn',
			deleteBtn: '.delete-btn',
			chartBtn: '.chart-btn'
		},

		events: {
			'click @ui.editBtn': 'edit',
			'click @ui.deleteBtn': 'deleteEvent',
			'click @ui.chartBtn': 'viewChart'
		},

		onRender: function() {
			if (this.model.get('min') != null) {
				Commands.execute('init:popover', {
					container: $('body'),
					element: this.$el.find('.occurred > span'),
					data: {
						headline: '',
						text: 'Min: ' + this.humanAverage(moment.duration(this.model.get('min'), 'seconds')) +
						      '<br>Avg: ' + this.humanAverage(moment.duration(this.model.get('avg'), 'seconds')) +
						      '<br>Max: ' + this.humanAverage(moment.duration(this.model.get('max'), 'seconds')),
						placement: 'top'
					},
					html: true
				});
			}
		},

		edit: function() {
			Vent.trigger('newchart:event-form', {model: this.model});
		},

		deleteEvent: function() {
			Crudder.deleting({
				entity: this.model,
				successMessage: 'Event deleted successfully.',
				errorMessage: 'Unable to delete event.'
			}, function(response) {
				if (response.success) {
					Vent.trigger('newchart:event-list');
				}
			});
		},

		viewChart: function() {
			return Commands.execute('app:sequencesReportLightbox', {
				installationId: this.model.get('install_id'),
				eventId: this.model.get('event_id')
			});
		},

		humanAverage: function(momentDuration) {
			if (momentDuration.minutes() < 1) {
				return momentDuration.seconds() + ' second' + (momentDuration.seconds() != 1 ? 's' : '');
			} else {
				return momentDuration.minutes() + ' minute' + (momentDuration.minutes() != 1 ? 's' : '');
			}
		}

	});

});
