define('app/modules/newcharts/features/events/models/event.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		url: function() {
			if (!this.get('system_num')) {
				return 'api/v1/AccelerometerEvents/' + this.get('install_id') + '/default';
			}
			if (!this.get('event_id')) {
				return 'api/v1/AccelerometerEvents/' + this.get('install_id');
			}

			return 'api/v1/AccelerometerEvents/' + this.get('install_id') + '/' + this.get('event_id');
		},

		isNew: function() {
			return this.get('event_id') == false || this.get('event_id') == null;
		},

		defaults: {
			'start_time': '',
			'end_time': '',
			'all_day': false
		}
	});

});
