 /**
Main Application
*/

/*
Requires:
  * Backbone
  * Marionette
  * ApplicationConfig
  * Commands
  * Requests
  * CommandList
  * RequestList
  * Crudder
  * APIRoot
  * AJAX
  * MainApp
Contents:
  * Define the Marionette Application
  * App Root Route
  * Main Application Regions
  * Before Initialisation
    * Initialize all commands
    * Initialize all request
    * AJAX Initialise
    * API Root Initialise
    * Initialise Crudder
    * Initialise Backbone Crudder
    * Initialise Sync
    * Validation Config
    * Moment defaults
    * Mobiscroll defaults
  * Add Initialisers
    * API Root
    * Authentication check (Incomplete)
  * Post-Initialisation
    * Backbone History
    * Homepage route
Author(s):
  * Just Checking
*/

define('app/app',['require','marionette','ajax.init','app/config/backbone/config.backbone.validation','app/config/backbone/config.backbone.sync','app/config/marionette/config.marionette.application','app/base/routers/marionette/base.routers.marionette.router','app/base/controllers/marionette/base.controllers.marionette.controller','app/base/views/marionette/base.views.marionette.view','app/base/views/marionette/base.views.marionette.compositeView','app/base/models/backbone/base.models.backbone.model','app/base/collections/backbone/base.collections.backbone.collection','app/commands/commands.vent','app/requests/requests.vent','app/commands/commands.init','app/requests/requests.init','backbone.crudder.settings','backbone.apiRoot','backbone.historytracker','backbone.routefilter','app/app.module','app/modules/login/login.module','jquery.panels','jquery.toggleWrapper','moment','mobiscroll','parsley','bugsnag','app/modules/newcharts/state'],function (require) {

	'use strict';

	var Marionette 			= require('marionette'),
		AjaxConfig 			= require('ajax.init'),
		ValidationConfig 	= require('app/config/backbone/config.backbone.validation'),
		SyncConfig 			= require('app/config/backbone/config.backbone.sync'),
		ApplicationConfig 	= require('app/config/marionette/config.marionette.application'),
		BaseRouter 			= require('app/base/routers/marionette/base.routers.marionette.router'),
		BaseController 		= require('app/base/controllers/marionette/base.controllers.marionette.controller'),
		BaseView 			= require('app/base/views/marionette/base.views.marionette.view'),
		BaseCompositeView 	= require('app/base/views/marionette/base.views.marionette.compositeView'),
		BaseModel 			= require('app/base/models/backbone/base.models.backbone.model'),
		BaseCollection 		= require('app/base/collections/backbone/base.collections.backbone.collection'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		CommandList 		= require('app/commands/commands.init'),
		RequestList 		= require('app/requests/requests.init'),
		Crudder 			= require('backbone.crudder.settings'),
		APIRoot 			= require('backbone.apiRoot'),
		HistoryTracker 		= require('backbone.historytracker'),
		RouteFilter 		= require('backbone.routefilter'),
		MainApp 			= require('app/app.module'),
		LoginApp 			= require('app/modules/login/login.module'),
		Panels 				= require('jquery.panels'),
		ToggleWrapper 		= require('jquery.toggleWrapper'),
		Moment 				= require('moment'),
		Mobiscroll 			= require('mobiscroll'),
		Parsley 			= require('parsley'),
		Bugsnag				= require('bugsnag'),
		State               = require('app/modules/newcharts/state');

		/* Define the Marionette Application */
		var App = new Backbone.Marionette.Application();

		/* Main Application Regions */
		App.addRegions({
		    header: '#page-header',
		    main: '#page-main',
		    notifications: '#page-notifications',
		    panels: '#panel-wrapper',
		    highlights: '#highlights',
		    lightbox: '#lightboxcontainer'
		});

		/* Before Initialisation */
		App.on('initialize:before', function() {

		    /* Initialize all commands */
		    CommandList.initialize();

		    /* Initialize all request */
		    RequestList.initialize();

		    /* AJAX Initialise */
		    AjaxConfig.initialize();

		    /* API Root Initialise */
		    APIRoot.initialize();

		    /* Initialise Crudder */
		    Crudder.initialize();

		    /* Initialise Backbone Crudder */
		    Backbone.Crudder.initialize({
		        beforeSendActivated: true,
		        onCompleteActivated: true,
		        onSuccessActivated: true,
		        onErrorActivated: true
		    });

		    /* Initialise Sync */
		    SyncConfig.initialize();

		    /* Validation Config */
		    ValidationConfig.initialize();

		    /* Moment defaults */

		    // Set the default UTC format
		    Moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

		    /* Mobiscroll defaults */

		    // Set global config
		    $.mobiscroll.setDefaults({
		        display: 'bubble',
		        mode: 'scroller',
		        dateFormat: 'D ddd M yyyy',
		        timeFormat: 'H:ii',
		        timeWheels: 'Hii',
		        dateOrder: 'dMyy',
		        nowText: 'Today'
		    });

		});

		/* Add Initialisers */
		App.addInitializer(function() {
		    /* API Root */
		    Backbone.apiRoot = {
		        root: '/api/v1/'
		    };

		    /* Authentication check */
		    // TODO: How to authenticate at this point?
		    // Make an initial request to the API to check for a session?

		    var loggedIn = $.ajax('/api/v1/Account/session', {
			    	type: 'GET',
			    	async: true,
			    	xhrFields: {
			    	    withCredentials: false
			    	},
			        success: function(response) {
			    		if (window.smartlook && response.jcData) {
			    			if (response.jcData.email && response.jcData.email.trim() != '') {
								window.smartlook('tag', 'email', response.jcData.email.trim());
							}
							if (response.jcData.name && response.jcData.name.trim() != '') {
								window.smartlook('tag', 'name', response.jcData.name.trim());
							}
						}

			    		State.rememberEmail(response.jcData.email.trim());

			            /* Initialise the panels */
			            $('#panel-wrapper').panels({
			                topPanel: true,
			                rightPanel: true,
			                bottomPanel: true,
			                leftPanel: true,
			                touchEnabled: false,
			                touchActiveState: false,
			                onInit: function(instance) {
			                    // Add the plugin instance to the app state object so we can add events in views
			                    Commands.execute('set:state:data', {
			                        panels: instance
			                    });
			                }
			            });

			            /* Main Module */

			            // Start the main module
			            Commands.execute('start:module', {
			                module: MainApp,
			                name: 'MainApp'
			            });

			            /* Backbone History */

			            // Start backbone history
			            App.startHistory();

			            /* Homepage route */

			            // Navigate to the default route
			            App.navigateHome(App.state.get('rootRoute'));
			            //console.log(App.state.get('rootRoute'));
			        },
			        error: function(error) {

			            // Set the new root route
			            Commands.execute('set:state:data', {
			                rootRoute: 'login'
			            });
			            /* Login Module */

			            // Start the main module
			            Commands.execute('start:module', {
			                module: LoginApp,
			                name: 'LoginApp'
			            });
			            /* Backbone History */

			            // Start backbone history
			            App.startHistory();

			            /* Homepage route */

			            // Navigate to the default route
			            App.navigateHome(App.state.get('rootRoute'));
			        }
		    });

		});

		/* Post-Initialisation */
		App.on('initialize:after', function() {
		});

		return App;
});

