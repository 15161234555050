/**
Users Application - Form Layout
@module UsersApp
@submodule UsersApp.Form.Layout
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Template
Contents:
  * User Item View
		* className
		* template
		* regions
Author(s):
  * Just Checking
*/

define('app/modules/users/form/settings.users.panels.layout',[

  'marionette',
  'tpl!app/modules/users/templates/panels.tmpl',
  'app/requests/requests.vent',
  'app/commands/commands.vent'

], function(Marionette, Template, Requests, Commands) {

  'use strict';

  /**
  User Form Layout
  @class UsersApp.Form.Layout
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.Layout.extend({

    /**
    @property className
    @type String
    */
    //className: 'large-8 columns right extra-padding',

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {
			
			firstPanel: '#first-panel',
			secondPanel: '#second-panel'
		
		},

      /**
    @property ui
    @type Object
    */
    ui: {

      viewInstallationsButton: '#systems-detail-installations-button',
      viewChartButton: '#systems-detail-view-button',
      viewEditButton: '#systems-detail-edit-button',
      alertsButton: '#system-alert-settings-button'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click @ui.viewInstallationsButton': 'navigateToInstallations',
      'click @ui.viewChartButton': 'navigateToChart',
      'click @ui.viewEditButton': 'navigateToEdit',
      'click @ui.alertsButton': 'navigateToAlertSettings'

    },

    navigateToInstallations: function(e){

      // Build the route
      var route = this.buildTargetRoute();

      // Add the users bit
      //route += '/users';

      route = route.replace(route.substring(route.indexOf('/charts')), '');


      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

        /**
    @method navigateToChart
    */
    navigateToChart: function(e) {

      // Build the route
      var route;
      if (this.chartVersion == 2) {
          route = Requests.request('get:current:route').replace('/users','').replace('/charts/', '/newcharts/');
      } else {
          route = this.buildTargetRoute();
      }

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    /**
    @method navigateToAlertSettings
    */
    navigateToAlertSettings: function(e) {

      // Build the route
      var route = this.buildTargetRoute();

      // Add the alert settings bit
      route += '/alerts-settings';

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    /**
    @method navigateToEdit
    */
    navigateToEdit: function(e) {

      // Build the route
      var route = this.buildTargetRoute();

      // Add the edit bit
      route += '/edit';

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    /**
    @method buildTargetRoute
    */
    buildTargetRoute: function() {

      // Get the current route
      var currentRoute = Requests.request('get:current:route');

      currentRoute = currentRoute.substring(0,currentRoute.indexOf('/users'));

      return currentRoute;

    },

     /**
    @method navigate
    */
    navigate: function(route) {

      // Navigate the app to the route
      Commands.execute('app:navigate', {

        route: route

      });

    }

  });

});

