/**
Application Collections - Buttons
@module Collections
@submodule Collections.Buttons
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Button Collection
		* model
Author(s):
  * Just Checking
*/

define('app/collections/abstract/collections.abstract.buttons',[

  'backbone',
  'app/models/abstract/models.abstract.button'

], function(Backbone, Model) {

  'use strict';

  /**
  Button Collection
  @class Collections.Buttons
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property model
    @type Object
    */
    model: Model

  });

});
