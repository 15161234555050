/**
System Installation List
@module Systems
@submodule Systems.Views.Installation.List
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * System Installation List
    * template
    * itemView
    * itemViewContainer
    * emptyView
    * itemViewOptions
    * initialize
Author(s):
  * Just Checking
*/

define('app/modules/systems/views/systems.views.installations.list',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/systems/views/systems.views.installations.item',
  'tpl!app/modules/systems/templates/systems.templates.installations.list.tmpl',
  'app/views/views.noItems'

], function(Marionette, Commands, Requests, ItemView, Template, NoItemsView) {

  'use strict';

  /**
  System Installation List
  @class Systems.Views.Installation.List
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#installations-list',

    /**
    @property emptyView
    @type Function
    */
    emptyView: function() {

      return new NoItemsView({

        itemName: 'installations',
        border: true,
        marginTop: true

      });

    },

    /**
    @property itemViewOptions
    @type Object
    */
    itemViewOptions: function() {

      // Request the previous params
      var previousParams = Requests.request('get:state:data').get('previousParams');

      // Do we have a installation Id param?
      if (previousParams.installationId) {

        // Pass the current zoom level into the item view
        return {

          installationId: parseInt(previousParams.installationId, 10),
          systemId: this.model.id

        };

      }
      else {

        return {

          systemId: this.model.id

        };

      }
    
    },

    /**
    @method initialize
    */
    initialize: function() {

      // Listen to the add event and re-render
      this.listenTo(this.collection, 'add', this.render, this);

    }

  });

});

