define('app/modules/newcharts/newcharts.router',[
    'marionette'
], function(Marionette) {
    'use strict';

    return Marionette.AppRouter.extend({

        /**
         @property appRoutes
         */
        appRoutes: {

            /* Main Chart View */
            'systems/:systemId/newcharts/:installationId': 'chartView',
            'systems/:systemId/newcharts/:installationId/:type': 'chartView'
        }

    });

});

