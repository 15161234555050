/**
Main Application - Vent
@module Application
@submodule Application.Vent
*/

/*
Requires:
  * jQuery
  * Backbone
  * Underscore
  * Marionette
  * backbone.wreqr
Contents:
  * Return a new Event aggregator
Author(s): 
  * Just Checking  
*/

define('app/app.vent',[

	'backbone',
  'marionette'

], function(Backbone, Marionette) {

  // Return a new Event aggregator
  return new Backbone.Wreqr.EventAggregator();

});
