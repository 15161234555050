/**
Application Requests - Get formatted date
@module Requests
@submodule Requests.GetSessionData
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get session data
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFormattedDate',[

	'underscore',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment'

], function(_, Commands, Requests, Moment) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get formatted date
			@event get:session:data
			@param date {Date}
			@param format {String}
			@param adjust {Object}
			@param parseInt {Boolean} Parse the value to an integer
			@return date {Date} The formatted date
			*/
			return Requests.setHandler('get:formatted:date', function(options) {

				var formattedDate;

				// Set some defaults for date and format used
				_.defaults(options, {

					date: new Moment(),
					format: Requests.request('get:state:data').get('dateTimeFormat')
					
				});

				// Do we need to adjust the date value?
				if (options.adjust) {

					// Set some defaults, just in case
					_.defaults(options.adjust, {

            method: 'subtract',
            key: 'hours',
            amount: 12

          });

					// What type of adjustment?
					if (options.adjust.method === 'subtract') {

						options.date = new Moment(options.date).subtract(options.adjust.key, options.adjust.amount);
						
					}
					else if (options.adjust.method === 'add') {

						options.date = new Moment(options.date).add(options.adjust.key, options.adjust.amount);

					}
					
				}
				
				// Format the date via moment
				formattedDate = new Moment(options.date).format(options.format);

				// Do we need to parse as an integer?
				if (options.parseInt) {

					// Parse as an integer
					formattedDate = parseInt(formattedDate, 10);

				}

				return formattedDate;

			});

    }

  };

});

