/**
Application Behaviors - Show Calendar
@module Behaviors
@submodule Behaviors.ShowCalendar
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
Contents:
	* Show Calendar
		* events
		* showCalendar
Author(s):
  * Just Checking
*/

define('app/behaviors/behaviors.showCalendar',[

	'marionette'

], function(Marionette) {

  'use strict';

  /**
	Show Calendar
	*/
	return Marionette.Behavior.extend({

    /**
		@property events
		*/
    events: {

       'click .show-calendar': 'showCalendar'

    },

    /**
		@method showCalendar
		*/
    showCalendar: function(e) {

     // Show the datepicker
      $(e.currentTarget).next('input').mobiscroll('show');

     e.preventDefault();

        var html = $('html');
        if (html.hasClass('touch') && !html.hasClass('mouse')) {
            setTimeout(function() {
              $('.dwo')
                  .unbind('click')
                  .unbind('touch')
                  .unbind('touchstart')
                  .unbind('touchend');
            }, 500);
        }

    }

	});

});

