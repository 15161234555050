/**
Application Requests - Get Form Buttons
@module Requests
@submodule Requests.GetFormButtons
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get form buttons
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFormButtons',[

	'app/requests/requests.vent',
	'app/controllers/abstract/controllers.abstract.buttons'

], function(Requests, ButtonController) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get form buttons
			@event get:change:status
			@return {Boolean} Has a form change been recorded?
			*/
			return Requests.setHandler('form:button:entities', function(buttons, model) {
				
				// Do we have buttons?
				if (!buttons) {
				
					buttons = {};
				
				}

				// Create our new button controller and return our buttons
				return new ButtonController().getFormButtons(buttons, model);
			
			});

    }

  };

});
