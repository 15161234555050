/**
Application Layout - Section Header Model
@module Layout
@submodule Layout.Models.Header
*/

/*
Requires:
  * Backbone
Contents:
  * Section Header Model
    * defaults
Author(s):
  * Just Checking
*/

define('app/layout/models/layout.models.main.header',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Section Header Model
  @class Layout.Models.Header
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property defaults
    @type Object
    */
    defaults: {

      title: 'No section title supplied',
      logo: null,
      subtitle: null,
      controls: null

    }

  });

});

