define('app/modules/newcharts/features/notifications/models/notification.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		url: function() {
			return 'api/v1/Alerts/' + this.get('installId');
		},

		isNew: function() {
			return this.get('id') == false || this.get('id') == null;
		},

		toJSON: function() {
			var obj = _.clone(this.attributes);

			obj['isNew'] = this.isNew();
			return obj;
		}
	});

});
