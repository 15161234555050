/**
Application Components - Panels Layout
@module Components
@submodule Components.Panels.Layouts.Panels
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
Contents:
  * Layout Panels View
Author(s):
  * Just Checking
*/

define('app/components/panels/layouts/panels.layouts.panels',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'tpl!app/components/panels/templates/panels.templates.panels.tmpl'

], function(Marionette, Commands, Requests, Template) {

  'use strict';

  /**
  Layout Panels View
  @class MainApp.Layout.Panels
  @constructor
  @extends Backbone.Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    
    ui: {

      chartLink: '.back-to-chart'

    },*/

    /**
    @property events
    @type Object
    
    events: {

      'click .back-to-chart': 'chartNavigate'

    },*/

    /**
    Create the regions for the desktop and mobile menus
    @property regions
    @type Object
    */
    regions: {

      panelTop: '#panel-top',
      panelRight: '#panel-right',
      panelBottom: '#panel-bottom',
      panelLeft: '#panel-left'

    },

    /**
    @method onRender
    */
    onRender: function() {

      // console.log('panels rendered');

    }

  });

});

