define('app/tracking',['moment'], function(Moment){

    function doAjax(feature, installId) {
        $.ajax({
            method: 'POST',
            url: 'api/v1/Tracking',
            data: {
                feature: feature,
                installId: installId
            }
        });
    }

    return {
        track: function(feature, installId) {
			if (installId && installId != null) {
				installId = parseInt(installId, 10);
			}

			if (window.smartlook) {
				window.smartlook('tag', feature);
			}

            doAjax(feature, installId);
        }
    }
});
