define('app/modules/newcharts/features/notifications_family/views/notifications.view',[
	'app/modules/newcharts/features/notifications/views/notifications.view',
	'app/modules/newcharts/features/notifications_family/views/notification.view'
], function(ParentView, NotificationView) {
	'use strict';

	return ParentView.extend({

		itemView: NotificationView

	});

});
