/*
System Installation Item
@module Systems
@submodule Systems.Views.Installation.Item
*/

/*
Requires:
	* Backbone
	* Marionette
	* jQuery
	* Underscore
	* ModelBinder
Contents:
	* System Installation Item
		* template
		* tagName
		* className
		* events
		* bindings
		* behaviors
		* initialize
		* onRender
		* navigate
Author(s):
	* Just Checking
*/

define('app/modules/systems/views/systems.views.installations.item',[

	'marionette',
	'tpl!app/modules/systems/templates/systems.templates.installations.item.tmpl',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'app/behaviors/behaviors.calendar',
	'app/behaviors/behaviors.showCalendar',
	'mobiscroll',
	'moment',
	'backbone.modelbinder',
    'app/behaviors/behaviors.tooltip'

], function(Marionette, Template, Commands, Requests, Calendar, ShowCalendar, Mobiscroll, Moment, ModelBinder, Tooltip) {

	'use strict';

	/**
	System Installation Item
	@class Systems.Views.Installation.Item
	@constructor
	@extends Marionette.ItemView
	*/
	return Marionette.ItemView.extend({

		/**
		@property template
		@type String
		*/
		template: Template,

		/**
		@property tagName
		@type String
		*/
		tagName: 'tr',

		childViewContainer: '.charts',

		/**
		@property className
		@type String
		*/
		className: function() {
			// Is this the previously viewed system?
			if (this.options.installationId === this.model.get('installationId')) {
				return 'selected-row';
			}
			else {
				return;
			}
		},

		/**
		@property events
		@type Object
		*/
		events: {
			'click .previouschart': 'navigate',
			'click .evallink' : 'loadEvaluations',
			'click .print': 'print'
		},

		// Previous installations evaluation form link function
		//getevalstatus: function () {
		//	var prevurl = this.model.get('evaluationUrl');
		//	store.set('evalLink', prevurl);
        //
		//	$.ajax({
		//			url:'/api/v1/Installations/?systemId=' + this.model.get('systemId'),
		//			type:'GET',
		//			dataType:"json",
		//			success: function(response) {
        //
		//				var cacheclientId = response.jcData.clientId;
		//				store.set('clientId', cacheclientId);
        //
		//				$.ajax({
		//					url:'/api/v1/EvaluationReport/' + cacheclientId,
		//					type:'GET',
		//					dataType:"json",
		//					success: function(response) {
		//						if (typeof response.jcData.Day != 'undefined') {
		//							store.set('DayCareHrsWeekly', response.jcData.Day.weekly);
		//							store.set('DayCareHrsYearly', response.jcData.Day.yearly);
        //
		//							store.set('NotionalSavingWeekly', response.jcData.Residential.weekly);
		//							store.set('NotionalSavingYearly', response.jcData.Residential.yearly);
        //
		//							store.set('ActualSavingsWeekly', response.jcData.Night.weekly);
		//							store.set('ActualSavingsYearly', response.jcData.Night.yearly);
        //
		//							$('.daycarehrsweekly').html(response.jcData.Day.weekly);
		//							$('.daycarehrsyearly').html(response.jcData.Day.yearly);
        //
		//							$('.notionalsavingweekly').html(response.jcData.Residential.weekly);
		//							$('.notionalsavingyearly').html(response.jcData.Residential.yearly);
        //
		//							$('.actualsavingsweekly').html(response.jcData.Night.weekly);
		//							$('.actualsavingsyearly').html(response.jcData.Night.yearly);
        //
		//							store.set('reportUrl', response.jcData.ReportUrl);
		//						  }
		//						},
		//						error: function(error) {
		//						}
		//					});
		//				},
		//				error: function(error){
		//				}
		//	});
        //
		//	if (prevurl.indexOf('static') > 0) {
		//		$('.reportbtn').hide();
		//	}
		//	else {
		//		$('.reportbtn').show();
		//	}
        //
		//	$('.rootiframe').remove();
		//	$('.iframecontainer').html('<iframe id="rootiframe" class="rootiframe" src='+ store.get('evalLink') +'></iframe>');
        //
		//	// Stop body from scrolling when modal is being shown
		//	$('body').css('overflow', 'hidden');
        //
		//	// Will need to open static model displaying a static collect of all completed fields
		//	// We know the correct evaluation URL as its available in the model
		//	$('.overlay').css("display","block");
		//	$('.modaldialog').css("display", "block");
		//	$('.highlightcontainer, .charthighlights').hide();
        //
		//	$('.reportbtn').removeClass('evaltabselected');
		//	$('.evalbtn').addClass('evaltabselected');
		//	$('.reportsavings').css("display", "none");
		//	$('.iframecontainer').css("height", "90%");
		//},

        loadEvaluations: function(e) {
            Commands.execute('app:EvaluationLightbox', {
                requestData: {
                    installationId: $(e.target).data('id')
                }
            });
        },

		/**
		@property bindings
		@type Object
		*/
		bindings: function() {
			return {
				installationStart: [{
					selector: '[class~=installation-start-date]',
					converter: this.templateHelpers().formatDate
				}],
				installationEnd: [{
					selector: '[class~=installation-end-date]',
					converter: this.templateHelpers().formatDate
				}]
			};
		},

		/**
		@property behaviors
		@type Object
		*/
		behaviors: {
			ShowCalendar: {
				behaviorClass: ShowCalendar
			},

			Calendar: {
				behaviorClass: Calendar,
				showDate: true,
				showTime: true,
				showNow: true,
				onSelect: function(valueText, inst, view, modelAttribute) {
					var
					map = {},
					UTCFormat = Requests.request('get:state:data').get('utcFormat');

					// Map the new value to supplied model attribute
					map[modelAttribute] = new Moment(valueText).format(UTCFormat);

					// Set the new value
					view.model.set(map);

					// Trigger the save installation event
					view.trigger('save:installation');
				}
			},

            Tooltip: {
                behaviorClass: Tooltip
            }
		},

		/**
		@method initialize
		*/
		initialize: function() {
			// Init a model binder
			this.modelBinder = new Backbone.ModelBinder();
		},

		/**
		@method onRender
		*/
		onRender: function() {
			// Bind the model to the UI
			//this.modelBinder.bind(this.model, this.el, this.bindings());
		},

		/**
		@method navigate
		*/
		navigate: function(e) {
            if (this.model.get('chartVersion') == 2) {
                Commands.execute('app:navigate', {
                    route: 'systems/' + this.options.systemId + '/newcharts/' + this.model.get('installationId')
                });
            } else {
                Commands.execute('app:navigate', {
                    route: 'systems/' + this.options.systemId + '/charts/' + this.model.get('installationId')
                });
            }

			e.preventDefault();
		},

		print: function(e) {
			e.preventDefault();

			Commands.execute('app:printLightbox', {
				installationId: this.model.get('installationId')
			});
		}

	});

});

