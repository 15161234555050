define('app/views/view.roomoccupancyView',['require','marionette','app/commands/commands.vent','tpl!app/modules/charts/templates/charts.templates.roomoccupancy.tmpl','d3','app/behaviors/behaviors.showCalendar','app/behaviors/behaviors.calendar','moment'],function (require) {
	'use strict';

	var Marionette 				= require('marionette'),
		Commands 				= require('app/commands/commands.vent'),
		RoomoccupancyTemplate 	= require('tpl!app/modules/charts/templates/charts.templates.roomoccupancy.tmpl'),
		d3 						= require('d3'),
		ShowCalendar 			= require('app/behaviors/behaviors.showCalendar'),
		Calendar 				= require('app/behaviors/behaviors.calendar'),
		Moment					= require('moment');

		return Marionette.ItemView.extend({

			template: RoomoccupancyTemplate,

			behaviors: {

			  ShowCalendar: {

			    behaviorClass: ShowCalendar

			  },

			  Calendar: {
			    behaviorClass: Calendar,
                //colors: ['#f48028','#2ca2bc', '#63cae1', '#c0b6ab', '#998b7d'],
                colors: ['#998b7d', '#FFFFFF', '#F48028', '#FFB085', '#2ca2bc', '#63cae1', '#c0b6ab'],
                loadPie: function(data, pieElem, dateChosenElem) {
                      var pieElemJ = $(pieElem),
                          dateChosenElemJ = $(dateChosenElem);

                      pieElemJ.html('');

                      if (data.totalTimeTracked == "0 minutes") {
                          dateChosenElemJ.html(moment(data.date).format('MMMM Do YYYY'));
                          pieElemJ.html('<h4>No data to display</h4>');
                      } else {
                          var dataset = data.values, that = this;
                          setTimeout(function() {
                              var width = 360,
                                  height = 360,
                                  radius = Math.min(width, height) / 2,
                                  donutWidth = 65,
                                  legendRectSize = 18,
                                  legendSpacing = 4,
                                  strokeColor = "#000",
                                  strokeWidth = 0.3;

                              // var color = d3.scale.category20b();
                              var color = d3.scale.ordinal().range(that.colors);

                              var svg = d3.select(pieElem)
                                  .append('svg')
                                  .attr('width', width)
                                  .attr('height', height)
                                  .append('g')
                                  .attr('transform', 'translate(' + (width / 2) +
                                  ',' + (height / 2) + ')');

                              var arc = d3.svg.arc()
                                  .innerRadius(radius - donutWidth)
                                  .outerRadius(radius);

                              var pie = d3.layout.pie()
                                  .value(function(d) { return d.time; })
                                  .sort(null);

                              var path = svg.selectAll('path')
                                  .data(pie(dataset))
                                  .enter()
                                  .append('path')
                                  .attr('d', arc)
                                  .attr('fill', function(d, i) {
                                      return color(d.data.title);
                                  })
                                  .attr('stroke', '#000')
                                  .attr('stroke-width', strokeWidth);

                              var legend = svg.selectAll('.legend')
                                  .data(color.domain())
                                  .enter()
                                  .append('g')
                                  .attr('class', 'legend')
                                  .attr('transform', function(d, i) {
                                      var height = legendRectSize + legendSpacing;
                                      var offset =  height * color.domain().length / 2;
                                      var horz = -2 * (legendRectSize + 20);
                                      var vert = i * height - offset;
                                      return 'translate(' + horz + ',' + vert + ')';
                                  });

                              legend.append('rect')
                                  .attr('width', legendRectSize)
                                  .attr('height', legendRectSize)
                                  .style('fill', color)
                                  .style('stroke', strokeColor)
                                  .style('stroke-width', '0.7');

                              legend.append('text')
                                  .attr('x', legendRectSize + legendSpacing)
                                  .attr('y', legendRectSize - legendSpacing)
                                  .text(function(d) { return d; });

                              dateChosenElemJ.html(moment(data.date).format('MMMM Do YYYY'));
                          });
                      }
                  },
                buildUrl: function() {
                    var url = '/api/v1/Insights/' + store.get('installid') + "?type=roomOccupancy";

                    if (store.get('roLeftDate') != null) {
                        url += '&previousDate=' + store.get('roLeftDate');
                    }
                    if (store.get('roRightDate') != null) {
                        url += '&recentDate=' + store.get('roRightDate');
                    }

                    return url;
                },
			    showNow: true,
                isMobile: function() {
                    return $('#highlights').width() <= 500;
                },
                showGenerateButton: function() {
                    if (store.get('roLeftDate') != null && store.get('roRightDate') != null) {
                        $('.bkbtncontainer').show();
                    }
                },
			    onSelect: function(valueText, inst, view, modelAttribute, elem) {
			    	var datecache = new Moment(valueText).format('YYYY-MM-DD'),
                        that = this;

                    if (elem[0].id == "chart-date-input-left") {
						store.set('roLeftDate', datecache);
                        if (!this.isMobile()) {
                            $.ajax({
                                url: that.buildUrl(),
                                type: 'GET',
                                dataType: "json",
                                success: function (response) {
                                    return that.loadPie(
                                        response.jcData.ranges.previous,
                                        '#roomoccupancypie',
                                        '.leftchosendate'
                                    );
                                },
                                error: function (error) {
                                    console.error(error);
                                }
                            });
                        } else {
                            $('.leftchosendate').html(moment(datecache).format('MMMM Do YYYY'));
                            $('.leftpie > .title').show();
                            this.showGenerateButton();
                        }
                    } else {
						store.set('roRightDate', datecache);
                        if (!this.isMobile()) {
                            $.ajax({
                                url: that.buildUrl(),
                                type: 'GET',
                                dataType: "json",
                                success: function (response) {
                                    return that.loadPie(
                                        response.jcData.ranges.recent,
                                        '#roomoccupancypiedate',
                                        '.datechoosen'
                                    );
                                },
                                error: function (error) {
                                    console.error(error);
                                }
                            });
                        } else {
                            $('.datechoosen').html(moment(datecache).format('MMMM Do YYYY'));
                            $('.rightpie > .title').show();
                            this.showGenerateButton();
                        }
                    }
			    }
			  }

			},

			ui: {
				bathroomVists: '.bathroomvists',
				overlayclick : '.overlay',
				exitclick : '.exitform',
				//goback : '.gobackbtn',
				dateInput: '.dateinput',
                print : '.printcontainer',
                info: '.info-icon-container',
                infoClose: '.info-close',
                generateBtn: '#generateBtn'
			},

			events: {
				'change @ui.dateInput' : 'datefunc',
				'click @ui.bathroomVists' : 'bathroomshow',
				'click @ui.overlayclick' : 'closelightbox',
				'click @ui.exitclick' : 'closelightbox',
				//'click @ui.goback' : 'goback',
                'click @ui.print' : 'print',
                'click @ui.info' : 'info',
                'click @ui.infoClose' : 'info',
                'click @ui.generateBtn': 'generateMobilePDF'
			},

			datefunc : function (val){
				var datecache = new Moment(val.target.value).format('YYYY-MM-DD');

			},

			initialize:function () {
                if (!this.isMobile()) {
                    $('.overlay').click(this.closelightbox);
                }
            },

			closelightbox:function() {
				$('.overlay').hide();
				$('#lightboxcontainer').hide();
			},

			goback:function () {
				Commands.execute('app:Lightbox', {
					requestData: {
		          		installationId: this.model.get('_id')
		        	}
				});
			},

			onRender: function() {
                // Fix date pickers to be limited by the API.
                $('[data-calendar="true"]')
                    .attr('data-calendar-min', moment(this.model.get('minimumDate')).format("Y-m-d"))
                    .attr('data-calendar-max', moment(this.model.get('maximumdate')).format("Y-m-d"));

                setTimeout(function(){
                    if (this.isMobile()) {
                        // Hide and show required elements.
                        $('.mobiletext').show();
                        $('.info-icon-container').hide();
                        $('.title').hide();
                        $('.printcontainer').hide();
                        $('#roomoccupancypie').hide();
                        $('#roomoccupancypiedate').hide();
                    } else {
                        // Create default pies.
                        if (this.model.get('ranges').previous != null) {
                            this.behaviors.Calendar.loadPie(this.model.get('ranges').previous, '#roomoccupancypie', '.leftchosendate');
                        }
                        if (this.model.get('ranges').recent != null) {
                            this.behaviors.Calendar.loadPie(this.model.get('ranges').recent, '#roomoccupancypiedate', '.datechoosen');
                        }
                    }
                }.bind(this));
			},

            print: function(e) {
                // Undo any mobile changes
                $('#roomoccupancypie').show();
                $('#roomoccupancypiedate').show();

                var elems = $.parseHTML($('#highlights').html()),
                    url = '/api/v1/Generate/PDF',
                    leftPie = elems[3],
                    rightPie = elems[4],
                    now = moment().format('LLL');

                leftPie.removeChild(leftPie.lastChild);
                rightPie.removeChild(rightPie.lastChild);

                var rawHTML = "<!doctype html>\n<html lang=\'en\'>\n<head>\n<link rel=\'stylesheet\' href=\'"+ document.querySelector("link[href*='.css']").href +"\'>\n<style>\n    body {\n        background: white !important;\n    }\n    #highlights {\n        background: white !important;\n    }\n    #roomoccupancypie, #roomoccupancypiedate, .center {\n        text-align:center;\n    }\n    .generated-text {\n        margin-top: 55%;\n    }\n</style>\n</head>\n<body>";
                rawHTML += "<img src='assets/images/png/just_checking_logo.png' width='300' height='120' />";
                rawHTML += "<div id=\"highlights\" class=\"highlightcontainer\">\n";
                rawHTML += "<div class=\"leftpie\" style=\"float:left;width:49%;\">" + leftPie.innerHTML + "</div>";

                if (rightPie.lastChild.children.length > 0) {
                    rawHTML += "<div class=\"rightpie\" style=\"float:right; width:49%;\">" + rightPie.innerHTML + "</div>";
                }
                rawHTML += "</div>";

                rawHTML += "<p class=\"generated-text\">" +
                    "Generated: " + now +
                    " | Chart: " + store.get('chartTitle') +
                    " | System: " + store.get('systemNum') +
                    "</p>";

                rawHTML += "</body></html>";

                // Redo any mobile changes
                if (this.isMobile()) {
                    $('#roomoccupancypie').hide();
                    $('#roomoccupancypiedate').hide();
                }

                $("<form action=\"" + url + "\" method=\"post\" style=\"display:none;\"><input type=\"hidden\" name=\"html\" value=\"" + encodeURI(rawHTML) + "\" /></form>").appendTo("body").submit().remove();
                e.preventDefault();
            },

            isMobile: function() {
                return $('#highlights').width() <= 500;
            },

            generateMobilePDF: function() {
                var that = this.behaviors.Calendar;
                if (store.get('roLeftDate') != null && store.get('roRightDate') != null) {
                    $.ajax({
                        url: that.buildUrl(),
                        type: 'GET',
                        dataType: "json",
                        success: function (response) {
                            that.loadPie(
                                response.jcData.ranges.previous,
                                '#roomoccupancypie',
                                '.leftchosendate'
                            );
                            that.loadPie(
                                response.jcData.ranges.recent,
                                '#roomoccupancypiedate',
                                '.datechoosen'
                            );
                            setTimeout(function(){
                                $('.printcontainer').click();
                            });
                        },
                        error: function (error) {
                            console.error(error);
                        }
                    });
                }
            },

            info: function() {
                $('.info-container').toggle(400);
            }

		});
});

