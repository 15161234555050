/**
Chart Application - Detail Controller
@module Chart
@submodule Chart.Controllers.Detail
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Crudder
Contents:
  * Detail Controller
    * initialize
    * getChartRowsJSON
    * resizeEventListener
    * setChartPosition
    * getChartPosition
    * setViewType
    * checkViewType
    * getCurrentHour
    * showChartLayout
    * showMenu
    * showControls
    * showRows
    * showTimeline
    * updateModuleState
    * storeRows
    * scrollChart
    * navigate
Author(s):
  * Just Checking
*/

define('app/modules/charts/controllers/charts.controllers.detail',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'backbone.crudder',
  'app/modules/charts/models/charts.models.chart',
  'app/modules/charts/models/charts.models.menu',
  'app/modules/charts/models/charts.models.meta',
  'app/modules/charts/models/charts.models.state',
  'app/modules/charts/layouts/charts.layouts.main',
  'app/modules/charts/layouts/charts.layouts.chart',
  'app/modules/charts/views/charts.views.menu',
  'app/modules/charts/views/charts.views.rows.list',
  'app/modules/charts/views/charts.views.timeline.list',
  'app/modules/charts/views/charts.views.main.header.subtitle',
  'app/modules/charts/views/charts.views.controls.list',
  'app/modules/charts/collections/charts.collections.controls',
  'app/modules/charts/collections/charts.collections.rows',
  'app/modules/charts/collections/charts.collections.timeline',
  'jquery.ui',
  'jquery.resize',
  'moment',
  'utils'

], function(Marionette, Commands, Requests, Crudder, Model, MenuModel, MetaModel, StateModel, Layout, ChartLayout, MenuView, RowsListView, TimelineListView, SubTitleView, ControlListView, ControlCollection, RowsCollection, TimelineCollection, JQueryUI, Resize, Moment, Utils) {

  'use strict';

  /**
  Detail Controller
  @class Chart.Controllers.Detail
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      // Debug
      if (options) {
        //console.log(options);
      }

      var
      that = this,
      App = require('app/app');

      // Define the main layout
      this.layout = new Layout();

      // Define the main region
      this.region = App.main.currentView.content;

      // Define our chart model
      this.model = new Model({

        _id: options.installationId

      });

      // Create a models object
      this.models = {};

      // Create a collections object
      this.collections = {};

      // Listen for the main layout's 'show' event
      this.layout.on('show', function() {

        // Debug
        if (options.debug) {
          console.log('Main chart layout shown');
        }

        /*
        Use the data
        */

        // New meta data model
        that.models.meta = new MetaModel();

        // Set the Meta data model (This causes the showHeader method to be called)
        that.models.meta.set(that.model.get('chartMeta'));

        // The row collection
        that.collections.rows = new RowsCollection(that.model.get('chartRows'));

        /*
        Enabled Rows
        */

        // The row collection
        that.collections.enabledRows = new RowsCollection();

        // Set the disabled row collection
        that.collections.enabledRows.add(that.collections.rows.where({

          rowInUse: true

        }));

        /*
        Chart state object
        */

        // Get today's date
        var
            today = Requests.request('get:formatted:date', {

              format: Requests.request('get:state:data').get('utcFormat')

            }),

        // Changed to resolve PD-1200
        // chartDate = (options.date) ? options.date : that.models.meta.get('chartDate'),

            chartDate = that.models.meta.get('chartDate'),
            lastUpdate = (options.lastUpdate) ? options.lastUpdate : that.models.meta.get('lastUpdate');


        // Update the chart module state
        that.updateModuleState({

          features: that.model.get('chartFeatures'),
          systemId: options.systemId,
          installationId: options.installationId,
          date: chartDate,
          zoom: parseInt(options.zoom, 10),
          today: today,
          lastUpdateToday: new Moment(chartDate).isSame(lastUpdate, 'day')

        });

        /*
        Update the top bar
        */

        // Update the topbar title with the chart title
        Commands.execute('update:topbar', {

          title: {

            text: that.models.meta.get('chartTitle')

          }

        });

        /*
        View type
        */

        // Set the view type
        that.setViewType();

        // Check the view type
        that.checkViewType();

        /*
        Header view
        */

        // Show the section header
        that.showHeader({

          title: that.models.meta.get('chartTitle') || 'Untitled',
          logo: true,
          subtitle: new SubTitleView({

            model: that.models.meta,
            state: that.module.state

          })

        });

        /*
        Chart layout
        */

        // Show the chart layout
        that.showChartLayout({

          chartMenuData: that.model.get('chartMenu'),
          chartTimelineData: that.model.get('chartTimeline'),
          chartControlsData: that.model.get('chartNavMenu')

        });


         /* On-boarding tour */
        Commands.execute('init:onBoard', {
          page: 'chart'
        });

      });

      /*
      Fetch
      */

      var fetchOptions;

      // Check for any options to add to the fetch request
      if (options.date && options.zoom) {
        // Add the options to send
        fetchOptions = {
          date: options.date,
          zoom: options.zoom
        };

         // If we had selected the date from a date picker then we need to tell the API this is LCT
        if(options.userSelected){
          fetchOptions.userSelected = 1;
        }
      }

      // Fetch the model data
      this.model.fetch({

        data: fetchOptions

      });

      /*
      Show
      */

      var
      closeSectionHeader = false,
      loadingType = 'spinner';

      // Is there a current view in our region?
      if (this.region.currentView) {

        // Is there a chart specific region in the current view?
        if (this.region.currentView.chart) {

          // We require the opacity loader
          loadingType = 'opacity';

        }
        else {

          // We need the section header closed
          closeSectionHeader = true;

        }

      }

      // Call the controller 'show' method
      this.show({
        loader: true,
        loadingType: loadingType,
        closeSectionHeader: closeSectionHeader,
        defaultOpacityTarget: true,
        debug: false
      });

    },

    /**
    @method getChartRowsJSON
    */
    getChartRowsJSON: function() {

      var
      extractedData = [],
      // Get any stored data
      previousChartRows = JSON.parse(store.get('chartRows'));

      // Do we have previous rows stored?
      if (!previousChartRows) {

        return;

      }
      else {

        // For each row
        _.each(previousChartRows, function(item) {

          // Extract only the data we require
          extractedData.push(_.pick(item, 'chartLineId', 'rowCurrentPosition', 'rowShow'));

        });

        return JSON.stringify(extractedData);

      }

    },

    /**
    @method resizeEventListener
    */
    resizeEventListener: function() {

      var that = this;

      // Detect any chart wrapper resizes
      this.layout.chart.currentView.rows.$el.find('.chart-data-wrapper').resize(function(e) {

        // Reset the timeline and data position
        that.setChartPosition({

          resize: true

        });

      });

    },

    /**
    @method setChartPosition
    @param resize {Boolean} Has there been a chart resize?
    */
    setChartPosition: function(options) {

      // Set any defaults
      _.defaults(options, {

        resize: false

      });

      var
      chartData = this.layout.chart.currentView.rows.$el.find('.chart-data'),
      timeline = this.layout.chart.currentView.timeline.$el.find('#chart-timeline-hours'),
      position = (options.resize) ? 0 : this.module.state.get('position');

      /*
      Last Update
      */

      // Are we looking at today and last update is same day?
      if (this.module.state.get('lastUpdateToday') && this.module.state.get('zoom') === 120) {

        var
        // Extract the hour value from the last update date/time
        updateHour = Requests.request('get:formatted:date', {

          date: this.models.meta.get('lastUpdate'),
          format: 'HH'

        });

        position = this.getChartPosition({

          hour: updateHour

        });

      }

      /*
      Set the chart position
      */

      // Set the current timeline x position
      timeline.css('left', position);

      // Set the current data x position
      chartData.css('left', position);

    },

    /**
    @method getChartPosition
    @param hour {Integer} The target hour to show
    */
    getChartPosition: function(options) {

      var
      position = 0,
      // Get the width of the data wrapper
      wrapperWidth = parseInt(this.layout.chart.currentView.rows.$el.find('.chart-data-wrapper').css('width'), 10),
      // Detect how many hours are currently being shown
      hoursShown = wrapperWidth / this.module.state.get('chartIntervalWidth'),
      // Set our target hour so last update is shown
      targetHour = options.hour - (Math.round(hoursShown / 2));

      /*
      Is hour in viewport?
      */

      // If hour is not in the viewport
      if (options.hour > Math.round(hoursShown / 2)) {

        // Adjust the position value to show the correct hour
        position = -targetHour * this.module.state.get('chartIntervalWidth');

      }

      /*
      Gone too far?
      */

      // Is the position value and wrapper width greater than chart width?
      if (-position + wrapperWidth > this.module.state.get('chartWidth')) {

        var
        combinedWidth = -position + wrapperWidth,
        widthDifference = combinedWidth - this.module.state.get('chartWidth'),
        timesGreater = widthDifference / this.module.state.get('chartIntervalWidth');

        // Adjust the position
        position = position + (this.module.state.get('chartIntervalWidth') * timesGreater);

      }

      return position;

    },

    /**
    @method setViewType
    */
    setViewType: function(options) {

      // Are we zoomed out?
      if (this.models.meta.get('zoom') === 120) {

        // Update the chart module state
        this.updateModuleState({

          view: this.models.meta.get('viewType')

        });

      }

    },

    /**
    @method checkViewType
    */
    checkViewType: function() {

      var
      showBg = false,
      zoom = this.module.state.get('zoom'),
      view = this.module.state.get('view'),
      hour = this.getCurrentHour({

        date: this.module.state.get('date')

      });

      if (zoom === 120 && hour > 11) {
        showBg = true;
      }

      // Do we need night view?
      if (showBg) {

     // console.log(' NIGHT VIEW');


        // Show the stars
        Commands.execute('toggle:content:class', {

          add: true,
          className: 'bg-night'

        });


      }
      else {
        // Hide the stars
        Commands.execute('toggle:content:class', {
          add: false,
          className: 'bg-night'
        });
      }
    },

    /**
    @method getCurrentHour
    */
    getCurrentHour: function(options) {
      // Set default value
      _.defaults(options, {
        date: this.module.state.get('date')
      });
      return Requests.request('get:formatted:date', {
        date: options.date,
        format: 'HH'
      });
    },

    /**
    Show the chart
    @method showChart
    @param chartMenuData {Object} The chart menu data
    @param chartTimelineData {Object} The chart timeline data
    @param chartControlsData {Object} The chart controls data
    */
    showChartLayout: function(options) {

      // Create a chart layout
      var chartLayout = new ChartLayout();

      // Show the chart layout
      this.layout.chart.show(chartLayout);
      /*
      Chart Rows
      */

      this.showRows();

      /*
      Timeline
      */

      this.showTimeline({

        chartTimelineData: options.chartTimelineData

      });

      /*
      Main Menu
      */

      this.showMenu({

        chartMenuData: options.chartMenuData

      });

      /*
      Controls
      */

      this.showControls({

        chartControlsData: options.chartControlsData

      });

      /*
      Chart Resizes
      */

      // Listen to any layout resizes
      this.resizeEventListener();

      /*
      Chart Positioning
      */

      // Reset the timeline and data position
      this.setChartPosition({

        resize: false

      });

    },

    /**
    @method showMenu
    @param chartMetaData {Object} The chart meta data
    */
    showMenu: function(options) {

      // Create a new menu model and view
      var
      App = require('app/app'),
      menuModel = new MenuModel(_.extend(options.chartMenuData, this.models.meta.attributes)),
      menuView = new MenuView({

        model: menuModel,
        state: this.module.state,
        meta: this.models.meta,
        chartModel: this.model

      });

      // Show the chart menu view
      this.layout.menu.show(menuView);

    },

    /**
    @method showControls
    @param chartMetaData {Object} The chart meta data
    */
    showControls: function(options) {

      // Create a new controls collection and composite view
      var
      controlsButtonCollection = new ControlCollection(options.chartControlsData),
      controlsListView = new ControlListView({

        collection: controlsButtonCollection,
        model: this.module.state,
        meta: this.models.meta

      });

      // Show the chart controls layout
      this.layout.controls.show(controlsListView);

    },

    /**
    @method showRows
    @param chartMetaData {Object} The chart meta data
    */
    showRows: function(options) {

      var that = this;

      // Create a rows collection and view
      var rowsListView = new RowsListView({

        collection: this.collections.enabledRows,
        inlineEditing: this.module.state.get('inlineEditing')

      });

      // On the rows region show event
      this.layout.chart.currentView.rows.on('show', function() {

        // Initialise the row sort feature
        //that.initRowSort();

        // Check if we require inline editing
        //that.initInlineEdit();

      });

      // Show the rows list view
      this.layout.chart.currentView.rows.show(rowsListView);

    },

    /**
    @method showTimeline
    @param chartMetaData {Object} The chart meta data
    */
    showTimeline: function(options) {

      var
      that = this,
      timelineCollection = new TimelineCollection(options.chartTimelineData),
      timelineListView = new TimelineListView({

        collection: timelineCollection,
        model: this.module.state

      });

      // Show the controls buttons view
      this.layout.chart.currentView.timeline.show(timelineListView);

    },

    /**
    @method updateModuleState
    @param inlineEditing {Boolean} Does the chart required row sort/remove etc?
    */
    updateModuleState: function(options) {

      // Set defaults from current settings
      _.defaults(options, {

        features: {

          inlineEditing: this.module.state.get('inlineEditing')

        },
        systemId: this.module.state.get('systemId'),
        installationId: this.module.state.get('installationId'),
        date: this.module.state.get('date'),
        previousDate: this.module.state.get('previousDate'),
        zoom: this.module.state.get('zoom'),
        view: this.module.state.get('view'),
        position: this.module.state.get('position'),
        today: this.module.state.get('today'),
        lastUpdateToday: this.module.state.get('lastUpdateToday')

      });

      // Update the chart state object
      this.module.state.set({

        inlineEditing: options.features.inlineEditing,
        systemId: options.systemId,
        installationId: options.installationId,
        date: options.date,
        previousDate: options.previousDate,
        zoom: options.zoom,
        hour: this.getCurrentHour({

          date: options.date

        }),
        view: options.view,
        position: options.position,
        today: options.today,
        lastUpdateToday: options.lastUpdateToday

      });

    },

    /**
    @method storeRows
    */
    storeRows: function() {

      var
      that = this,
      rowsCurrentView = this.layout.chart.currentView.rows.currentView;

      /*
      Update the temp row collection
      */

      // Loop through each sensor option
      _.each(rowsCurrentView.$el.children(), function(item, index) {

        var
        position = index + 1,
        row = $(item),
        rowId = parseInt(row.attr('data-id'), 10);

        // Loop through each model in the rows view
        rowsCurrentView.children.each(function(view) {

          // Match the row to its model
          if (view.model.id === rowId) {

            // Update the model
            view.model.set({

              rowCurrentPosition: position

            },
            {

              silent: true

            });

          }

        });

      });

      // Store the rows in local storage
      store.set('chartRows', JSON.stringify(this.collections.rows));

    },

    /**
    @method scrollChart
    */
    scrollChart: function(options) {

	  if (this.layout.chart.currentView === undefined) {
		  return;
	  }

      var
      // Get the default chart width
      chartWidth = this.module.state.get('chartWidth'),
      // Get the default chart interval width
      chartIntervalWidth = this.module.state.get('chartIntervalWidth'),
      // Get the current chart view
      chartView = this.layout.chart.currentView,
      // Get the timeline hours element
      timeline = chartView.timeline.$el.find('#chart-timeline-hours'),
      // Get the current timeline x position
      timelinePosition = parseInt(timeline.css('left'), 10),
      // Get the chart data wrapper element
      chartDataWrapper = chartView.rows.$el.find('.chart-data-wrapper'),
      // Get all the chart data rows
      chartData = chartView.rows.$el.find('.chart-data'),
      // Get the current chart data x position
      chartDataPosition = parseInt(chartData.css('left'), 10),
      // Get the current viewport position
      viewportPos = -chartWidth + parseInt(chartDataWrapper.css('width'), 10),
      mindTheGap,
      scrollDistance;

      /*
      Defaults
      */

      // Set default direction as right
      _.defaults(options, {

        direction: 'right'

      });

      /*
      Validate Event
      */

      // Stop multiple clicks if already animated
      if (timeline.is(':animated') || chartData.is(':animated')) { return; }

      /*
      Scroll Direction
      */

      // Has a scroll right been requested?
      if (options.direction === 'right') {

        /*
        Set scroll distance
        */

        // Calculate the remaining gap until the limit
        mindTheGap = (chartDataPosition + chartIntervalWidth) === 0;

        // Get the desired scrolling distance
        scrollDistance = (mindTheGap) ? chartIntervalWidth : chartIntervalWidth * 2;

        /*
        Scroll the chart
        */

        // check if we are at the start of the timeline and trying to press back
        if (chartDataPosition < 0) {

          timeline.animate({

            'left': '+=' + scrollDistance + 'px'

          });

          chartData.animate({

            'left': '+=' + scrollDistance + 'px'

          });

        }
        else {

          // Navigate to the supplied date
          this.navigate({

            date: options.date,
            targetHour: 22

          });

        }

      }
      else {

        /*
        Set scroll distance
        */

        // Calculate the remaining gap until the limit
        mindTheGap = (chartWidth - (parseInt(chartDataWrapper.css('width'), 10) - chartDataPosition)) <= chartIntervalWidth;

        // Get the desired scrolling distance
        scrollDistance = (mindTheGap) ? chartIntervalWidth : chartIntervalWidth * 2;

        /*
        Scroll the chart
        */

        // check if we are at the end of the timeline and trying to move forward
        if (chartDataPosition > viewportPos) {

          timeline.animate({

            'left': '-=' + scrollDistance + 'px'

          });

          chartData.animate({

            'left': '-=' + scrollDistance + 'px'

          });

        }
        else {

          // Navigate to the supplied date
          this.navigate({

            date: options.date

          });

        }

      }

    },

    /**
    @method navigate
    */
    navigate: function(options) {

      // Start the route build
      var route = 'systems/' + this.module.state.get('systemId') + '/charts/' + this.module.state.get('installationId');


      // Set default via the module state object
      _.defaults(options, {

        date: this.module.state.get('date'),
        zoom: this.module.state.get('zoom'),
        trigger: true,
        refresh: false,
        targetHour: 0,
        userSelected: 0

      });

      //create the route passing in 'LCT' (Local Controller Time) if the date has come from a user selected picker.
      route += '/date/' + (options.userSelected == 1 ? options.date + 'LCT' : options.date);
      route += '/zoom/' + options.zoom;

      // Record current date and position before moving on
      this.updateModuleState({

        previousDate: this.module.state.get('date'),
        position: this.getChartPosition({

          hour: options.targetHour

        })

      });

      // Has a refresh of the current data been requested?
      if (options.refresh) {
/*
        this.getById({

          systemId: this.module.state.get('systemId'),
          installationId: this.module.state.get('installationId'),
          date: this.module.state.get('date'),
          zoom: this.module.state.get('zoom')

        });*/


      window.location.reload();

      }
      else {

        // Navigate the app to the route
        Commands.execute('app:navigate', {

          route: route,
          trigger: options.trigger

        });

      }

    }

	});

});

