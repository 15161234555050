/**
Application Layout - Main Layout Header
@module Layout
@submodule Layout.Layouts.Main.Header
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Layout Header
    * template
    * regions
Author(s):
  * Just Checking
*/

define('app/layout/layouts/layout.layouts.main.header',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.main.header.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Main Layout Header
  @class Menu.ItemView
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

      title: '#section-header-title',
      subtitle: '#section-header-subtitle',
      controls: '#section-header-controls'

    }

  });

});

