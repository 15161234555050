define('app/modules/charts/views/charts.views.notewindow',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/modules/charts/templates/charts.templates.notewindow.tmpl'],function (require) {
    'use strict';

    var Marionette 			= require('marionette'),
        Vent 				= require('app/app.vent'),
        Commands 			= require('app/commands/commands.vent'),
        Requests 			= require('app/requests/requests.vent'),
        template         	= require('tpl!app/modules/charts/templates/charts.templates.notewindow.tmpl');

    return Marionette.ItemView.extend({

        template: template,

        ui: {
            overlayclick : '.overlay',
            exitclick : '.exitform',
            noteform: '#noteform'
        },

        events: {
            'click @ui.overlayclick' : 'closelightbox',
            'click @ui.exitclick' : 'closelightbox',
            'submit @ui.noteform': 'saveNote'
        },

        chartModel: null,

        initialize:function () {
            setTimeout(function(){
                $('#note-subject').val(this.model.get('subject'));
                $('#note-message').val(this.model.get('message'));
            }.bind(this));
        },

        closelightbox:function() {
            $('.overlay').hide();
            $('#lightboxcontainer').hide();
        },

        onRender:function () {
            var $lightboxcontainer = $('#lightboxcontainer');

            $lightboxcontainer.html(template);
            $('.overlay').show();
            $lightboxcontainer.show();
        },

        saveNote: function(e) {
            var subject = $('#note-subject').val(),
                message = $('#note-message').val();

            this.model.set('subject', subject);
            this.model.set('message', message);

			var originalId = this.model.get('id');

            function success(resp) {
                this.model.set('id', resp.jcData.id);

                if (resp.jcData.id == null) {
                    $('#show-note-icon').removeClass('has-note');
                } else {
                    $('#show-note-icon').addClass('has-note');
                }

                if (this.options.chartModel) {
                    this.options.chartModel.set('note', {
                        id: this.model.get('id'),
                        installationId: this.model.get('installationId'),
                        date: this.model.get('date'),
                        subject: this.model.get('subject'),
                        message: this.model.get('message')
                    });
                }

				var markers = JSON.parse(store.get('calendar.markers'));
				if (this.model.get('id') == null) {
					// It was deleted!
					markers = _.filter(markers, function(marker) {
						return marker.id != originalId;
					}.bind(this));
				} else {
					// Its a new one!
					markers.push({
						id: this.model.get('id'),
						date: this.model.get('date'),
						installationId: this.model.get('installationId'),
						subject: this.model.get('subject')
					});
				}

				store.set('calendar.markers', JSON.stringify(markers));
            }


			if (this.model.get('id') == null) {
                // It's a new one!
                this.model.sync("create", this.model, {
                    success: success.bind(this)
                });
            } else {
                // It's an old one.
                this.model.sync("update", this.model, {
                    success: success.bind(this)
                });
            }
            this.closelightbox();

            e.stopPropagation();
            e.preventDefault();
        }

    });

});

