/**
Application Components - Form Layout
@module Components
@submodule Components.Form.Layout
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
Contents:
  * Form Layout
		* tagName
		* attributes
		* template
		* regions
		* ui
		* triggers
		* serializeData
		* onShow
		* getFormDataType
		* focusFirstInput
Author(s):
  * Just Checking
*/

define('app/components/form/components.form.layout',[

  'marionette',
  'tpl!app/components/form/templates/components.form.templates.form.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Form Layout
  @class Components.Form.Layout
  @constructor
  @extends Marionette.ItemLayout
  */
  return Marionette.Layout.extend({

    /**
    @property tagName
    @type String
    */
    tagName: 'form',

    /**
    @property className
    @type String
    */
    className: 'pure-form pure-form-custom',

    /**
    @property attributes
    @type Object
    */
    attributes: function() {

			return {
      
				'data-type': this.getFormDataType(),
				'novalidate': 'novalidate'
			
			};

    },

    /**
    @property template
    @type Object
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

      formContentRegion: '#form-content-region'

    },

    /**
    @property ui
    @type Object
    */
    ui: {
		
			buttonContainer: '#form-button-container',
			errorMessage: '#form-error'

		},

		/**
    @property triggers
    @type Object
    */
		triggers: {

			'submit': 'form:submit',
			'click [data-form-button="cancel"]': 'form:cancel'
    
    },

    /**
    @method serializeData
    */
    serializeData: function() {

			var _ref1, _ref2;

			return {
    
        footer: this.options.config.footer,
        buttons: (_ref1 = (_ref2 = this.options.buttons) !== null ? _ref2.toJSON() : void 0) !== null ? _ref1 : false
      
      };

    },

    /**
    @method onShow
    */
    onShow: function() {

			var that = this;
      
      // Defer these actions to the bottom of the call stack
      return _.defer(function() {
        
        // Do we want to focus on the first input?         
        if (that.options.config.focusFirstInput) {
          
          that.focusFirstInput();
        
        }
      
      });

    },

    /**
    @method getFormDataType
    */
    getFormDataType: function() {

			/*
			Add a data-type to the form
      */

      // Is the model new?
      if (this.model.isNew()) {
      
        return 'new';
      
      }
      else {
      
        return 'edit';
      
      }
    
    },

    /**
    @method focusFirstInput
    */
    focusFirstInput: function() {
      
      // Focus on the first input in the form
      this.$(':input:visible:enabled:first').focus();
    
    }

  });

});

