/**
Application Requests - Get a view
@module Requests
@submodule Requests.GetView
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get a view
Author(s):
  * Just Checking
*/

define('app/requests/requests.getView',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get a view
			@event get:view
			@param request {String} The request to execute
			@param panelView {Boolean} Is the destination a panel?
			@param debug {Boolean} Debug this code?
			*/
			return Requests.setHandler('get:view', function(options) {

				var App = require('app/app');

				// Do we want to debug?
				if (options.debug) {

					console.log(options);

				}

				// options are required
				if (options) {

					// request option is required
					if (options.request) {

						// Make the request on the target module and return the view
						return Requests.request(options.request, options);

					}
					else {

						return false;

					}

				}
				else {

					return false;

				}

			});

    }

  };

});

