/**
Application Commands - Show Panel
@module Commands
@submodule Commands.ShowPanel

This command allows a view to be displayed within a chosen panel from anywhere in the application.
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
		* Show Panel
Author(s):
  * Just Checking  
*/

define('app/commands/commands.showPanel',[

	'backbone',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'backbone.crudder',
	'app/components/panels/views/panels.views.header'

], function(Backbone, Commands, Requests, Crudder, HeaderView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Show Panel
			@event show:panel
			@param targetPanel {String}
			@param view {Object}
			@param requestData {Object} Any data to add to the data request
			@param title {String}
			@param closable {Boolean}
			*/
			return Commands.setHandler('show:panel', function(options) {

				// Make options an empty object if not supplied
				options = options || {};

				// Set the option defaults
				_.defaults(options, {

					targetPanel: 'right',
					title: 'Just Checking',
					closable: true

				});

				var
				App = require('app/app'),
				appPanels = Requests.request('get:state:data').get('panels'),
				panelWrapper = appPanels.$element,
				panel = panelWrapper.find('#panel-' + options.targetPanel),
				panelOpen = panel.attr('data-open') === 'true',
				entity;

				// Has a view been supplied?
				if (options.view) {

					// Extract the model or collection
					entity = (options.view.collection) ? options.view.collection : options.view.model;
				
				}

				/*
				Validate
				*/

				// We require a view with a model or collection
				if (options.view && entity) {

					/*
					Region
         */

         var targetRegion;

         // Which region has been supplied?
         switch(options.targetPanel) {

            case 'top':

              targetRegion = App.panels.currentView.panelTop;

              break;

            case 'right':

              targetRegion = App.panels.currentView.panelRight;

              break;

            case 'bottom':

              targetRegion = App.panels.currentView.panelBottom;

              break;

            case 'left':

              targetRegion = App.panels.currentView.panelLeft;

              break;

          }

					/*
					Load the data
					*/

					// Fetch the data via crudder
          Crudder.reading({

            entity: entity,
            errorMessage: 'Unable to retrieve data',
            data: options.requestData

          }, function(response) {

            // Has the request been successful?
            if (response.success) {

              // Show the panel header
              targetRegion.currentView.header.show(new HeaderView({

               panelReference: options.targetPanel,
               close: options.closable,
               title: options.title

              }));

              // Show the content in the panel
              targetRegion.currentView.content.show(options.view);

              /*
							Show the panel
              */

              // If panel is currently close
              if (!panelOpen) {

               // Show the panel via the jQuery plugin
                appPanels.clickEventHandler({

                  panelReference: options.targetPanel

                });

              }

						}

					});
				
				}
				else {

					/*
					Show the panel
          */

          // If panel is currently close
          if (!panelOpen) {

            // Show the panel via the jQuery plugin
            appPanels.clickEventHandler({

              panelReference: options.targetPanel

            });

          }

				}

			});

    }

  };

});

