/**
Chart Application - Header Subtitle
@module Chart
@submodule Chart.Views.Header.Subtitle
*/

/*
Requires:
  * Backbone
  * Marionette
	* templates
Contents:
  * Header Subtitle
    * template
    * ui
    * onRender
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.main.header.subtitle',[

  'marionette',
  'app/requests/requests.vent',
  'moment',
  'tpl!app/modules/charts/templates/charts.templates.main.header.subtitle.tmpl'

], function(Marionette, Requests, Moment, Template) {

  'use strict';

  /**
  Header Subtitle
  @class Chart.Views.Header.Subtitle
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template

  });

});

