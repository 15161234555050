define('app/modules/newcharts/controllers/main.controller',[

    'marionette',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'backbone.crudder',
    'jquery.ui',
    'jquery.resize',
    'moment',
    'utils',

    'app/modules/newcharts/layouts/main.layout',
    'app/modules/newcharts/models/chart.model',
    'app/modules/newcharts/views/main-header.view',
    'app/modules/newcharts/layouts/body.layout',

    'app/modules/newcharts/state',

    'app/tracking',

], function(Marionette, Commands, Requests, Crudder, JQueryUI, Resize, Moment, Utils, Layout, ChartModel, MainHeaderView, BodyLayout, State, Tracking) {
    'use strict';

    return Marionette.Controller.extend({

        initialize: function(options) {
            var App = require('app/app'),
                fetchOptions = {
					date: new Moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                    minimal: 1
                };

			$.cookie("interface", window.location.href);

            switch(options.type) {
                case 'multi':
                    Tracking.track('openMulti', options.installationId);
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Multiday View',
                        type: 'multi'
                    });
                    break;

                case 'bathroom':
                    Tracking.track('openBathroom', options.installationId);
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Bathroom Visits',
                        type: 'bathroom'
                    });
                    break;

                case 'doorActivity':
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Door Activity',
                        type: 'doorActivity'
                    });
                    break;

                case 'nonOccupancy':
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Non-Occupancy',
                        type: 'nonOccupancy'
                    });
                    break;

				case 'notifications':
					this.model = new ChartModel({
						_id: options.installationId,
						typeTitle: 'Notifications',
						type: 'notifications'
					});
					break;

				case 'notifications_family':
					this.model = new ChartModel({
						_id: options.installationId,
						typeTitle: 'Notifications',
						type: 'notifications_family'
					});
					break;

				case 'postassessment':
					this.model = new ChartModel({
						_id: options.installationId,
						typeTitle: 'Post Assessment',
						type: 'postassessment'
					});
					break;

				case 'events':
					this.model = new ChartModel({
						_id: options.installationId,
						typeTitle: 'Sequences',
						type: 'events'
					});
					break;

				case 'carerVisits':
					this.model = new ChartModel({
						_id: options.installationId,
						typeTitle: 'Carer Visits',
						type: 'carerVisits'
					});
					break;

                case 'heatLight':
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Heat and light',
                        type: 'heatLight'
                    });
                    break;

                default:
                    Tracking.track('openChart', options.installationId);
                    // Main chart view
                    this.model = new ChartModel({
                        _id: options.installationId,
                        typeTitle: 'Chart View',
                        type: 'chart'
                    });
                    break;
            }

            if (
				this.model.get('type') != 'bathroom' && this.model.get('type') != 'notifications'
			) {
                var state = State.load(this.model);
                fetchOptions = _.extend(fetchOptions, state);

                if (state.startDate && state.endDate) {
                    this.model.set('startDate', new Moment(state.startDate));
                    this.model.set('endDate', new Moment(state.endDate));
                }
            }

            this.layout = new Layout({
                type: this.model.get('type')
            });

            this.region = App.main.currentView.content;

            this.layout.on('show', function() {
                this.generateMobileHeadingText();

                this.layout.header.show(new MainHeaderView({
                    model: this.model
                }));
                this.layout.body.show(new BodyLayout({
                    model: this.model
                }));
            }.bind(this));

            _.each(fetchOptions, function(value, key) {
                if (Moment.isMoment(value)) {
                    fetchOptions[key] = value.toISOString();
                }
            });

            this.model.fetch({
                data: fetchOptions
            });

            this.show({
                loader: true,
                loadingType: 'spinner',
                closeSectionHeader: true,
                defaultOpacityTarget: true,
                debug: false
            });
        },

        generateMobileHeadingText: function () {
            var text = this.model.get('chartMeta').chartTitle;

            if (!text || text == null || text == '') {
                text = 'Untitled';
            }

            text += ' - ' + this.model.get('chartMeta').systemNo;
            $('h1#page-header-title').text(text);
        }
    });

});
