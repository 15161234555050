/**
Chart Application - Menu Model
@module Chart
@submodule Chart.Models.Menu
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Chart.Models.Menu
    * defaults
Author(s):
  * Just Checking
*/

/*
Example Data:
  alerts: {
    show: true,
    showAlertsHistory: true,
    showAlertsSettings: true
  },
  logins: {
    show: true
  },
  settings: {
    show: true,
    showChartSettings: true,
    showUserSettings: true,
    showAlertsSettings: true
  }
*/

define('app/modules/charts/models/charts.models.menu',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Menu Model
  @class Chart.Models.Menu
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property defaults
    @type Object
    */
    defaults: {

      alerts: {

        show: true,
        showAlertsHistory: true,
        showAlertsSettings: false

      },
      logins: {

        show: true

      },
      settings: {

        show: true,
        showChartSettings: true,
        showUserSettings: true,
        showAlertsSettings: true

      }

    }


  });

});

