/**
Application Views - No Items
@module Views.NoItems
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Commands
Contents:
  * No Items View
		* template
		* ui
		* serializeData
		* goBack 
Author(s):
  * Just Checking
*/

define('app/views/views.noItems',[

  'marionette',
  'app/commands/commands.vent',
  'tpl!app/views/templates/views.templates.noResults.tmpl'

], function(Marionette, Commands, Template) {

  'use strict';

  /**
  No Items View
  @class Views.NoItems
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      noResultsBack: '#no-results-back'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click a': 'goBack'

    },

    /**
    @method initialize
    */
    serializeData: function(options) {

			_.defaults(this.options, {

				className: '',
        itemName: 'items',
        showBack: false,
        border: false,
        marginTop: false

			});

      // Return the note and any options
      return {
    
        className: this.options.className,
        itemName: this.options.itemName,
        showBack: this.options.showBack,
        border: this.options.border,
        marginTop: this.options.marginTop
      
      };

    },

    /**
    @method goBack
    */
    goBack: function() {

      // Back to the previous page
      Commands.execute('app:navigate:back');

    }

  });

});

