/**
Users Application - Item View
@module UsersApp
@submodule UsersApp.Views.Item
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Template
	* Vent
Contents:
  * User Item View
		* tagName
		* template
		* ui
		* events
		* initialize
		* onRender
		* showUser
Author(s):
  * Just Checking
*/

define('app/modules/users/views/menu/settings.users.views.menu.empty',[

  'marionette',
  'app/commands/commands.vent',
  'tpl!app/modules/users/templates/usersMenuItemEmpty.tmpl',
  'app/app.vent'

], function(Marionette, Commands, Template, Vent) {

  'use strict';

  /**
  User Item View
  @class UsersApp.Views.Item
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    The template for this view (index.html > templates > users)
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      userLink: '.mobile-navigate',
      userNewText: '.user-menu-new',
      userName: '#menu-user-name'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click a': 'showUser'

    },

    /**
    @method initialize
    */
    initialize: function() {

      // Bind this model to a change event
      this.listenTo(this.model, 'change', this.render, this);

    },

    /**
    @method onRender
    */
    onRender: function() {

      // Does this user have a name?
      if (!this.model.get('name')) {

        // Set the name to unknown
        this.ui.userName.text('No name supplied');

        Commands.execute('set:change:true', {view:this});

      }

    },

    /**
    @method showUser
    */
    showUser: function() {

      // Trigger the show:user event in the controller
      // Vent.trigger('show:user', {
      //
		// 		id: this.model.get('accessId')
      //
      // });

    }

  });

});

