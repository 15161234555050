/**
Logins Controller
@module Logins
@submodule Logins.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Logins App Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/modules/logins/logins.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/logins/logins.collection',
  'app/modules/logins/views/logins.views.list'

], function(Marionette, Commands, Requests, Collection, ListView) {

  'use strict';

  /**
  Logins Controller
  @class Logins.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var
      that = this,
      App = require('app/app');

      // Define our system collection
      this.collection = new Collection();

      // Define the main view
      this.view = new ListView({

        collection: this.collection

      });

      /*
      Destination Control
      */

      // Has a panel view been requested
      if (!options.panelView) {

        // Define the main region
        this.region = App.main.currentView.content;        

        /*
        Update the top bar
        */

        // Listen for the main layout's 'show' event
        this.view.on('show', function() {

          /*
          Top bar
          */

          // Update the topbar title with the chart title
          Commands.execute('update:topbar', {

            title: {
            
              text: 'My Logins'

            }

          });

          /*
          Header view
          */

          // Show the section header
          that.showHeader({

            title: 'My Logins'

          });

        });

        /*
        Fetch
        */

        // Fetch the model data
        this.collection.fetch();

        /*
        Show
        */

        // Call the controller 'show' method
        this.show({

          loader: true
          // debug: true

        });

      }

    }

	});

});
