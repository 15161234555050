define('app/modules/newcharts/features/notifications/views/notifications.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment',
	'd3',
	'app/modules/newcharts/features/notifications/views/notification.view',
	'tpl!app/modules/newcharts/features/notifications/templates/notifications.template.tmpl',

], function(Marionette, Vent, Commands, Requests, Moment, D3, NotificationView, Template) {
	'use strict';

	return Marionette.CompositeView.extend({

		itemView: NotificationView,

		itemViewContainer: 'tbody',

		template: Template

	})

});
