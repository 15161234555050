define('app/modules/newcharts/features/notifications/collections/notification-history.collection',[
	'backbone',
	'app/modules/newcharts/features/notifications/models/notification-history.model'
], function(Backbone, Model) {
	'use strict';

	return Backbone.Collection.extend({

		model: Model,

		url: function() {
			return 'api/v1/AlertHistory/' + this.models[0].id;
		}
	});

});
