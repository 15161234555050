
define('app/modules/systems/models/feature.promotion',[
    'backbone'
], function(Backbone) {

    return Backbone.Model.extend({

        defaults: {
            showFeature: false
        },

        getFeatures: function(acknowledged) {
            if (typeof acknowledged == "undefined" || acknowledged == null) {
                acknowledged = false;
            }

            return _.filter(this.get('promotedFeatures'), function(feature) {
                return feature.acknowledged == acknowledged;
            });
        },

        getArchived: function() {
            return this.getFeatures(true);
        },

        dismissFeature: function(id, callback) {
            $.ajax("api/v1/AcknowledgedFeatures/" + id, {
                method: "PUT",
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({
                    acknowledged: true
                }),
                success: function() {
                    var features = this.get('promotedFeatures');

                    for(var i = 0; i < features.length; i++) {
                        if (features[i].id == id) {
                            features[i].acknowledged = true;
                        }
                    }

                    this.set({
                        promotedFeatures: features
                    });

                    if (callback) callback();
                }.bind(this)
            });
        },

        // This is the route to the api - this is where the request will go when backbone syncs the model with the server
        urlRoot: function() {
            return 'PromotedFeatures';
        }

    });

});
