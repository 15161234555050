define('app/commands/commands.printLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/views/views.printLightbox','app/models/printLightbox.model','app/app'],function (require) {
	'use strict';

	var Commands            = require('app/commands/commands.vent'),
		Requests            = require('app/requests/requests.vent'),
		Lightbox            = require('app/views/views.printLightbox'),
		Model 	            = require('app/models/printLightbox.model');

		return {
			initialize: function() {
				return Commands.setHandler('app:printLightbox', function(options) {
					var App = require('app/app'),
                        cacheinstall = options.installationId,
                        model = new Model({
                            _id: cacheinstall
                        });

					model.fetch({
						success: function(model) {
							var view = new Lightbox({
								model: model,
								closeCallback: options.callback
							});

							App.lightbox.show(view);
						}
					});
				});
			}
		};
});




