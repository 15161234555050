define('app/models/replica.model',[
	'backbone',
	'app/commands/commands.vent'
], function(Backbone, Commands) {
	'use strict';

	Backbone.emulateHTTP = true;

	return Backbone.Model.extend({

		idAttribute: '_id',

		url: function() {
			return 'api/v1/ReplicateInstall/' + this.id
		},

		isNew: function() {
			return true;
		}

	});
});

