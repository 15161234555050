define('app/modules/newcharts/7day/views/chart-row-mini.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/7day/templates/chart-row-mini.template.tmpl',
    'moment',
    'd3'

], function(Marionette, Vent, Commands, Requests, Template, Moment, D3) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        onRender: function() {
			if (this.options.renderData) {
				var id = this.model.get('chartLineId'),
					rowData = this.model.get('chartLineData'),
					rowDataWrapper = this.$el.find('#chartRow' + id).find('.chart-data'),
					svgElement,
					container = this.$el;

				D3.select(rowDataWrapper[0])
					.append('svg')
					.attr('id', 'rowData' + id)
					.attr('width', '100%')
					.append('defs')
					.append('pattern')
					.attr('id', 'diagonalHatch-' + id)
					.attr('patternUnits', 'userSpaceOnUse')
					.attr('width', '10')
					.attr('height', '10')
					.append('g')
					.attr('class', 'offline-hatch')
					.append('path')
					.attr('d', 'M0,10 l10,-10');

				svgElement = rowDataWrapper.find('#rowData' + id);

				_.each(rowData, function(element, index) {
					// Loop through each data item for this alert type
					_.each(element.dataItems, function(value, key) {
						// Append the svg element with a rect element
						if (element.dataType == 'event' || element.dataType == 'event_ajar') {
							if (element.dataType == 'event') {
								value.w = 2;
							}

							D3.select(svgElement[0])
								.append('rect')
								.attr('class', element.dataType)
								.attr('x', function () {
									return value.x / 2;
								})
								.attr('y', function () {
									return element.dataType == 'event_ajar' ? 11 : 5;
								})
								.attr('width', function () {
									return value.w / 2;
								})
								.attr('height', function () {
									return element.dataType == 'event_ajar' ? 4 : 10;
								})
								.attr('fill', '#ccc');
						} else if (element.isNotification) {
							D3.select(svgElement[0])
								.append('rect')
								.attr('class', element.dataType)
								.attr('x', function () {
									return value.x / 2;
								})
								.attr('y', function () {
									return 5;
								})
								.attr('width', function () {
									return 1;
								})
								.attr('height', function () {
									return 10;
								})
								.attr('fill', '#ccc');
						} else {
							if (!(element.dataType == 'accelerometer' && value.child)) {
								D3.select(svgElement[0])
									.append('rect')
									.attr('class', element.dataType)
									.attr('style', function () {
										if (element.dataType === 'offline' || element.dataType === 'dataLoss') {
											return 'fill:url(#diagonalHatch-' + id + ')';
										} else {
											return '';
										}
									})
									.attr('x', function () {
										// Return the data x position value
										return value.x / 2;
									})
									.attr('y', function () {
										// Set the correct y position depending on data type
										switch (element.dataType) {
											case 'movement':
												return 2;

											case 'doorAjar':
											case 'interpretedAjar':
											case 'event_ajar':
												return 11;

											case 'doorEvent':
												return 5;

											case 'accelerometer':
												return 8;

											default:
												return 0;
										}
									})
									.attr('width', function () {
										// Return the data width value
										return value.w / 2;
									})
									.attr('height', function () {
										// Set the correct y position depending on data type
										switch (element.dataType) {
											case 'motion':
												return 13;

											case 'doorAjar':
											case 'interpretedAjar':
											case 'event_ajar':
												return 4;

											case 'doorEvent':
												return 10;

											case 'accelerometer':
												return 6;

											default:
												return 15;
										}
									});
							}
						}
					});
				});
			}
        }

    });

});
