/**
Chart Application - Meta Model
@module Chart
@submodule Chart.Models.Meta
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Meta Model
    * urlRoot
    * idAttribute
    * defaults
Author(s):
  * Just Checking
*/

/*
Example Data:
  "installationId": 17824,
  "chartDate": "2014-01-07 00:00:00",
  "chartEnd": "2014-01-08 00:00:00",
  "zoom": 120,
  "chartTitle": "JR",
  "lastUpdate": "2014-01-07 15:32:00",
  "systemNo": "31342"
*/

define('app/modules/charts/models/charts.models.meta',[

  'backbone',
  'moment',
  'backbone.validation'

], function(Backbone, Moment, Validation) {

  'use strict';

  /**
  Chart Model
  @class ChartApp.Meta.Model
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    Point to the source of the json
    @property urlRoot
    @type String
    */
    urlRoot: 'Chart',

    /**
    @property idAttribute
    @type String
    */
    idAttribute: 'installationId',

    /**
    @property defaults
    @type Object
    */
    defaults: {

      "chartEnd": new Moment(),
      "zoom": 120 // 24 hours

    },

    /**
    @property validation
    @type Object
    */
    validation: {

      chartTitle: {
        required: true,
        msg: 'Please enter a chart title'
      }

    }

  });

});
