/**
Chart Application - Controls Collection
@module Chart
@submodule Chart.Collections.Controls
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Controls Collection
    * model
Author(s):
  * Just Checking
*/

define('app/modules/charts/collections/charts.collections.controls',[

  'backbone',
  'app/modules/charts/models/charts.models.controls.item'

], function(Backbone, Model) {

  'use strict';

  /**
  Chart Collection
  @class ChartApp.Collection
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model

  });

});
