define('app/modules/newcharts/features/notifications/views/notification-history-menu.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-history-menu.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		ui: {
			reportSwitch: '#notification-report-switch',
			backBtn: '#back-btn',
			daySelector: '#day-selector'
		},

		events: {
			'click @ui.backBtn': 'goBack'
		},

		onShow: function() {
			Switcher.init({
				view: this.ui.reportSwitch
			});
			setTimeout(function() {
				if (this.model.get('enabled') == false) {
					this.ui.reportSwitch.find('.switch-off').click();
				}

				// Setup events
				this.ui.reportSwitch.change(this.toggleReport.bind(this));
				this.ui.daySelector.change(this.toggleReport.bind(this));
			}.bind(this));
		},

		goBack: function(e) {
			e.preventDefault();

			Vent.trigger('newchart:notification-list', {});
		},

		toggleReport: function(e) {
			e.preventDefault();

			this.model.set('enabled', !this.ui.reportSwitch.find('input').is(':checked'));
			this.model.set('day', this.ui.daySelector.val());

			Crudder.creatingUpdating({
				entity: this.model,
				patch: false,
				successMessage: 'Saved successfully',
				errorMessage: 'Error occurred'
			});
		}
	});

});
