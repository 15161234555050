/**
Main Application - Module
@module Application
*/

/*
Requires:
  * jQuery
  * Backbone
  * Underscore
  * Marionette
Contents:
  * initialize
    * Init Modules
		* Start Event
Author(s):
  * Just Checking  
*/

define('app/app.module',[

  'marionette',
  'app/commands/commands.vent',
  'app/app.controller',
  'app/modules/systems/systems.module',
  'app/modules/charts/charts.module',
  'app/modules/help/help.module',
  'app/modules/account/account.module',
  'app/modules/logins/logins.module',
  'app/modules/users/settings.users.module',
  'app/modules/logout/logout.module',
  'app/modules/newcharts/newcharts.module',
  'app/modules/newcharts/state',

], function(Marionette, Commands, Controller, SystemsApp, ChartsApp, HelpApp, AccountApp, LoginsApp, UsersApp, LogoutApp, NewChartsApp, State) {

  'use strict';

  return {

		/**
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			MainApp = App.module('MainApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				// Do this when this module is started
				this.addInitializer(function() {

					/*
          Init Modules
          */

          // Send in an array of modules to start
					Commands.execute('start:module', [
						{

              module: SystemsApp,
              name: 'SystemsApp'

           },
           {

              module: ChartsApp,
              name: 'ChartsApp'

           },
           {

              module: LoginsApp,
              name: 'LoginsApp'

           },
           {

              module: UsersApp,
              name: 'UsersApp'

           },
           {

              module: HelpApp,
              name: 'HelpApp'

           },
           {

              module: AccountApp,
              name: 'AccountApp'

           },
           {

              module: LogoutApp,
              name: 'LogoutApp'
           },
            {
                module: NewChartsApp,
                name: 'NewChartsApp'
            }
          ]);

        });

				/**
				Start Event
				@event start
				*/
				this.on('start', function() {
					// Create a new controller
					MainApp.controller = new Controller();

				});

			});

			return MainApp;

		}

	};

});

