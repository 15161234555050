define('app/modules/newcharts/features/notifications/views/notification-setup.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-setup.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder',
	'app/commands/commands.vent'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder, Commands) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		className: 'notification-setup',

		ui: {
			acceptBtn: '#accept',
			rejectBtn: '#reject'
		},

		events: {
			'click @ui.acceptBtn': 'accept',
			'click @ui.rejectBtn': 'reject'
		},

		onShow: function() {
			$('.overlay').show();
		},

		accept: function(e) {
			e.preventDefault();

			var result = false;
			if (this.options.accept) {
				result = this.options.accept();
			}

			if (result) {
				$('.overlay').hide();
				this.close();
			}
		},

		reject: function(e) {
			e.preventDefault();

			var result = false;
			if (this.options.reject) {
				result = this.options.reject();
			}

			if (result) {
				$('.overlay').hide();
				this.close();
			}
		}

	});

});
