define('app/modules/systems/models/system.totals',[
	'backbone'
], function(Backbone) {

	return Backbone.Model.extend({

		defaults: {
			active: 0,
			inactive: 0,
			k8: 0,
			k6: 0,
			r6: 0,
			installed: 0,
			notInstalled: 0
		},

		// This is the route to the api - this is where the request will go when backbone syncs the model with the server
		urlRoot: function() {
			return 'SystemTotals';
		}

	});

});
