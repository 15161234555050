/*
Application Config - Crudder
@module Config
@submodule Config.Backbone.Crudder
*/

/*
Requires:
	* jQuery
  * Backbone
  * Underscore
  * backbone.crudder
Contents:
  * beforeSend
  * onComplete
  * onSuccess
  * onError
Author(s):
  * Just Checking  
*/

define('backbone.crudder.settings',[

  'backbone.crudder',
  'alertify',
  'jquery.okeyDokey'

], function(Crudder, Alertify, OkeyDokey) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

     	var App = require('app/app');

     	// Init Okey Dokey
     	$('body').okeyDokey({

        iconOk: '<i class="icon-ok"></i>',
        iconFail: '<i class="icon-cancel"></i>'

      });

			/*
			Extend Backbone Crudder
			@extends Backbone.Crudder.prototype
			*/
			_.extend(Crudder.prototype, {

				beforeSend: function() {

					App.main.currentView.addOpacityWrapper({

						add: true

					});

				},

				onComplete: function() {

					App.main.currentView.addOpacityWrapper({

						add: false

					});

				},

				onSuccess: function(options) {

					// Trigger an okey dokey message
					$('body').trigger('okey:dokey');

				},

				onError: function(options) {

					// Trigger a not okey dokey message
					$('body').trigger('not:okey:dokey');

				}

			});

		}

	};

});
