define('app/commands/commands.appLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/views/views.lightboxView','app/modules/charts/models/charts.models.insights','app/views/view.bathroomView','app/views/view.roomoccupancyView','app/modules/charts/models/charts.models.note','app/modules/charts/views/charts.views.notewindow','app/tracking','app/app'],function (require) {
	'use strict';

	var Commands            = require('app/commands/commands.vent'),
		Requests            = require('app/requests/requests.vent'),
		Lightbox            = require('app/views/views.lightboxView'),
		Model 	            = require('app/modules/charts/models/charts.models.insights'),
        bathroomView		= require('app/views/view.bathroomView'),
        roomoccupancyView 	= require('app/views/view.roomoccupancyView'),
        NoteModel           = require('app/modules/charts/models/charts.models.note'),
        noteView            = require('app/modules/charts/views/charts.views.notewindow'),
        Tracking            = require('app/tracking');

		return {
			initialize: function() {
				return Commands.setHandler('app:Lightbox', function(options) {
					var App = require('app/app'),
                        cacheinstall = options.requestData.installationId,
                        chartTitle = options.requestData.chartTitle,
                        systemNum = options.requestData.systemNum,
                        type = options.requestData.type,
                        model = new Model({
                            _id: cacheinstall
                        });

                    // Clear out old localStorage items
                    var oAuthBearerObject = store.get('oAuth_bearer_object')
                    store.clear();
                    setTimeout(function(){
                        store.set('oAuth_bearer_object', oAuthBearerObject)
                    },200)
                    store.set('installid', cacheinstall);
                    store.set('chartTitle', chartTitle);
                    store.set('systemNum', systemNum);

                    model.url = function() {
                        return 'api/v1/' + this.urlRoot + '/' + this.id + '?type=' + type;
                    };

                    if (type == "roomOccupancy") {
                        Tracking.track('roomOccupancyv2', cacheinstall);
                        model.fetch({
                            success: function (data){
                                App.lightbox.show(new Lightbox({
                                    model: model,
                                    type: type
                                }));

                                if (data.get('ranges').previous != null) {
                                    store.set('roLeftDate', data.get('ranges').previous.date);
                                }
                                if (data.get('ranges').recent != null) {
                                    store.set('roRightDate', data.get('ranges').recent.date);
                                }

                                App.lightbox.show(new roomoccupancyView({model: data}));
                            }
                        });
                    } else if (type == "bathroomVisits") {
                        Tracking.track('bathroomVisitsv2', cacheinstall);
                        model.fetch({
                            success: function (data){
                                App.lightbox.show(new Lightbox({
                                    model: model,
                                    type: type
                                }));

                                if (data.get('ranges').previous != null) {
                                    store.set('bvLeftDate', data.get('ranges').previous.date);
                                }
                                if (data.get('ranges').recent != null) {
                                    store.set('bvRightDate', data.get('ranges').recent.date);
                                }

                                App.lightbox.show(new bathroomView({model: data}));
                            }
                        });
                    }
				});
			}
		};
});




