define('app/modules/newcharts/features/doorActivity/views/nonOccupancy-menu.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'app/commands/commands.vent',
    'tpl!app/modules/newcharts/features/doorActivity/templates/nonOccupancy-menu.template.tmpl',
    'moment',
    'app/behaviors/behaviors.calendar',
    'app/behaviors/behaviors.showCalendar'

], function(Marionette, Vent, Requests, Commands, Template, Moment, Calendar, ShowCalendar) {
    'use strict';

    return Marionette.ItemView.extend({

        firstResize: true,

        template: Template,

        behaviors: {

            ShowCalendar: {
                behaviorClass: ShowCalendar
            },

            Calendar: {
                behaviorClass: Calendar,
                showNow: true,
                onSelect: function(valueText, inst, view, model, elem) {
                    if (elem[0].id == 'chart-week1-input') {
                        $('#week1-date').text(new Moment(valueText).format('DD/MM/YYYY'));
                    } else if (elem[0].id == 'chart-week2-input') {
                        $('#week2-date').text(new Moment(valueText).format('DD/MM/YYYY'));
                    }

                    view.ui.dateError.css({
                        visibility: 'hidden'
                    });
                    view.ui.timeError.css({
                        visibility: 'hidden'
                    });
                    view.ui.bathroomErrors.hide();
                }
            }
        },

        ui: {
            dateWeek1: '#chart-week1-input',
            dateWeek2: '#chart-week2-input',
            timeFrom: '#bathroom-time-from',
            timeTo: '#bathroom-time-to',
            time24: '#bathroom-24',
            dateError: '#dateError',
            timeError: '#timeError',
            goBtn: '#goBtn',

            showMeExpand: '#showme-expand',
            showMeCollapse: '#showme-collapse',
            showMeForm: '#showme-form',
            weeksExpand: '#weeks-expand',
            weeksCollapse: '#weeks-collapse',
            timesExpand: '#times-expand',
            timesCollapse: '#times-collapse',
            week1: '.week1',
            week2: '.week2',
            timesForm: '#times-form',

            bathroomErrors: '#bathroom-errors',

            showMeSelect: '#showme-select'
        },

        events: {
            'focus @ui.timeFrom': 'timeFocus',
            'focus @ui.timeTo': 'timeFocus',

            'changeTime @ui.timeFrom': 'updateTimeTo',
            'change @ui.timeFrom': 'updateTimeTo',
            //'changeTime @ui.timeTo': 'clearTimeError',
            //'change @ui.timeTo': 'clearTimeError',
            'click @ui.goBtn': 'go',
            'change @ui.time24': 'set24',

            'changeTime @ui.timeTo': function(e) {
                this.clearTimeError(e);

                if (store.get('_bathroom_setTime_ignore')) {
                    store.remove('_bathroom_setTime_ignore');
                } else {
                    this.go(e);
                }
            },
            'change @ui.timeTo': function(e) {
                this.clearTimeError(e);
                this.go(e);
            },

            'resize': 'onResize',

            'click @ui.showMeExpand': 'expandShowMe',
            'click @ui.showMeCollapse': 'collapseShowMe',
            'click @ui.weeksExpand': 'expandWeeks',
            'click @ui.weeksCollapse': 'collapseWeeks',
            'click @ui.timesExpand': 'expandTimes',
            'click @ui.timesCollapse': 'collapseTimes',
            'change @ui.showMeSelect': 'switchMode'
        },

        templateHelpers: {
            state24: function() {
                return this.timeFrom == "00:00" && this.timeTo == "00:00";
            }
        },

        onRender: function() {
            var timeOptions = {
                step: 15,
                forceRoundTime: true
            };

            this.ui.timeFrom.timepicker(timeOptions);
            this.ui.timeTo.timepicker(timeOptions);

            if (!(this.model.get('timeFrom') == "00:00" && this.model.get('timeTo') == "00:00")) {
				store.set('_bathroom_setTime_ignore', true);
                this.ui.timeFrom.timepicker('setTime', new Moment(this.model.get('timeFrom'), 'HH:mm').toDate());
                this.ui.timeTo.timepicker('setTime', new Moment(this.model.get('timeTo'), 'HH:mm').toDate());
            }
        },

        updateTimeTo: function(e) {
            var elem = $(e.target);

            if (this.isValidTime(elem.val())) {
                // Valid time
                var date = new Moment(elem.val(), 'h:mma');

                date = date.add(15, 'minutes').format('h:mma');

                this.ui.timeTo.timepicker('option', {
                    minTime: date,
                    durationTime: elem.val(),
                    showDuration: true
                });

                if (this.ui.timeTo.val() != '') {
                    this.ui.timeTo.val('');
                }
            } else {
                // Invalid time
                this.ui.timeTo.timepicker('option', {
                    minTime: null,
                    durationTime: null,
                    showDuration: false
                });
            }

            this.clearTimeError();
        },

        go: function(e) {
            var dateWeek1 = new Moment(this.ui.dateWeek1.val(), 'ddd DD MMM YYYY'),
                dateWeek2 = new Moment(this.ui.dateWeek2.val(), 'ddd DD MMM YYYY'),
                timeFrom = this.ui.timeFrom.val(),
                timeTo = this.ui.timeTo.val(),
                time24 = this.ui.time24.is(':checked'),
                passed = true;

            // Validation
            if (!this.isValidDate(dateWeek1) || !this.isValidDate(dateWeek2)) {
                this.ui.dateError.css({
                    visibility: 'visible'
                });
                passed = false;
            }

            if (!time24 && (!this.isValidTime(timeFrom) || !this.isValidTime(timeTo))) {
                this.ui.timeError.css({
                    visibility: 'visible'
                });
                passed = false;
            }

            // Execution
            if (time24) {
                timeFrom = '00:00';
                timeTo = '00:00';
            } else {
                timeFrom = this.convertTo24Hours(timeFrom);
                timeTo = this.convertTo24Hours(timeTo);
            }

            if (passed) {
                Vent.trigger('newchart:bathroom-change', {
                    week1: dateWeek1,
                    week2: dateWeek2,
                    timeFrom: timeFrom,
                    timeTo: timeTo
                });
            } else {
                this.ui.bathroomErrors.show();
            }
        },

        isValidTime: function(time) {
            var regex = new RegExp(/1?[0-9]:(?:00|15|30|45)(?:am|pm)/);

            return regex.test(time);
        },

        isValidDate: function(date) {
            if (!Moment.isMoment(date)) {
                date = new Moment(date);
            }

            var minDate = new Moment(this.model.get('minimumDate')),
                maxDate = new Moment(this.model.get('maximumDate'));

            // Check if the date is between the allowed dates
            return (date.isSame(minDate) || date.isAfter(minDate)) && (date.isSame(maxDate) || date.isBefore(maxDate));
        },

        convertTo24Hours: function(time) {
            return new Moment(time, 'h:mma').format('HH:mm');
        },

        set24: function(e) {
            var target = $(e.target);

            if (target.is(':checked')) {
                this.ui.timeFrom.attr('disabled', 'disabled');
                this.ui.timeTo.attr('disabled', 'disabled');

                this.go(e);
            } else {
                this.ui.timeFrom.removeAttr('disabled');
                this.ui.timeTo.removeAttr('disabled');

                if (this.ui.timeFrom.val() != '' && this.ui.timeTo.val() != '') {
                    this.go(e);
                }
            }
        },

        clearTimeError: function() {
            this.ui.timeError.css({
                visibility: 'hidden'
            });

            if (this.ui.dateError.css('visibility') == 'hidden') {
                this.ui.bathroomErrors.hide();
            }

            if (this.ui.time24.is(':checked')) {
                this.ui.time24.click();
            }
        },

        onResize: function(e) {
            // Ignore first resize
            if (this.firstResize) {
                this.firstResize = false;
                return;
            }

            Vent.trigger('newchart:bathroom-render');
        },

        timeFocus: function(e) {
            document.activeElement.blur();
        },

        expandShowMe: function() {
            this.ui.showMeExpand.hide();
            this.ui.showMeCollapse.show();

            this.ui.showMeForm.attr('style', 'display: block !important');
        },

        collapseShowMe: function() {
            this.ui.showMeCollapse.hide();
            this.ui.showMeExpand.show();

            this.ui.showMeForm.removeAttr('style');
        },

        expandWeeks: function() {
            this.ui.weeksExpand.hide();
            this.ui.weeksCollapse.show();

            this.ui.week1.attr('style', 'display: block !important');
            this.ui.week2.attr('style', 'display: block !important');
            this.ui.goBtn.attr('style', 'display: block !important');
        },

        collapseWeeks: function() {
            this.ui.weeksCollapse.hide();
            this.ui.weeksExpand.show();

            this.ui.week1.removeAttr('style');
            this.ui.week2.removeAttr('style');
            this.ui.goBtn.removeAttr('style');
        },

        expandTimes: function() {
            this.ui.timesExpand.hide();
            this.ui.timesCollapse.show();

            this.ui.timesForm.attr('style', 'display: block !important');
        },

        collapseTimes: function() {
            this.ui.timesExpand.show();
            this.ui.timesCollapse.hide();

            this.ui.timesForm.removeAttr('style');
        },

        switchMode: function(e) {
            if (e.target.value != '') {
                Commands.execute('app:navigate', {
                    route: Backbone.history.getFragment().replace('nonOccupancy', e.target.value)
                });
            }
        }

    });

});
