/**
jQuery - Toggle Wrapper
v1.0.0
*/

/*
Requires:
  * jQuery
Author(s):
  * Just Checking  
*/

;(function ($, window, document, undefined) {

	$.fn.toggleWrapper = function(obj, init) {

    // If not obj is supplied
    if (obj == null) {

      // Create an empty object
      obj = {};

    }

    // If init has not been supplied
    if (init == null) {

      // Set init to true
      init = true;

    }

    // If init is true
    if (init) {
      
      // Add relative positioning to the element
      this.addClass('relative');

      // Create a new div element
      var overlay = $('<div>');

      // Add the right classes and attributes
      overlay.addClass('overlay opacity').attr('data-wrapper', true);

      // Append the overlay to the target element
      this.append(overlay);

    }
    else {

      // Remove the relative positioning
      this.removeClass('relative');
      
      // Remove the data wrapper
      this.find('[data-wrapper]').remove();
    
    }

  };

})(jQuery, window, document);

/*
console.log(this);
    var $height, $offset, $width;
    if (obj == null) {
      obj = {};
    }
    if (init == null) {
      init = true;
    }
    _.defaults(obj, {
      className: "",
      backgroundColor: this.css("backgroundColor") !== "transparent" ? this.css("backgroundColor") : "white",
      zIndex: this.css("zIndex") === "auto" || 0 ? 1000 : Number(this.css("zIndex"))
    });
    $offset = this.offset();
    $width = this.outerWidth(false);
    $height = this.outerHeight(false);
    if (init) {
      return $("<div>").appendTo("body").addClass(obj.className).attr("data-wrapper", true).css({
        width: $width,
        height: $height,
        top: $offset.top,
        left: $offset.left,
        position: "absolute",
        zIndex: obj.zIndex + 1,
        backgroundColor: 'white'//obj.backgroundColor
      });
    } else {
      return $("[data-wrapper]").remove();
    }

    */
;
define("jquery.toggleWrapper", ["jquery"], function(){});

