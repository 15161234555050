/**
Application Requests - Get Current Level
@module Requests
@submodule Requests.GetCurrentRoute
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get the current level
Author(s):
  * Just Checking
*/

define('app/requests/requests.getCurrentLevel',[

	'backbone',
	'app/requests/requests.vent'

], function(Backbone, Requests) {

	'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get the current level
			@event get:current:level
			*/
			return Requests.setHandler('get:current:level', function() {

				var frag = Backbone.history.fragment;

				// Is there a fragment?
				if (_.isEmpty(frag)) {

					return;
				
				}
				else {

					// Return the number of '/' in the fragment
					return frag.split(/\//g).length - 1;

				}

			});

    }

  };

});

