/**
Application Requests - Get state value
@module Requests
@submodule Requests.GetStateData
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get session data
Author(s):
  * Just Checking
*/

define('app/requests/requests.getStateData',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get session data
			@event get:state:data
			@param properties {String or Array} The properties to be returned
			*/
			return Requests.setHandler('get:state:data', function(options) {

				var App = require('app/app');

				return App.state;

			});

    }

  };

});

// Has the required param been supplied?
// if (options.properties) {

//  // Do we have an array?
//  if (_.isArray(options.properties)) {

//    var properties = {};

//    // Loop through each required property
//    _.each(options.properties, function(property, index) {

//      // Add the requested data to the properties object
//      properties[property] = App.state[property];

//    });

//    return properties;

//  }
//  else if (options.properties === 'all') {

//    // If 'all' is requested, return the state object
//    return App.state;

//  }
//  else {

//    return App.state[options.properties];

//  }

// }
// else {

//  return;

// };
