/**
Systems Collection
@module Systems
@submodule Systems.Collection
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Systems Collection
    * urlSource
    * model
Author(s):
  * Just Checking
*/

define('app/modules/systems/collections/systems.collections.systems',[

  'backbone',
  'app/modules/systems/models/systems.models.system'

], function(Backbone, Model) {

  'use strict';

  /**
  Systems Collection
  @class Systems.Collection
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property urlSource
    @type String
    */
    urlSource: 'Systems',

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model,

    /**
    @method comparator
    */
    comparator: function(model) {

      // Order the installations by 'systemId' ASC
      return model.get('systemId');

    }

  });

});

