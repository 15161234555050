/**
Application Users - Model
@module Users
@submodule Users.Model
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Users Model
    * defaults
Author(s):
  * Just Checking
*/

define('app/modules/account/models/users.models.user',[

  'backbone',
  'backbone.validation'

], function(Backbone, Validation) {

  'use strict';

  /**
  Main Users Model
  @class UsersModel
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property idAttribute
    @type String
    */
    idAttribute: 'userId',

    /**
    @property urlRoot
    @type String
    */
    urlRoot: 'Account',

    /**
    @property validation
    @type Object
    */
    validation: {

      emailAddress: {
        required: false,
        pattern: 'email',
        msg: 'Please enter a valid email address'
      },
      password: [
        {
          required: false,
          rangeLength: [5, 50],
          msg: 'Password must be at least 5 characters long'
        },
        {
          pattern: /^[a-zA-Z0-9@_]+$/,
          msg: 'Only letters, numbers, @ and _ characters allowed'
        },
        {
          equalTo: 'passwordConfirm',
          msg: 'Please enter matching passwords'
        }
      ],
      passwordConfirm: [
        {
          required: false,
          rangeLength: [5, 50],
          msg: 'Password must be at least 5 characters long'

        },
        {
          equalTo: 'password',
          msg: 'Please enter matching passwords'
        }
      ]

    }

  });

});
