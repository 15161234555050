/**
Chart Application - Edit Row List
@module Chart
@submodule Chart.Views.Edit.Rows
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Edit Row List
    * template
    * itemView
    * itemViewContainer
    * emptyView
    * onRender
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.edit.accelerometers.list',[

  'marionette',
  'tpl!app/modules/charts/templates/charts.templates.edit.accelerometers.tmpl',
  'app/modules/charts/views/charts.views.edit.accelerometers.item',
  'app/views/views.noItems',
  'jquery.ui'

], function(Marionette, Template, ItemView, NoItemsView, JQueryUI) {

  'use strict';

  /**
  Edit Row List
  @class Chart.Views.Edit.Rows
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Object
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#chart-edit-rows-list',

    itemViewOptions: function() {

    	return {
    		meta: this.model,
			sensors: this.options.allCollection
		};
	},

    /**
    @property emptyView
    @type Function
    */
    emptyView: function() {

      return new NoItemsView({

        itemName: 'activity sensors'

      });

    },

    /**
    @method onRender
    */
    onRender: function() {

      // Initialise the row sortability
      this.$itemViewContainer.sortable({

        // Set a delay in milliseconds before drag will start
        delay: 300,
        handle: this.$itemViewContainer.find('> li'),
        cursor: 'move',
        placeholder: 'ui-sortable-placeholder'

      });

    }

  });

});

