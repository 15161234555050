define('app/modules/newcharts/newcharts.module',[
    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'moment',
    'app/modules/newcharts/newcharts.router',
    'app/modules/newcharts/controllers/main.controller'
], function(Marionette, Vent, Commands, Requests, Moment, Router, MainController) {
    'use strict';

    return {
        initialize: function() {

            var App = require('app/app'),
                NewChartsApp = App.module('NewChartsApp', function() {
                    this.startWithParent = false;

                    /*
                        Methods
                     */
                    var API = {
                        chartView: function(systemId, installationId, type) {
                            NewChartsApp.controller = new MainController({
                                type: type,
                                systemId: parseInt(systemId, 0),
                                installationId: parseInt(installationId, 10),
                                debug: false
                            });
                            NewChartsApp.controller.module = NewChartsApp;
                        }
                    };

                    this.on('start', function() {
                        NewChartsApp.router = new Router({
                            controller: API
                        });
                    });
                });

            return NewChartsApp;
        }
    };
});

