define('app/modules/newcharts/features/events/views/event-form.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/events/templates/event-form.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder',
	'app/modules/newcharts/features/events/views/event-sensor.view',
	'app/views/views.noItems',

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder, EventSensorView, NoItemsView) {
	'use strict';

	return Marionette.CompositeView.extend({

		template: Template,

		itemView: EventSensorView,

		itemViewContainer: '#chart-edit-rows-list',

		emptyView: function() {
			return new NoItemsView({
				itemName: 'sensors',
				border: true
			});
		},

		templateHelpers: function() {
			return {
				inactiveSensors: function() {
					return _.filter(this.sensors, function(sensor) {
						return sensor.order == 99;
					});
				},

				activeSensors: function() {
					var sensors = _.filter(this.sensors, function(sensor) {
						return sensor.order != 99;
					});

					return _.sortBy(sensors, function(sensor) {
						return sensor.order;
					});
				},

				allSensors: function() {
					return _.sortBy(this.sensors, function(sensor) {
						return sensor.order;
					});
				}
			}
		},

		ui: {
			addBtn: '.add-btn',
			saveBtn: '.save-btn',
			deleteBtn: '.delete-btn',
			backBtn: '.back-btn',
			sensorDropdown: '#event-sensor-list',
			timeframe: 'input[name=timeframe]',
			inOrderSwitchWrapper: '#in-order-switch-wrapper',
			inOrderSwitch: '#in-order-switch',
			startTime: '#start_time',
			endTime: '#end_time',
			allDaySwitch: "input[name=allday-check]",
			allDaySwitchWrapper: '.allday-switch',

			nameError: '#name-error',
			sensorError: '#sensor-error',
			timeError: '#time-error'
		},

		events: {
			'click @ui.addBtn': 'addRow',
			'click @ui.saveBtn': 'saveForm',
			'click @ui.backBtn': 'goBack',
			'click @ui.deleteBtn': 'deleteEvent',
			'change @ui.sensorDropdown': 'addSensor',

			'change @ui.allDaySwitch': 'changeAllDay'
		},

		onRender: function() {
			this.setupSortable();

			var timeframeOptions = {
					step: 1,
					forceRoundTime: true,
					timeFormat: function (date) {
						var integer = parseInt(date.getMinutes());

						if (integer == 1) {
							return integer + ' minute';
						} else if (integer == 0) {
							integer = 60;
						}

						return integer + ' minutes';
					},
					minTime: '00:01',
					maxTime: '01:00'
				},
				timerangeOptions = {
					step: 15,
					forceRoundTime: true,
					timeFormat: 'H:i'
				};

			this.ui.timeframe.timepicker(timeframeOptions);
			this.ui.startTime.timepicker(timerangeOptions);
			this.ui.endTime.timepicker(timerangeOptions);

			// Setup the In Order switch.
			if (this.model.get('in_order') == true) {
				this.ui.inOrderSwitch.attr('checked', 'checked');
			}
			Switcher.init({
				view: this.ui.inOrderSwitchWrapper
			});

			// Setup the 24 Hour switch.
			if (this.model.get('all_day') == true) {
				this.ui.allDaySwitch.attr('checked', 'checked');
				this.ui.startTime.attr('disabled', 'disabled');
				this.ui.endTime.attr('disabled', 'disabled');
			} else {
				this.ui.allDaySwitch.removeAttr('checked');
			}
			Switcher.init({
				view: this.ui.allDaySwitchWrapper
			});

			Vent.on('events:remove-sensor', this.removeSensor.bind(this));
		},

		onShow: function() {
			if (this.collection.length == 1 && this.collection.first().get('label') == null) {
				this.collection.pop();
			}
		},

		addRow: function(e) {
			e.preventDefault();

			var sensorRow = _.template('<div class="row margin-top padding-top">\n\t<div class="column">\n\t\t<select name="sensors" class="pure-input-1">\n\t\t\t<option value=""></option>\n\t\t\t<% _.each(allSensors(), function(sensor) { %>\n\t\t\t<option value="<%= sensor.code %>"><%= sensor.label %></option>\n\t\t\t<% }) %>\n\t\t</select>\n\t</div>\n</div>');
			this.$el.find('.sensors').append(sensorRow({
				allSensors: function() {
					return _.sortBy(this.model.get('sensors'), function(sensor) {
						return sensor.order;
					});
				}.bind(this)
			}));
		},

		saveForm: function(e) {
			e.preventDefault();

			var data = Backbone.Syphon.serialize(this);

			// Validation?
			this.clearValidation();
			if (!this.performValidation(data)) {
				return;
			}

			this.model.set({
				event_name: data.name,
				in_order: data.in_order,
				sensors: this.collection.toJSON(),
				timeframe_seconds: this.convertTimeframe(data.timeframe),
				start_time: this.ui.startTime.val(),
				end_time: this.ui.endTime.val(),
				all_day: this.ui.allDaySwitch.is(':checked')
			});

			Crudder.creatingUpdating({
				entity: this.model,
				patch: false,
				successMessage: 'Your event has been saved.',
				errorMessage: 'Unable to save'
			}, function(response) {
				if (response.success) {
					Vent.trigger('newchart:event-list');
				}
			}.bind(this));
		},

		deleteEvent: function(e) {
			Crudder.deleting({
				entity: this.model,
				successMessage: 'Event deleted successfully.',
				errorMessage: 'Unable to delete event.'
			}, function(response) {
				if (response.success) {
					Vent.trigger('newchart:event-list');
				}
			});
		},

		sorted: function(e, ui) {
			var i = 1, sensor, list = $('#chart-edit-rows-list');

			list.find('li').each(function(index, elem) {
				sensor = this.collection.find(function(sensor) {
					return sensor.get('code') == $(elem).data('code');
				}.bind(this));

				sensor.set('order', i);
				list.find('li[data-code=' + sensor.get('code') + ']')
					.find('span.order')
					.text(i);

				i++;
			}.bind(this));
		},

		setupSortable: function() {
			this.$itemViewContainer.sortable({

				// Set a delay in milliseconds before drag will start
				delay: 300,
				handle: this.$itemViewContainer.find('> li'),
				cursor: 'move',
				placeholder: 'ui-sortable-placeholder',
				stop: this.sorted.bind(this)

			});
		},

		addSensor: function(e) {
			if (e.target.value != "" && e.target.value != "-") {
				// Find selected sensor
				var selectedSensor = this.model.get('sensors').find(function(sensor) {
					return sensor['code'] == e.target.value;
				});

				// Find the next order number
				var lastOrder = $('#chart-edit-rows-list > li').last().find('span.order').text();
				if (!lastOrder) {
					selectedSensor.order = 1;
				} else {
					selectedSensor.order = parseInt(lastOrder) + 1;
				}

				// Add the sensor to the list.
				this.collection.push(selectedSensor);

				// Remove from select list
				this.ui.sensorDropdown
					.find('option[value=' + selectedSensor.code + ']')
					.remove();

				// Set select list back to 'Choose...'
				this.ui.sensorDropdown.val('');

				this.setupSortable();
			}
		},

		removeSensor: function(sensor) {
			this.bindUIElements();

			sensor.set('order', 99);

			var newSensors = this.model.get('sensors');
			newSensors.push({
				code: sensor.get('code'),
				label: sensor.get('label'),
				name: sensor.get('name'),
				order: sensor.get('order')
			});
			this.model.set('sensors', newSensors);

			if (!this.ui.sensorDropdown || this.ui.sensorDropdown) {
				this.ui.sensorDropdown = this.$el.find('#event-sensor-list');
			}
			this.ui.sensorDropdown.append('<option value="' + sensor.get('code') + '">' + sensor.get('label') + '</option>');

			this.collection.remove(sensor);
			this.setupSortable();
		},

		goBack: function(e) {
			e.preventDefault();

			Vent.trigger('newchart:event-list');
		},

		convertTimeframe: function(timeframe) {
			return parseInt(timeframe.replace(' minutes', '')) * 60;
		},

		changeAllDay: function(e) {
			if (!this.ui.allDaySwitch.is(':checked')) {
				this.ui.startTime.removeAttr('disabled');
				this.ui.endTime.removeAttr('disabled');
			} else {
				this.ui.startTime.attr('disabled', 'disabled');
				this.ui.endTime.attr('disabled', 'disabled');
			}
		},

		performValidation: function(data) {
			var errors = false;

			if (data.name == '') {
				this.ui.nameError.fadeIn().fadeOut().fadeIn().fadeOut().fadeIn().fadeOut().fadeIn();
				errors = true;
			}
			if (this.collection.length == 0) {
				this.ui.sensorError.fadeIn().fadeOut().fadeIn().fadeOut().fadeIn().fadeOut().fadeIn();
				errors = true;
			}
			if (
				(this.ui.startTime.val() == '' || this.ui.endTime.val() == '') && !this.ui.allDaySwitch.is(':checked')
			) {
				this.ui.timeError.fadeIn().fadeOut().fadeIn().fadeOut().fadeIn().fadeOut().fadeIn();
				errors = true;
			}

			return !errors;
		},

		clearValidation: function() {
			this.ui.nameError.hide();
			this.ui.sensorError.hide();
			this.ui.timeError.hide();
		}

	});

});
