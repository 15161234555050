/**
Chart Application - Router
@module Chart
@submodule Chart.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/charts/charts.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  /**
  Router
  @class ChartApp.Router
  @constructor
  @extends Marionette.AppRouter
  */
  return Marionette.AppRouter.extend({

		/**
		@property appRoutes
		*/
    appRoutes: {

      'systems/:systemId/charts/:installationId': 'getById',
      'systems/:systemId/charts/:installationId/edit': 'edit',
      'systems/:systemId/charts/:installationId/date/:date': 'getById',
      'systems/:systemId/charts/:installationId/date/:date/edit': 'edit',
      'systems/:systemId/charts/:installationId/date/:date/zoom/:zoom': 'getById',
      'systems/:systemId/charts/:installationId/date/:date/zoom/:zoom/edit': 'edit',
      'systems/:systemId/charts/:installationId/users': 'getUsers',
      'systems/:systemId/charts/:installationId/users/:userId': 'getUser',

    }

  });

});

