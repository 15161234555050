define('app/modules/newcharts/views/bathroom-table.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/bathroom-table.template.tmpl',
    'moment',
    'app/modules/newcharts/state'

], function(Marionette, Vent, Requests, Template, Moment, State) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            bathroomDate: '.bathroom-date'
        },

        events: {
            'click @ui.bathroomDate': 'loadChart'
        },

        templateHelpers: {
            pillLimit: function() {
                var browserWidth = $(window).width(),
                    formula = browserWidth / 60,
                    result = Math.floor(formula),
                    difference = formula - result;

                if (difference <= 0.5) {
                    result -= 1;
                }

                // Upper cap.
                if (result > 15) {
                    result = 15;
                }

                return result;
            },

            mobileDate: function(date) {
                date = date.split(" ");
                date[1] = date[1].replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');

                date = new Moment(date[1] + ' ' + date[2] + ' ' + date[3], 'D MMMM YYYY');

                return date.format('Do MMM YYYY');
            }
        },

        initialize: function() {
            this.render();
        },

        loadChart: function(e) {
            var date = e.target.text;

            date = date.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
            date = new Moment(date, 'dddd D MMMM YYYY');

            // Set chart state
            State.setType('chart', this.model.get('installationId'), {
                date: date,
                zoom: 120
            });

            // Click chart image.
            $('#feature-chart-icon').click();
        }

    });

});
