/**
Logins Application
@module Logins
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Module method
		* API
		* Start Event
Author(s):
  * Just Checking
*/

define('app/modules/logins/logins.module',[

  'marionette',
  'app/requests/requests.vent',
  'app/modules/logins/logins.router',
  'app/modules/logins/logins.controller'

], function(Marionette, Requests, Router, LoginsController) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			LoginsApp = App.module('LoginsApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				/**
				@property API
				*/
				var API = {

					/*
					Get item list
					*/

					getList: function(options) {

						// Options are not required
						options = options || {};

						// Defaults
						_.defaults(options, {

							panelView: false

						});

						// Create a new controller
						LoginsApp.controller = new LoginsController({

							panelView: options.panelView

						});

						// Are the logins to be shown in a panel?
						if (options.panelView) {

							// Return the view to be shown elsewhere
							return LoginsApp.controller.view;

						}

					}

				};

				/**
				Get list of logins
				@event logins:get:list
				@param panelView {Boolean} Is the destination a panel?
				*/
				Requests.setHandler('logins:get:list', function(options) {

					// Call the correct method of the API
					return API.getList(options);

				});

				/**
				Start Event
				@event start
				*/
				this.on('start', function(hash) {

					// Start the router for this sub app
					LoginsApp.router = new Router({

						controller: API

					});

				});

			});

			return LoginsApp;

		}

	};

});

