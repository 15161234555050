/**
Application Commands - Initialise a popover
@module Commands
@submodule Commands.InitPopover

Use this command to assign a popover to an element
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
		* Initialise a popover
Author(s):
  * Just Checking
*/

define('app/commands/commands.initPopover',[

	'backbone',
	'app/commands/commands.vent',
	'bootstrap.popover'

], function(Backbone, Commands, HeaderLayout, Popover) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Initialise a popover
			@event init:popover
			@param element {Object} The target element
      @param data {Object} The tooltip data to show
      @param container {Object} The target container to append the popover HTML to
			*/
			return Commands.setHandler('init:popover', function(options, debug) {

				// Debug
				if (debug) {

					console.log(options);

				}

				// Validation
        if (options.data.text) {

          // Define the options for the popover
          var popoverOptions = {
		    html: options.html ? true : false,
            trigger: options.trigger || 'hover',
            content: options.data.text,
            placement: options.data.placement || 'right'

          };

          // Has a headline been supplied
          if (options.data.headline) {

            // Add the headline to the options object
            popoverOptions.title = options.data.headline;

          }

          // Has an alternative container been supplied?
          if (options.container) {

            // Add the headline to the options object
            popoverOptions.container = options.container;

          }

          // Initilise the element popover
          options.element.popover(popoverOptions);

        }
        else {

          return;

        }

			});

    }

  };

});

