/**
Chart Application - State Model
@module Chart
@submodule Chart.Models.State
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * State Model
    * idAttribute
    * defaults
Author(s):
  * Just Checking
*/

/*
Example Data:
  date: "2013-06-24 06:00:00"
  installationId: "12345"
  systemId: "30998"
  view: "night"
  zoom: "2"
*/

define('app/modules/charts/models/charts.models.state',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  State Model
  @class Chart.Models.State
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    Define the id attribute of the model
    @property idAttribute
    @type Integer
    */
    idAttribute: 'installationId',

    /**
    @property defaults
    @type Object
    */
    defaults: {

      'view': 'day',
      'zoom': 120, // 24 hours
      'inlineEditing': false,
      'chartWidth': 720,
      'chartIntervalWidth': 30,
      'position': 0

    }

  });

});
