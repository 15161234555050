/**
Chart Application - Timeline View
@module Chart
@submodule Chart.Views.Timeline
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Timeline View
    * tagName
    * template
    * className
    * events
    * serializeData
    * setFormat
    * navigate
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.timeline.item',[

  'marionette',
  'app/app.vent',
  'tpl!app/modules/charts/templates/charts.templates.timeline.item.tmpl',
  'app/requests/requests.vent'

], function(Marionette, Vent, Template, Requests) {

  'use strict';

  /**
  Timeline View
  @class Chart.Views.Timeline
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property tagName
    @type String
    */
    tagName: 'a',

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property className
    @type Function
    */
    className: function() {

      var
      className = (this.options.zoom === 10) ? 'chart-timeline-minutes' : '',
      hour = this.templateHelpers().formatDate(this.model.get('axisLabel'), this.setFormat());

      // Detect midnight
      if (hour === '00' || hour === '00:00') {

        className += ' chart-timeline-label-midnight';

      }
      // Detect noon
      else if (hour === '12' || hour === '12:00') {

        className += ' chart-timeline-label-noon';

      }

      return className;

    },

    /**
    @property events
    @type Object
    */
    events: {

			'click': 'navigate'

    },

    /**
    @method serializeData
    */
    serializeData: function() {

      return {

        'axisLabel': this.model.get('axisLabel'),
        'format': this.setFormat()

      };

    },

    /**
    @method setFormat
    @return The format required for the timeline values
    */
    setFormat: function() {

      return (this.options.zoom === 10) ? 'HH:mm' : 'HH';

    },

    /**
		@method navigate
		*/
    navigate: function(e) {
      // Are we zoomed in?
      if (this.options.zoom === 10) {

        return;

      }
      else {
        // Navigate the chart app
        Vent.trigger('chart:navigate', {

          date: this.model.get('axisData'),
          zoom: 10

        });

      }

      e.preventDefault();

    }

  });

});

