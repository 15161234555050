define('app/modules/newcharts/state',['moment'], function(Moment){

    var types = [
        'chart',
        'multi',
        'doorActivity',
        'nonOccupancy',
        'bathroom'
    ];

    function setItem(key, item) {
        if (typeof item == 'object') {
            item = JSON.stringify(item);
        }

        try {
            store.set(key, item);
        } catch(e) {
            console.warn('Could not save state ' + key + ' into localstorage, skipping.', e);
        }
    }

    function hasItem(key) {
        var hasItem = false;

        try {
            hasItem = store.get(key) !== null;
        } catch(e) {
            console.warn('Could not check state ' + key + ' in localstorage, ignoring.', e);
        }

        return hasItem;
    }

    function getItem(key) {
        var value = {};

        try {
            value = store.get(key);
        } catch(e) {
            console.warn('Could not retrieve state ' + key + ' from localstorage, using blank state.', e);
        }

        if (typeof value == 'string') {
            value = JSON.parse(value);
        }

        return value;
    }

    function removeItem(key) {
        try {
			store.remove(key);
        } catch(e) {
            console.warn('Could not remove state ' + key + ' from localstorage, ignoring.', e);
        }
    }

    return {
        /**
         * Load the state from LocalStorage
         * @param model
         */
        load: function(model) {
            var state,
                type = model.get('type'),
                installationId = model.id;

            if (!type && model.get('multi')) {
                type = 'multi';
            }

            if (!$.inArray(type, types)) {
                type = 'chart';
            }

            state = getItem(type + '_' + installationId);

            if (type == 'doorActivity' || type == 'nonOccupancy') {
                this.setLastDoorActivityTab(installationId, type);
            }

            if (!state) {
                return {};
            }

            switch(type) {
                case 'multi':
                    return {
                        startDate: new Moment(state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                        endDate: new Moment(state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
                        date: new Moment(state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                        zoom: state.zoom,
                        hiddenSensors: state.hiddenSensors,
                        hideNotes: state.hideNotes,
                        nightView: state.nightView
                    };

                case 'bathroom':
                case 'bathroomVisits':
                    return {
                        week1: new Moment(state.week1),
                        week2: new Moment(state.week2),
                        timeFrom: state.timeFrom,
                        timeTo: state.timeTo
                    };

                case 'doorActivity':
                    this.setLastDoorActivityTab(installationId, 'doorActivity');
                    return {
                        week1: new Moment(state.week1),
                        week2: new Moment(state.week2),
                        timeFrom: state.timeFrom,
                        timeTo: state.timeTo,
                        sensorId: state.sensorId
                    };

                case 'nonOccupancy':
                    this.setLastDoorActivityTab(installationId, 'nonOccupancy');
                    return {
                        week1: new Moment(state.week1),
                        week2: new Moment(state.week2),
                        timeFrom: state.timeFrom,
                        timeTo: state.timeTo
                    };

                case 'heatLight':
                    return {
                        mode: state.mode,
						sensorId: state.sensorId
                    };

                default:
                    return {
                        date: new Moment(state.date).format('YYYY-MM-DDTHH:mm:ss'),
                        zoom: state.zoom
                    };
            }
        },

        /**
         * Save the state into LocalStorage.
         * @param model
         */
        save: function(model) {
            var type = model.get('type'),
                installationId = model.id,
                hiddenSensors = [];

            switch(type) {
                case 'multi':
                    setTimeout(function() {
                        // Detect hidden sensors
                        model.get('chartRows').forEach(function(row) {
                            var input = $('#switchChartRow' + row.get('chartLineId'));

                            if (!input.is(':checked') && row.get('rowShow') == true) {
                                hiddenSensors.push(row.get('chartLineId'));
                            } else if (input.is(':checked') && row.get('rowShow') == false) {
                                hiddenSensors.push(row.get('chartLineId'));
                            }
                        });

                        setItem('multi_' + installationId, {
                            startDate: model.get('startDate'),
                            endDate: model.get('endDate'),
                            zoom: model.get('chartMeta').zoom,
                            hiddenSensors: hiddenSensors,
                            hideNotes: !$('#switchHideNotes').is(':checked'),
                            nightView: $('#switchNightView').is(':checked')
                        });
                    });
                    break;

                case 'bathroom':
                case 'bathroomVisits':
                    setItem('bathroom_' + installationId, {
                        week1: model.get('week1').format('YYYY-MM-DD'),
                        week2: model.get('week2').format('YYYY-MM-DD'),
                        timeFrom: model.get('timeFrom'),
                        timeTo: model.get('timeTo')
                    });
                    break;

                case 'doorActivity':
                    setItem('doorActivity_' + installationId, {
                        week1: model.get('week1').format('YYYY-MM-DD'),
                        week2: model.get('week2').format('YYYY-MM-DD'),
                        timeFrom: model.get('timeFrom'),
                        timeTo: model.get('timeTo'),
                        sensorId: model.get('sensorId')
                    });
                    this.setLastDoorActivityTab(installationId, 'doorActivity');
                    break;

                case 'nonOccupancy':
                    setItem('nonOccupancy_' + installationId, {
                        week1: model.get('week1').format('YYYY-MM-DD'),
                        week2: model.get('week2').format('YYYY-MM-DD'),
                        timeFrom: model.get('timeFrom'),
                        timeTo: model.get('timeTo')
                    });
                    this.setLastDoorActivityTab(installationId, 'nonOccupancy');
                    break;

                case 'heatLight':
                    setItem('heatLight_' + installationId, {
                        mode: model.get('mode'),
						sensorId: model.get('sensorId')
                    });
                    break;

                default:
                    setItem('chart_' + installationId, {
                        date: new Moment(model.get('chartMeta').chartDate),
                        zoom: model.get('chartMeta').zoom
                    });
                    break;
            }
        },

        /**
         * Manually set state for a given type.
         * @param {String} type
         * @param {int} installationId
         * @param {Object} options
         */
        setType: function(type, installationId, options) {
            var existingOptions = getItem(type + '_' + installationId);

            if (typeof existingOptions != 'object' || existingOptions == null) {
                existingOptions = {};
            }

            options = _.extend(existingOptions, options);

            setItem(type + '_' + installationId, options);
        },

        /**
         * Remember if they use a mouse.
         */
        rememberMouse: function() {
            store.set('mouse_user', true);
        },

        /**
         * Are they a mouse user?
         * @returns {boolean}
         */
        isMouse: function() {
            var mouseUser = store.get('mouse_user');

            return mouseUser == 'true' || $('html').hasClass('no-touch');
        },

        /**
         * Remember that we've jumped time.
         * @param installationId
         */
        rememberTimeJump: function(installationId) {
            setItem('multi_timejump_' + installationId, 'true');
        },

        /**
         * Has there been a multiday time jump for this installation?
         * @param installationId
         */
        hasTimeJump: function(installationId) {
            return hasItem('multi_timejump_' + installationId);
        },

        removeTimeJump: function(installationId) {
			removeItem('multi_timejump_' + installationId);
		},

        setLastDoorActivityTab: function(installationId, tab) {
            setItem('doorActivity_nonOccupancy_' + installationId, {
                page: tab
            });
        },

        /**
         * Get the last active tab for doorActivity / nonOccupancy
         * @param {int} installId
         * @param {string} def Default
         * @returns {string}
         */
        getLastDoorActivityTab: function(installId, def) {
            var lastTab = getItem('doorActivity_nonOccupancy_' + installId);

            if (lastTab == null || typeof lastTab != 'object') {
                lastTab = def;
            } else {
                lastTab = lastTab.page;
            }

            return lastTab;
        },

		rememberExpand: function(installId, sensorId) {
        	setItem('chart_' + installId + '_' + sensorId, 'expanded');
		},

		rememberCollapse: function(installId, sensorId) {
			removeItem('chart_' + installId + '_' + sensorId);
		},

		isExpanded: function(installId, sensorId) {
			return store.get('chart_' + installId + '_' + sensorId) == 'expanded';
		},

        rememberEmail: function(email) {
            // Create a custom cookie
            document.cookie = "email=" + email + ";";
        },

        getEmail: function() {
            var cookies = decodeURIComponent(document.cookie).split(';');

            for (var cookie in cookies) {
                cookie = cookies[cookie];

                while(cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }

                if (cookie.indexOf('email') === 0) {
                    return cookie.substring('email'.length+1, cookie.length);
                }
            }

            return '';
        },

        getTypeManual: function(type, installId) {
            return getItem(type + '_' + installId);
        },

        getHeatLightAttempts: function(installId) {
            return getItem('heatLight_' + installId + '_attempts');
        },

        setHeatLightAttempts: function(installId, attempts) {
            setItem('heatLight_' + installId + '_attempts', attempts);
        },

        setBearerObject: function(values){
            if(!values){
                setItem('oAuth_bearer_object', null);
                return
            }
            // var created_at = Date.now() / 1000;
            var bearerObject = {
                access_token: values['access_token'],
                token_type: values['token_type'],
                expires_in: values['expires_in'],
                refresh_token: values['refresh_token'],
                scope: values.scope,
                created_at: Date.now() / 1000
            }
            var bearerString = JSON.stringify(bearerObject)
            setItem('oAuth_bearer_object', bearerString);
        },

        getBearerObject: function(){
            var obj = getItem('oAuth_bearer_object');
            return obj;//JSON.parse(obj)
        },

        getRefreshToken: function(){
            var obj = this.getBearerObject();
            if(!obj){
                return null
            }
            return obj.refresh_token ? obj.refresh_token : null
        },


        // getExpiresIn: function(){
        //     return getItem('oAuth_bearer');
        // },

        hasRefreshToken: function(){
            return this.getRefreshToken() ? true : false;
        },

        isBearerValid: function() {
            var that = this;
            return new Promise(function(resolve, reject) {
              if (!that.hasRefreshToken()) {
                reject(false);
                return;
              }
              var token = that.getBearerObject();
              if (!token || typeof token !== 'object') {
                reject(false);
                return;
              }

            //   var now = Date.now() / 1000;
            //   var expiry = token.created_at + token.expires_in;

            //   if (now < expiry) {
            //     resolve(true);
            //     return;
            //   }

            that.checkSession()
                .then(function() {
                    resolve(true);
                })
                .catch(function() {
                    // if check session fails, then try the refresh
                    that.refreshToken()
                    .then(function() {
                        resolve(true);
                    })
                    .catch(function(err) {
                        reject(false);
                    });
                });
            });
          },

          checkSession: function() {
            var that = this;
            var token = that.getBearerObject().access_token;
            return new Promise(function(resolve, reject) {
              var xhr = new XMLHttpRequest();
              xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    resolve();
                  } else {
                    reject(xhr.status);
                  }
                }
              };
              xhr.open('GET', 'https://api.justchecking.com/session/check', true);
              xhr.setRequestHeader('Authorization', 'Bearer ' + token)
              xhr.send();
            });
          },

          refreshToken: function() {
            var that = this;
            return new Promise(function(resolve, reject) {
              var xhr = new XMLHttpRequest();
              xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    var token = JSON.parse(xhr.responseText);
                    that.setBearerObject(token);
                    resolve();
                  } else {
                    reject(xhr.status);
                  }
                }
              };
              xhr.open('POST', 'https://api.justchecking.com/oauth/refresh', true);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            //   xhr.setRequestHeader('Authorization', 'Bearer ' + that.getRefreshToken());
              xhr.send('grant_type=refresh_token&refresh_token=' + that.getRefreshToken());
            });
          },

          requestToken: function(username, password) {
            var that = this;
            return new Promise(function(resolve, reject) {
              var xhr = new XMLHttpRequest();
              xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    var token = JSON.parse(xhr.responseText);
                    that.setBearerObject(token);
                    resolve();
                  } else {
                    reject(xhr.status);
                  }
                }
              };
              xhr.open('POST', 'https://api.justchecking.com/oauth/login', true);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
              xhr.send('grant_type=password&username=' + username + '&password=' + password);
            });
          },

          destroyToken: function() {
            var that = this;
            return new Promise(function(resolve, reject) {
              var xhr = new XMLHttpRequest();
              xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    that.setBearerObject(null);
                    resolve();
                  } else {
                    that.setBearerObject(null);
                    reject(xhr.status);
                  }
                }
              };
              xhr.open('POST', 'https://api.justchecking.com/oauth/logout', true);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
              xhr.setRequestHeader('Authorization', 'Bearer ' + State.getBearerObject().access_token);
              xhr.send('grant_type=logout');
            });
          },

          //  chart lines list
            // model:
            // chartID: element.dataType,
            // visible: Boolean,
            // chartLabel: element.dataType,
            // type: "rect"/"path" etc
        getChartLinesList: function() {
            var chartLinesListString = localStorage.getItem("chart_lines_list");
            if (chartLinesListString) {
              return JSON.parse(chartLinesListString);
            } else {
              return [];
            }
        },
        saveChartLinesList: function(chartLinesList) {
            localStorage.setItem("chart_lines_list", JSON.stringify(chartLinesList));
        },

        syncChartLinesOptions:function(chartLineId, optionsArray) {
            var chartLinesList = this.getChartLinesList();
            var existingIndex = chartLinesList.findIndex(function(item) {
              return item.chartLineId === chartLineId;
            });

            if (existingIndex !== -1) {
              chartLinesList[existingIndex].options = optionsArray;
            } else {
              chartLinesList.push({
                chartLineId: chartLineId,
                options: optionsArray
              });
            }
            this.saveChartLinesList(chartLinesList);
        },

        getChartLineOptionsListFromId: function(chartLineId){
            var chartLinesList = this.getChartLinesList();
            if(!chartLinesList || !chartLinesList.length){
                return []
            }
            // find item from chartLineId
            var foundItem = chartLinesList.find(function(item) {
                return item.chartLineId === chartLineId;
            });

            if (foundItem) {
                return foundItem.options || [];
            }
            return [];
        },

        getVisibleByChartId:function(chartLineId, chartId) {
            var foundOptions = this.getChartLineOptionsListFromId(chartLineId)
            if (foundOptions) {
                var foundEntry = foundOptions.find(function(entry) {
                    return entry.chartID === chartId;
                });
                if (foundEntry) {
                    return foundEntry.visible;
                }
            }

            return true; // Default to true if no matching entry is found
          }

    };

});

