define('app/models/sequencesReportLightbox.model',[
	'backbone',
	'app/commands/commands.vent'
], function(Backbone, Commands) {
	'use strict';

	Backbone.emulateHTTP = true;

	return Backbone.Model.extend({

		idAttribute: '_id',

		url: function() {
			return 'api/v1/AccelerometerEvents/Chart/' + this.id + '/' + this.get('eventId');
		},

		defaults: {
			email: '',
			autocomplete: [],
			eventId: null
		},

		isNew: function() {
			return true;
		}

	});
});

