/**
Chart Application - Timeline Collection
@module Chart
@submodule Chart.Collections.Timeline
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Timeline Collection
    * model
Author(s):
  * Just Checking
*/

define('app/modules/charts/collections/charts.collections.timeline',[

  'backbone',
  'app/modules/charts/models/charts.models.timeline.item'

], function(Backbone, Model) {

  'use strict';

  /**
  Timeline Collection
  @class ChartApp.Collections.Timeline
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model

  });

});
