/**
Account Application - Router
@module Account
@submodule Account.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/account/account.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  /**
  Router
  @class Account.Router
  @constructor
  @extends Marionette.AppRouter
  */
  return Marionette.AppRouter.extend({

		/**
		@property appRoutes
		*/
    appRoutes: {

      'account': 'getAccount'

    }

  });

});

