define('app/modules/newcharts/models/chart-row.model',[
    'backbone'
], function(Backbone) {
    'use strict';

    return Backbone.Model.extend({

        idAttribute: '_id'

    });
});

