/**
Application Components - Panels Layout
@module Components
@submodule Components.Panels.Layouts.Panel
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
Contents:
  * Layout Panel View
    * template
    * regions
Author(s):
  * Just Checking
*/

define('app/components/panels/layouts/panels.layouts.panel',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'tpl!app/components/panels/templates/panels.templates.panel.tmpl'

], function(Marionette, Commands, Requests, Template) {

  'use strict';

  /**
  Layout Panel View
  @class MainApp.Layout.Panel
  @constructor
  @extends Backbone.Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    Create the regions for the desktop and mobile menus
    @property regions
    @type Object
    */
    regions: {

      header: '#panel-header',
      content: '#panel-inner'

    }

  });

});

