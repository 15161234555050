/**
System List
@module Systems
@submodule Systems.Views.List
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * System List
    * template
    * itemView
    * itemViewContainer
    * emptyView
    * itemViewOptions
Author(s):
  * Just Checking
*/

define('app/modules/systems/views/systems.views.list',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/systems/views/systems.views.item',
  'tpl!app/modules/systems/templates/systems.templates.list.tmpl',
  'app/views/views.noItems',
  'app/modules/systems/models/feature.promotion',
  'tpl!app/modules/systems/templates/feature.promotion.tmpl',
  'app/modules/systems/models/feedback.form',
  'backbone.crudder',
  'moment',
  'app/modules/systems/models/system.totals',
  'tpl!app/modules/systems/templates/system.totals.tmpl'

], function(Marionette, Commands, Requests, ItemView, Template, NoItemsView, FeatureModel, WhatsNewTemplate, FeedbackModel, Crudder, Moment, SystemTotalsModel, SystemTotalsTemplate) {

  'use strict';

  /**
  System List
  @class Systems.Views.List
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#systems-list',

    featureModel: null,
    totalsModel: null,

    stage: "whats-new",

    skipWhatsNewRender: false,
    skipSystemTotalsRender: false,

    sortBy: 'lastUpdate',
    direction: 'desc',

    events: {
        'click .sortable': 'sort'
    },

	templateHelpers: function() {
    	var collection = this.collection.clone();

		return _.extend({
			showLastNotification: function() {
				collection = collection.filter(function(item) {
					return item.get('lastNotification') != null;
				});

				return collection.length > 0;
			}
		}, Marionette.ItemView.prototype.templateHelpers.call(this));
    },

    /**
    @property itemViewOptions
    @type Object
    */
    itemViewOptions: function() {

      // Request the previous params
      var previousParams = Requests.request('get:state:data').get('previousParams');

      // Do we have a system Id param?
      if (previousParams.systemId) {

        // Pass the current zoom level into the item view
        return {

          systemId: parseInt(previousParams.systemId, 10),
		  collection: this.collection

        };

      }
      else {

        return {
			collection: this.collection
		};

      }

    },

    /**
    @property emptyView
    @type Function
    */
    emptyView: function() {

      return new NoItemsView({

        itemName: 'systems',
        border: true,
        marginTop: true

      });

    },

      onRender: function() {
      	  if (!window.top || window.top.location == window.location) {
              if (!this.skipWhatsNewRender) {
                  this.loadFeaturePromotion();

                  this.skipWhatsNewRender = false;
              }
      	  }

          setTimeout(function() {
              this.renderSortArrows();
          }.bind(this));

          var systems = this.collection.models;

          for(var i = 0; i < systems.length; i++) {
              if (systems[i].get('evaluationStatus') != false && systems[i].get('evaluationStatus') != "") {
                setTimeout(function(){
                    $('th.evaluation').show();
                });
              }
          }
      },

      /**
       * Load the promotion feature.
       */
      loadFeaturePromotion: function() {
          this.featureModel = new FeatureModel();

          this.featureModel.fetch({
              success: function() {
                  this.renderFeaturePromotion();
				  if (!this.skipSystemTotalsRender) {
					  this.loadSystemTotals();

					  this.skipSystemTotalsRender = false;
				  }
              }.bind(this)
          });
      },

      /**
       * Render the promotion feature.
       */
      renderFeaturePromotion: function() {
          var $page = $('#page-main');

          $page.find('#feature-promotion').remove();

          // Check if we're on the 'My Systems' page before attempting to render.
          if (Backbone.history.getFragment() != "systems") {
              // Nope!
              return;
          }

          $page.prepend(WhatsNewTemplate({
              showFeature: this.featureModel.get('showFeature'),
              features: this.featureModel.getFeatures(),
              archived: this.featureModel.getArchived(),
              feedback: this.featureModel.get('feedback')
          }));
          $page.find('#feature-promotion').slideDown(500, function() {
              // if($.cookie('showTour') !== 'false') {
              //     // Load help bubbles.
              //     $('.system:eq(1)').grumble({
              //         text: 'Manage installations &amp; see system information',
              //         distance: 40,
              //         angle: 90
              //     });
              //
              //     $('.pure-button[title=Chart]').first().grumble({
              //         text: 'View the chart',
              //         angle: 225
              //     });
              // }
          }.bind(this));

          $('#whats-new-link').click(function() {
              this.switchFeaturePromotion("whats-new");
          }.bind(this));
          $('#feedback-link').click(function() {
              this.switchFeaturePromotion("feedback");
          }.bind(this));
          $('#archived-link').click(function() {
              this.switchFeaturePromotion("archived");
          }.bind(this));

          $('.dismiss').click(this.dismissPromo.bind(this));
          $('#feedback-submit').click(this.submitFeedback.bind(this));
      },

      /**
       * Switch panel on promotion feature.
       * @param stage Which panel to switch to.
       */
      switchFeaturePromotion: function(stage) {
          if (this.stage == stage) {
              // You're already on it!
              return;
          }

          var container = $('#feature-promotion');

          container.find('#whats-new').hide();
          container.find('#feedback-form').hide();
          container.find('#archived').hide();

          container.find('.section-header-controls > h4 > span#whats-new-link').removeClass('selected');
          container.find('.section-header-controls > h4 > span#feedback-link').removeClass('selected');
          container.find('.section-header-controls > h4 > span#archived-link').removeClass('selected');

          switch(stage) {
              case "whats-new":
                  container.find('#whats-new').show();
                  container.find('#section-header-title > h1').text("What's New?");
                  container.find('.section-header-controls > h4 > span#whats-new-link').addClass('selected');
                  break;

              case "feedback":
                  container.find('#feedback-form').show();
                  container.find('#section-header-title > h1').text("Your Suggestions");
                  container.find('.section-header-controls > h4 > span#feedback-link').addClass('selected');
                  break;

              case "archived":
                  container.find('#archived').show();
                  container.find('#section-header-title > h1').text("Archive");
                  container.find('.section-header-controls > h4 > span#archived-link').addClass('selected');
                  break;
          }

          this.stage = stage;
      },

      /**
       * Dismiss the promo box.
       * @param e JavaScript event.
       */
      dismissPromo: function(e) {
          var target = $(e.target),
              id = target.data('id'),
              featureDiv = $('.feature[data-id=' + id + ']'),
              archived = $('#archived'),
              whatsNew = $('#whats-new');

          target.prop('disabled', 'disabled');

          this.featureModel.dismissFeature(id, function() {
              featureDiv.fadeOut(500, function() {
                  featureDiv.removeClass('feature').addClass('archived-feature');
                  featureDiv.find(".dismiss-button").remove();

                  archived.find('.no-items').remove();
                  archived.append(featureDiv.html()).find('.archived-feature[data-id=' + id + ']').show();

                  if (this.featureModel.getFeatures().length == 0) {
                      whatsNew.append('<div class="no-items"><div class="row"><div class="column small-12"><p>Looks like you are up to date, click archive to see previous announcements.</p></div></div></div>');
                  }
              }.bind(this));
          }.bind(this));
      },

      /**
       * Submit the feedback form.
       * @param e JavaScript Event.
       */
      submitFeedback: function(e) {
          var container = $('#feature-promotion'),
              name = container.find('#feedback_name'),
              emailAddress = container.find('#feedback_emailAddress'),
              telephoneNumber = container.find('#feedback_telephoneNumber'),
              suggestion = container.find('#feedback_suggestion'),
              canContact = container.find('#feedback_canContact'),
              suggestionError = container.find('#feedback_suggestion_error'),
              emailError = container.find('#feedback_emailAddress_error');

          emailError.hide();
          suggestionError.hide();

          // Validation
          if (
              emailAddress.val() != "" &&
              (emailAddress.val().indexOf("@") == -1 || emailAddress.val().indexOf(".") == -1)
          ) {
              emailError.show();
              return;
          }
          if (suggestion.val() == "" && suggestion.val().length < 11) {
              // Validation failure
              suggestionError.show();
              return;
          }

          // Store original details from model.
          var origFeedback = this.featureModel.get("feedback");

          // Update model
          var formObj = {
              name: name.val(),
              emailAddress: emailAddress.val(),
              telephoneNumber: telephoneNumber.val(),
              suggestion: suggestion.val(),
              canContact: canContact.is(':checked')
          };

          this.featureModel.set({
              feedback: formObj
          });
          var model = new FeedbackModel();
          model.set(formObj);

          Crudder.creatingUpdating({
              entity: model,
              successMessage: "We have received your feedback.",
              errorMessage: "Unable to send feedback."
          }, function(response) {
              // Reset form.
              this.featureModel.set({
                  feedback: origFeedback
              });
              name.val(origFeedback.name);
              emailAddress.val(origFeedback.emailAddress);
              telephoneNumber.val(origFeedback.telephoneNumber);
              suggestion.val(origFeedback.suggestion);
              canContact.prop('checked', origFeedback.canContact ? 'checked' : '');
          }.bind(this));

          e.preventDefault();
      },

      renderSortArrows: function() {
          $('.' + this.sortBy + '.sortable')
              .first()
              .addClass('sort-' + this.direction);
      },

      sort: function(e) {
          var classes = $(e.target).attr('class').toString().split(' '),
              comparator = _.filter(classes, function(classItem) {
                  return classItem != 'sortable' && classItem != 'sort-asc' && classItem != 'sort-desc';
              })[0];

          if (this.sortBy != comparator) {
              this.sortBy = comparator;
              this.direction = 'asc';
          } else {
              if (this.direction == 'asc') {
                  this.direction = 'desc';
              } else {
                  this.direction = 'asc';
              }
          }

          switch(comparator) {
              case 'status':
                  // Special function for status.
                  // Live
                  // Ended
                  // Lost
                  comparator = function(a, b) {
                      var aStatus = a.get('status'),
                          bStatus = b.get('status'),
                          aSystem = a.get('systemId'),
                          bSystem = b.get('systemId'),
                          direction = this.direction;

                      function compareSystems() {
                          if (aSystem == bSystem) {
                              return 0;
                          }
                          if (aSystem < bSystem) {
                              return direction == 'desc' ? 1 : -1;
                          }
                          return direction == 'desc' ? -1 : 1;
                      }

                      switch(aStatus) {
                          case 'live':
                              if (aStatus == bStatus) {
                                  // Compare systems
                                  return compareSystems();
                              } else {
                                  // Should be above any other status.
                                  return direction == 'desc' ? 1 : -1;
                              }
                              break;

                          case 'ended':
                              if (aStatus == bStatus) {
                                  // Compare systems
                                  return compareSystems();
                              } else if (bStatus == 'live') {
                                  // B before A
                                  return direction == 'desc' ? -1 : 1;
                              } else {
                                  // A before B
                                  return direction == 'desc' ? 1 : -1;
                              }
                              break;

                          case 'lost':
                              if (aStatus == bStatus) {
                                  // Compare systems
                                  return compareSystems();
                              } else {
                                  // Should be after any other status
                                  return direction == 'desc' ? -1 : 1;
                              }
                              break;
                      }
                  }.bind(this);
                  break;

              case 'lastUpdate':
                  // Special function for last update.
                  comparator = function(a, b) {
                      a = new Moment(a.get('lastUpdate'));
                      b = new Moment(b.get('lastUpdate'));

                      if (a.isBefore(b)) {
                          return this.direction == 'desc' ? -1 : 1;
                      }
                      if (a.isSame(b)) {
                          return 0;
                      }

                      return this.direction == 'desc' ? 1 : -1;
                  }.bind(this);
                  break;

              case 'system':
                  comparator = 'systemId';
                  break;

              case 'chart':
                  comparator = 'chartTitle';
                  break;
          }

          if (this.direction == 'desc' && typeof comparator == 'string') {
              // Reverse sort!
              var oldComparator = comparator;
              comparator = function(a, b) {
                  var aValue, bValue;

                  aValue = a.get(oldComparator);
                  bValue = b.get(oldComparator);

                  if (aValue == null) {
                      aValue = '';
                  }
                  if (bValue == null) {
                      bValue = '';
                  }

                  if (aValue > bValue) return -1;
                  if (bValue > aValue) return 1;
                  return 0;
              }
          }

          this.collection.comparator = comparator;
          this.collection.sort();
          this.skipWhatsNewRender = true;
          this.render();
      },

	  loadSystemTotals: function() {
      	  this.totalsModel = new SystemTotalsModel();

      	  this.totalsModel.fetch({
			  success: function() {
				  this.renderSystemTotals();
			  }.bind(this)
		  });
	  },

	  renderSystemTotals: function() {
		  var $page = $('#page-main');

		  $page.find('#system-totals').remove();

      	  // Don't load unless we are on the 'My Systems' page.
		  if (Backbone.history.getFragment() !== "systems" || !this.totalsModel.get('permission')) {
			  // Nope!
			  if($.cookie('showTour') !== 'false') {
				  // Load help bubbles.
				  $('.system:eq(1)').grumble({
					  text: 'Manage installations &amp; see system information',
					  distance: 30,
					  angle: 90
				  });

				  $('.pure-button[title=Chart]').first().grumble({
					  text: 'View the chart',
					  angle: 225
				  });
			  }
			  return;
		  }

		  $page.find('#feature-promotion').after(SystemTotalsTemplate(this.totalsModel.attributes));

		  $page.find('#system-totals').slideDown(500, function() {
			  if($.cookie('showTour') !== 'false') {
				  // Load help bubbles.
				  $('.system:eq(1)').grumble({
					  text: 'Manage installations &amp; see system information',
					  distance: 40,
					  angle: 90
				  });

				  $('.pure-button[title=Chart]').first().grumble({
					  text: 'View the chart',
					  angle: 225
				  });
			  }
		  }.bind(this));
	  }

  });

});

