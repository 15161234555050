/**
Application Models - Buttons
@module Models
@submodule Models.Abstract.Buttons
*/

/*
Requires:
  * Backbone
Contents:
  * Button Model
Author(s):
  * Just Checking
*/

define('app/models/abstract/models.abstract.button',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Button Model
  @class Models.Abstract.Buttons
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend();

});

