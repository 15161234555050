/**
Application Requests - Get Current User
@module GetCurrentUser
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get the current user
Author(s):
  * Just Checking
*/

define('app/requests/requests.getCurrentUser',[

	'app/requests/requests.vent'

], function(Requests) {

	'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get the current user
			@event get:current:user
			@return {Object} The current user information
			*/
			return Requests.setHandler('get:current:user', function() {

				var App = require('app/app');

				return App.state.currentUser;

			});

    }

  };

});

