/**
Chart Application - Timeline List View
@module Chart
@submodule Chart.Views.TimelineList
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Timeline List View
    * template
    * itemView
    * itemViewOptions
    * itemViewContainer
    * ui
    * events
    * onRender
    * zoomOut
    * scrollLeft
    * scrollRight
    * getTargetHour
    * getTimeAdjust
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.timeline.list',[

  'marionette',
  'app/app.vent',
  'app/requests/requests.vent',
  'tpl!app/modules/charts/templates/charts.templates.timeline.tmpl',
  'app/modules/charts/views/charts.views.timeline.item',
  'moment'

], function(Marionette, Vent, Requests, Template, ItemView, Moment) {

  'use strict';

  /**
  Timeline List View
  @class Chart.Views.TimelineList
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemView
    @type Object
    */
    itemViewOptions: function() {

      // Pass the current zoom level into the item view
      return {

        zoom: this.model.get('zoom')

      };

    },

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#chart-timeline-hours',

    /**
    @property ui
    @type Object
    */
    ui: {

      zoomControls: '#chart-timeline-unzoom',
      zoomInControls: '#chart-timeline-zoom',
      unzoomButton: '#chart-unzoom-button',
      backButton: '#chart-timeline-back',
      forwardButton: '#chart-timeline-forward'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click @ui.backButton': 'scrollRight',
      'click @ui.forwardButton': 'scrollLeft',
      'click @ui.unzoomButton': 'zoomOut'

    },

    /**
    @method onRender
    */
    onRender: function() {

      /*
      UI Adjustments
      */

      // Are we zoomed in?
      if (this.model.get('zoom') === 10) {

        // Show the zoom controls
        this.ui.zoomControls.show();

        //hide the zoom in icon
        this.ui.zoomInControls.hide();

        /*
        Extra timeline elements
        */

        var itemViewCount = this.children.length;

        // For each item view (except last)
        _.each(_.toArray(this.children._views), function(view, index) {

          // Unless we are at the last view
          if ((index + 1) < itemViewCount) {

            // Add hidden links to force timeline intervals
            view.$el.after('<a aria-hidden="true" class="chart-timeline-minutes white">-</a><a aria-hidden="true" class="chart-timeline-minutes white">-</a>');

          }

        });

      }
      else {

        // Hide the zoom controls
        this.ui.zoomControls.hide();

        //show the zoom in controls
        this.ui.zoomInControls.show();

      }

		/*
          Back button
          */

		// Calculate the previous day
		// var previousDay = Requests.request('get:formatted:date', {
		//
		//   date: this.model.get('date'),
		//   format: Requests.request('get:state:data').get('utcFormat'),
		//   adjust: {
		//
		//     method: 'subtract',
		//     key: 'day',
		//     amount: 1
		//
		//   }
		//
		// });
		//
		// // Attach the date to the back button
		// this.ui.backButton.attr('data-date', previousDay);

		if (this.model.get('zoom') === 10) {
			this.ui.backButton.attr('data-date', moment(this.model.get('date')).subtract(2, 'hours').format('YYYY-MM-DDTHH:mm:ss'));
		} else {
			this.ui.backButton.attr('data-date', moment(this.model.get('date')).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'));
		}

		/*
        Forward button
        */

		// Calculate the next day
		// var nextDay = Requests.request('get:formatted:date', {
		//
		//   date: this.model.get('date'),
		//   format: Requests.request('get:state:data').get('utcFormat'),
		//   adjust: {
		//
		//     method: 'add',
		//     key: 'day',
		//     amount: 1
		//
		//   }
		//
		// });
		//
		// // Attach the date to the next button
		// this.ui.forwardButton.attr('data-date', nextDay);

		if (this.model.get('zoom') === 10) {
			this.ui.forwardButton.attr('data-date', moment(this.model.get('date')).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'));
		} else {
			this.ui.forwardButton.attr('data-date', moment(this.model.get('date')).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'));
		}

    },

    /**
    @method zoomOut
    */
    zoomOut: function(e) {

      var
      // Get the current hour value
      currentHour = Requests.request('get:formatted:date', {

        date: this.model.get('date'),
        format: 'HH',
        parseInt: true

      }),
      // Calc the target hour
      targetHour = this.getTargetHour({

        currentHour: currentHour

      }),
      // Calc and time adjustment required
      amount = this.getTimeAdjust({

        currentHour: currentHour

      }),
      // Create our target date
      date = Requests.request('get:formatted:date', {

        date: this.model.get('date'),
        format: Requests.request('get:state:data').get('utcFormat'),
        adjust: {

          method: 'subtract',
          key: 'hours',
          amount: amount

        }

      });

      // Navigate the chart app and set zoom back to default
      Vent.trigger('chart:navigate', {

        date: date,
        zoom: this.model.defaults.zoom,
        targetHour: targetHour

      });

      e.preventDefault();

    },

    /**
    @method scrollLeft
    */
    scrollLeft: function(e) {
      Vent.trigger('chart:scroll', {

        direction: 'left',
        date: $(e.currentTarget).attr('data-date')

      });

      e.preventDefault();

    },

    /**
    @method scrollRight
    */
    scrollRight: function(e) {

      Vent.trigger('chart:scroll', {

        direction: 'right',
        date: $(e.currentTarget).attr('data-date')

      });

      e.preventDefault();

    },

    /**
    @method getTargetHour
    */
    getTargetHour: function(options) {

      // Detect if current date is > previous date
      var differentDays = new Moment(this.model.get('previousDate')).isBefore(this.model.get('date'), 'day');

      // If day view and not midnight of next day
      if (this.model.get('view') === 'day' && !differentDays) {

        // Just return the current hout
        return options.currentHour;

      }
      else {

        // Default target hour changes
        var targetHour = (options.currentHour >= 12) ? options.currentHour - 12 : options.currentHour + 12;

        // Is the current hour 12 and dates different?
        if (options.currentHour === 12 && differentDays) {

          // Day view?
          if (this.model.get('view') === 'day') {

            // We need to go back 24 hours and change target hour value
            targetHour = targetHour + 12;

          }
          else {

            // We need to go back 24 hours and change target hour value
            targetHour = targetHour + 24;

          }

        }

        // Is the current hour 0 and dates different?
        if (options.currentHour === 0 && differentDays) {

          // Day view?
          if (this.model.get('view') === 'day') {

            // We need to go back 24 hours and change target hour value
            targetHour = targetHour + 12;

          }

        }

        return targetHour;

      }

    },

    /**
    @method getTargetHour
    */
    getTimeAdjust: function(options) {

      // Set the initial amount of time depending on view type
      var timeAdjust = (this.model.get('view') === 'day') ? options.currentHour : options.currentHour - 12;

      // Is the previous date a day before the current date?
      // AKA night view zoom in on hours after midnight
      if (new Moment(this.model.get('previousDate')).isBefore(this.model.get('date'), 'day')) {

        // We need to go back 24 hours to previous date
        timeAdjust = timeAdjust + 24;

      }

      return timeAdjust;

    }

  });

});

