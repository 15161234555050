/**
Application Layout - Header Controller
@module Layout
@submodule Layout.Controllers.Header
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Header Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/layout/controllers/layout.controllers.header',[

  'marionette',
  'app/commands/commands.vent',
  'app/layout/layouts/layout.layouts.header',
  'app/layout/controllers/layout.controllers.header.menu'

], function(Marionette, Commands, HeaderLayout, MenuController) {

  'use strict';

  /**
  Main Header Controller
  @class Layout.Controllers.Header
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var
      that = this,
      App = require('app/app');

      // Define the main panel region
      this.region = App.header;

      // Define the controller layout
      this.layout = new HeaderLayout();

      /*
      Header layout callback events
      */

      // Fire this event when the layout is shown
      this.layout.on('show', function() {

        // We need a new menu controller
        var menuController = new MenuController();

      });

      // Show the header layout
      this.show();

    }

  });

});


// Subscribe to the desktop menu show event
      // headerLayout.menu.on('show', function() {

      //   // Call the 'You are here' method
      //   // Commands.execute('show:current:location');

      // });
