define('app/modules/newcharts/features/events/views/event-chart.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/events/templates/event-chart.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder',
	'chartjs'

], function(Marionette, Vent, Commands, Requests, Template, Moment, Switcher, Crudder, Chart) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		ui: {
			loader: '.loader-container',
			canvas: '.bar-chart'
		},

		onShow: function() {
			var ctx = this.ui.canvas[0].getContext('2d');

			// Load a spinner and fetch data
			this.ui.loader.spin(true);

			this.model.fetch({
				success: function(model) {
					this.ui.loader.spin(false);
					this.draw(model.get('data'));
				}.bind(this)
			})
		},

		draw: function(data) {
			var ctx = this.ui.canvas[0].getContext('2d'),
				chart = new Chart(ctx, {
				type: 'bar',
				data: data,
				options: {
					legend: {
						display: true,
						position: 'right'
					}
				}
			});

			this.ui.canvas.show();
		}

	});

});
