/**
Application Requests - Get Authentication Token
@module Requests
@submodule Requests.GetAuthToken
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get Authentication Token
Author(s):
  * Just Checking
*/

define('app/requests/requests.getAuthToken',[

	'app/requests/requests.vent',
	'jquery.cookie'

], function(Requests) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get Authentication Token
			@event get:auth:token
			@return {Boolean} Are any users enabled to receive alerts
			*/
			return Requests.setHandler('get:auth:token', function() {
/*
				var authToken = $.cookie('jcAuthToken');
				//console.log(authToken);

				// Do the session values exist?
				if (authToken) {

					// Return the auth token
					return authToken;

				}
				else {

					return false;

				}

				*/


				/* WE DONT REALLY NEED ANY OF THIS! EXCEPT TO LOAD THE LOGIN MODULE INSTEAD OF THE APP WHEN FIRST VISITING */

				/*var redirectToLogin = function () {
		            window.location.href = '/#login';
		        };*/

		        // syncronous ajax request to check login status on initial app load
		        /*
		        	TODO: use app API route instead of hardcoding!
		        */
		    /*   var loggedIn = $.ajax('/api/v1/Account/session', {
		        	success: function(){ return true; },
		        	error: function(){ return true; },
		        	type: 'GET',
		        	async: false
		        });

		        //console.log(loggedIn.status);

		        // If the user is logged in continue loading the app otherwise display the login module
		        if(loggedIn.status !== 401){
		        	return true;
		        }else{
		        	return false;
		        };
*/


			});

    }

  };

});

