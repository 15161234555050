define('app/modules/newcharts/features/events/models/event_chart.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		urlRoot: 'AccelerometerEvents/Chart'
	});

});
