/**
Account Application - Form ItemView
@module Account
@submodule Account.Views.Form
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Form ItemView
    * template
    * form
    * behaviors
    * initialize
    * save
Author(s):
  * Just Checking
*/

define('app/modules/account/views/account.views.form',[

  'marionette',
  'tpl!app/modules/account/templates/account.templates.form.tmpl',
  'app/commands/commands.vent',
  'app/behaviors/behaviors.showPasswords',
  'backbone.validation'

], function(Marionette, Template, Commands, ShowPasswords, Validation) {

  'use strict';

  /**
  Form ItemView
  @class Account.Views.Form
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({
    /**
    @property template
    @type String
    */
    template: Template,
    /**
    @property form
    @type Object
    */
    form: {
      buttons: {
        primary: 'Save changes',
        cancel: 'Cancel'
      }
    },
    /**
    @property behaviors
    @type Object
    */
    /*behaviors: {
      ShowPasswords: {
        behaviorClass: ShowPasswords
      }
    },*/

    /**
    @method initialize
    */
    initialize: function() {
      // Listen for change events and render
      this.listenTo(this.model, 'change', this.render, this);
    },
    /**
    @method save
    @param data {Object} Data syphoned from the form
    */
    save: function(data) {

      // Set the model
      this.model.set(data, {

        silent: true

      });

      // Validate the model
      this.model.validate();

      // Is the model valid?
      if (this.model.isValid()) {

        // Trigger the save event
        this.trigger('save:account');

      }
      else {

        return;

      }
    }
  });
});
