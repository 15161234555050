define('app/modules/newcharts/features/notifications/views/notification-history-item.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-history-item.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder',
	'app/commands/commands.vent',
	'app/modules/newcharts/state'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder, Commands, State) {
	'use strict';

	return Marionette.ItemView.extend({

		tagName: 'tr',

		template: Template,

		templateHelpers: {
			action: function() {
				switch(this.log_type) {
					case 1:
						return 'Notification';

					case 2:
						return 'Edited by ' + (this.user_name != "" ? this.user_name : this.user_email);

					case 4:
						return 'Nobody has acknowledged';

					case 5:
						return 'Acknowledged by ' + (this.user_name != "" ? this.user_name : this.user_email);

					default:
						return '';
				}
			}
		},

		ui: {
			sentToBtn: '.sent-to-btn'
		},

		onRender: function() {
			var data = {
				placement: !State.isMouse() ? 'bottom' : 'right',
				text: this.model.get('sent_to_user_names').join('<br/>').replace(/ /g, '&nbsp;')
			};

			if (!State.isMouse()) {
				data.container = 'body';
			}

            Commands.execute('init:popover', {
				html: true,
				element: this.ui.sentToBtn,
				data: data
			});
		},

		onShow: function() {
			this.$el.find('td:last-child').each(function() {
				var elem = $(this);

				if (elem.children().length > 0) {
					elem.addClass('mobile-full-width');
				}
			});
		}

	});

});
