/**
Application Commands - Form Changes False
@module Commands
@submodule Commands.FormChangesFalse
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
	* Module Initialize
		* Form Changes False
Author(s):
  * Just Checking
*/

define('app/commands/commands.setChangeFalse',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Form Changes False
			@event set:change:false
			*/
			return Commands.setHandler('set:change:false', function() {

				var App = require('app/app');

				// Set changes detected to false
				App.state.changeDetected = false;

			});

    }

  };

});

