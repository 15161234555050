/**
Application Behaviors - Tooltip
@module Behaviors
@submodule Behaviors.Tooltip
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
	* Tooltip
    * onShow
Author(s):
  * Just Checking
*/

define('app/behaviors/behaviors.tooltip',[

	'backbone',
	'marionette',
  'app/commands/commands.vent'

], function(Backbone, Marionette, Commands) {

  'use strict';

  /**
	Tooltip
	*/
	return Marionette.Behavior.extend({

    /**
    @method onShow
    */
    onShow: function() {

      var element = this.$('[data-tooltip="true"]');

	  element.each(function(i, elem) {
		  elem = $(elem);

		  // Initialise a popover for this element
		  Commands.execute('init:popover', {
			  element: elem,
			  data: {

				  headline: elem.attr('data-tooltip-title'),
				  text: elem.attr('data-tooltip-text'),
				  placement: elem.attr('data-tooltip-placement') || 'right'

			  }
		  });
	  });

    }

	});

});

