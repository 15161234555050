/**
Application Config - Application
@module Config
@submodule Config.Marionette.Application
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
Contents:
  * onInitializeBefore
  * startHistory
  * navigateHome
  * register
  * unregister
  * resetRegistry
  * closeRegions
  * getRegistrySize
Author(s):
  * Just Checking
*/

define('app/config/marionette/config.marionette.application',[

	'marionette',
	'app/requests/requests.vent',
	'app/commands/commands.vent',
	'app/models/models.state'

], function(Marionette, Requests, Commands, StateModel) {

  'use strict';

  /**
  Marionette Application
  @class Marionette.Application
  @constructor
  @extends Marionette.Application
  */
  return _.extend(Marionette.Application.prototype, {

		/**
		@method onInitializeBefore
		*/
		onInitializeBefore: function() {

			/*
			Application State
			Add an object to store changing state values for easy access
			*/
			this.state = new StateModel();

			/*
			Application Environment
			*/

			// TODO: Add a grunt process to remove any dev code
      this.environment = 'development';
			
      if (this.environment === 'development') {

				window.App = this;
       
      }

		},

		/**
		@method startHistory
		*/
    startHistory: function() {

     var that = this;

			/*
			Start Backbone History
			*/

			// Is Backbone history available?
      if (Backbone.history) {

        // Listen for route changes
       Backbone.history.on('route', function() {

         /*
         Topbar
          */

         // Reset the topbar
         Commands.execute('update:topbar', {

           reset: true

         });

         /*
         Set current state
          */

          // Request the current level within the app
          var
          currentLevel = Requests.request('get:current:level'),
          currentApp = Requests.request('get:fragment:section', {

           currentFragment: Backbone.history.fragment,
           limit: 0,
           removeLastChar: true

          });

          // Are we are currently in an app homepage
          if (currentLevel === 0) {

           // Hide the back button
           Commands.execute('update:topbar', {

             buttonLeft: {

                visible: false

              }

           });

          }

          // Set the current level value in the app state object
          Commands.execute('set:state:data', {

           currentApp: currentApp,
            currentLevel: currentLevel

          });

       });

				// Start it up!
				// trackDirection: true
        Backbone.history.start();

      }
      else {

				// Show an error
				throw('Backbone history is not available');

      }

    },

    /**
    @method navigateHome
    @param route {String} Route for the 'homepage'
    */
    navigateHome: function(route) {
			// Check for no hash fragment
			if (!Requests.request('get:current:route') ||
				Requests.request('get:current:route') == 'systems' ||
				Requests.request('get:current:route') == 'login') {

				// Navigate to the homepage
				Commands.execute('app:navigate', {

					route: route,
					trigger: true

				});

			}
    },

    /**
    @method navigateHome
    */
    register: function(instance, id) {

      if (!this._registry) {

        this._registry = {};
      
      }

      this._registry[id] = instance;

      return;

    },

    /**
    @method navigateHome
    */
    unregister: function(instance, id) {

      return delete this._registry[id];

    },

    /**
    @method navigateHome
    */
    resetRegistry: function() {

      var controller, key, msg, oldCount, _ref;

      oldCount = this.getRegistrySize();

      _ref = this._registry;

      for (key in _ref) {

        controller = _ref[key];

        this.closeRegions(controller);
        
      }

      msg = 'There were ' + oldCount + ' controllers in the registry, there are now ' + (this.getRegistrySize());

      if (this.getRegistrySize() > 0) {

        return console.warn(msg, this._registry);

      } else {

        return console.log(msg);

      }

    },

    /**
    @method closeRegions
    */
    closeRegions: function(controller) {

      _.each(controller._regions, function(region) {
        
        region.close();
      
      });

    },

    /**
    @method navigateHome
    */
    getRegistrySize: function() {

      return _.size(this._registry);
      
    }

  });

});
