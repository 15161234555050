define('app/modules/newcharts/features/notifications/views/notification.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder'

], function(Marionette, Vent, Commands, Requests, Template, Moment, Switcher, Crudder) {
	'use strict';

	return Marionette.ItemView.extend({

		tagName: 'tr',

		template: Template,

		ui: {
			enabledSwitch: '.enabled-switch',
			visibleSwitch: '.visible-switch',
			enabledSwitchCheckbox: '.enabled-switch input[type=checkbox]',
			visibleSwitchCheckbox: '.visible-switch input[type=checkbox]',
			setupBtn: '.setup-btn',
			editBtn: '.edit-btn',
			eitherBtn: '.pure-button'
		},

		events: {
			'click @ui.setupBtn': 'setup',
			'click @ui.editBtn': 'edit',
			'change @ui.enabledSwitchCheckbox': 'toggleEnabled',
			'change @ui.visibleSwitchCheckbox': 'toggleVisible'
		},

		onRender: function() {
			if (this.model.get('setup')) {
				Switcher.init({
					view: this.ui.enabledSwitch
				});
				if (!this.ui.enabledSwitch.data('checked')) {
					this.ui.enabledSwitch.find('.switch-off').click();
				}

				Switcher.init({
					view: this.ui.visibleSwitch
				});
				if (!this.ui.visibleSwitch.data('checked')) {
					this.ui.visibleSwitch.find('.switch-off').click();
				}
			}

			if (this.model.get('disabled') == true) {
				setTimeout(function() {
					Commands.execute('init:popover', {
						element: this.ui.eitherBtn,
						data: {
							container: 'body',
							placement: 'top',
							text: this.model.get('disabledReason')
						}
					});
				}.bind(this));
			}
		},

		setup: function() {
			this.loadEditForm();
		},

		edit: function() {
			this.loadEditForm();
		},

		toggleSetting: function (setting, e){
			var checked = !$(e.target).is(':checked'),
				needsSave = false;

			if (checked && !this.model.get(setting)) {
				this.model.set(setting, true);
				needsSave = true;
			} else if (e.target.checked && this.model.get(setting)) {
				this.model.set(setting, false);
				needsSave = true;
			}

			if (needsSave) {
				Crudder.creatingUpdating({
					entity: this.model,
					patch: false,
					successMessage: 'Saved successfully',
					errorMessage: 'Error occurred'
				});
			}
		},

		toggleEnabled: function(e) {
			var checked = !$(e.target).is(':checked');

			this.toggleSetting('enabled', e);

			if (checked) {
				this.ui.visibleSwitch.removeClass('switch-disabled');
			} else {
				this.ui.visibleSwitch.addClass('switch-disabled');
			}
		},

		toggleVisible: function(e) {
			if (!this.ui.visibleSwitch.hasClass('switch-disabled')) {
				this.toggleSetting('visible', e);
			}
		},

		loadEditForm: function() {
			if (this.model.get('disabled') == false) {
				Vent.trigger('newchart:notification-edit', {model: this.model});
			}
		}

	});

});
