/**
Initialise Application Commands
@module Commands
@submodule Commands.Init
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
Contents:
  * Module Initialize
		* Initialise all the required commands
Author(s):
  * Just Checking
*/

define('app/commands/commands.init',[

	'app/commands/commands.appNavigate',
	'app/commands/commands.appNavigateBack',
	'app/commands/commands.appNavigateTop',
	'app/commands/commands.editUsersAlert',
	'app/commands/commands.resetPanel',
	'app/commands/commands.setChangeFalse',
	'app/commands/commands.setChangeTrue',
	'app/commands/commands.showCurrentItem',
	'app/commands/commands.showCurrentLocation',
	'app/commands/commands.showSectionHeader',
	'app/commands/commands.closeSectionHeader',
	'app/commands/commands.pagingShow',
	'app/commands/commands.initPopover',
	'app/commands/commands.setStateData',
	'app/commands/commands.showNotifications',
	'app/commands/commands.toggleContentClass',
	'app/commands/commands.showPanel',
	'app/commands/commands.closePanel',
	'app/commands/commands.whenFetched',
	'app/commands/commands.registerInstance',
	'app/commands/commands.unregisterInstance',
	'app/commands/commands.updateTopbar',
	'app/commands/commands.initController',
	'app/commands/commands.startModule',
	'app/commands/commands.stopModule',
	'app/commands/commands.onBoarding',
	'app/commands/commands.modal',
	'app/commands/commands.appLightbox',
	'app/commands/commands.evaluationLightbox',
	'app/commands/commands.noteLightbox',
    'app/commands/commands.confirmModal',
    'app/commands/commands.7dayLightbox',
	'app/commands/commands.printLightbox',
	'app/commands/commands.generatePDF',
	'app/commands/commands.sequencesReportLightbox',
	'app/commands/commands.enhancedServiceQuestionsLightbox',
	'app/commands/commands.outcomePredictionLightbox'

], function(AppNavigate, AppNavigateBack, AppNavigateTop, EditUsersAlert, ResetPanel, SetChangeFalse, SetChangeTrue, ShowCurrentItem,
	ShowCurrentLocation, ShowSectionHeader, CloseSectionHeader, PagingShow, InitPopover, SetStateData, ShowNotifications, ToggleContentClass,
	ShowPanel, ClosePanel, WhenFetched, RegisterInstance, UnregisterInstance, UpdateTopbar, InitController, StartModule, StopModule, OnBoarding,
	Modal, Lightbox, EvaluationLightbox, NoteLightbox, ConfirmModal, SevenDayLightbox, PrintLightbox, GeneratePDF, SequencesReportLightbox, EnhancedServiceQuestionsLightbox, OutcomePredictionLightbox) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			/*
			Initialise all the required commands
			*/
			AppNavigate.initialize();
			AppNavigateBack.initialize();
			AppNavigateTop.initialize();
			EditUsersAlert.initialize();
			ResetPanel.initialize();
			SetChangeFalse.initialize();
			SetChangeTrue.initialize();
			ShowCurrentItem.initialize();
			ShowCurrentLocation.initialize();
			ShowSectionHeader.initialize();
			PagingShow.initialize();
			InitPopover.initialize();
			SetStateData.initialize();
			ShowNotifications.initialize();
			ToggleContentClass.initialize();
			ShowPanel.initialize();
			ClosePanel.initialize();
			WhenFetched.initialize();
			RegisterInstance.initialize();
			UnregisterInstance.initialize();
			CloseSectionHeader.initialize();
			UpdateTopbar.initialize();
			InitController.initialize();
			StartModule.initialize();
			StopModule.initialize();
			OnBoarding.initialize();
			Modal.initialize();
			Lightbox.initialize();
			EvaluationLightbox.initialize();
			NoteLightbox.initialize();
            ConfirmModal.initialize();
            SevenDayLightbox.initialize();
			PrintLightbox.initialize();
			GeneratePDF.initialize();
			SequencesReportLightbox.initialize();
			EnhancedServiceQuestionsLightbox.initialize();
			OutcomePredictionLightbox.initialize();

    }

  };

});

