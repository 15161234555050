define('app/modules/newcharts/views/main-menu.view',[
    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/main-menu.template.tmpl',
    'tpl!app/modules/newcharts/templates/chart.pdf.template.tmpl',
    'tpl!app/modules/newcharts/templates/bathroom.pdf.template.tmpl',
    'moment',
    'bootstrap.dropdown',
    'app/behaviors/behaviors.calendar',
    'app/behaviors/behaviors.showCalendar',
    'app/modules/newcharts/state',
    'switcher'
], function(Marionette, Vent, Commands, Requests, Template, ChartPDFTemplate, BathroomPDFTemplate, Moment, Dropdown, Calendar, ShowCalendar, State, Switcher) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            datePickerInput: '#chart-date-input',
            datePickerButtons: '.chart-datepicker-button',
            refreshButton: '#chart-refresh-button',
            navigateButtons: '[data-hash]',
            setupButton: '#chart-settings-edit',
            printButton: '#chart-print',
            viewLoginsButton: '#view-logins'
        },

        events: {
            'click @ui.refreshButton': 'refresh',
            'click @ui.navigateButtons': 'navigateAway',
            'click @ui.printButton': 'print',
            'click @ui.viewLoginsButton': 'viewLogins',

            'change @ui.hideNotesBtn': 'toggleNotes',
            'change @ui.nightViewBtn': 'toggleNightView'
        },

        behaviors: {

            ShowCalendar: {

                behaviorClass: ShowCalendar

            },

            Calendar: {
                behaviorClass: Calendar,
                showNow: true,
                onSelect: function(valueText, inst) {
                    Vent.trigger('newchart:datepicker', {
                        value: new Moment(valueText)
                    });

                    $('#chart-date-input').mobiscroll('setDate', new Moment(valueText).toDate(), true);
                },
                onMonthLoaded: function(year, month, inst) {
                    var notes = JSON.parse(store.get('calendar.markers')),
                        marked = [];

                    if (notes != null) {
                        $.each(notes, function(index, noteObj) {
                            var date = new Moment(noteObj.date).toDate(),
                                text = noteObj.subject;

                            marked.push({d: date, text: text});
                        });

                        inst.settings.marked = marked;
                        inst.refresh();
                    }
                }
            }
        },

        onRender: function() {
            var type = this.model.get('type');
            if (type == 'bathroom' || type == 'multi' || type == 'nonOccupancy' || type == 'doorActivity') {
                setTimeout(function() {
                    $('#main-menu-picker').parent().hide();
                    $('#right-menu').addClass('multi');
                });
            }

            store.set('calendar.markers', JSON.stringify(this.model.get('calendarNotes')));
        },

        refresh: function() {
            if (this.model.get('type') == 'chart') {
                Vent.trigger('newchart:check-note', function() {
                    window.location.reload();
                });
                return;
            }

            window.location.reload();
        },

        navigateAway: function(e) {
            var that = this;
            function go() {
                var hash = $(e.currentTarget).attr('data-hash'),
                    route = 'systems/' + that.model.get('chartMeta').systemNo;

                if (hash == 'users') {
                    route = route + '/charts/' + that.model.get('chartMeta').installationId + '/users';
                } else if (hash == 'edit') {
                    route = route + '/charts/' + that.model.get('chartMeta').installationId + '/edit';
                }

                Commands.execute('app:navigate', {
                    route: route
                });
            }

            if (this.model.get('type') == 'chart') {
                Vent.trigger('newchart:check-note', go);
                return;
            }

            go();
        },

        print: function(e) {
            e.preventDefault();


            var storedArrayString = sessionStorage.getItem('printCSSClasses');
            var storedArray = '';
            var classString = '';
            if(storedArrayString){
                storedArray = JSON.parse(storedArrayString);
                // Iterate over the storedArray and concatenate the classNames
                for (var i = 0; i < storedArray.length; i++) {
                    var classStr = storedArray[i].class;
                    classString += classStr + '\n';
                }
            }
        

            function generateForm(params) {

                var url = '/api/v1/Generate/PDF',
                    html = "<form action='" + url + "' method='post' style='display: none;'>";

                _.each(params, function(value, key) {
                    if (key == 'html') {
                        value = value.replace(/'/g, "\'").replace(/'/g, "&apos;");
                        value = encodeURI(value);
                    }
                    html += "<input type='hidden' name='" + key + "' value='" + value + "' />";
                });

                html += "</form>";
                console.log(decodeURI(html))
               $(html).appendTo('body').submit().remove();
            }

            switch (this.model.get('type')) {
                case 'chart':
                    var note = $('#note-form').is(':hidden') ? null : this.model.get('note'),
                        noteHtml = '';

                    if (this.model.get('chartMenu').canAccessNote && (note != null && (note.subject || note.message))) {
                        noteHtml += '<div id="printnote">';

                        if (note.subject) {
                            noteHtml += "<h3>" + note.subject + "</h3>";
                        }
                        if (note.message) {
                            noteHtml += "<p>" + note.message + "</p>";
                        }

                        noteHtml += "</div>";
                    }

                    var rawHTML = $('#page-main').clone();
                    rawHTML.find('#body > div').prepend('<h3>' + new Moment(this.model.get('chartMeta').chartDate).format('dddd Do MMMM YYYY') + '</h3>');

                    rawHTML = ChartPDFTemplate({
                        mainCSS: document.querySelector("link[href*='.css']").href,
                        chartLineCSS: classString,
                        html: rawHTML.html(),
                        generated: new Moment().format('LLL'),
                        chartTitle: this.model.get('chartMeta').chartTitle,
                        systemNum: this.model.get('chartMeta').systemNo,
                        note: noteHtml
                    });
                    break;

                case 'multi':
                    Vent.trigger('newchart:multi-print', {
                        callback: function(html) {
                            generateForm({
                                html: html
                            });
                        }
                    });
                    return;

                case 'bathroom':
                case 'nonOccupancy':
                case 'doorActivity':
                    Vent.trigger('newchart:bathroom-print', {
                        callback: function(html) {
                            html = BathroomPDFTemplate({
                                mainCSS: document.querySelector("link[href*='.css']").href,
                                generated: new Moment().format('LLL'),
                                chartTitle: this.model.get('chartMeta').chartTitle,
                                systemNum: this.model.get('chartMeta').systemNo,
                                html: html
                            });

                            generateForm({
                                html: html,
                                zoom: 1.4
                            });
                        }.bind(this)
                    });
                    return;

                default:
                    alert('Print not implemented');
                    return;
            }

            generateForm({
                html: rawHTML
            });
        },

        viewLogins: function(e) {
            var loginListView = Requests.request('get:view', {
                request: 'logins:get:list',
                panelView: true
            });

            // Show logins in the bottom panel
            Commands.execute('show:panel', {
                targetPanel: 'bottom',
                requestData: {
                    installationId: this.model.get('chartMeta').installationId
                },
                view: loginListView,
                title: 'Logins'
            });
            e.preventDefault();
        }

    });

});
