/**
Account Application
@module Account
*/

/*
Requires:
  * jQuery
  * Underscore
  * Backbone
  * Marionette
Contents:
  * Module Initialize
		* initialize
Author(s):
  * Just Checking  
*/

define('app/modules/account/account.module',[

  'marionette',
  'app/modules/account/account.router',
  'app/modules/account/controllers/account.controllers.form'

], function(Marionette, Router, Controller) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			AccountApp = App.module('AccountApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				/**
				@property API
				*/
				var API = {

					/*
					Get Account
					*/

					getAccount: function() {

						// Create a new controller
						AccountApp.controller = new Controller();

					}

				};

				/**
				Start Event
				@event start
				*/
				this.on('start', function(hash) {

					// Start the router for this sub app
					AccountApp.router = new Router({

						controller: API

					});

				});

			});

			return AccountApp;

		}

	};

});

