/**
Application Commands - Show Section Header
@module Commands
@submodule Commands.ShowSectionHeader

This command allows main header data to be sent and displayed from anywhere in the application.

If a sub title or controls are required, they must be supplied as valid marionette item views
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
		* Show Section Header
Author(s):
  * Just Checking
*/

define('app/commands/commands.showSectionHeader',[

	'backbone',
	'app/commands/commands.vent',
	'app/layout/layouts/layout.layouts.main.header',
	'app/layout/views/layout.views.main.header.title',
	'app/layout/views/layout.views.main.header.subtitle'

], function(Backbone, Commands, HeaderLayout, TitleView, SubtitleView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Show Section Header
			@event show:section:header
			@param model {Model} A valid main header model
			*/
			return Commands.setHandler('show:section:header', function(options) {

				var
				App = require('app/app'),
				headerView = new HeaderLayout({

          model: options.model

        });

        // Do this when the header layout is shown
        App.main.currentView.header.on('show', function() {

         // Create a new title view
         var titleView = new TitleView({

            model: options.model

          });

          // Show the title view
          this.currentView.title.show(titleView);

          // Show the JC logo if requested to
          if (options.model.get('logo')) {

           // Add the logo to show the JC logo
           this.$el.addClass('bg-logo');

          }
          else {

           // Remove the logo
           this.$el.removeClass('bg-logo');

          }

          // Show the subtitle view if one has been supplied
          if (options.model.get('subtitle')) {

           // Has a string been supplied?
           if (_.isString(options.model.get('subtitle'))) {

             // Create a default subtitle view and send it the subtitle
             var subtitleView = new SubtitleView({

               subtitle: options.model.get('subtitle')

             });

             // Show the view
             this.currentView.subtitle.show(subtitleView);


           }
           else {

             // Show the supplied view
             this.currentView.subtitle.show(options.model.get('subtitle'));
             
           }
           
          }
          else {

           // Close the current view
           this.currentView.subtitle.close();

          }

          // Show the controls view if one has been supplied
          if (options.model.get('controls')) {

            // Show the subtitle
            this.currentView.controls.show(options.model.get('controls'));

          }
          else {

           // Close the current view
           this.currentView.controls.close();

          }

        });

				// Show the header layout
				App.main.currentView.header.show(headerView);

			});

    }

  };

});

