define('app/modules/newcharts/features/moonshot/models/moonshot.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		url: function() {
			return 'api/v1/Moonshot/' + this.id;
		},

		defaults: {
			care_package_changes: '',
			recommending_residential: null,
			how_did_we_support_outcome: '',
			did_we_speed_up_assessment: null,
            request_email_only: false,
			notes: ''
		}
	});

});
