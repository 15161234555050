define('app/modules/newcharts/features/moonshot/views/moonshot.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/moonshot/templates/moonshot.tmpl',
	'moment',
	'backbone.crudder',
    'switcher'

], function(Marionette, Vent, Requests, Template, Moment, Crudder, Switcher) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		templateHelpers: {
			carePackageChangesList: function() {
				return [
					'Increase',
					'Decrease',
					'No Change'
				];
			}
		},

		ui: {
			cancelBtn: '#cancel-btn',
			saveBtn: '#save-btn',
            requestSwitch: '#request-switch-wrapper',

			// Fields
			care_package_changes: '[name=care_package_changes]',
			recommending_residential: '[name=recommending_residential]',
			how_did_we_support_outcome: '[name=how_did_we_support_outcome]',
			did_we_speed_up_assessment: '[name=did_we_speed_up_assessment]',
			did_we_add_value: '[name=did_we_add_value]',
			did_we_delay_assessment: '[name=did_we_delay_assessment]',
			notes: '[name=notes]',
			client_id: '[name=client_id]',
            request_email_only: '[name=request_email_only]',
			status: 'select[name=status]'
		},

		events: {
			'click @ui.cancelBtn': 'cancelForm',
			'click @ui.saveBtn': 'saveForm'
		},

        onRender: function() {
		    if (this.model.get('request_email_only')) {
		        this.ui.requestSwitch.find('input[type=checkbox]').attr('checked', 'checked');
            }

            Switcher.init({
                view: this.ui.requestSwitch
            });
        },

		cancelForm: function(e) {
			e.preventDefault();
		},

		saveForm: function(e) {
			e.preventDefault();

			var data = {
				care_package_changes: this.ui.care_package_changes.val(),
				recommending_residential: this.boolean(this.ui.recommending_residential.val()),
				how_did_we_support_outcome: this.ui.how_did_we_support_outcome.val(),
				did_we_speed_up_assessment: this.boolean(this.ui.did_we_speed_up_assessment.val()),
				did_we_add_value: this.boolean(this.ui.did_we_add_value.val()),
				did_we_delay_assessment: this.boolean(this.ui.did_we_delay_assessment.val()),
                request_email_only: this.ui.request_email_only.is(':checked'),
				notes: this.ui.notes.val(),
				client_id: this.ui.client_id.val(),
				status: this.ui.status.val()
			};

			this.model.set(data);

			Crudder.creatingUpdating({
				entity: this.model,
				patch: false,
				successMessage: 'Your post-assessment has been saved.',
				errorMessage: 'Unable to save'
			}, function(response) {
				// Do nothing.
			}.bind(this));
		},

		boolean: function(value) {
			value = value.toLowerCase();

			if (value == 'yes') {
				return true;
			} else if (value == 'no') {
				return false;
			}

			return null;
		}

	});

});
