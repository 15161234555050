/**
Application Components - Pagination Controller
@module Components
@submodule Components.Pagination.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Commands
Contents:
  * Paging Controller
		* initialize
		* start
Author(s):
  * Just Checking
*/

define('app/components/paging/components.paging.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/components/paging/components.paging.model',
  'app/components/paging/components.paging.view'

], function(Marionette, Commands, PagingModel, PagingView) {

  'use strict';

  /**
  Paging Controller
  @class Paging.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

      // Define our paging model
      this.model = new PagingModel();

    },

    /**
    @method start
    @param pagingData {Object} Model JSON data sent in via the collection
    @param view {Backbone View} The view to input the paging into
    */
    start: function(pagingData, view) {

      var
      pagingView,
      pagingContainer = view.$('#paging-container');

      // Set the paging model to the data supplied
      this.model.set(pagingData);

      // Create a new paging view using this model
      pagingView = new PagingView({

        model: this.model

      });

      // Listen for a navigate event form the view
      pagingView.on('paging:navigate', function(e) {

        // Move Backbone history to the correct place and trigger the route to load in the new items
        Commands.execute('app:navigate', {

					route: e.attr('data-hash'),
					trigger: true

				});

      });

      // Show the paging in the paging container
      pagingContainer.html(pagingView.render().el);

    }

  });

});

