/**
Chart Application
@module Chart
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Module method
		* API
		* Start Event
Author(s):
  * Just Checking
*/

define('app/modules/charts/charts.module',[

  'marionette',
  'app/app.vent',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/charts/charts.router',
  'app/modules/charts/controllers/charts.controllers.detail',
  'app/modules/charts/controllers/charts.controllers.edit',
  'app/modules/charts/models/charts.models.state',
  'moment',
  'app/tracking'

], function(Marionette, Vent, Commands, Requests, Router, DetailController, EditController, StateModel, Moment, Tracking) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

		var
		App = require('app/app'),
		ChartsApp = App.module('ChartsApp', function() {

			// prevent starting with parent
			this.startWithParent = false;

			// Add a state model to the module
			this.state = new StateModel();

			/**
			@property API
			*/
			var API = {

				/*
				Get an item by id
				*/


				// Parameters that are not passed in will be undefined
				getById: function(systemId, installationId, date, zoom) {

				//Check if the URL has 'LCT' (Local Controller Time) in the URL and change to special GET parameter if so...
				if(date) {
          			if(date.indexOf('LCT')>-1){

            			date = date.replace('LCT', '');
           				//set userSelected flag for use when sending data into model fetch
            			userSelected = 1;

         			} else{
			          	var userSelected = 0;
			          }
		        	} else{
		        		var userSelected = 0;
                        Tracking.track('openChartv2', installationId);
		        	}

					// Create a new controller
					ChartsApp.controller = new DetailController({
						systemId: parseInt(systemId, 10),
			            installationId: parseInt(installationId, 10),
			            date: date,
			            zoom: zoom,
			            userSelected: userSelected,
			            debug: false

					});
					// Add a reference to this module
					ChartsApp.controller.module = ChartsApp;
				},

				/*
				Edit the chart
				*/

				edit: function(systemId, installationId, date, zoom, model) {
					// Create a new edit controller
					ChartsApp.controller = new EditController({
						systemId: parseInt(systemId, 10),
          				installationId: parseInt(installationId, 10),
          				date: date,
          				zoom: zoom,
						model: model
					});

				},

				/*
				Get chart users
				*/

				getUsers: function(systemId, installationId) {
					// Init a user controller
					Commands.execute('init:controller', {
						eventHandler: 'users:init:list:controller',
						systemId: systemId,
						installationId: installationId,
						sectionTitle: 'Chart Users'

					});
				},

				/*
				Get chart user
				*/

				getUser: function(systemId, installationId, userId) {
					// Init a user controller
					Commands.execute('init:controller', {
						eventHandler: 'users:init:detail:controller',
						systemId: systemId,
						installationId: installationId,
						userId: userId
					});
				},

				/*
				Scroll the chart
				*/

				scrollChart: function(options) {
					// Call the correct method on the controller
					ChartsApp.controller.scrollChart(options);
				},

				/*
				Navigate to new url
				*/

				navigate: function(options) {
					// Call the correct method on the controller
					ChartsApp.controller.navigate(options);
				},

				/*
				Store rows
				*/

				storeRows: function() {
					// Call the correct method on the controller
					ChartsApp.controller.storeRows();
				},

			};

			/**
			Navigate the chart app
			@event chart:navigate
			@param date {Date} The date to navigate to
			*/
			Vent.on('chart:navigate', function(options) {
				// Call the correct method of the API
				API.navigate(options);
			});

			/**
			Show a chart edit state
			@event chart:edit
			@param model {Object} The model to send to the edit controller
			*/
			Vent.on('chart:edit', function(options) {
				// Update route
				Commands.execute('app:navigate', {
					route: Requests.request('get:current:route').replace('newcharts', 'charts') + '/edit',
					trigger: false
				});
				// Call the correct method of the API
				API.edit(options.systemId, options.installationId, options.date, options.zoom, options.model);
			});

			/**
			Scroll the chart
			@event chart:scroll
			@param direction {Integer} The direction to horz scroll the chart
			*/
			Vent.on('chart:scroll', function(options) {
				// Call the correct method of the API
				API.scrollChart(options);
			});

			/**
			Show current chart's users
			@event chart:show:users
			*/
			Vent.on('chart:show:users', function(options) {
				// Call the correct method of the API
				API.getUsers(options.systemId, options.installationId);
			});

			/**
			Store current chart's rows
			@event chart:store:rows
			*/
			Vent.on('chart:store:rows', function() {
				// Call the correct method of the API
				API.storeRows();

			});

			/**
			Start Event
			@event start
			*/
			this.on('start', function(hash) {
				// Start the router for this sub app
				ChartsApp.router = new Router({
					controller: API
				});
			});

		});
		return ChartsApp;

		}
	};
});

