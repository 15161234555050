/**
Chart Application - Controls Layout
@module Chart
@submodule Chart.Layouts.Chart
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Controls Layout
    * template
    * regions
    * events
    * saveChanges
    * cancelChanges
Author(s):
  * Just Checking
*/

define('app/modules/charts/layouts/charts.layouts.chart',[

  'marionette',
  'app/app.vent',
  'app/requests/requests.vent',
  'tpl!app/modules/charts/templates/charts.templates.chart.tmpl',
  'd3'

], function(Marionette, Vent, Requests, Template, D3) {

  'use strict';

  /**
  Controls Layout
  @class Chart.Layouts.Chart
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

			rows: '#chart-rows',
      timeline: '#chart-timeline'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click button[type="submit"]': 'saveChanges',
      'click button[type="cancel"]': 'cancelChanges'

    },

    /**
    @method saveChanges
    */
    saveChanges: function() {

      // Save any changes made
      Vent.trigger('chart:save:changes');

    },

    /**
    @method cancelChanges
    */
    cancelChanges: function() {

      // Cancel any changes made
      Vent.trigger('chart:cancel:changes');

    },

    onRender: function(){

	    //check if this is touch screen or not
	    function is_touch_device() {
	     return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	    }

	    // makes the d3 line, accepts param for start position
	    function create_line(left) {

			var vertical = D3.select(that.el)
			.append("div")
			.attr("id", "chartLineMouseCursor")
			.style("pointer-events", "none")
			.style("display", "block")
			.style("position", "absolute")
			.style("z-index", "2")
			.style("width", "1px")
			.style("height", "100%")
			.style("top", "-15px")
			.style("left", left + "px")
			.style("background", "#26C9FF");

	 	}

	 	// effect the left attribute of the cursor and line
    	function render_hand(reset, offset, pageX) {

    		var line = document.getElementById('chartLineMouseCursor');

    		// reset line and cursor called by orientation change
    		if(reset) {
	    		if($(window).width() > 939) {
					chart_cursor.style.left =  122 + 'px';
	    			line.style.left = 141 + 'px';
				} else {
					chart_cursor.style.left =  73 + 'px';
	    			line.style.left = 91 + 'px';
				}
    		} else {
    			// if not called by orientation change then triggered by touch event
    			chart_cursor.style.left = pageX - offset - 25 + 'px';
	    		line.style.left = pageX - offset - 7 + 'px';
    		}
    	}

    	// event listeners for touch and orientation change created here
	 	function track_cursor() {

			chart_cursor.addEventListener('touchmove', function(event) {

				var touch = event.targetTouches[0],
					line = document.getElementById('chartLineMouseCursor'),
					offset = get_offset(document.getElementById('chart'));

				// check for screen size and if the cursor is within boundries of the chart
				if($(window).width() > 939 && (touch.pageX  - offset.left - 7) > 139 && (touch.pageX  - offset.left - 7) < 861) {
					render_hand(false, offset.left, touch.pageX);
		    	} else if ($(window).width() < 940 && (touch.pageX - offset.left - 7) > 90 && (touch.pageX - offset.left - 7) < $('.chart-data-wrapper').width() + 93) {
		    		render_hand(false, offset.left, touch.pageX);
		    	}	else {
		    		return false;
		    	}

		    	event.preventDefault();

			}, false);

			// detect mobile orientation change
			window.addEventListener('orientationchange', function(){
				render_hand(true);
			}, false);
    	}

		// get offset value for chart wrapper (used to calculate correct left value for cursor/line)
		function get_offset(obj) {
		    var offsetLeft = 0;
		    do {
		        if (!isNaN(obj.offsetLeft)) {
		            offsetLeft += obj.offsetLeft;
		        }
		    } while (obj = obj.offsetParent );
		    return {left: offsetLeft};
		}

	    if (is_touch_device()) {

	    	// create new element for chart_cursor, declare global for storing the line, put this in that for use in create_line()
	    	var chart_cursor = document.createElement('div'),
	    		line,
				that = this;

			// give newly created chart_cursor an id, append the cursor to el
			chart_cursor.setAttribute('id','chart-cursor');
			this.el.appendChild(chart_cursor);

			// create line elment with correct start position
	    	if($(window).width() > 939) {
	    		create_line(141);
	    	} else {
	    		create_line(91);
	    	}

	    	// start listening for events
			track_cursor();
	    }

	    if (!is_touch_device()) {

			// Add the cursor hover
			var vertical = D3.select(this.el)
				.append("div")
				.attr("id", "chartLineMouseCursor")
				.style("pointer-events", "none")
				.style("display", "none")
				.style("position", "absolute")
				.style("z-index", "2")
				.style("width", "1px")
				.style("height", "100%")
				.style("top", "-15px")
				.style("left", "0px")
				.style("background", "#26C9FF");

			this.$el.mousemove(function(evt){
					var curPos = evt.clientX - $('#chart>div').offset().left;
					$('#chartLineMouseCursor').css("left", curPos + "px");
			});

			this.$el.mouseout(function(){
						 $('#chartLineMouseCursor').css("display", "none");
			});

			this.$el.mouseover(function(evt){

				var curPos = evt.clientX - $('#chart>div').offset().left;
					if(curPos > "140" && curPos < "860" ){
						 $('#chartLineMouseCursor').css("display", "block");
					}else{
						$('#chartLineMouseCursor').css("display", "none");
					}
			});

		}

    }

  });

});

