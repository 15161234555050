define('app/modules/newcharts/features/carerVisits/views/carerVisits.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment',
	'app/modules/newcharts/features/carerVisits/views/carerVisit.view',
	'tpl!app/modules/newcharts/features/carerVisits/templates/carerVisits.template.tmpl',
	'app/views/views.noItems'

], function(Marionette, Vent, Commands, Requests, Moment, CarerVisitView, Template, NoItemsView) {
	'use strict';

	return Marionette.CompositeView.extend({

		itemView: CarerVisitView,

		itemViewContainer: 'tbody',

		template: Template,

		emptyView: function() {
			return new NoItemsView({
				itemName: 'carer visits',
				border: true,
				marginTop: true
			});
		},

		templateHelpers: {
			currentPage: function() {
				return (this.offset / this.limit) + 1;
			},

			lastPage: function() {
				return Math.ceil(this.count / this.limit);
			},

			previousPage: function() {
				return this.currentPage() - 1;
			},

			nextPage: function() {
				return this.currentPage() + 1;
			},

			pagination: function() {
				var html = '',
					currentPage = (this.offset / this.limit) + 1,
					lastPage = Math.ceil(this.count / this.limit);

				if (lastPage === 1) {
					return '';
				}

				// Left Arrow Calculation
				if (currentPage !== 1) {
					// We can go backward
					html += '<a href="#" class="navigation-button" data-page="' + (currentPage - 1) + '">';
					html += '<i class="icon-left-dir"></i>';
					html += '</a>';
				}

				// Links
				for (var i = 1; i <= lastPage; i++) {
					if (i !== currentPage) {
						html += '<a href="#" class="navigation-button" data-page="' + i + '">';
					}

					html += i;

					if (i !== lastPage) {
						html += '&nbsp;|&nbsp;';
					}

					if (i !== currentPage) {
						html += '</a>';
					}
				}

				// Right Arrow Calculation
				if (currentPage < lastPage) {
					// We can go forward
					html += '<a href="#" class="navigation-button" data-page="' + (currentPage + 1) + '">';
					html += '<i class="icon-right-dir"></i>';
					html += '</a>';
				}

				return html;
			}
		},

		ui: {
			navigationButtons: '.navigation-button'
		},

		events: {
			'click @ui.navigationButtons': 'navigate'
		},

		navigate: function(e) {
			e.preventDefault();
			var page = $(e.target).closest('button').data('page'),
				offset,
				limit = this.model.get('limit');

			if (page) {
				// Calculate new offset
				offset = (limit * page) - limit;

				Vent.trigger('newchart:carer-visits', {
					offset: offset
				});
			}
		}

	})

});
