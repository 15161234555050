define('app/modules/newcharts/features/notifications/views/notification-form.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-form.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		ui: {
			dayButtons: '.days-box button',
			timeFrom: '#start_time',
			timeTo: '#end_time',
			allDaySwitch: '.allday-switch',
			allDaySwitchInput: '.allday-switch input',
			backBtn: '#back-button',
			saveBtn: '#save-button',
			recipientSwitches: '.recipient-switch',
			mobileNumberInputs: 'input.mobile_number',

			sensorInputs: 'input[name=sensor-check]',
			dayBtns: '.day-btn',
			startTime: '#start_time',
			endTime: '#end_time',

			sensorError: '#sensorError',
			daysError: '#daysError',
			timeRangeError: '#timeRangeError',

			cooldown: '#cooldown',

			recipientsError: '#recipientsError',
			mobileNumberFields: '.mobile_number',
			mobileInvalidError: '#mobile_invalid_error'
		},

		events: {
			'click @ui.dayButtons': 'dayButtonClick',
			'mouseover @ui.dayButtons': 'dayButtonHover',
			'mouseout @ui.dayButtons': 'dayButtonHoverOut',
			'changeTime @ui.timeFrom': 'setTimeFrom',
			'change @ui.timeFrom': 'setTimeFrom',
			'change @ui.allDaySwitchInput': 'changeAllDay',
			'click @ui.backBtn': 'goBack',
			'click @ui.saveBtn': 'save',
			'change @ui.mobileNumberFields': 'mobileNumberKey',
			'keyup @ui.mobileNumberFields': 'mobileNumberKey'
		},

		templateHelpers: function() {

			return {
				sensorChunk: function() {
					return _.chain(this.sensors)
						.groupBy(function(element, index) {
							return Math.floor(index/2);
						})
						.toArray()
						.value();
				},

				chunkedDays: function() {
					var days = this.daysList;

					days.push('e');

					return _.chain(days)
						.groupBy(function(element, index) {
							return Math.floor(index/4);
						})
						.toArray()
						.value();
				},

				dayToText: function(day) {
					if (day == 'e') {
						return 'Every day';
					}

					return moment().day(day).format('dddd');
				},

				getText: function() {
					switch(this.type) {
						case 1:
							// First Movement
							return "Choose which sensors trigger your notification. If more than one sensor is chosen, the notification will send when one sensor is triggered.";

						case 2:
							// No Movement
							return "Choose which sensors trigger your notification. If more than one sensor is chosen, the notification will send if no movement has been received from all selected sensors.";

						case 3:
							// First Door
							return "Choose which door sensors trigger your notification. If more than one sensor is chosen, the notification will send when the first door activity has been detected.";

						case 4:
							// No Door
							return "Choose which door sensors trigger your notification. If more than one sensor is chosen, the notification will send if no activity has been detected from all selected sensors.";

						case 5:
							// Property Exit
							return "Choose which sensors trigger your notification. Property Exit uses the non occupancy feature of the Just Checking system. This means that after a main door has been triggered and no activity has been detected within the property for 10 minutes a notification will trigger.";

						case 6:
							// First Door Ajar
							return "Choose which door sensors trigger your notification. Door ajar will trigger when the selected door(s) has been left open for 5 or more minutes.";

						case 7:
							// System Power
							return "We will let you know if for any reason your system is offline for more than 30 minutes.";

						case 8:
							// Login notification. (UNUSED)
							return '';

						case 9:
							// All Door Activity.
							return 'Choose which sensors trigger your notification. Each time your chosen sensor is triggered you will receive a notification.';
					}

					return '';
				}
			};
		},

		onBeforeRender: function() {
			store.set('_notifications_setTime_ignore', true);
		},

		onRender: function() {
			setTimeout(function() {
				Switcher.init({
					view: this.ui.allDaySwitch
				});
				setTimeout(function() {
					if (
						this.model.get('startTime') != null || this.model.get('endTime') != null || this.model.isNew()
					) {
						this.ui.allDaySwitch.find('.switch-off').click();
					} else {
						this.ui.allDaySwitch.find('.switch-off').click();
						this.ui.allDaySwitch.find('.switch-off').click();
					}
				}.bind(this));

				_.each(this.model.get('sensors'), function(sensor) {
					var elem = $('.sensor-switch[data-id=' + sensor.id + ']');
					Switcher.init({
						view: elem
					});
					setTimeout(function() {
						if (!sensor.selected) {
							elem.find('.switch-off').click();
						}
					}.bind(this));
				});
				_.each(this.model.get('recipients'), function(recipient) {
					var elems = $('.recipient-switch[data-id=' + recipient.id + ']');
					$.each(elems, function() {
						$(this).find("*").addBack().off();
						if ($(this).length == 1) {
							$(this).find('input[type=checkbox]').unbind();
							Switcher.init({
								view: $(this)
							});
						}
					});
				}.bind(this));

				var timeOptions = {
					step: 15,
					forceRoundTime: true,
					timeFormat: 'H:i'
				};

				this.ui.timeFrom.timepicker(timeOptions);
				this.ui.timeTo.timepicker(timeOptions);
				this.ui.cooldown.timepicker({
					step: 5,
					forceRoundTime: true,
					timeFormat: function(date) {
						date = moment(date);

						return date.format('m') + ' minutes';
					},
					minTime: '00:00:00',
					maxTime: '00:30:00'
				});

				// Days buttons
				_.each(this.model.get('days'), function(day) {
					$('button[data-day-id=' + day + ']').addClass('selected').data('selected', true);
				});
				if (this.model.get('days').length == 7) {
					$('button[data-day-id=e]').addClass('selected').data('selected', true);
				}
			}.bind(this));
		},

		onShow: function() {
			// Do nothing. its just for extension.
		},

		dayButtonClick: function(e) {
			e.preventDefault();

			var target = $(e.target),
				everydayButton = $(this.ui.dayButtons.selector + '.everyday');

			if (target.hasClass('everyday')) {
				if (target.hasClass('selected') && target.data('selected')) {
					// It's being de-selected
					this.ui.dayButtons.removeClass('selected').data('selected', false);
				} else {
					this.ui.dayButtons.addClass('selected').data('selected', true);
				}
			} else if (target.hasClass('selected') && target.data('selected')) {
				target.removeClass('selected').data('selected', false);
				everydayButton.removeClass('selected').data('selected', false);
			} else {
				target.addClass('selected').data('selected', true);

				var allSelected = true;
				this.ui.dayButtons.each(function(index, button) {
					button = $(button);
					if (!button.hasClass('everyday') && !button.hasClass('selected')) {
						allSelected = false;
						return;
					}
				});

				if (allSelected) {
					everydayButton.addClass('selected').data('selected', true);
				}
			}
		},

		dayButtonHover: function(e) {
			$(e.target).addClass('selected');
		},

		dayButtonHoverOut: function(e) {
			var target = $(e.target);

			if(!target.data('selected')) {
				target.removeClass('selected');
			}
		},

		isValidTime: function(time) {
			var regex = new RegExp(/1?[0-9]:(?:00|15|30|45)(?:am|pm)/);

			return regex.test(time);
		},

		setTimeFrom: function(e) {
			if (store.get('_notifications_setTime_ignore')) {
				store.remove('_notifications_setTime_ignore');
				return;
			}

			var elem = $(e.target);

			if (this.isValidTime(elem.val())) {
				// Valid time
				var date = new Moment(elem.val(), 'h:mma');

				date = date.add(15, 'minutes').format('h:mma');

				this.ui.timeTo.timepicker('option', {
					minTime: date,
					durationTime: elem.val(),
					showDuration: true
				});

				if (this.ui.timeTo.val() != '') {
					this.ui.timeTo.val('');
				}
			} else {
				// Invalid time
				this.ui.timeTo.timepicker('option', {
					minTime: null,
					durationTime: null,
					showDuration: false
				});
			}
		},

		changeAllDay: function(e) {
			if ($(e.target).is(':checked')) {
				this.ui.timeFrom.removeAttr('disabled');
				this.ui.timeTo.removeAttr('disabled');
			} else {
				this.ui.timeFrom.attr('disabled', 'disabled');
				this.ui.timeTo.attr('disabled', 'disabled');
			}
		},

		goBack: function(e) {
			e.preventDefault();
			Vent.trigger('newchart:notification-list');
		},

		save: function(e) {
			e.preventDefault();

			var permissions = this.model.get('permissions'),
				sensors = [],
				days = [],
				recipients = this.model.get('recipients');

			if (this.validate()) {
				// Go through each field and update model.
				// Sensors
				if (permissions.canChooseSensors) {
					_.each(this.model.get('sensors'), function (sensor) {
						var input = $('.sensor-switch[data-id=' + sensor.id + ']').find('input'),
							checked = !input.is(':checked');

						if (checked) {
							sensors.push({id: sensor.id});
						}
					});
					this.model.set('sensors', sensors);
				}

				if (permissions.canChooseCooldown) {
					this.model.set('cooldown', moment().startOf('day').minute(this.ui.cooldown.val().replace(' minutes', '')).format('HH:mm:ss'));
				}

				// Days
				this.ui.dayButtons.each(function() {
					var elem = $(this);

					if (elem.hasClass('selected') && elem.data('day-id') != 'e') {
						days.push(elem.data('day-id'));
					}
				});
				this.model.set('days', days);

				// Time Range
				if (permissions.canChooseTimeRange) {
					if (!this.ui.allDaySwitchInput.is(':checked')) {
						this.model.set('startTime', null);
						this.model.set('endTime', null);
					} else {
						this.model.set('startTime', moment(this.ui.timeFrom.val(), 'HH:mm').format('HH:mm'));
						this.model.set('endTime', moment(this.ui.timeTo.val(), 'HH:mm').format('HH:mm'));
					}
				}

				// Recipients
				$(this.ui.mobileNumberInputs.selector).each(function() {
					var elem = $(this);

					var recipient = _.find(recipients, function(recipient) {
							return recipient.id == elem.data('id');
						}),
						recipientIndex = recipients.indexOf(recipient);

					recipients[recipientIndex].mobile_number = elem.val();
				});
				$(this.ui.recipientSwitches.selector).each(function() {
					var elem = $(this),
						recipient = _.find(recipients, function(recipient) {
							return recipient.id == elem.data('id');
						}),
						recipientIndex = recipients.indexOf(recipient);

					if (elem.data('id') == recipient.id) {
						var input = elem.find('input');

						if (input.attr('name') == 'notify-sms-check') {
							recipient.notify_sms = input.is(':checked');
						} else if (input.attr('name') == 'notify-email-check') {
							recipient.notify_email = input.is(':checked');
						}
					}

					recipients[recipientIndex] = recipient;
				});
				this.model.set('recipients', recipients);

				// Business Logic: The rule should be re-enabled when edited.
				this.model.set('enabled', true);

				// CRUD save
				Crudder.creatingUpdating({
					entity: this.model,
					patch: false,
					successMessage: 'Saved successfully',
					errorMessage: 'Error occurred'
				}, function(response) {
					if (response.success) {
						Vent.trigger('newchart:notification-list');
					}
				});
			}
		},

		validate: function() {
			var permissions = this.model.get('permissions'),
				error = false;

			this.ui.sensorError.hide();
			this.ui.daysError.hide();
			this.ui.timeRangeError.hide();
			this.ui.recipientsError.hide();

			if (permissions.canChooseSensors) {
				// Check if the user has chosen one or more sensors.
				var sensorSelected = false;

				this.ui.sensorInputs.each(function() {
					if (!$(this).is(':checked')) {
						sensorSelected = true;
						return false;
					}
				});

				if (!sensorSelected) {
					error = true;
					this.ui.sensorError.show();
				}
			}

			// Check if the user has chosen one or more days.
			var daySelected = false;
			this.ui.dayBtns.each(function() {
				if ($(this).hasClass('selected')) {
					daySelected = true;
					return false;
				}
			});

			if (!daySelected) {
				error = true;
				this.ui.daysError.show();
			}

			if (permissions.canChooseTimeRange) {
				// Check if the user has specified a time range.
				var timeSelected = false;
				if (this.ui.startTime.val() == '' || this.ui.endTime.val() == '') {
					if (!this.ui.allDaySwitchInput.is(':checked')) {
						timeSelected = true;
					}
				} else {
					timeSelected = true;
				}

				if (!timeSelected) {
					error = true;
					this.ui.timeRangeError.show();
				}
			}

			// Recipients
			var atLeastOne = false;
			$(this.ui.recipientSwitches.selector).find('input[type=checkbox]').each(function() {
				if ($(this).is(':checked')) {
					atLeastOne = true;

					return false;
				}
			});
			if (!atLeastOne) {
				error = true;
				this.ui.recipientsError.show();
			}

			return !error;
		},

		mobileValid: function (mobile) {
			// Mobile number at least characters long containing numbers or + characters.
			var regex = /^[0-9\s+]{9,}$/;

			return regex.test(mobile);
		},

		mobileNumberKey: function(e) {
			var id = $(e.target).data('id'),
				mobile = this.$el.find('input[name=mobile_number_' + id + ']'),
				textSwitch = mobile.parent().parent().find('input[name=notify-sms-check]'),
				switchDiv = textSwitch.parent().parent();

			if (this.mobileValid(mobile.val()) && !this.isSwitchOn(id)) {
				// Enable switch
				this.enableSwitch(switchDiv);
			} else if (!this.mobileValid(mobile.val()) && this.isSwitchOn(id)) {
				// Disable and turn off switch
				this.disableSwitch(switchDiv);
			}

			if (!this.mobileValid(mobile.val()) && mobile.val().length > 0) {
				this.ui.mobileInvalidError.show();
				mobile.parent().parent().find('td:first-child').addClass('errorText');
			} else {
				this.ui.mobileInvalidError.hide();
				mobile.parent().parent().find('td:first-child').removeClass('errorText');
			}
		},

		isSwitchOn: function(id) {
			var $elem = $('input[name=mobile_number_' + id + ']')
				.parent()
				.parent()
				.find('input[name=notify-sms-check]')
				.parent()
				.parent();

			return $elem.find('ul').find('li:first-child').hasClass('switch-on');
		},

		enableSwitch: function($elem) {
			// Firstly, enable the switch to be edited.
			$elem
				.removeClass('switch-disabled');

			// Secondly, switch it on.
			$elem
				.find('.switch-off')
				.attr('class', 'switch-temp');
			$elem
				.find('.switch-on')
				.attr('class', 'switch-off');
			$elem
				.find('.switch-temp')
				.attr('class', 'switch-on');

			$elem.find('input[type=checkbox]').attr('checked', true).prop('checked', true);
		},

		disableSwitch: function($elem) {
			// Firstly, switch it off.
			$elem
				.find('.switch-on')
				.attr('class', 'switch-temp');
			$elem
				.find('.switch-off')
				.attr('class', 'switch-on');
			$elem
				.find('.switch-temp')
				.attr('class', 'switch-off');

			// Secondly, disable the switch from being edited.
			$elem
				.addClass('switch-disabled');

			$elem.find('input[type=checkbox]').attr('checked', false).prop('checked', false);
		}

	});

});
