define('app/modules/newcharts/features/notifications/views/notification-history.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment',
	'd3',
	'app/modules/newcharts/features/notifications/views/notification-history-item.view',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-history.template.tmpl',
	'app/views/views.noItems',

], function(Marionette, Vent, Commands, Requests, Moment, D3, NotificationHistoryItemView, Template, NoItemsView) {
	'use strict';

	return Marionette.CompositeView.extend({

		itemView: NotificationHistoryItemView,

		itemViewContainer: 'tbody',

		template: Template,

		/**
		 @property emptyView
		 @type Function
		 */
		emptyView: function() {

			return new NoItemsView({

				itemName: 'history',
				border: true,
				marginTop: true

			});

		},

		templateHelpers: {
			currentPage: function() {
				return (this.offset / this.limit) + 1;
			},

			lastPage: function() {
				return Math.ceil(this.count / this.limit);
			},

			previousPage: function() {
				return this.currentPage() - 1;
			},

			nextPage: function() {
				return this.currentPage() + 1;
			},

			pagination: function() {
				var html = '',
					currentPage = (this.offset / this.limit) + 1,
					lastPage = Math.ceil(this.count / this.limit);

				if (lastPage == 1) {
					return '';
				}

				// Left Arrow Calculation
				if (currentPage != 1) {
					// We can go backward
					html += '<a href="#" class="navigation-button" data-page="' + (currentPage - 1) + '">';
					html += '<i class="icon-left-dir"></i>';
					html += '</a>';
				}

				// Links
				for (var i = 1; i <= lastPage; i++) {
					if (i != currentPage) {
						html += '<a href="#" class="navigation-button" data-page="' + i + '">';
					}

					html += i;

					if (i != lastPage) {
						html += '&nbsp;|&nbsp;';
					}

					if (i != currentPage) {
						html += '</a>';
					}
				}

				// Right Arrow Calculation
				if (currentPage < lastPage) {
					// We can go forward
					html += '<a href="#" class="navigation-button" data-page="' + (currentPage + 1) + '">';
					html += '<i class="icon-right-dir"></i>';
					html += '</a>';
				}

				return html;
			}
		},

		ui: {
			navigationButtons: '.navigation-button'
		},

		events: {
			'click @ui.navigationButtons': 'navigate',
			'click .sortable': 'sort'
		},

		onRender: function() {
			if (this.model.get('dir') == 'asc') {
				this.$el.find(".sortable[data-column='" + this.model.get('sort') + "']").addClass('sort-asc');
			} else if (this.model.get('dir') == 'desc') {
				this.$el.find(".sortable[data-column='" + this.model.get('sort') + "']").addClass('sort-desc');
			}
		},

		navigate: function(e) {
			e.preventDefault();
			var page = $(e.target).closest('button').data('page'),
				offset,
				limit = this.model.get('limit');

			if (page) {
				// Calculate new offset
				offset = (limit * page) - limit;

				Vent.trigger('newchart:notification-history', {
					offset: offset
				});
			}
		},

		sort: function(e) {
			var target = $(e.target),
				sort = target.data('column'),
				dir = 'asc';

			if (target.hasClass('sort-asc')) {
				// Sort DESC
				dir = 'desc';
			}

			Vent.trigger('newchart:notification-history', {
				offset: 0,
				sort: sort,
				dir: dir
			});
		}

	})

});
