define('app/modules/newcharts/features/notifications_family/views/notification-form.view',[
	'backbone',
	'switcher',
	'app/commands/commands.vent',
	'app/views/views.noItems',
	'app/modules/newcharts/features/notifications/views/notification-form.view',
	'tpl!app/modules/newcharts/features/notifications_family/templates/notification-form.template.tmpl',
	'tpl!app/modules/newcharts/features/notifications_family/templates/recipient.template.tmpl'
], function(Backbone, Switcher, Commands, NoItemsView, ParentView, Template, RecipientTemplate) {
	'use strict';

	return ParentView.extend({

		template: Template,

		ui: _.extend(ParentView.prototype.ui, {
			noResultsBox: '#no-results',
			recipientAdd: '#recipient-add',
			recipientsList: '#recipients-list',
			recipientsWrapper: '#recipients',
			removeButton: '.remove-button',
			upArrow: '.order-buttons > .icon-up-open',
			downArrow: '.order-buttons > .icon-down-open',
			navigateButtons: '[data-hash]'
		}),

		events: _.extend(ParentView.prototype.events, {
			'change @ui.recipientAdd': 'addRecipient',
			'click @ui.removeButton': 'removeRecipient',
			'click @ui.upArrow': 'moveUp',
			'click @ui.downArrow': 'moveDown',
			'click @ui.navigateButtons': 'navigateAway'
		}),

		templateHelpers: function() {
			var helpers = ParentView.prototype.templateHelpers.call(this),
				view = this;

			return _.extend(helpers, {

				activeRecipients: function() {
					return _.sortBy(_.filter(view.model.get('recipients'), function(recipient) {
						return recipient.cascade_order != null && recipient.cascade_order > 0;
					}), 'cascade_order');
				},

				inactiveRecipients: function() {
					return _.sortBy(_.filter(view.model.get('recipients'), function(recipient) {
						return recipient.cascade_order == null || recipient.cascade_order < 1;
					}), 'cascade_order');
				},

				recipientTemplate: function() {
					return RecipientTemplate({
						activeRecipients: view.templateHelpers().activeRecipients(),
						inactiveRecipients: view.templateHelpers().inactiveRecipients()
					});
				}

			});
		},

		onBeforeRender: function() {
			ParentView.prototype.onBeforeRender.call(this);

			var recipients = this.model.get('recipients');

			for(var i = 0; i < recipients.length; i++) {
				if (recipients[i].cascade_order == null || recipients[i].cascade_order == 0) {
					recipients[i].notify_sms = false;
					recipients[i].notify_email = false;
				}
			}

			this.model.set('recipients', recipients);
		},

		onRender: function() {
			ParentView.prototype.onRender.call(this);

			// Vent.on('notifications_family:remove-sensor', this.removeSensor.bind(this));
		},

		onShow: function() {
			ParentView.prototype.onShow.call(this);

			this.setupSortable();
		},

		setupSortable: function() {
			// Sort out elements.
			this.ui.recipientsWrapper.find('.recipient-switch > input[type=checkbox]').unbind();
			this.ui.recipientsWrapper.html(this.templateHelpers().recipientTemplate);

			// Setup switches
			_.each(this.templateHelpers().activeRecipients(), function(recipient) {
				var elems = $('.recipient-switch[data-id=' + recipient.id + ']');
				$.each(elems, function() {
					if ($(this).length == 1) {
						Switcher.init({
							view: $(this)
						});
					}
				});
			});

			// Make sortable.
			this.ui.recipientsList = $(this.ui.recipientsList.selector);
			this.ui.recipientsList.sortable({

				// Set a delay in milliseconds before drag will start
				delay: 300,
				handle: this.ui.recipientsList.find('> li'),
				cursor: 'move',
				placeholder: 'ui-sortable-placeholder',
				start: this.startSort.bind(this),
				stop: this.sorted.bind(this)

			});
		},

		startSort: function(e, ui) {
			// Save the state of all recipients!
			// For every active recipient, find its row and check all of the fields.
			var allRecips;
			_.each(this.templateHelpers().activeRecipients(), function(recipient) {
				// Mobile
				recipient.mobile_number = this.ui.recipientsWrapper.find('input.mobile_number[data-id=' + recipient.id + ']').val();

				// Notify by text
				recipient.notify_sms = this.ui.recipientsWrapper.find('li[data-id=' + recipient.id + ']').find('input[name=notify-sms-check]').is(':checked');

				// Notify by email
				recipient.notify_email = this.ui.recipientsWrapper.find('li[data-id=' + recipient.id + ']').find('input[name=notify-email-check]').is(':checked');

				// Save.
				allRecips = this.model.get('recipients');
				for(var i = 0; i < allRecips.length; i++) {
					if (allRecips[i].id == recipient.id) {
						allRecips[i] = recipient;
						break;
					}
				}
				this.model.set('recipients', allRecips);
			}.bind(this));
		},

		sorted: function(e, ui) {
			var i = 1, view = this;
			this.ui.recipientsList.find('span.order').each(function() {
				$(this).text(i);
				i++;
			});

			// Reset the recipient list cascade orders
			var id, order, recipients = this.model.get('recipients');
			this.ui.recipientsList.find('> li').each(function() {
				id = $(this).data('id');
				order = parseInt($(this).find('span.order').text());

				for(i = 0; i < recipients.length; i++) {
					if (recipients[i].id == id) {
						recipients[i].cascade_order = order;
						break;
					}
				}
			});
			this.model.set('recipients', recipients);

			this.setupSortable();
		},

		addRecipient: function(e) {
			if (e.target.value != "" && e.target.value != "-") {
				// Find the Recipient
				var recipients = this.model.get('recipients');
				for(var index in recipients) {
					if (recipients[index].id == e.target.value) {
						break;
					}
				}

				// Find the next order number
				var lastOrder = this.ui.recipientsList.find('> li').last().find('span.order').text();
				if (!lastOrder) {
					recipients[index].cascade_order = 1;
				} else {
					recipients[index].cascade_order = parseInt(lastOrder) + 1;
				}
				recipients[index].notify_email = true;

				// Set the recipients
				this.model.set('recipients', recipients);

				// Remove from select list.
				this.ui.recipientAdd
					.find('option[value=' + recipients[index].id + ']')
					.remove();

				// Set select list back to 'Choose...'
				this.ui.recipientAdd.val('');

				this.setupSortable();
			}
		},

		removeRecipient: function(e) {
			var li = this.$el.find(e.target).parent().parent().parent(),
				id = li.data('id'),
				recipients = this.model.get('recipients'),
				recipient;

			for(var i = 0; i < recipients.length; i++) {
				if (recipients[i].id == id) {
					recipients[i].cascade_order = null;
					recipient = recipients[i];
					li.remove();
					break;
				}
			}

			this.model.set('recipients', recipients);
			this.sorted();
		},

		moveUp: function(e) {
			var elem = $(e.target).closest('li');

			this.startSort();

			// Move the element up.
			elem.prev().insertAfter(elem);

			// Redo order
			this.sorted();
		},

		moveDown: function(e) {
			var elem = $(e.target).closest('li');

			this.startSort();

			// Move the element up.
			elem.next().insertBefore(elem);

			// Redo order
			this.sorted();
		},

		navigateAway: function(e) {
			var hash = $(e.currentTarget).attr('data-hash'),
				current = Backbone.history.getFragment().split('/'),
				route = 'systems/' + current[1];

			if (hash == 'users') {
				route = route + '/charts/' + this.model.get('installId') + '/users';
			} else if (hash == 'edit') {
				route = route + '/charts/' + this.model.get('installId') + '/edit';
			}

			Commands.execute('app:navigate', {
				route: route
			});
		}

	});

});
