/**
Application Layout - Collection
@module Layout
@submodule Layout.Collections.Menu
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Menu Collection
		* url
		* model
		* comparator
Author(s):
  * Just Checking
*/

define('app/layout/collections/layout.collections.header.menu.collection',[

  'backbone',
  'app/layout/models/layout.models.header.menu.model'

], function(Backbone, Model) {

  'use strict';

  /**
  Main Menu Collection
  @class Layout.Collections.Menu
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property url
    @type String
    */
    url: '/assets/json/menu.json',

		/**
    @property model
    @type Object
    */
    model: Model,

    /**
    @method comparator
    @param menuItem {Object} The collection models
    */
    comparator: function(menuItem) {

      // Order the collection by 'position'
      return menuItem.get('position');

    }

  });

});
