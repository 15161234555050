/**
Chart Application - Edit Controller
@module Chart
@submodule Chart.Controllers.Edit
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Chart.Controllers.Edit
    * initialize
    * setModel
    * setMetaModel
    * setRowsCollection
    * showForm
    * showRows
    * showButtons
    * enableRow
    * disableRow
    * saveChanges
Author(s):
  * Just Checking
*/

define('app/modules/charts/controllers/charts.controllers.edit',[
	'backbone',
    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'app/modules/charts/models/charts.models.chart',
    'app/modules/charts/models/charts.models.meta',
    'app/modules/charts/models/charts.models.state',
    'app/modules/charts/views/charts.views.edit.form',
    'app/modules/charts/collections/charts.collections.rows',
    'app/modules/charts/layouts/charts.layouts.edit',
    'app/modules/charts/views/charts.views.edit.buttons',
    'app/modules/charts/views/charts.views.edit.rows.list',
    'app/modules/charts/views/charts.views.main.header.subtitle',
    'app/modules/charts/views/charts.views.main.header.controls',
    'backbone.crudder',
    'backbone.validation',
    'jquery.ui',
	'app/modules/charts/views/charts.views.edit.accelerometers.list',
	'app/modules/charts/views/charts.views.edit.carers.list'
], function(
    Backbone, Marionette, Vent, Commands, Requests, Model, MetaModel, StateModel, FormView, RowsCollection, Layout,
    ButtonsView, RowListView, SubTitleView, HeaderControlsView, Crudder, Validation, JQueryUI, AccelerometerListView, CarerListView) {
    'use strict';
    /**
    Edit Controller
    @class ChartApp.Controller.Edit
    @constructor
    @extends Marionette.Controller
    */
    return Marionette.Controller.extend({
      /**
       @method initialize
      */
      initialize: function(options) {

        var
          that = this,
          App = require('app/app');

        // Define the main layout
        this.layout = new Layout();

        // Define the main region
        this.region = App.main.currentView.content;

        // Create a collections object
        this.collections = {};

        // Create a models object
        this.models = {};

        /*
        Model
        */

        // Set the model
        this.setModel(options);

        /*
        Events
        */

        // Listen for the main view's 'show' event
        this.layout.on('show', function() {

          var headerControlsView = new HeaderControlsView({
              model: this.model,
              chartVersion: that.model.get('chartMenu').chartVersion
          });

          /*
          Meta Model
          */

          that.setMetaModel(options);

          /*
          Rows
          */

          that.setRowsCollection(options);

          /*
          Form View
          */

          that.showForm();

          /*
          Buttons View
          */

          that.showButtons();

          /*
          Rows View
          */

          that.showRows();

		  that.showAccelerometers();
		  that.showCarers();

          /*
          Header view
          */

          // Listen for users button click
          headerControlsView.on('show:users', function() {
              // Show the chart users
              Vent.trigger('chart:show:users', {
                  systemId: that.models.meta.get('systemNo'),
                  installationId: that.models.meta.get('installationId')
              });
          });

          Commands.execute('update:topbar', {
            title: {
              text: 'Chart Setup (' + that.models.meta.get('systemNo') + ')'
            }
          });

          // Show the section header
          that.showHeader({
              title: 'Chart Setup (' + that.models.meta.get('systemNo') + ')',
              subtitle: ' ',
              controls: headerControlsView
          });
           /* On-boarding tour */
          Commands.execute('init:onBoard', {
            page: 'chartSetup'
          });
        });
        /*
        Show
        */
        // Call the controller 'show' method
        this.show({
            loader: true,
            entities: this.model
        });
      },

      /**
      @method setModel
      */
      setModel: function(options) {
        // Has a model been supplied?
        if (options.model) {
          // Use the supplied model
          this.model = options.model;
        } else {
          // Define our chart model
          this.model = new Model({
          _id: options.installationId
        });
                // Fetch the model data
        this.model.fetch({
          data: {
            date: options.date,
            zoom: options.zoom
                // delay: 1
          }
        });
      }
    },

    /**
    @method setMetaModel
    */
    setMetaModel: function(options) {
      // Create a new meta model
      this.models.meta = new MetaModel();

      // Add the data to it
      this.models.meta.set(this.model.get('chartMeta'));
    },

    /**
    @method setRowsCollection
    */
    setRowsCollection: function() {
      // The row collection
      var allRows = new RowsCollection(this.model.get('chartRows'));
		this.collections.rows = new RowsCollection();
		this.collections.rows.add(allRows.filter(function(model, index) {
			return model.get('chartType') != 'accelerometer';
		}));
      // Keep a record of the original row collection
      this.collections.rows.store();

      //Enabled Rows

      // The row collection
      this.collections.enabledRows = new RowsCollection();

      // Set the disabled row collection
		this.collections.enabledRows.add(this.collections.rows.filter(function(model, index) {
			return (
					model.get('chartLineId') != '-1' &&
					model.get('chartLineId') != '-2' &&
					model.get('rowInUse')
				) && (
					model.get('chartLineName').toLowerCase().indexOf('accelerometer') === -1 &&
					model.get('chartLineName').toLowerCase().indexOf('activity') === -1 &&
					model.get('chartLineName').toLowerCase().indexOf('carer') === -1
				);
		}));

      //Disabled Rows

      // The row collection
      this.collections.disabledRows = new RowsCollection();

      // Set the disabled row collection
      this.collections.disabledRows.add(this.collections.rows.where({
        rowInUse: false
      }));

		// Accelerometers
		this.collections.accelerometers = new RowsCollection();
		this.collections.accelerometers.add(allRows.filter(function(model, index) {
			return model.get('rowInUse') && (
				model.get('chartLineName').toLowerCase().indexOf('accelerometer') !== -1 ||
				model.get('chartLineName').toLowerCase().indexOf('activity') !== -1
			);
		}));

		// Carer sensors
		this.collections.carers = new RowsCollection();
		this.collections.carers.add(allRows.filter(function(model, index) {
			return model.get('rowInUse') && (
				model.get('chartLineName').toLowerCase().indexOf('carer') !== -1
			);
		}));
    },

    /**
    @method showForm
    */
    showForm: function() {
      // Create a new form and form wrapper views
      var
        that = this,
        formView = new FormView({

          model: this.models.meta,
          collection: this.collections.disabledRows

        });
      /*
      Events
      */
      // Enable row event
      formView.on('enable:row', this.enableRow, this);
      formView.on('enable:all:motion-door', this.enableAllMotionDoor, this);
      formView.on('disable:all:motion-door', this.disableAllMotionDoor, this);
      formView.on('enable:all:activity', this.enableAllActivity, this);
      formView.on('disable:all:activity', this.disableAllActivity, this);

      // Listen for the view render event
      formView.on('render', function() {
        // Bind validation to the form view
        Backbone.Validation.bind(this);
        Commands.execute('set:change:true', {view:this});
      });

      /*
      Show
      */
      // Show the form view
      this.layout.form.show(formView);
    },

    /**
    @method showRows
    */
    showRows: function() {
      // Create a new row list view
      var rowListView = new RowListView({
        collection: this.collections.enabledRows,
	    model: this.models.meta
      });

      // Enable row event
      rowListView.on('itemview:disable:row', this.disableRow, this);

      rowListView.on('render', function() {
        // Bind validation to the form view
        Backbone.Validation.bind(this);
        Commands.execute('set:change:true', {view:this});
      });

      // Show the rows
      this.layout.rows.show(rowListView);
    },

	showAccelerometers: function() {
    	if (this.hasAccelerometers()) {
    		if (this.collections.accelerometers.length == 0) {
				this.collections.accelerometers.push({
					chartLineName: null,
					chartLineLabel: null,
					parent: null
				});
			}

			// Create a new row list view
			var rowListView = new AccelerometerListView({
				collection: this.collections.accelerometers,
				allCollection: this.collections.enabledRows,
				model: this.models.meta
			});

			// Enable row event
			rowListView.on('itemview:disable:accelerometer', this.disableAccelerometer, this);

			rowListView.on('render', function() {
				// Bind validation to the form view
				Backbone.Validation.bind(this);
				Commands.execute('set:change:true', {view:this});
			});

			this.layout.accelerometers.on('show', function() {
				if (this.collections.accelerometers.length == 1 && this.collections.accelerometers.first().get('chartLineName') == null)
				this.collections.accelerometers.pop();
			}.bind(this));

			// Show the rows
			this.layout.accelerometers.show(rowListView);
		}
	},

	showCarers: function() {
		if (this.hasCarers()) {
			if (this.collections.carers.length === 0) {
				this.collections.carers.push({
					chartLineName: null,
					chartLineLabel: null
				});
			}

			// Create a new row list view
			var rowListView = new CarerListView({
				collection: this.collections.carers,
				allCollection: this.collections.enabledRows,
				model: this.models.meta
			});

			// Enable row event
			rowListView.on('itemview:disable:carer', this.disableCarer, this);

			rowListView.on('render', function() {
				// Bind validation to the form view
				Backbone.Validation.bind(this);
				Commands.execute('set:change:true', {view:this});
			});

			this.layout.carers.on('show', function() {
				if (this.collections.carers.length === 1 && this.collections.carers.first().get('chartLineName') == null)
					this.collections.carers.pop();
			}.bind(this));

			// Show the rows
			this.layout.carers.show(rowListView);
		}
	},

    /**
    @method showButtons
    */
    showButtons: function() {
      // Create a new buttons view
      var buttonsView = new ButtonsView({
        model: this.models.meta
      });

      //View Events

      buttonsView.on('save:changes', this.saveChanges, this);
      // Show the buttons
      this.layout.buttons.show(buttonsView);
    },

    /**
    @method enableRow
    */
    enableRow: function(model) {
      // Update the model
      model.set({
        rowInUse: true
      }, {
        silent: true
      });
      // Add the model to the enabled collection
	  if(
	  	model.get('chartLineName').toLowerCase().indexOf('accelerometer') !== -1 ||
	  	model.get('chartLineName').toLowerCase().indexOf('activity') !== -1
	  ) {
		  this.collections.accelerometers.add(model);
	  } else if(model.get('chartLineName').toLowerCase().indexOf('carer') !== -1) {
		this.collections.carers.add(model);
	  } else {
		this.collections.enabledRows.add(model);
	  }
      // Remove the model from the disabled collection
      this.collections.disabledRows.remove(model);
      // Show the rows again
      this.showRows();
	  this.showAccelerometers();
	  this.showCarers();
      // This needs to happen else the drag and drop doesn't function correctly
      //alert("The " + model.get('chartLineLabel') + ' Sensor has been added to the chart');
    },

    /**
    @method disableRow
    */
    disableRow: function(view) {
      // Update the model
      view.model.set({
        rowInUse: false
      }, {
        silent: true
      });
      // Add the model to the disabled collection
      this.collections.disabledRows.add(view.model);

      // Remove the model from the enabled collection
      this.collections.enabledRows.remove(view.model.id);

      // Show the rows again
      //this.showRows();
      // No need to redraw the rows, we are just augmenting the DOM?
    },

	/**
	 @method disableRow
	 */
	disableAccelerometer: function(view) {
		// Update the model
		view.model.set({
			rowInUse: false,
            parent: null
		}, {
			silent: true
		});
		// Add the model to the disabled collection
		this.collections.disabledRows.add(view.model);

		// Remove the model from the enabled collection
		this.collections.accelerometers.remove(view.model.id);

		// Show the rows again
		//this.showRows();
		// No need to redraw the rows, we are just augmenting the DOM?
	},

	/**
	 @method disableRow
	 */
	disableCarer: function(view) {
		// Update the model
		view.model.set({
			rowInUse: false
		}, {
			silent: true
		});
		// Add the model to the disabled collection
		this.collections.disabledRows.add(view.model);

		// Remove the model from the enabled collection
		this.collections.carers.remove(view.model.id);

		// Show the rows again
		//this.showRows();
		// No need to redraw the rows, we are just augmenting the DOM?
	},

    /**
    @method saveChanges
    */
    saveChanges: function() {

      Commands.execute('set:change:false', {view:this});

      var
        that = this,
        rowsCurrentView = this.layout.rows.currentView,
	    accelCurrentView = this.layout.accelerometers.currentView,
	    carerCurrentView = this.layout.carers.currentView;

      //Submit form

      // Submit the meta form (Updated the meta model)
      this.layout.form.currentView.$el.find('form').submit();

      // Services
	  var services;
	  if (this.models.meta.get('services').enhanced.enabled) {
	  	services = this.models.meta.get('services');

	  	services.enhanced.data = this.models.meta.get('moonshotData');

		this.models.meta.set('services', services);
	  }

	  if (this.models.meta.get('services').managed.enabled) {
	  	services = this.models.meta.get('services');

	  	services.managed.data.activity_sensor_objectives = this.layout.form.currentView.$el.find('textarea#activity_sensor_objectives').val();
	  	services.managed.data.notes = this.layout.form.currentView.$el.find('textarea#notes').val();

	  	this.models.meta.set('services', services);
	  }

      //Validate

      // Validate the meta model
      this.models.meta.validate();

      // Is the meta model valid?
      if (this.models.meta.isValid()) {

        //Row collections
	    var rows = new Backbone.Collection();

        // We require models in the collection
        //if (rowsCurrentView.collection.length > 0) {
          // Loop through each sensor row
          _.each(rowsCurrentView.$itemViewContainer.children(), function(item, index) {
            var
              position = index + 1,
              row = $(item),
              rowId = parseInt(row.attr('data-id'), 10);
            // Loop through each model in the rows view
            rowsCurrentView.children.each(function(view) {
              // Match the row to its model
              if (view.model && view.model.id === rowId) {
                // Update the model
                view.model.set({
                  rowCurrentPosition: position,
                  rowPosition: position,
                  chartLineLabel: view.ui.labelInput.val()
                }, {
                  silent: true
                });
              }
            });
          });
		  if (accelCurrentView) {
			  _.each(accelCurrentView.$itemViewContainer.children(), function(item, index) {
				  var
					  position = index + 101,
					  row = $(item),
					  rowId = parseInt(row.attr('data-id'), 10);
				  // Loop through each model in the rows view
				  accelCurrentView.children.each(function(view) {
					  // Match the row to its model
					  if (view.model && view.model.id === rowId) {
						  // Update the model
						  view.model.set({
							  rowCurrentPosition: position,
							  rowPosition: position,
							  chartLineLabel: view.ui.labelInput.val(),
							  parent: view.ui.parent.val() === '' ? null : parseInt(view.ui.parent.val())
						  }, {
							  silent: true
						  });
					  }
				  });
			  });
		  }
		  if (carerCurrentView) {
			  _.each(carerCurrentView.$itemViewContainer.children(), function(item, index) {
				  var
					  position = index,
					  row = $(item),
					  rowId = parseInt(row.attr('data-id'), 10);
				  // Loop through each model in the rows view
				  carerCurrentView.children.each(function(view) {
				  	console.log(view, view.model.attributes);
					  // Match the row to its model
					  if (view.model && view.model.id === rowId) {
						  // Update the model
						  view.model.set({
							  rowCurrentPosition: position,
							  rowPosition: position,
							  chartLineLabel: view.ui.labelInput.val(),
							  sensitivity: parseInt(view.ui.sensitivity.val())
						  }, {
							  silent: true
						  });
					  }
				  });
			  });
		  }
          // Reset the collection
          this.collections.rows.reset();

          // Add all the models to the rows collection
		  rows.add(this.collections.enabledRows.models);
		  rows.add(this.collections.disabledRows.models);
		  if (this.collections.accelerometers) {
		  	rows.add(this.collections.accelerometers.models);
		  }
		  if (this.collections.carers) {
			  rows.add(this.collections.carers.models);
		  }
          // this.collections.rows.add(this.collections.enabledRows.models);
          // this.collections.rows.add(this.collections.disabledRows.models);
        //}

        //Save the model

        // Save this model via crudder
        Crudder.creatingUpdating({
          entity: this.model,
          entityData: {
            chartRows: rows.toJSON(),
            chartMeta: this.models.meta.toJSON()
          },
          patch: false,
          successMessage: 'Chart has been saved',
          errorMessage: 'Unable to save chart'
        }, function(options) {
          // Has the request been successful?
          if (options.success) {
            // Return to the chart
            that.layout.buttons.currentView.cancelChanges();
          } else {
            console.log('update failed');
          }
        });
      }
    },

		hasAccelerometers: function() {
    		if (this.collections.accelerometers.length > 0) {
    			return true;
			}

			var hasAccels = false;

			this.collections.disabledRows.each(function(item) {
				hasAccels = (
					item.get('chartLineName').indexOf('Accelerometer') !== -1 ||
					item.get('chartLineName').indexOf('Activity') !== -1
				);

				if (hasAccels) {
					return;
				}
			});

			return hasAccels;
		},

		hasCarers: function() {
			if (this.collections.carers.length > 0) {
				return true;
			}

			var hasCarers = false;

			this.collections.disabledRows.each(function(item) {
				hasCarers = (
					item.get('chartLineName').indexOf('Carer') !== -1
				);

				if (hasCarers) {
					return;
				}
			});

			return hasCarers;
		},

        enableAllMotionDoor: function() {
            this.collections.rows.each(function(model) {
                if (
                    !model.get('rowInUse') && (
                        model.get('chartLineName').toLowerCase().indexOf('accelerometer') === -1 &&
                        model.get('chartLineName').toLowerCase().indexOf('activity') === -1
                    )
                ) {
                    $('#chart-sensor-list').val(model.id).trigger('change');
                }
            }.bind(this));
        },

        disableAllMotionDoor: function() {
            Commands.execute('app:confirmModal', {
                message: 'Removing all sensors may deactivate one or more notifications.<br />Do you wish to proceed?',
                confirmText: 'Yes, remove this sensor',
                cancelText: 'No, do not remove',
                confirmCallback: function() {
                    $('#chart-edit-rows .chart-edit-row-remove-button').each(function() {
                        $(this).addClass('no-modal').click();
                    });
                }.bind(this)
            });
        },

        enableAllActivity: function() {
            this.collections.rows.each(function(model) {
                if (
                    !model.get('rowInUse') && (
                        model.get('chartLineName').toLowerCase().indexOf('accelerometer') !== -1 ||
                        model.get('chartLineName').toLowerCase().indexOf('activity') !== -1
                    )
                ) {
                    $('#chart-sensor-list').val(model.id).trigger('change');
                }
            }.bind(this));
        },

        disableAllActivity: function() {
            Commands.execute('app:confirmModal', {
                message: 'Removing all sensors may deactivate one or more notifications.<br />Do you wish to proceed?',
                confirmText: 'Yes, remove this sensor',
                cancelText: 'No, do not remove',
                confirmCallback: function() {
                    $('#chart-edit-accelerometers .chart-edit-row-remove-button').each(function() {
                        $(this).addClass('no-modal').click();
                    });
                }.bind(this)
            });
        }
  });
});

