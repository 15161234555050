/**
Application Components - Notifications CollectionView
@module Components
@submodule Components.Notifications.CollectionView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Notification Collection View
    * itemView
Author(s):
  * Just Checking
*/

define('app/components/notifications/views/components.notifications.views.list',[

  'marionette',
  'app/components/notifications/views/components.notifications.views.item'

], function(Marionette, ItemView) {

  'use strict';

  /**
  Notification Collection View
  @class ChartApp.NotificationList
  @constructor
  @extends Marionette.CollectionView
  */
  return Marionette.CollectionView.extend({

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView

  });

});

