/**
Application Commands - Initialise a controller
@module Commands
@submodule Commands.InitPopover
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
	* Vent
Contents:
  * Module Initialize
  	* Initialise a controller
Author(s):
  * Just Checking
*/

define('app/commands/commands.initController',[

	'backbone',
	'app/app.vent',
	'app/commands/commands.vent'

], function(Backbone, Vent, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Initialise a controller
			@event init:controller
			@param element {Object} The target element
			*/
			return Commands.setHandler('init:controller', function(options) {

				// 
				Vent.trigger(options.eventHandler, options);

			});

    }

  };

});

