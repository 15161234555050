/**
Application Commands - Show Current Item
@module Commands
@submodule Commands.ShowCurrentItem
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
	* Requests
Contents:
  * Module Initialize
		* Show Current Item
Author(s):
  * Just Checking
*/

define('app/commands/commands.showCurrentItem',[

	'backbone',
	'app/commands/commands.vent',
	'app/requests/requests.vent'

], function(Backbone, Commands, Requests) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Show Current Item
			@event show:current:item
			*/
			return Commands.setHandler('show:current:item', function() {

				var
				that = this,
				currentFragment = Backbone.history.fragment,
				links = $('#side-menu a'),
				firstLink = $('#side-menu li:first-child'),
				newFragment,
				isMatch = false;

				// console.log(currentFragment);

				// Extract what we want from the fragment
				/*newFragment = Requests.request('get:fragment:section', {

					currentFragment: currentFragment,
					limit: 4,
					removeLastChar: false

				});*/

				//Grab the userId from the backbone route
				newFragment = currentFragment.substr(currentFragment.lastIndexOf('/users/') + 7);

				// console.log(newFragment);

				// Loop through each link in the side menu
				_.each(links, function(link) {

					// Remove the # so we can detect matches
					var
					currentLink = $(link),
					href = '',
					parent = currentLink.parent();

					// Extract what we want from the fragment
					/*href = Requests.request('get:fragment:section', {

						currentFragment: currentLink.attr('data-hash').substr(1),
						limit: 1,
						removeLastChar: false

					});*/

					href = currentLink.attr('data-hash').substr(1);

					//console.log(href);

					// Is there a match?
					if (href === newFragment) {

						// Set this switch to true
						isMatch = true;

						// add the active class to the parent element
						parent.addClass('active');

					}
					else {

						// Remove any active class from the parent element
						parent.removeClass('active');

					}

				});

				// Was there a match returned?
				if (!isMatch) {

					// add the active class to the parent element
					firstLink.addClass('active');

				}

				return false;

			});

    }

  };

});

