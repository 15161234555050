/**
Application Commands - Extract fragment value
@module Requests
@submodule Requests.ExtractFragmentValue
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Extract fragment value
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFragmentItem',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Extract fragment value
			@event get:fragment:item
			@param fragment {String} The fragment you wish to manipulate
			@param index {Integer} Which part of the fragment you want returned
			@return {String} A single section of the backbone fragment
			*/
			return Requests.setHandler('get:fragment:item', function(options) {

				var
				targetIndex = options.index - 1,
				fragmentParts = options.fragment.split('/');

				return fragmentParts[targetIndex];

			});

    }

  };

});

