define('app/modules/evaluation/models/evaluation.models.home',['require','backbone'],function (require) {
	'use strict';

	var Backbone = require('backbone');

	return Backbone.Model.extend({
		idAttribute: '_id',

		// This is the route to the api - this is where the request will go when backbone syncs the model with the server
		urlRoot: function() {
            return 'EvaluationForms';
        }
	});

});

