define('app/modules/newcharts/views/chart.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/chart.template.tmpl',
    'moment',
    'app/modules/newcharts/views/chart-row.view',
    'app/modules/newcharts/models/note.model',
    'app/modules/newcharts/state',
    'backbone.crudder',
	'app/tracking',
	'app/modules/newcharts/rectMap',
	'tpl!app/modules/newcharts/templates/chart.pdf.template.tmpl'

], function(Marionette, Vent, Commands, Requests, Template, Moment, ChartRowView, NoteModel, State, Crudder, Tracking, RectMap, ChartPDFTemplate) {
    'use strict';

    return Marionette.CompositeView.extend({

        template: Template,

        itemView: ChartRowView,

        itemViewContainer: '.chart-wrapper',

		itemViewOptions: function() {
			return {
				meta: this.model.get('chartMeta'),
				menu: this.model.get('chartMenu'),
				rows: this.collection
			};
		},
        rendered: false,
        ui: function() {
            var ui = {
                chartOverlay: '#chart-overlay',
                chartRows: '#chart-rows',
                chartNotice: '#chart-notice',
                chartLineText: '#chart-overlay .line-text',
                chartDayBar: '#chart-day-bar',

                chartMinusBtn: '#chart-minus',
                chartPlusBtn: '#chart-plus',
                resetZoomBtn: '#chart-zoom-reset button',

                noteBox: '#notebox',
                noteClearBtn: '#note-clear-btn',
                noteSaveBtn: '#note-save-btn',
                noteSubjectField: '#note-subject',
                noteMessageField: '#note-message',
                noteIcon: '#note-indicator',
                noteExpandBtn: '#notebox-header .expand',
                noteCollapseBtn: '#notebox-header .collapse',
                noteExpandMsg: '#notebox-expand-message',
                noteForm: '#note-form'
            };

            var date = new Moment(this.options.model.get('chartMeta').chartDate).format('YYYYMMDDHHmmss');

            ui.chartContainer = '#chart-' + date;
            ui.noteBox = ui.noteBox + '-' + date;

            _.each(ui, function(item, key) {
                switch(key) {
                    case 'chartContainer':
                    case 'noteBox':
                    case 'chartDayBar':
                        // Do nothing
                        break;

                    case 'chartNotice':
                        ui[key] = ui[key] + '-' + date;
                        break;

                    case 'noteClearBtn':
                    case 'noteSaveBtn':
                    case 'noteSubjectField':
                    case 'noteMessageField':
                    case 'noteIcon':
                    case 'noteExpandBtn':
                    case 'noteCollapseBtn':
                    case 'noteExpandMsg':
                    case 'noteForm':
                        ui[key] = ui.noteBox + ' ' + ui[key];
                        break;

                    default:
                        ui[key] = ui.chartContainer + ' ' + ui[key];
                        break;
                }
            });

            return ui;
        },

        zoomLevels: [
            120,
            //60,
            //30,
            //20,
            10,
            5
            //1
        ],

       // Override the render method
        render: function() {
            // Perform check on the stored bearer
            var that = this;
            this.checkBearerPreRender()
                .then(function(isValid) {
                    if (isValid) {
                        Marionette.CompositeView.prototype.render.apply(that, arguments);
                        that.rendered = true;
                        that.onShow();
                    } else {
                        console.log("No Token as invalid");
                        Commands.execute('app:navigate', {
                            route: "logout"
                        });
                    }
                })
                .catch(function(err) {
                    // Handle any errors that occurred while checking the token
                    console.log("No Token");
                    Commands.execute('app:navigate', {
                        route: "logout"
                    });
                });
           
   
        },

        checkBearerPreRender: function(){
            this.rendered = false;
            return State.isBearerValid()
                .then(function(isValid) {
                    if (isValid) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(function(err) {
                    // Handle any errors that occurred while checking the token
                    // Do something if the token is invalid
                    console.log(err)
                    return false;
                });
        },

        templateHelpers: {
            generateChartDate: function() {
                var start = this.chartDate,
                    end = new Moment(this.chartDate),
                    seconds = (720 * this.chartMeta.zoom) - 1;

                end.add(seconds, 'seconds');

                if (start.isSame(end, 'day')) {
                    return this.chartDate.format('dddd Do MMMM YYYY');
                }

                return this.chartDate.format('dddd Do') + ' - ' + new Moment(this.chartDate).add(1, 'days').format('dddd Do MMMM YYYY');
            },

            isTouch: function() {
                var $html = $('html');
                return $html.hasClass('touch') && !$html.hasClass('mouse');
            }
        },

        initialize: function() {
            this.model.set('chartDate', new Moment(this.model.get('chartMeta').chartDate));
            this.model.set('interval', this.getInterval());
        },


        updateChartRows: function(childView) {
            // Iterate over all the chart-row views in the composite view
            this.children.forEach(function(row) {
                if (row.model.get('chartLineId') === childView.chartLineId) {
                    // Update the other chart-row views here
                    row.handleUpdateChartOptions();
                }
            });
        },

        onShow: function() {
            if(!this.rendered){
                return null;
            }
            if (!this.model.get('multi')) {
                this.renderDayBar();
            }

            this.registerEvents();
            this.showZoomOverlay();
            this.renderTimeline();

            // Work out whether you can scroll previous / next or not.
            var chartDate = new Moment(this.model.get('chartMeta').chartDate),
                installationStart = new Moment(this.model.get('chartMeta').installationStart).startOf('day'),
                installationEnd = new Moment(this.model.get('chartMeta').installationEnd),
                interval = this.getInterval();

            // Check previous
            if (chartDate.subtract(interval[0], interval[1]).isBefore(installationStart)) {
                // Can't go back.
                this.ui.chartMinusBtn.attr('disabled', 'disabled');
            }

            // Check next
            if (chartDate.add(interval[0], interval[1]).isAfter(installationEnd)) {
                // Can't go forward.
                this.ui.chartPlusBtn.attr('disabled', 'disabled');
            }

            Vent.on('newchart:hide-all-notes', this.hideNote.bind(this));
            Vent.on('newchart:show-all-notes', this.showNote.bind(this));
            Vent.on('newchart:hide-row', this.hideRow.bind(this));
            Vent.on('newchart:show-row', this.showRow.bind(this));
            //
            Vent.on('newchart:update-row-options', this.updateChartRows.bind(this));
            //
			if (!this.model.get('multi')) {
				Vent.off('newchart:expand-children');
				Vent.off('newchart:collapse-children');
			}
			Vent.on('newchart:expand-children', this.expandChildren.bind(this));
			Vent.on('newchart:collapse-children', this.collapseChildren.bind(this));

            // Check if the user has access to notes.
            Vent.off('newchart:check-note');
            Vent.once('newchart:check-note', this.checkNote.bind(this));
            Vent.off('newchart:multi-check-note-' + chartDate.format('YYYYMMDDHHmmss'));
            Vent.once('newchart:multi-check-note-' + chartDate.format('YYYYMMDDHHmmss'), this.checkNote.bind(this));

            // Overlay management
            Vent.on('newchart:multi-expandcollapse-note', this.recalculateOverlay.bind(this));

			window.goTo = this.goTo.bind(this);
			window.getHtml = this.getHtml.bind(this);

			if (!this.model.get('multi')) {
				this.renderExpands();
			}
        },

        registerEvents: function() {
            this.ui.chartMinusBtn.unbind().click(this.previousPart.bind(this));
            this.ui.chartPlusBtn.unbind().click(this.nextPart.bind(this));
            this.ui.resetZoomBtn.unbind().click(this.resetZoom.bind(this));
            this.ui.noteClearBtn.unbind().click(this.clearNote.bind(this));
            this.ui.noteSaveBtn.unbind().click(this.saveNote.bind(this));
            this.ui.noteExpandBtn.unbind().click(this.expandNote.bind(this));
            this.ui.noteCollapseBtn.unbind().click(this.collapseNote.bind(this));
        },

        showZoomOverlay: function() {
            var currentZoomLevel = this.currentZoomLevel();

            // Let's check if they have a mouse.
            var html = $('html');
            if (html.hasClass('mouse') || !html.hasClass('touch')) {
                this.ui.chartContainer.one('mousemove', function () {
                    this.ui.chartContainer.prepend("<div id=\"chart-overlay\">\n\t<div class=\"left-side\"></div>\n\t<div class=\"inner\"></div>\n\t<div class=\"right-side\"></div>\n    <div class=\'line\'></div>\n    <div class=\'line-text\'>10:00</div>\n</div>");
                    this.ui.chartContainer = $(this.ui.chartContainer.selector);

                    setTimeout(function () {
                        this.recalculateOverlay();

                        // Setup events
                        this.ui.chartOverlay
                            .mousemove(function (e) {
								var offsetX = (e.pageX - this.ui.chartOverlay.offset().left),
									offsetY = (e.pageY - this.ui.chartOverlay.offset().top);

								RectMap.triggerChartBlip($(e.target).closest('#chart-overlay').parent(), offsetX, offsetY);

                                var chartWidth = 720,
                                    newSizes = {
                                        left: {
                                            height: this.ui.chartRows.height(),
                                            width: chartWidth,
                                            left: 0
                                        },
                                        inner: {
                                            height: this.ui.chartRows.height(),
                                            width: 0,
                                            left: 0
                                        },
                                        right: {
                                            height: this.ui.chartRows.height(),
                                            width: chartWidth,
                                            left: 0
                                        },
                                        line: {
                                            width: 1,
                                            left: 0
                                        },
                                        lineText: {
                                            width: 50,
                                            left: 0
                                        }
                                    },
                                    difference = 0,
                                    maxZoom = this.zoomLevels[0];

                                // Calculate the new element sizes
                                newSizes.inner.width = chartWidth / (this.currentZoomLevel() / this.nextZoomLevel());
                                newSizes.inner.left = (e.pageX - this.ui.chartOverlay.offset().left) - (newSizes.inner.width / 2);
                                newSizes.left.left = newSizes.inner.left - newSizes.left.width;
                                newSizes.right.left = newSizes.inner.left + newSizes.inner.width;
                                newSizes.line.left = (e.pageX - this.ui.chartOverlay.offset().left) - (newSizes.line.width / 2);
                                newSizes.lineText.left = (e.pageX - this.ui.chartOverlay.offset().left) - (newSizes.lineText.width / 2);

                                // Constrain boundaries
                                if (newSizes.inner.left < 0) {
                                    // Constrain left boundary
                                    difference = newSizes.inner.left * -1;
                                    newSizes.inner.left = newSizes.inner.left + difference;
                                    newSizes.left.left = newSizes.left.left + difference;
                                    newSizes.right.left = newSizes.right.left + difference;
                                } else if (newSizes.inner.left > (chartWidth - newSizes.inner.width)) {
                                    newSizes.inner.left = chartWidth - newSizes.inner.width;
                                    newSizes.left.left = newSizes.inner.left - newSizes.left.width;
                                    newSizes.right.left = chartWidth;
                                }

                                // Check line text boundaries
                                if (newSizes.lineText.left < 0) {
                                    newSizes.lineText.left = 0;
                                } else if (newSizes.lineText.left > (chartWidth - newSizes.lineText.width)) {
                                    newSizes.lineText.left = (chartWidth - newSizes.lineText.width);
                                }

                                // Change line text based on time.
								var newDate = this.convertLineToDate(this.ui.chartOverlay.find('.line'));
								this.ui.chartOverlay.find('.line-text').text(newDate.format('HH:mm'));

                                // Apply size changes to the overlay elements
                                this.ui.chartOverlay.find('.left-side').css(newSizes.left);
                                this.ui.chartOverlay.find('.inner').css(newSizes.inner);
                                this.ui.chartOverlay.find('.right-side').css(newSizes.right);
                                this.ui.chartOverlay.find('.line').css(newSizes.line);
                                this.ui.chartOverlay.find('.line-text').css(newSizes.lineText);
                            }.bind(this))
                            .hover(function (e) {
                                this.ui.chartOverlay.find('.left-side').addClass('hover');
                                this.ui.chartOverlay.find('.right-side').addClass('hover');
                                this.ui.chartOverlay.find('.line').show();
                                this.ui.chartOverlay.find('.line-text').show();
                            }.bind(this), function (e) {
                                this.ui.chartOverlay.find('.left-side').removeClass('hover');
                                this.ui.chartOverlay.find('.right-side').removeClass('hover');
                                this.ui.chartOverlay.find('.line').hide();
                                this.ui.chartOverlay.find('.line-text').hide();

								RectMap.hideTooltips($(e.target).closest('#chart-overlay').parent());
                            }.bind(this));

                        $(window).resize(function() {
                            this.recalculateOverlay();
                        }.bind(this));

                        // Check if we're zoomed in as far as we can go, if we're not allow them to zoom
                        if (!this.isMaxZoomLevel()) {
                            this.ui.chartOverlay.click(function () {
                                var newDate = this.convertSelectionToDate(currentZoomLevel);

								RectMap.reset();

                                if (this.model.get('multi')) {
									Tracking.track(
										'zoomInMulti-' + this.nextZoomLevel(currentZoomLevel),
										this.model.get('chartMeta').installationId
									);

                                    Vent.trigger('newchart:multi-navigate', {
                                        slot: this.model.get('slot'),
                                        date: newDate,
                                        zoom: this.nextZoomLevel(currentZoomLevel),
                                        nightView: this.model.get('nightView')
                                    });
                                } else {
									Tracking.track(
										'zoomIn-' + this.nextZoomLevel(currentZoomLevel),
										this.model.get('chartMeta').installationId
									);

                                    Vent.trigger('newchart:navigate', {
                                        date: newDate,
                                        zoom: this.nextZoomLevel(currentZoomLevel),
                                        note: {
                                            subject: this.ui.noteSubjectField.val(),
                                            message: this.ui.noteMessageField.val()
                                        }
                                    });
                                }
                            }.bind(this));
                        }
                    }.bind(this));
                }.bind(this));
            } else {
                // For touchscreen peeps, let's give them a hand.
                setTimeout(function() {
                    this.ui.chartContainer
                        .find('.chart-timeline')
                        .after('<div class="chart-hand"><span class="time-text"></span></div>');

                    this.ui.chartContainer.prepend('<div class="hand-line"></div>');

                    this.ui.chartContainer.find('.hand-line')
                        .height(this.ui.chartRows.height() + 70);

                    var timeOffset = $(window).width() < 939 ? 103 : 153;
                    this.ui.chartContainer
                        .find('.chart-hand')
                        .unbind()
                        .bind('touchmove', function(e) {
                            e.preventDefault();

                            var touch = e.originalEvent.targetTouches[0],
                                line = this.ui.chartContainer.find('.hand-line'),
                                hand = this.ui.chartContainer.find('.chart-hand'),
                                left = touch.pageX - this.ui.chartContainer.offset().left,
                                lineOffset = 18,
                                lowerBound = $(window).width() < 939 ? 85 : 135,
                                upperBound = lowerBound + this.ui.chartContainer
                                        .find('.chart-data-wrapper')
                                        .first()
                                        .width() + 2;

                            if (left < lowerBound) {
                                left = lowerBound;
                            } else if (left > upperBound) {
                                left = upperBound;
                            }

                            line.css('left', left + lineOffset);
                            hand.css('left', left);

                            // Get time text
							var newDate = this.convertLineToDate(this.ui.chartContainer.find('.hand-line'), timeOffset - 1);
							this.ui.chartContainer.find('.chart-hand').find('.time-text').text(newDate.format('HH:mm'));
                        }.bind(this));

                    // Get time text
                    var newDate = this.convertLineToDate(this.ui.chartContainer.find('.hand-line'), timeOffset);
                    this.ui.chartContainer.find('.chart-hand').find('.time-text').text(newDate.format('HH:mm'));
                }.bind(this));
            }
        },

        renderTimeline: function() {
            var container = this.$el.find('#chart-timeline-hours'),
                offset = this.model.get('chartTimeline')[0].axisOffset;

            _.each(this.model.get('chartTimeline'), function(item) {
                var html = '<a data-date="' + item.axisData + '">';

                var classNames = [],
                    hour;

                if (this.model.get('chartMeta').zoom == 120) {
                    hour = new Moment(item.axisLabel).format('HH');
                } else {
                    hour = new Moment(item.axisLabel).format('HH:mm');
                }

                if (hour === '00' || hour === '00:00') {
                    classNames.push('chart-timeline-label-midnight');
                } else if (hour === '12' || hour === '12:00') {
                    classNames.push('chart-timeline-label-noon');
                }

                html += hour;
                html += '</a>';

                container
                    .append(
                        html
                    )
                    .addClass(classNames)
                    .css({
                        left: offset
                    });
            }.bind(this));

            if (!this.isMaxZoomLevel()) {
                container.children().each(function(index, elem) {
                    $(elem)
                        .unbind()
                        .click(function(e) {
                            // Zoom baby zoom
                            var date = new Moment($(e.target).data('date'));

                            if (this.model.get('multi')) {
                                Vent.trigger('newchart:multi-navigate', {
                                    slot: this.model.get('slot'),
                                    date: date,
                                    zoom: this.nextZoomLevel(this.currentZoomLevel()),
                                    nightView: this.model.get('nightView')
                                });
                            } else {
                                Vent.trigger('newchart:navigate', {
                                    date: date,
                                    zoom: this.nextZoomLevel(this.currentZoomLevel()),
                                    note: {
                                        subject: this.ui.noteSubjectField.val(),
                                        message: this.ui.noteMessageField.val()
                                    }
                                });
                            }
                        }.bind(this))
                        .addClass('clickable');
                }.bind(this));
            }
        },

        renderDayBar: function() {
            if(!this.ui.chartDayBar){
                return null;
            }
            // 
            var date = new Moment(this.model.get('chartMeta').chartDate),
                zoom = this.model.get('chartMeta').zoom;

            // Find out how many pixels back midnight is from origin.
            var pixelsBackToMidnight = this.getPixelsToMidnight('back', date, zoom);

            // Find out how many pixels forward midnight is from origin.
            var pixelsForwardToMidnight = this.getPixelsToMidnight('forward', date, zoom);

            
            // Render day bar.
            this.ui.chartDayBar.append('<div class="start"></div><div class="end"></div>');

            setTimeout(function() {
                this.ui.chartDayBar = $(this.ui.chartDayBar.selector);

                this.ui.chartDayBar.find('.start').css({
                    left: pixelsBackToMidnight,
                    width: this.getDayBarWidth(zoom)
                }).text(date.format('dddd'));
                this.ui.chartDayBar.find('.end').css({
                    left: (pixelsForwardToMidnight * -1),
                    width: this.getDayBarWidth(zoom)
                }).text(date.add(1, 'days').format('dddd'));
            }.bind(this));
        },

        getPixelsToMidnight: function(direction, date, zoom) {
            date = new Moment(date);

            var midnight = new Moment(date);

            if (direction != 'back' && direction != 'forward') {
                throw 'Incorrect direction';
            }

            if (direction == 'forward') {
                midnight = new Moment(date).add(1, 'day');
            }

            midnight = midnight.startOf('day');

            if (direction == 'forward') {
                return this.getPixelsBetween(date, midnight, zoom);
            }

            return this.getPixelsBetween(midnight, date, zoom);
        },

        getDayBarWidth: function(zoom) {
            var chartWidth = 720,
                maxZoom = this.zoomLevels[0],
                timesBy = maxZoom / zoom;

            return chartWidth * timesBy;
        },

        getPixelsBetween: function(startDate, endDate, zoom) {
            startDate = new Moment(startDate);
            endDate = new Moment(endDate);

            var seconds = startDate.diff(endDate, 'seconds');

            return seconds / zoom;
        },

        clearNote: function(e) {
            e.preventDefault();
            var oldNote = this.model.get('note');

            if (oldNote == false) {
                return;
            }

            Commands.execute('app:confirmModal', {
                message: 'Are you sure you want to clear this note?',
                confirmText: 'Yes, clear my note',
                cancelText: 'No go back!',
                confirmCallback: function() {
                    var newAttrs = {
                            id: oldNote.id,
                            date: new Moment(this.model.get('chartMeta').chartDate).format('YYYY-MM-DD'),
                            installationId: this.model.get('chartMeta').installationId,
                            subject: '',
                            message: ''
                        },
                        noteM = new NoteModel(newAttrs);

                    Crudder.creatingUpdating({
                        entity: noteM,
                        successMessage: "Note saved successfully.",
                        errorMessage: "Unable to save note."
                    }, function(response) {
                        if (response.success) {
                            this.model.set('note', false);
                            this.ui.noteSubjectField.val('');
                            this.ui.noteMessageField.val('');
                            this.ui.noteClearBtn.attr('disabled', 'disabled');

                            this.updateCalendarMarkers(new Moment(this.model.get('chartMeta').chartDate), oldNote.id, this.model.get('note'));

                            this.updateNoteIcon(false);
                        }
                    }.bind(this));
                }.bind(this)
            });
        },

        saveNote: function(e) {
            e.preventDefault();
            var subject = this.ui.noteSubjectField.val(),
                message = this.ui.noteMessageField.val();

            // Check if the note is empty (clearing the note).
            if (subject == '' && message == '') {
                // Its empty, we need to clear it.
                return this.clearNote(e);
            }

            var oldNote = this.model.get('note'),
                newAttrs = {
                    id: oldNote ? oldNote.id : null,
                    date: new Moment(this.model.get('chartMeta').chartDate).format('YYYY-MM-DD'),
                    installationId: this.model.get('chartMeta').installationId,
                    subject: subject,
                    message: message
                },
                noteM = new NoteModel(newAttrs);

            Crudder.creatingUpdating({
                entity: noteM,
                successMessage: "Note saved successfully.",
                errorMessage: "Unable to save note."
            }, function(response) {
                if (response.success) {
                    this.model.set('note', response.response.jcData);
                    this.ui.noteClearBtn.removeAttr('disabled');

                    this.updateCalendarMarkers(new Moment(this.model.get('chartMeta').chartDate), this.model.get('note').id, this.model.get('note'));

                    this.updateNoteIcon(true);
                }
            }.bind(this));
        },

        checkNote: function(callback) {
            var chartDate = new Moment(this.model.get('chartMeta').chartDate);

            Vent.off('newchart:check-note');
            Vent.off('newchart:multi-check-note-' + chartDate.format('YYYYMMDDHHmmss'));

            // Check if the note boxes values match the actual note in the model.
            // If not, show a confirmation window.
            var note = (typeof this.model.get('note') != 'object' || this.model.get('note') == null) ? {} : this.model.get('note');

            note = _.defaults(note, {
                subject: '',
                message: ''
            });

            var typedSubject = this.ui.noteSubjectField ? this.ui.noteSubjectField.val() : note.subject,
                typedNote = this.ui.noteMessageField ? this.ui.noteMessageField.val() : note.message;

            if (this.model.get('chartMenu').canAccessNote && typeof callback == 'function' && (note.subject != typedSubject || note.message != typedNote)) {
                // Houston, we have a problem.
                Commands.execute('app:confirmModal', {
                    message: 'Your note has not been saved!<br />Do you wish to proceed on without saving?',
                    confirmText: 'Yes, continue',
                    cancelText: 'No go back!',
                    confirmCallback: callback.bind(this),
                    cancelCallback: function() {
                        Vent.once('newchart:check-note', this.checkNote.bind(this));
                        Vent.once('newchart:multi-check-note-' + chartDate.format('YYYYMMDDHHmmss'), this.checkNote.bind(this));
                    }.bind(this)
                });
                return;
            }

            Vent.once('newchart:multi-check-note-' + chartDate.format('YYYYMMDDHHmmss'), this.checkNote.bind(this));
            if (typeof callback == 'function') {
                callback();
            }
        },

        resetZoom: function(e) {
			RectMap.reset();
            if (this.model.get('multi')) {
				Tracking.track(
					'resetZoomMulti',
					this.model.get('chartMeta').installationId
				);

                Vent.trigger('newchart:multi-reset-zoom', {
                    zoom: this.zoomLevels[0]
                });
            } else {
				Tracking.track(
					'resetZoom',
					this.model.get('chartMeta').installationId
				);

                Vent.trigger('newchart:navigate', {
                    date: new Moment(this.model.get('chartMeta').chartDate).startOf('day'),
                    zoom: this.zoomLevels[0],
                    note: {
                        subject: this.ui.noteSubjectField.val(),
                        message: this.ui.noteMessageField.val()
                    }
                });
            }
        },

        previousPart: function(e) {
            var interval = this.getInterval(),
                date = moment.utc(this.model.get('chartMeta').chartDate).subtract(interval[0], interval[1]);

            $('#chart-minus').attr('disabled', 'disabled');

            this.movePart(date);
        },

        nextPart: function(e) {
            var interval = this.getInterval(),
                date = moment.utc(this.model.get('chartMeta').chartDate).add(interval[0], interval[1]);

            $('#chart-plus').attr('disabled', 'disabled');

            this.movePart(date);
        },

        movePart: function(date) {
            var that = this;
            function callback(note) {
				RectMap.reset();
                Vent.trigger('newchart:navigate', {
                    date: date,
                    zoom: that.model.get('chartMeta').zoom,
                    note: note ? note : null
                });
            }

            // Are we moving to a new day? If so, check the note!
            var oldDate = new Moment(this.model.get('chartMeta').chartDate);

            if (oldDate.isSame(date, 'day')) {
                // Rearm the note
                var subject = this.ui.noteSubjectField.val(),
                    message = this.ui.noteMessageField.val();

                callback({
                    subject: subject,
                    message: message
                });
            } else {
                // Popup!
                this.checkNote(callback);
                $('#chart-plus').removeAttr('disabled');
                $('#chart-minus').removeAttr('disabled');
            }
        },

        getInterval: function() {
            // First: integer
            // Second: Moment unit
            // Third: Display unit

            switch(this.model.get('chartMeta').zoom) {
                case 120:
                case 60:
                    return [1, 'hour', 'hour'];

                case 30:
                case 20:
                    return [30, 'minutes', 'mins'];

                case 10:
                case 5:
                case 1:
                    return [10, 'minutes', 'mins'];

                default:
                    throw 'Invalid zoom level.';
            }
        },

        multiPrint: function() {
            var html = this.$el.clone(),
                noteHtml = '';

            // Remove bits not required
            html.find('hr').remove();
            html.find('.chart-notice').remove();
            html.find('.notebox').remove();
            html.find('#chart-zoom-reset').remove();

            // Add on the new notebox html
            var note = this.ui.noteForm.is(':hidden') ? null : this.model.get('note');

            if (this.model.get('chartMenu').canAccessNote && (note != null && (note.subject || note.message))) {
                noteHtml += "<div id='printnote'>";

                if (note.subject) {
                    noteHtml += "<h3>" + note.subject + "</h3>";
                }
                if (note.message) {
                    noteHtml += "<p>" + note.message + "</p>";
                }

                noteHtml += "</div>";
            }

            return html.html() + noteHtml;
        },

		renderExpands: function() {
			this.collection.each(function(row) {
				if (State.isExpanded(this.model.get('chartMeta').installationId, row.get('chartLineId'))) {
					// Let's expand the sensor again.
					setTimeout(function() {
						this.$el.find('.chartRow' + row.get('chartLineId')).find('i').click();
					}.bind(this));
				}
			}.bind(this));
		},

        /*
            UI Utilities
         */

        recalculateOverlay: function () {
            if (this.ui.chartOverlay && this.ui.chartLineText) {
                this.ui.chartOverlay = $(this.ui.chartOverlay.selector).css({
                    height: this.ui.chartRows.height() + 70,
                    top: this.ui.chartRows.position().top
                });
                this.ui.chartLineText = $(this.ui.chartLineText.selector).css({
                    'margin-top': this.ui.chartRows.height() + 53
                });
            }

            setTimeout(function() {
                if (this.ui.chartContainer && this.ui.chartContainer.find('.hand-line')) {
                    this.ui.chartContainer
                        .find('.hand-line')
                        .height(this.ui.chartRows.height() + 70);
                }
            }.bind(this));
        },

        updateNoteIcon: function(hasNote) {
            if (hasNote) {
                this.ui.noteIcon.addClass('has-note');
                return;
            }

            this.ui.noteIcon.removeClass('has-note');
        },

        expandNote: function() {
            this.ui.noteExpandBtn.hide();
            this.ui.noteCollapseBtn.show();

            this.ui.noteExpandMsg.hide();
            this.ui.noteForm.show();

            if (this.model.get('multi')) {
                Vent.trigger('newchart:multi-expandcollapse-note');
            }
        },

        collapseNote: function() {
            this.ui.noteCollapseBtn.hide();
            this.ui.noteExpandBtn.show();

            this.ui.noteExpandMsg.show();
            this.ui.noteForm.hide();

            if (this.model.get('multi')) {
                Vent.trigger('newchart:multi-expandcollapse-note');
            }
        },

        showNote: function() {
            if (this.ui.chartNotice && this.ui.noteBox) {
                this.ui.chartNotice.find('.notice-breaker').show();
                this.ui.noteBox.show();
            }

            this.recalculateOverlay();
        },

        hideNote: function() {
            if (this.ui.chartNotice && this.ui.noteBox) {
                this.ui.chartNotice.find('.notice-breaker').hide();
                this.ui.noteBox.hide();
            }

            this.recalculateOverlay();
        },

        showRow: function(options) {
        	var elem = $('.chartRow' + options.id);

        	if (this.model.get('multi')) {
				// Grab its parent
				var parentId = elem.data('parent');

				// Check whether the parent is expanded or collapsed
				if ($('.chartRow' + parentId).find('i').hasClass('icon-minus')) {
					elem.show();
					this.recalculateOverlay();
				}
			} else {
        		elem.show();
				this.recalculateOverlay();
			}
        },

        hideRow: function(options) {
            $('.chartRow' + options.id).hide();
            this.recalculateOverlay();
        },

        currentZoomLevel: function() {
            return this.model.get('chartMeta').zoom;
        },

        nextZoomLevel: function() {
            var result;

            _.each(this.zoomLevels, function(zoomLevel, index) {
                if (zoomLevel == this.currentZoomLevel()) {
                    result = this.zoomLevels[index + 1];
                }
            }.bind(this));

            return result;
        },

        isMaxZoomLevel: function() {
            return this.zoomLevels[this.zoomLevels.length - 1] == this.currentZoomLevel();
        },

        convertSelectionToDate: function() {
            var date = new Moment(this.model.get('chartMeta').chartDate),
                target = this.ui.chartOverlay.find('.left-side'),
                selectedPosition = parseInt(target.css('left').replace('px', '')) + target.width(),
                seconds = selectedPosition * this.currentZoomLevel();

            date.add(seconds, 'seconds');

            return date;
        },

        convertLineToDate: function(target, offset) {
            if (!offset) {
                offset = 0;
            }

            var date = new Moment(this.model.get('chartMeta').chartDate),
                selectedPosition = parseInt(target.css('left').replace('px', ''), 10) - parseInt(offset, 10),
                seconds = selectedPosition * this.currentZoomLevel();

            date.add(seconds, 'seconds');

            return date;
        },

        updateCalendarMarkers: function(date, id, note) {
            var markers = JSON.parse(store.get('calendar.markers'));

            if (!note) {
                // Note deleted.
                // Find note in markers list and delete it.
                markers = _.filter(markers, function(marker) {
                    return marker.id != id;
                }.bind(this));
            } else {
                // Note saved.
                // Check if note exists in markers list.
                // If not, add it.
                var found = false;
                _.each(markers, function(marker) {
                    if (marker.id == id) {
                        found = true;
                    }
                });

                if (!found) {
                    markers.push(note);
                }
            }

            store.set('calendar.markers', JSON.stringify(markers));
        },

        remove: function() {
            $(window).off('resize');
            Backbone.View.prototype.remove.apply(this, arguments);
        },

		expandChildren: function(options) {
        	if (this.model.get('multi')) {
        		// Only expand the children that are showing
				this.collection.each(function(row) {
					if (row.get('parent') == options.parent && row.get('rowInUse') && $('#switchChartRow' + row.get('chartLineId')).is(':checked')) {
						$(this.el).find('[data-id=' + row.get('chartLineId') + ']').show();
					}
				}.bind(this));
			} else {
				this.collection.each(function (row) {
					if (row.get('parent') == options.parent && row.get('rowInUse')) {
						$(this.el).find('[data-id=' + row.get('chartLineId') + ']').show();
					}
				}.bind(this));
			}

			var currentRow = this.collection.findWhere({
				chartLineId: options.parent
			});

			if (currentRow) {
				$(this.el)
					.find('[data-id=' + currentRow.get('chartLineId') + ']')
					.find('.child')
					.hide();
			}

			State.rememberExpand(this.model.get('chartMeta').installationId, currentRow.get('chartLineId'));

			this.recalculateOverlay();

			RectMap.recalculate();
		},

		collapseChildren: function(options) {
			this.collection.each(function(row) {
				if (row.get('parent') == options.parent && row.get('rowInUse')) {
					$(this.el).find('[data-id=' + row.get('chartLineId') + ']').hide();
				}
			}.bind(this));

			var currentRow = this.collection.findWhere({
				chartLineId: options.parent
			});

			if (currentRow) {
				$(this.el)
					.find('[data-id=' + currentRow.get('chartLineId') + ']')
					.find('.child')
					.show();
			}

			State.rememberCollapse(this.model.get('chartMeta').installationId, currentRow.get('chartLineId'));

			this.recalculateOverlay();

			RectMap.recalculate();
		},

		/*
			Console Utilities
		 */
		goTo: function(timestamp, zoom) {
			window.ajaxDone = false;

			// Timestamp should be in format 2016-08-26 00:00:00
			// Zoom should be a valid zoom level.

			// Validation
			timestamp = new Moment(timestamp);
			if (!timestamp) {
				console.error('Timestamp must be in format YYYY-MM-DD HH:mm:ss');
				return;
			}

			if (this.zoomLevels.indexOf(zoom) == -1) {
				console.error('Zoom is not a valid zoom level.');
				return;
			}

			// Action
			if (this.model.get('multi')) {
				Vent.trigger('newchart:multi-navigate', {
					slot: this.model.get('slot'),
					date: timestamp,
					zoom: zoom,
					nightView: this.model.get('nightView')
				});
			} else {
				Vent.trigger('newchart:navigate', {
					date: timestamp,
					zoom: zoom,
					note: {
						subject: this.ui.noteSubjectField.val(),
						message: this.ui.noteMessageField.val()
					}
				});
			}
		},

		getHtml: function(first) {
			var html = this.multiPrint(),
				body = $('body').append('<div id="raw-html" style="display: none;"><div id="charts"></div></div>'),
				noteHtml = '';

			// Wrap in a frame.
			var page = $('#page-main').clone();
			page.find('#body > div').html(html);

			// Move the print note box into the right place.
			if (page.find('#printnote').length > 0) {
				noteHtml = page.find('#printnote')[0].outerHTML;
				page.find('#printnote').remove();
			}

			if (!first) {
				page.find('#chart-header').remove();
			}

			var dateHeader = this.templateHelpers.generateChartDate.bind(this.model.attributes);

			page.find('#body > div').prepend('<h3>' + dateHeader() + '</h3>');

			page = page.html();

			if (noteHtml && noteHtml != '') {
				page += noteHtml;
			}

			$('body').find('#raw-html').text(page);
		}

    });

});
