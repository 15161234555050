/**
Login Application
@module Login
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Module method
		* API
		* Start Event
Author(s):
  * Just Checking
*/

define('app/modules/logout/logout.module',[

  'marionette',
  'app/modules/logout/logout.router',
  'app/modules/logout/controllers/logout.controller'

], function(Marionette, Router, Controller) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			LogoutApp = App.module('LogoutApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				/**
				@property API
				*/
				var API = {

					/*
					Get Login
					*/

					logout: function() {

						// Create a new controller
						LogoutApp.controller = new Controller();

					}

				};

				/**
				Start Event
				@event start
				*/
				this.on('start', function(hash) {

					// Start the router for this sub app
					LogoutApp.router = new Router({

						controller: API

					});

				});

			});

			return LogoutApp;

		}

	};

});

