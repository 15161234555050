define('app/commands/commands.generatePDF',[

	'backbone',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'utils',
	'app/modules/newcharts/models/chart.model',
	'moment'

], function(Backbone, Commands, Requests, Utils, ChartModel, Moment) {

	'use strict';

	var PDFTemplate = _.template('<!DOCTYPE html>' +
		'<html class="print">' +
		'<head>' +
			'<link rel="stylesheet" href="<%= mainCSS %>">' +
		'</head>' +
		'<body class="print">' +
			'<%= html %>' +
			'<div class="generated-text">' +
				'<p>Generated: <%= generated %> | Chart: <%= chartTitle %> | System: <%= systemNum %></p>' +
			'</div>' +
		'</body>' +
		'</html>');

	function generatePDFHtml(model, html) {
		return PDFTemplate({
			mainCSS: document.querySelector("link[href*='.css']").href,
			html: html,
			generated: new Moment().format('LLL'),
			chartTitle: model.get('chartMeta').chartTitle,
			systemNum: model.get('chartMeta').systemNo
		});
	}

	function submitPost(params) {
		var url = '/api/v1/Generate/PDF',
			html = "<form action='" + url + "' method='post' style='display: none;'>";

		_.each(params, function(value, key) {
			if (key == 'html') {
				value = value.replace(/'/g, "\'").replace(/'/g, "&apos;");
				value = encodeURI(value);
			}
			html += "<input type='hidden' name='" + key + "' value='" + value + "' />";
		});

		html += "</form>";

		$(html).appendTo('body').submit().remove();
	}

	return {

		initialize: function() {

			return Commands.setHandler('generate:pdf', function(options, callback) {
				options = options || {};
				_.defaults(options, {
					installId: null,
					html: '',
					orientation: 'landscape',
					zoom: 1
				});

				if (!options.installId) {
					throw 'You must specify an installation ID.';
				}

				// Firstly, gather information about the installation.
				var model = new ChartModel({
					_id: options.installId
				});

				model.fetch({
					success: function(model) {
						// Next, generate the PDF HTML template
						options.html = generatePDFHtml(model, options.html);

						// Send off to the PDF writer to initiate download
						submitPost(_.omit(options, 'installId'));

						// Call callback
						if (callback) callback();
					}.bind(this)
				});
			});
		}
	};
});
