/**
Application Layout - Menu ItemView
@module Layout
@submodule Layout.Views.ItemView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Commands
Contents:
  * Menu Item View
		* template
		* tagName
		* events
		* navigate
		* menuClick
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.header.menu.item',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.header.menu.item.tmpl',
	'app/commands/commands.vent',
  'app/layout/models/layout.models.header.menu.model'

], function(Marionette, Template, Commands, Model) {

  'use strict';

  /**
  Menu ItemView
  @class Layout.Views.ItemView
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @property tagName
    @type String
    */
    tagName: 'li',

    /**
    @property className
    @type String
    */
    className: function() {

      var
      classValue = this.model.get('class'),
      mobileVisible = this.model.get('mobile'),
      returnClass = this.model.get('alignment');

      // Has a class been supplied?
      if (classValue) {

        // Add the class with a space between
        returnClass += ' ' + this.model.get('class');

      }

      // Is this item mobile friendly?
      if (!mobileVisible) {

        // Add the class with a space between
        returnClass += ' show-for-large-up';

      }

      return returnClass;

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click .top-menu-item': 'menuClick',
      'click .dropdown-menu a': 'subMenuClick'

    },

    /**
    @method navigate
    @param e {Object} The event object
    */
    navigate: function(model) {

      // Navigate the app to the route
      Commands.execute('app:navigate', {

        route: model.get('url')

      });

    },

    /**
    @method menuClick
    @param e {Object} The event object
    */
    menuClick: function(e) {

      // Is there a default action for this item?
      if (!this.model.get('action')) {

        // Navigate using the model data
        this.navigate(this.model);

        e.preventDefault();

      }

    },

    /**
    @method subMenuClick
    @param e {Object} The event object
    */
    subMenuClick: function(e) {

      var
      // Detect position of clicked item
      position = $(e.currentTarget).parent().attr('data-id'),
      // Get the clicked item's data
      modelData = this.model.get('subMenu')[position],
      // Create a new model using the data
      subModel = new Model(modelData);

      // Is there a default action for this item?
      if (!subModel.get('action')) {

        // Navigate using the model data
        this.navigate(subModel);

        e.preventDefault();

      }

    }

  });

});

