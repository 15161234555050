/**
Chart Application - Main Layout
@module Chart
@submodule Chart.Layouts.Main
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Layout
    * template
    * regions
Author(s):
  * Just Checking
*/

define('app/modules/charts/layouts/charts.layouts.main',[

  'marionette',
  'tpl!app/modules/charts/templates/charts.templates.main.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Main Layout
  @class Chart.Layouts.Main
  @constructor
  @extends Backbone.Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    Create the regions for the desktop and mobile menus
    @property regions
    @type Object
    */
    regions: {

      menu: '#chart-menu',
      chart: '#chart',
      controls: '#chart-controls'

    }

  });

});

