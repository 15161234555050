define('app/modules/newcharts/views/chart-menu.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/chart-menu.template.tmpl',
    'moment'

], function(Marionette, Vent, Requests, Template, Moment) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            prevDayBtn: '#prev-day-btn',
            nextDayBtn: '#next-day-btn'
        },

        events: {
            'click @ui.prevDayBtn': 'previousDay',
            'click @ui.nextDayBtn': 'nextDay'
        },

        onShow: function() {
            var chartDate = new Moment(this.model.get('chartMeta').chartDate),
                installationStart = new Moment(this.model.get('chartMeta').installationStart).startOf('day'),
                installationEnd = new Moment(this.model.get('chartMeta').installationEnd).startOf('day');

            if (new Moment(chartDate).subtract(1, 'day').isBefore(installationStart)) {
                this.ui.prevDayBtn.attr('disabled', 'disabled');
            }
            if (new Moment(chartDate).add(1, 'day').isAfter(installationEnd)) {
                this.ui.nextDayBtn.attr('disabled', 'disabled');
            }
        },

        previousDay: function(e) {
            var date = new Moment(this.model.get('chartMeta').chartDate).subtract(1, 'days');

            this.checkNoteAndNavigate(date);
        },

        nextDay: function(e) {
            var date = new Moment(this.model.get('chartMeta').chartDate).add(1, 'days');

            this.checkNoteAndNavigate(date);
        },

        checkNoteAndNavigate: function(date) {
            Vent.trigger('newchart:check-note', function() {
                Vent.trigger('newchart:navigate', {
                    date: date,
                    zoom: this.model.get('chartMeta').zoom
                });
            }.bind(this));
        }

    });

});
