/**
Application Behaviors - Calendar
@module Behaviors
@submodule Behaviors.Calendar
*/

/*
Requires:
  * jQuery
	* Backbone
	* Marionette
  * Underscore
  * Commands
  * Requests
  * Mobiscroll
  * Moment
Contents:
	* Calendar
    * defaults
    * onShow
Author(s):
  * Just Checking
*/

define('app/behaviors/behaviors.calendar',[

	'backbone',
	'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'mobiscroll',
  'moment'

], function(Backbone, Marionette, Commands, Requests, Mobiscroll, Moment) {

  'use strict';

  /**
	Calendar
	*/
	return Marionette.Behavior.extend({

    /**
    @property defaults
    */
    defaults: {

      'showTime': false,
      'showDate': false,
      'showNow': false

    },

    /**
    @method onShow
    */
    onShow: function() {

      var
      that = this,
      elements = this.$('[data-calendar="true"]');

      // For each element
      _.each(elements, function(element) {

        var
        $element = $(element),
        modelAttribute = $element.prop('name'),
        setDate = $element.attr('data-calendar-date'),
        //maxDate = Moment(new Date()),
        maxDate = $element.attr('data-calendar-max'),
        minDate = $element.attr('data-calendar-min'),
        controls = ['calendar'];

        /*
        Controls
        */

        // Has date been requested?
        if (that.options.showDate) {

          // Add date to the controls array
          controls.push('date');

        }

        // Has time been requested?
        if (that.options.showTime) {

          // Add time to the controls array
          controls.push('time');

        }

        /*
        Init
        */

        // Attach a datepicker to the date picker input
        $element.mobiscroll().calendar({



          maxDate: (maxDate) ? new Moment(maxDate).toDate() : new Moment().add('y', 100).toDate(),
          minDate: (minDate) ? new Moment(minDate).toDate() : new Moment().subtract('y', 100).toDate(),
          controls: controls,
          firstDay: 1,
          showNow: that.options.showNow,
		  marked: [],
		  markedDisplay: 'bottom',
		  markedText: false,
          onSelect: function(valueText, inst) {

            // Has a onSelect function been supplied?
            if (that.options.onSelect) {

              // Call the function and send it all it needs
              that.options.onSelect(valueText, inst, that.view, modelAttribute, $element);

            }

          },
		  onMonthLoaded: function(year, month, inst) {
			  if (that.options.onMonthLoaded) {
				  that.options.onMonthLoaded(year, month, inst);
			  }
		  },
          onShow: function(html, valueText, inst) {
              if (that.options.onShow) {
                  that.options.onShow(html, valueText, inst);
              }
          }

        });

        // Has a set date been supplied?
        if (setDate) {

          // Set the initial date
          $element.mobiscroll('setDate', new Moment($element.attr('data-calendar-date')).toDate(), true);

        }

      });

    }

	});

});

