/**
Users Application - Router
@module UsersApp
@submodule UsersApp.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/users/settings.users.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  /**
  Router
  @class UsersApp.Router
  @constructor
  @extends Marionette.AppRouter
  */
  return Marionette.AppRouter.extend({

		/**
		@property appRoutes
		*/
    appRoutes: {

      'systems/:systemId/charts/:installationId/users': 'showEmptyForm',
      'systems/:systemId/charts/:installationId/users/:id': 'getById',
      'systems/:systemId/charts/:installationId/date/:date/zoom/:zoomLevel/users': 'showEmptyForm'
      //'systems/:systemId/charts/:installationId/users/:id/*panel': 'getById'

    }

  });

});

