/**
Login Application - Form View
@module Login
@submodule Login.Views.Form
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Login Form View
    * template
Author(s):
  * Just Checking
*/

define('app/modules/logout/views/logout.views.form',[

  'marionette',
  'app/modules/newcharts/state',
  //'tpl!app/modules/logout/templates/logout.templates.form.tmpl'

], function(Marionette, State/*, Template*/) {

  'use strict';

  /**
  Login Form View
  @class Login.Views.Form
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    initialize: function(){
      var that = this;
      // that.logoutUser();
      State.destroyToken()
          .then(function() {
              console.log("success .... normal logout")
              that.logoutUser();
          })
          .catch(function(err) {
              console.log("error .... kill login")
              that.logoutUser();
          });

    },

    /**
    @property template
    @type String
    */
   // template: Template,

    logoutUser: function(){

      var url = '/api/v1/Account/session';

      $.ajax({
        url:url,
        type:'DELETE',
        dataType:"json",
        success: function(){
          //TODO: navigate to the last page being viewed before 401 was thrown
          //reload the app from the start
          alert('You have been successfully logged out');
          window.location.href = '/';
        },
        error: function(error){
          alert('You have been successfully logged out');
          window.location.href = '/';
        }
      });

       //e.preventDefault();
    }

  });

});

