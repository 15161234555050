/**
Systems Installation Model
@module Systems
@submodule Systems.Models.Installation
*/

/*
Requires:
  * Backbone
  * jQuery
  * Underscore
  * Mutators
Contents:
  * Systems Installation Model
		* idAttribute
    * urlRoot
    * statusClassLookups
    * statusTextLookups
    * mutators
Author(s):
  * Just Checking
*/

define('app/modules/systems/models/systems.models.installation',[

  'backbone',
  'backbone.mutators'

], function(Backbone, Mutators) {

  'use strict';

  /**
  Systems Installation Model
  @class Systems.Models.Installation
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

		/**
    @property idAttribute
    @type String
    */
    idAttribute: 'installationId',

    /**
    @property urlRoot
    @type String
    */
    urlRoot: 'Installations',

    /**
    @property statusClassLookups
    @type Object
    */
    statusClassLookups: {

      live: 'icon-ok text-green',
      lost: 'icon-attention text-orange',
      ended: 'icon-cancel text-red'

    },

    /**
    @property statusTextLookups
    @type Object
    */
    statusTextLookups: {

      live: 'Installation working correctly',
      lost: 'Not updated in the last 24 hours',
      ended: 'Installation has ended'
    },

    /**
    @property mutators
    @type Object
    */
    mutators: {

      // Get the status classes
      statusClass: function() {

        // Only if we have a status value
        if (this.get('status')) {

          return this.statusClassLookups[this.get('status')];

        }

      },

      // Get the status text
      statusText: function() {

        // Only if we have a status value
        if (this.get('status')) {

          return this.statusTextLookups[this.get('status')];

        }

      },

      // Build an evaluation link
      //evaluationLink: function() {
      //
      //  // Only if we have a status value
      //  if (this.get('evaluationStatus')) {
      //
      //    return 'https://static.justchecking.com/back_office/s_doc.asp?system=' + this.get('systemId') + '&installation=' + this.get('installationNumber');
      //
      //  }
      //
      //}

    },

      defaults: {
          "showEvaluationStatus": false,
          "evaluationStatus": ""
      }

  });

});

