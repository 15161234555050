define('app/modules/newcharts/features/carerVisits/models/carerVisits.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		url: function() {
			return 'api/v1/CarerVisits/' + this.id + '/meta';
		},

		defaults: {
			offset: 0,
			limit: 10,
			sort: 'date',
			dir: 'desc'
		}
	});

});
