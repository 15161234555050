/**
Application Layout - Menu Model
@module Layout
@submodule Layout.Models.Menu
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Menu Model
		* defaults
Author(s):
  * Just Checking
*/

define('app/layout/models/layout.models.header.menu.model',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Menu Model
  @class Layout.Models.Menu
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

		/**
    @property defaults
    @type Object
    */
    defaults: {

			alignment: 'left'

    }

  });

});
