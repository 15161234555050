
define('app/modules/systems/models/feedback.form',[
    'backbone'
], function(Backbone) {

    return Backbone.Model.extend({

        defaults: {
            name: "",
            emailAddress: "",
            telephoneNumber: "",
            suggestion: "",
            canContact: false
        },

        // This is the route to the api - this is where the request will go when backbone syncs the model with the server
        urlRoot: function() {
            return 'FeedbackEntries';
        }

    });

});
