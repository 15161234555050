/**
Help Application
@module Help
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Module method
		* API
		* Start Event
Author(s):
  * Just Checking
*/

define('app/modules/help/help.module',[

  'marionette',
  'app/modules/help/help.router',
  'app/modules/help/help.controller'

], function(Marionette, Router, Controller) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			HelpApp = App.module('HelpApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				/**
				@property API
				*/
				var API = {

					/*
					Get Help
					*/

					getHelp: function() {

						// Create a new controller
						HelpApp.controller = new Controller();

					}

				};

				/**
				Start Event
				@event start
				*/
				this.on('start', function(hash) {

					// Start the router for this sub app
					HelpApp.router = new Router({

						controller: API

					});

				});

			});

			return HelpApp;

		}

	};

});

