/**
Application Layout - Title ItemView
@module Layout
@submodule Layout.Views.Title
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Commands
Contents:
  * Title ItemView
    * template
    * serializeData
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.main.header.subtitle',[

  'marionette',
  'app/commands/commands.vent',
  'tpl!app/layout/templates/layout.templates.subtitle.tmpl'

], function(Marionette, Commands, Template) {

  'use strict';

  /**
  Title ItemView
  @class Layout.Views.Title
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @method serializeData
    */
    serializeData: function(options) {

      return {

        subtitle: this.options.subtitle

      };

    }

  });

});

