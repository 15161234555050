define('app/commands/commands.evaluationLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/modules/evaluation/views/views.lightboxView','app/modules/evaluation/models/evaluation.models.home','app/app'],function (require) {
	'use strict';

	var Commands = require('app/commands/commands.vent'),
		Requests = require('app/requests/requests.vent'),
		Lightbox = require('app/modules/evaluation/views/views.lightboxView'),
		Model 	 = require('app/modules/evaluation/models/evaluation.models.home');

		return {
			initialize: function() {

				return Commands.setHandler('app:EvaluationLightbox', function(options) {
					var App = require('app/app'),
                        cacheinstall = options.requestData.installationId,
                        disableClose = false,
                        greyAssessmentEnd = false,
                        html = $('html'),
                        body = $('body');

                    // Scroll the window to the top
                    if (html.scrollTop()) {
                        html.animate({ scrollTop: 0 });
                    } else if(body.scrollTop()) {
                        body.animate({ scrollTop: 0 });
                    }

                    if (options.requestData.disableClose) {
                        disableClose = true;
                    }
                    if (options.requestData.greyAssessmentEnd) {
                        greyAssessmentEnd = true;
                    }

                    store.set('disableClose', disableClose);
                    store.set('greyAssessmentEnd', greyAssessmentEnd);

					var model = new Model({
						_id: cacheinstall
					});

					App.lightbox.show(new Lightbox({
						model:model
					}));
				});
			},
		};
});




