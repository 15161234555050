define('app/modules/newcharts/features/notifications/collections/notifications.collection',[
	'backbone',
	'app/modules/newcharts/features/notifications/models/notification.model'
], function(Backbone, Model) {
	'use strict';

	return Backbone.Collection.extend({

		model: Model,

		url: function() {
			return 'api/v1/Alerts/' + this.models[0].id;
		}
	});

});
