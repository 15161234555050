/**
Application Components - Notifications ItemView
@module Components
@submodule Components.Notifications.ItemView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Notification Item View
    * template
    * className
    * events
    * onRender
    * navigate
Author(s):
  * Just Checking
*/

define('app/components/notifications/views/components.notifications.views.item',[

  'marionette',
  'tpl!app/components/notifications/templates/components.notifications.templates.item.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Notification Item View
  @class ChartApp.Notification.Item
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property className
    @type Function
    */
    className: function() {

      var classes = 'notification notification-' + this.model.get('notificationType');

      // Is this a global notification?
      if (!this.model.get('global')) {

        classes += ' notification-inline';

      }
      else {

        classes += ' notification-global';

      }

      // Do we have a link to send the user to?
      if (this.model.get('link')) {

        classes += ' cursor-pointer';

      }

      return classes;
      
    },

    /**
    @property events
    @type Object
    */
    events: {

			'click': 'navigate'

    },

    /**
    @method onRender
    */
    onRender: function() {

      /*
      Page Wrapper Adjustments
      */

      // Is this a global notification?
      if (this.model.get('global')) {

        // Add a class to allow for a notification
        $('#page-wrapper').addClass('page-wrapper-notification');

      }

    },

    /**
		@method navigate
		*/
    navigate: function(e) {

      // Do we have a link to send the user to?
      if (this.model.get('link')) {

        // TODO: Navigate to supplied link
        console.log(this.model.get('link'));

      }
      else {

        return;

      }

      e.preventDefault();

    }

  });

});

