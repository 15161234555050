/**
Application Commands - Set Alert Enabled Users
@module Commands
@submodule Commands.SetAlertEnabledUsers
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
Contents:
	* Module Initialize
		* Set Alert Enabled Users
Author(s):
  * Just Checking
*/

define('app/commands/commands.editUsersAlert',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Set Alert Enabled Users
			@event edit:users:alert
			@param state {Boolean} Value to set the global variable to
			*/
			return Commands.setHandler('edit:users:alert', function(options) {

				var App = require('app/app');

				App.state.alertEnabledUsers = options.state;

			});

    }

  };

});

