/**
Application Layout - Subtitle ItemView
@module Layout
@submodule Layout.Views.Subtitle
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
	* Commands
Contents:
  * Subtitle ItemView
    * template
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.main.header.title',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.main.header.title.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Subtitle ItemView
  @class Layout.Views.Subtitle
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type Template
    */
    template: Template

  });

});

