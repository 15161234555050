/**
Application Components - Pagination Model
@module Components
@submodule Components.Pagination.Model
*/

/*
Requires:
  * Backbone
Contents:
  * Paging Model
		* defaults
Author(s): 
  * Just Checking
*/

define('app/components/paging/components.paging.model',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Paging Model
  @class Paging.Model
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    Model default values
    @property defaults
    @type Object
    */
    defaults: {
      'pageNumber': 1,
      'pageCount': 1,
      'pageSize': 8,
      'category': null
    }

  });

});
