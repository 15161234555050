/**
Application Layout - Main Layout
@module Layout
@submodule Layout.Layouts.Main
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Layout Main View
		* Template
		* idAttribute
    * className
    * regions
Author(s):
  * Just Checking
*/

define('app/layout/layouts/layout.layouts.main',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.main.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  Main Layout
  @class Layout.Layouts.Main
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property idAttribute
    @type String
    */
    idAttribute: 'content-wrapper',

    /**
    @property className
    @type String
    */
    className: 'content-wrapper',

    /**
    Set the regions within this layout
    @property regions
    @type Object
    */
    regions: {

      notifications: '#page-main-content-notifications',
      header: '#page-main-content-header',
      content: '#page-main-content'

    }

  });

});

