/**
System Detail View
@module Systems
@submodule Systems.Views.Detail
*/

/*
Requires:
	* Backbone
	* Marionette
	* jQuery
	* Underscore
Contents:
	* System Detail View
		* template
		* ui
		* bindings
		* events
		* triggers
		* behaviors
		* initialize
		* onRender
		* navigateToChart
		* navigateToAlertSettings
		* navigateToEdit
		* navigateToUsers
		* buildTargetRoute
		* navigate
		* endInstallation
Author(s):
	* Just Checking
*/

define('app/modules/systems/views/systems.views.detail.system',[

	'marionette',
	'tpl!app/modules/systems/templates/systems.templates.detail.system.tmpl',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'app/behaviors/behaviors.calendar',
	'app/behaviors/behaviors.showCalendar',
	'mobiscroll',
	'moment',
	'backbone.modelbinder',
    'app/behaviors/behaviors.tooltip'

], function(Marionette, Template, Vent, Commands, Requests, Calendar, ShowCalendar, Mobiscroll, Moment, ModelBinder, Tooltip) {

	'use strict';

	/**
	System Detail View
	@class Systems.Views.Detail
	@constructor
	@extends Marionette.ItemView
	*/
	return Marionette.ItemView.extend({

		/**
		@property template
		@type String
		*/
		template: Template,

		/**
		@property ui
		@type Object
		*/
		ui: {
			installationStartInput: '#system-start-date-input',
			installationStartButton: '#system-start-date-button',
			installationEndInput: '#system-end-date-input',
			installationEndButton: '#system-end-date-button',
			viewChartButton: '#systems-detail-view-button',
			viewEditButton: '#systems-detail-edit-button',
			viewUsersButton: '#systems-detail-users-button',
			endButton: '#systems-detail-end-button',
			createButton: '#systems-detail-create-button',
			alertsButton: '#system-alert-settings-button',
			teamButton: '#system-team-settings-button',
			evalFormBtn: '.evalformbtn',
			printBtn: '.print'
		},

		/**
		@property bindings
		@type Object
		*/
		bindings: function() {
			return {
				installationStart: [{
					selector: '#system-start-date',
					converter: this.templateHelpers().formatDate
				}],
				installationEnd: [{
					selector: '#system-end-date',
					converter: this.templateHelpers().formatDate
				}]
			};
		},

		/**
		@property events
		@type Object
		*/
		events: {
			'click @ui.viewChartButton': 'navigateToChart',
			'click @ui.viewEditButton': 'navigateToEdit',
			'click @ui.viewUsersButton': 'navigateToUsers',
			'click @ui.endButton': 'endInstallation',
			'click @ui.alertsButton': 'navigateToAlertSettings',
			'click @ui.evalFormBtn' : 'loadEvaluations',
			'click @ui.printBtn': 'print'
		},

		/**
		@property triggers
		@type Object
		*/
		triggers: {
			'click #systems-detail-create-button': 'create:installation'
		},

		/**
		@property behaviors
		@type Object
		*/
		behaviors: {
			ShowCalendar: {
				behaviorClass: ShowCalendar
			},

			Calendar: {
				behaviorClass: Calendar,
				showDate: true,
				showTime: true,
				showNow: true,
				onSelect: function(valueText, inst, view, modelAttribute) {
					var
					map = {},
					UTCFormat = Requests.request('get:state:data').get('utcFormat');

					// Map the new value to supplied model attribute
					map[modelAttribute] = new Moment(valueText).format(UTCFormat);

					// Set the new value
					view.model.set(map);

					// Trigger the installation save event
					view.trigger('save:installation');
				}
			},

            Tooltip: {
                behaviorClass: Tooltip
            }
		},

		initialize: function() {
			// Init a model binder
			this.modelBinder = new Backbone.ModelBinder();
			// Listen to the add event and re-render
			this.listenTo(this.model, 'change:status', this.render, this);
		},

		onRender: function() {
			// Bind the model to the UI
		 	this.modelBinder.bind(this.model, this.el, this.bindings());
		},

		navigateToChart: function(e) {
			// Build the route
            var route;
            if (this.model.get('chartVersion') == 2) {
                route = Requests.request('get:current:route') + '/newcharts/' + this.model.get('installationId');
            } else {
                route = this.buildTargetRoute();
            }
			// Go to the route
			this.navigate(route);
			e.preventDefault();
		},

		navigateToAlertSettings: function(e) {
			// Build the route
			var route = this.buildTargetRoute();
			// Add the alert settings bit
			route += '/alerts-settings';
			// Go to the route
			this.navigate(route);
			e.preventDefault();
		},

        loadEvaluations: function(e) {
            Commands.execute('app:EvaluationLightbox', {
                requestData: {
                    installationId: $(e.target).data('id')
                }
            });
        },

		navigateToEdit: function(e) {
			// Build the route
			var route = this.buildTargetRoute();
			// Add the edit bit
			route += '/edit';
			// Go to the route
			this.navigate(route);
			e.preventDefault();
		},

		navigateToUsers: function(e) {
			// Build the route
			var route = this.buildTargetRoute();
			// Add the users bit
			route += '/users';
			// Go to the route
			this.navigate(route);
			e.preventDefault();
		},

		buildTargetRoute: function() {
			// Get the current route
			var currentRoute = Requests.request('get:current:route');
			// Add the current installation
			currentRoute += '/charts/' + this.model.get('installationId');
			return currentRoute;
		},

		navigate: function(route) {
			// Navigate the app to the route
			Commands.execute('app:navigate', {
				route: route
			});
		},

		endInstallation: function(e) {
			// Check they really want to end it all
			if (window.confirm('Are you sure you want to end this installation?')) {
				// Set the model's status to ended
				this.model.set({
					status: 'ended',
					p_createInstallation: true,
                    p_endInstallation: false
				});
				// Trigger the installation save event
				this.trigger('save:installation', { ended: true });
			}
			e.preventDefault();
		},

		print: function(e) {
			e.preventDefault();

			Commands.execute('app:printLightbox', {
				installationId: this.model.get('installationId')
			});
		}

	});
});

