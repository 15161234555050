/**
Application Layout - Topbar Button ItemView
@module Layout
@submodule Layout.Views.Topbar.Button
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
	* Commands
Contents:
  * Menu Item View
		* template
		* events
		* initialize
		* navigate
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.header.menu.topbar.button',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.header.menu.topbar.button.tmpl',
	'app/commands/commands.vent'

], function(Marionette, Template, Commands) {

  'use strict';

  /**
  Topbar Button ItemView
  @class Layout.Views.Topbar.Button
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @property events
    @type Object
    */
    events: {

      'click a': 'navigate'

    },

    /**
    @method initialize
    */
    initialize: function() {

      // listen for any change events
      this.listenTo(this.model, 'change', this.render, this);

    },

    /**
    @method navigate
    */
    navigate: function(e) {

      // Has a command been supplied?
      if (this.model.get('command')) {

        // Execute the supplied command
        Commands.execute(this.model.get('command'));

      }
      else {

        // Execute the defaul command (Go back)
        Commands.execute('app:navigate:back');
        
      }

      e.preventDefault();

    }

  });

});

