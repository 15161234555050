/**
Application Layout - Topbar Title ItemView
@module Layout
@submodule Layout.Views.Topbar.Title
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
	* Commands
Contents:
  * Menu Item View
		* template
		* initialize
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.header.menu.topbar.title',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.header.menu.topbar.title.tmpl',
	'app/commands/commands.vent'

], function(Marionette, Template, Commands) {

  'use strict';

  /**
  Topbar Title ItemView
  @class Layout.Views.Topbar.Title
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @method initialize
    */
    initialize: function() {

      // listen for any change events
      this.listenTo(this.model, 'change', this.render, this);

    }

  });

});

