/**
Application Logins - Collection
@module Logins
@submodule Logins.Collection
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Logins Collection
    * urlSource
    * model
Author(s):
  * Just Checking  
*/

define('app/modules/logins/logins.collection',[

  'backbone',
  'app/modules/logins/logins.model'

], function(Backbone, Model) {

  'use strict';

  /**
  Logins Collection
  @class Logins.Collection
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property urlSource
    @type String
    */
    urlSource: 'Logins',

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model

  });

});
