/**
Chart Application - Edit Row
@module Chart
@submodule Chart.Views.Edit.Row
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Edit Row
    * template
    * tagName
    * attributes
    * ui
    * events
    * removeRow
    * setModel
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.edit.carers.item',[

  'marionette',
  'app/app.vent',
  'tpl!app/modules/charts/templates/charts.templates.edit.carers.item.tmpl',
  'app/commands/commands.vent',
  'bootstrap.popover'

], function(Marionette, Vent, Template, Commands, Popover) {

  'use strict';

  /**
  Edit Row
  @class Chart.Views.Edit.Row
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property tagName
    @type String
    */
    tagName: 'li',

    /**
    @property attributes
    @type Object
    */
    attributes: function() {

      // Add the target date to the button as a data-* attribute
      return {

        'id': 'chartRow' + this.model.get('chartLineId'),
        //'class': 'selected-row',
        'data-id': this.model.get('chartLineId')

      };

    },

    /**
    @property ui
    @type Object
    */
    ui: {

      removeButton: '.chart-edit-row-remove-button',
      labelInput: 'input',
      autosensor: '.autocompleteName',
	  sensitivity: 'input.sensitivity'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click @ui.removeButton': 'removeRow',
      'blur @ui.labelInput': 'setModel'

    },

    onRender: function() {
      this.ui.autosensor.autocomplete({
        autoFocus: true,
        delay: 50,
        source: [
          'Back Bedroom',
          'Back Door',
          'Back Room',
          'Bathroom',
          'Bathroom Door',
          'Bedroom',
          'Conservatory',
          'Dining Room',
          'Downstairs Bathroom',
          'Downstairs Landing',
          'Downstairs Toilet',
          'Ensuite',
          'Fridge',
          'Front Bedroom',
          'Front Door',
          'Front Lounge',
          'Front Room',
          'Hallway',
          'Kitchen',
          'Kitchen Door',
          'Landing',
          'Landing Downstairs',
          'Landing Upstairs',
          'Living Room',
          'Lounge',
          'Office',
          'Patio Door',
          'Porch',
          'Side Door',
          'Spare Bedroom',
          'Spare Room',
          'Staff Door',
          'Staff Room',
          'Stairs',
          'Study',
          'Toilet',
          'Toilet Downstairs',
          'Toilet Upstairs',
          'Upstairs Landing',
          'Utility Room',
        ],
        select: function(event, ui) {
            // On tab events, take the highlighted item and populate the drop
            // down, but do not advance to he next form component.
            var TABKEY = 9;
            event.target.value = ui.item.value;

            if (event.keyCode == TABKEY) {
              event.preventDefault();
              event.target.focus();
            }

            return false;
        }
      });

      var handle = this.$el.find('.slider > .handle');
      this.$el.find('.slider').slider({
		  min: 1,
		  create: function() {
		  	var sensitivity = this.model.get('sensitivity');

		  	if (sensitivity === null) {
		  		sensitivity = 100;
			}

		  	handle.css('left', sensitivity + '%').text(sensitivity);
		    this.ui.sensitivity.val(sensitivity);
		  }.bind(this),
		  slide: function(event, ui) {
		  	if (ui.value === null) {
		  		ui.value = 100;
			}

		  	handle.text(ui.value);
		  	this.ui.sensitivity.val(ui.value);
		  }.bind(this)
	  });
    },

    /**
    @method removeRow
    */
    removeRow: function(e) {
      // Make sure they want to remove this row
      if (this.options.meta.get('canAccessAlerts')) {
		  Commands.execute('app:confirmModal', {
			  message: 'Removing this sensor may deactivate one or more notifications.<br />Do you wish to proceed?',
			  confirmText: 'Yes, remove this sensor',
			  cancelText: 'No, do not remove',
			  confirmCallback: function() {
				  this.trigger('disable:carer');
			  }.bind(this)
		  });
	  } else {
		  if (window.confirm('Remove the ' + this.model.get('chartLineLabel') + ' sensor from the chart?')) {
		    // Trigger the disable row event
		    this.trigger('disable:carer');
		  }
	  }
      e.preventDefault();
    },

    /**
    @method setModel
    */
    setModel: function(e) {
      // Update the model
      this.model.set({

        chartLineLabel: this.ui.labelInput.val()
      },
      {
        silent: true
      });
      e.preventDefault();
    }
  });
});

