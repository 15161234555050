/**
Application Components - Notifications Model
@module Components
@submodule Components.Notifications.Model
*/

/*
Requires:
  * Backbone
Contents:
  * Notification Model
    * idAttribute
    * defaults
Author(s):
  * Just Checking
*/

define('app/components/notifications/components.notifications.model',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Notification Model
  @class Models.Notification
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property idAttribute
    @type String
    */
    idAttribute: 'notificationId',

    /**
    @property defaults
    @type Object
    */
    defaults: {

      'notificationType': 'warning',
      'title': null,
      'message': null,
      'link': null

    }

  });

});

