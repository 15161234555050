/**
Users Application
@module UsersApp
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
  * Template
  * Vent
  * Commands
  * Router
  * Controller
Contents:
	* Module Initialize
		* Start with parent
		* API
		* Start Event
		* New user request
		* Show a user
		* Check for an enabled user
Author(s):
  * Just Checking
*/

define('app/modules/users/settings.users.module',[

  'marionette',
  'tpl!app/modal/templates/modalFormChanges.tmpl',
  'app/app.vent',
  'app/commands/commands.vent',
  'app/modules/users/settings.users.router',
  'app/modules/users/settings.users.controller'

], function(Marionette, TemplateModalFormChanges, Vent, Commands, Router, Controller) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			var
			App = require('app/app'),
			UsersApp = App.module('UsersApp', function() {

				// prevent starting with parent
				this.startWithParent = false;

				/**
				@property API
				*/
				var API = {

					/*
					Get the list of items
					*/
					getList: function(callback) {

						UsersApp.controller.getList(callback);

					},

					/*
					Get an item by its id
					*/
					getById: function(systemId, installationId, id) {

						//check if the controller has already been started if not this was a direct browser url request to view a specific users details
						if(!UsersApp.controller){
							UsersApp.controller = new Controller();
						}

						UsersApp.controller.getById(id);



					},

					/*
					Show the list of users
					*/
					showList: function(collection) {

						UsersApp.controller.showList(collection);

					},

					/*
					Show a user's details
					*/
					showDetails: function() {

						UsersApp.controller.showDetails();

					},

					/*
					Show the empty form
					*/
					showEmptyForm: function(systemNumber, installationId) {


						//start the controller and pass in the installation id from the router
						UsersApp.controller = new Controller(installationId);

						UsersApp.controller.showEmptyForm(systemNumber, installationId);

					}

				};

				/**
				Start Event
				@event start
				*/
				this.on('start', function(hash) {


					// Start the router for this sub app
					UsersApp.router = new Router({

						controller: API

					});

				});

				/**
				New user request
				@event new:user
				*/
				Vent.on('new:user', function() {

					// Move Backbone history to the correct place
					Commands.execute('app:navigate', {

						template: TemplateModalFormChanges,
						route: window.location.hash.replace(window.location.hash.substr(window.location.hash.lastIndexOf('/users')), '/users')
						//trigger: false

					},
					function(success) {

						// If navigation has been successful
						if (success) {

							// Show the new user form
							API.getById(0);

						}

					});

				});

				/**
				Show a user
				@event show:user
				@param id {Integer} The id of the user to show
				*/
				Vent.on('show:user', function(options) {

					// Move Backbone history to the correct place
					Commands.execute('app:navigate', {

						template: TemplateModalFormChanges,
						route: window.location.hash.replace(window.location.hash.substr(window.location.hash.lastIndexOf('/users')), '/users/'+ options.id),
						trigger: false

					},
					function(success) {

						// If navigation has been successful
						if (success) {

							// Show the requested user
							API.getById('','',options.id);

						}

					});

				});


			});

			return UsersApp;

		}

	};

});

