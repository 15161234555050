define('app/modules/newcharts/layouts/bathroom.layout',[

    'backbone',
    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'moment',
    'tpl!app/modules/newcharts/templates/bathroom.template.tmpl',
    'app/modules/newcharts/views/bathroom-table.view',
    'app/modules/newcharts/views/no-bathroom.view',
    'app/modules/newcharts/state'

], function(Backbone, Marionette, Vent, Requests, Moment, Template, TableView, NoBathroomView, State) {

    'use strict';


    return Marionette.Layout.extend({

        template: Template,

        regions: {
            week1: '#week1',
            week2: '#week2'
        },

        initialize: function() {
            Vent.on('newchart:bathroom-change', this.changeRange.bind(this));
            Vent.on('newchart:bathroom-print', this.print.bind(this));
            Vent.on('newchart:bathroom-render', this.loadTables.bind(this));
        },

        onBeforeShow: function() {
            this.loadTables();
        },

        loadTables: function() {
            if (!this.model.get('insightAvailable')) {
                this.week1.show(new NoBathroomView());
                return;
            }

            var previous = this.model.get('ranges').previous,
                recent = this.model.get('ranges').recent;

            if (typeof previous == 'object') {
                this.loadTable('week1', previous);
            }

            if (typeof recent == 'object') {
                this.loadTable('week2', recent);
            }
        },

        loadTable: function(regionName, data) {
            data = _.extend({
                installationId: this.model.id
            }, data);
            this[regionName].show(new TableView({
                model: new Backbone.Model(data)
            }));
        },

        changeRange: function(data) {
            this.model.set(data);
            this.model.fetch({
                success: this.loadTables.bind(this)
            });

            State.save(this.model);
        },

        print: function(options) {
            if (!this.model.get('insightAvailable')) {
                return;
            }

            // Lift the whole page
            var page1 = $('#page-main').clone(),
                page2 = $('#page-main').clone(),
                week1 = $('#week1').clone(),
                week2 = $('#week2').clone();

            // Switch page elements with just the heading and two tables
            page1.find('#chart-body').html(week1.html());
            page1.find('#chart-header').find('div').last('.row').remove();
            page2.find('#chart-body').html(week2.html());
            page2.find('#chart-header').find('div').last('.row').remove();

            // Return resulting HTML. (call the callback)
            options.callback(page1.html() + '<div class="page-break"></div>' + page2.html());
        }
    });

});
