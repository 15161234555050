define('app/modules/newcharts/features/heatLight/models/heatLightSettings.model',['require','backbone','moment'],function (require) {
    'use strict';

    var Backbone = require('backbone'),
        Moment = require('moment');

    return Backbone.Model.extend({
        idAttribute: '_id',

        url: function() {
            return 'api/v1/HeatLight/' + this.id;
        },
    });
});
