/**
Login Controller
@module Login
@submodule Login.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Commands
  * Requests
Contents:
  * Login App Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/modules/login/controllers/login.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/modules/login/views/login.views.form'

], function(Marionette, Commands, Requests, FormView) {

  'use strict';

  /**
  Login Controller
  @class Login.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

      var
      that = this,
      App = require('app/app');

      // Define the main layout
      this.view = new FormView();

      // Define the main region
      this.region = App.main;

      /*
      Events
      */

      // Listen for the main layout's 'show' event
      this.view.on('show', function() {

      });

      // Call the controller 'show' method
      this.show();

    }

	});

});
