/**
Application Commands - Toggle Content Class
@module Commands
@submodule Commands.ToggleContentClass
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
  	* Toggle Content Class
Author(s):
  * Just Checking
*/

define('app/commands/commands.toggleContentClass',[

	'backbone',
	'app/commands/commands.vent'

], function(Backbone, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Toggle Content Class
			@event toggle:content:class
			@param class {String} The class to toggle
			*/
			return Commands.setHandler('toggle:content:class', function(options) {

				var
				App = require('app/app'),
				target = App.main.currentView.$el;

				// Are we adding the class?
				if (options.add) {

					// Add the class
					target.addClass(options.className);
				
				}
				else {

					// Remove the class
					target.removeClass(options.className);

				}

			});

    }

  };

});

