/**
Chart Application - Header Controls
@module Chart
@submodule Chart.Views.Header.Controls
*/

/*
Requires:
  * Backbone
  * Marionette
	* templates
Contents:
  * Header Controls
    * template
    * triggers
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.main.header.controls',[

  'marionette',
  'app/requests/requests.vent',
  'moment',
  'app/commands/commands.vent',
  'tpl!app/modules/charts/templates/charts.templates.main.header.controls.tmpl'

], function(Marionette, Requests, Moment, Commands, Template) {

  'use strict';

  /**
  Header Controls
  @class Chart.Views.Header.Controls
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

      /**
    @property ui
    @type Object
    */
    ui: {

      viewInstallationsButton: '#systems-detail-installations-button',
      viewChartButton: '#systems-detail-view-button',
      viewUsersButton: '#systems-detail-users-button',
      alertsButton: '#system-alert-settings-button'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click @ui.viewInstallationsButton': 'navigateToInstallations',
      'click @ui.viewChartButton': 'navigateToChart',
      'click @ui.viewUsersButton': 'navigateToUsers',
      'click @ui.alertsButton': 'navigateToAlertSettings'

    },

        /**
    @method navigateToChart
    */
    navigateToChart: function(e) {

      // Build the route
      var route = this.buildTargetRoute();

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    /**
    @method navigateToAlertSettings
    */
    navigateToAlertSettings: function(e) {

      // Build the route
      var route = this.buildTargetRoute();

      // Add the alert settings bit
      route += '/alerts-settings';

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    navigateToInstallations: function(e){

      // Build the route
      var route = this.buildTargetRoute();

      // Add the users bit
      //route += '/users';

      if (this.options.chartVersion == 2) {
          route = route.replace(route.substring(route.indexOf('/newcharts')), '');
      } else {
          route = route.replace(route.substring(route.indexOf('/charts')), '');
      }

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

    navigateToUsers: function(e){

      // Build the route
      var route = this.buildTargetRoute();

      route = route.replace('newcharts', 'charts');

      // Add the users bit
      route += '/users';

      // Go to the route
      this.navigate(route);

      e.preventDefault();

    },

     /**
    @method buildTargetRoute
    */
    buildTargetRoute: function() {

      // Get the current route
      var currentRoute = Requests.request('get:current:route');

      currentRoute = currentRoute.replace('/edit','');
         
      if (this.options.chartVersion == 2) {
          currentRoute = currentRoute.replace('charts', 'newcharts');
      }

      return currentRoute;
    },

    /**
    @method navigate
    */
    navigate: function(route) {

      // Navigate the app to the route
      Commands.execute('app:navigate', {

        route: route

      });

    },

  });

});

