/**
System Application - Router
@module Systems
@submodule Systems.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/systems/systems.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  return Marionette.AppRouter.extend({
    appRoutes: {
      'systems': 'getList',
      'systems/:systemId': 'getById'
    }
  });

});

