/**
Application Commands - Close Section Header
@module Commands
@submodule Commands.CloseSectionHeader
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
Contents:
  * Module Initialize
		* Close Section Header
Author(s):
  * Just Checking  
*/

define('app/commands/commands.closeSectionHeader',[

	'backbone',
	'app/commands/commands.vent',
	'app/layout/layouts/layout.layouts.main.header',
	'app/layout/views/layout.views.main.header.title'

], function(Backbone, Commands, HeaderLayout, TitleView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Close Section Header
			@event close:section:header
			*/
			return Commands.setHandler('close:section:header', function(options) {

				var App = require('app/app');

				// Watch for a close event if in debug mode
				if (options.debug) {

          // Do this when the header layout is shown
          App.main.currentView.header.on('close', function() {

           console.log('section header closed');

          });

        }

        // Has a change of opacity been requested?
        if (options.opacity) {

        	// Add an opacity wrapper to the current section header
        	App.main.currentView.header.currentView.addOpacityWrapper({

        		add: true

        	});

        }
        else {

					// Close the header layout
					App.main.currentView.header.close();

				}

			});

    }

  };

});

