define('app/modules/newcharts/views/multi.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/multi.template.tmpl',
    'moment',
    'app/modules/newcharts/views/chart.view',
    'app/modules/newcharts/models/note.model',
    'backbone.crudder'

], function(Marionette, Vent, Commands, Requests, Template, Moment, ChartView, NoteModel, Crudder) {
    'use strict';

    return Marionette.CollectionView.extend({

        template: Template,

        itemView: ChartView,

        itemViewContainer: '#charts'

    });

});
