/**
Application Requests - Get alert enabled users
@module Requests
@submodule Requests.GetAlertEnabledUsers
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get alert enabled users
Author(s):
  * Just Checking
*/

define('app/requests/requests.getAlertEnabled',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get alert enabled users
			@event get:alert:enabled
			*/
			return Requests.setHandler('get:alert:enabled', function() {

				var App = require('app/app');

				return App.state.alertEnabledUsers;

			});

    }

  };

});

