define('app/modules/charts/models/charts.models.insights',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
        Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		// This is the route to the api - this is where the request will go when backbone syncs the model with the server
		urlRoot: 'Insights',

		date: '_date'
	});

});

