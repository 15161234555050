/**
Chart Application - Row List View
@module Chart
@submodule Chart.Views.Rows
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Row List View
    * tagName
    * className
    * idAttribute
    * itemView
    * itemViewOptions
    * emptyView
    * onRender
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.rows.list',[

  'marionette',
  'app/app.vent',
  'app/modules/charts/views/charts.views.rows.item',
  'app/views/views.noItems'

], function(Marionette, Vent, ItemView, NoItemsView) {

  'use strict';

  /**
  Row List View
  @class Chart.Views.Rows
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CollectionView.extend({

    /**
    @property tagName
    @type String
    */
    tagName: 'ul',

    /**
    @property className
    @type String
    */
    className: 'chart-wrapper no-list-styling',

    /**
    @property idAttribute
    @type String
    */
    idAttribute: 'chart-wrapper',

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemView
    @type Object
    */
    itemViewOptions: function() {

      return {
      
        inlineEditing: this.options.inlineEditing

      };
    
    },

    /**
    @property emptyView
    @type Function
    */
    emptyView: function() {

      return new NoItemsView({

        itemName: 'sensors'

      });

    },

    /**
    @method onRender
    */
    onRender: function() {

      // Is inline editing required?
      if (this.options.inlineEditing) {

        // Initialise the row sortability
        /* this.$el.sortable({

          // Set a delay in milliseconds before drag will start
          delay: 300,
          handle: this.$el.find('> li'),
          cursor: 'move',
          placeholder: 'ui-sortable-placeholder-inline',
          update: function() {

            // Store the changes
            Vent.trigger('chart:store:rows');

          }

        }); */

      }
      else {

        // Remove all the row hide icons
        this.$el.find('.chart-row-hide').remove();

      }

    }

  });

});

