/**
System Detail Controller
@module Systems
@submodule Systems.Controllers.Detail
*/

/*
Requires:
	* Backbone
	* Marionette
	* jQuery
	* Underscore
	* Mobiscroll
	* Crudder
Contents:
	* System Detail Controller
		* initialize
		* showDetails
		* showInstallations
		* saveInstallation
		* createInstallation
Author(s):
	* Just Checking
*/

define('app/modules/systems/controllers/systems.controllers.detail',[
	'marionette',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'app/modules/systems/models/systems.models.system',
	'app/modules/systems/models/systems.models.installation',
	'app/modules/systems/collections/systems.collections.installations',
	'app/modules/systems/layouts/systems.layouts.detail',
	'app/modules/systems/views/systems.views.detail.system',
	'app/modules/systems/views/systems.views.installations.list',
	'app/views/views.noItems',
	'mobiscroll',
	'backbone.crudder',
	'app/models/replica.model'
], function(Marionette, Commands, Requests, Model, InstallationModel, InstallationCollection, Layout, SystemView,
			InstallationListView, NoItemsView, Mobiscroll, Crudder, ReplicaModel) {
	'use strict';

	/**
	System Detail Controller
	@class Systems.Controllers.Detail
	@constructor
	@extends Marionette.Controller
	*/
	return Marionette.Controller.extend({

		/**
		@method initialize
		@param systemId {Integer} The id of the requested system
		*/
		initialize: function(options) {

			var
			that = this,
			App = require('app/app');

			// Create an emtpy collections object
			this.collections = {};

			// Create an installations collection
			this.collections.installations = {};

			// Create an empty models object
			this.models = {};

			// Define our system model
			this.model = new Model({
				systemId: options.systemId
			});

			// Define the main layout
			this.view = new Layout();

			// Define the main region
			this.region = App.main.currentView.content;

			/* Events */

			// Listen for the main layout's 'show' event
			this.view.on('show', function() {
				/* Installation collections */

				// Get all the installations
				that.collections.installations = new InstallationCollection(that.model.get('installations'));

				// Iterate through collection to make below properties available to view
				that.collections.installations.forEach(function(model, index) {
					model.set({
						p_createInstallation: that.model.get('p_createInstallation'),
						evaluationEnabled: that.model.get('evaluationEnabled'),
						p_openEvaluationForm: that.model.get('p_openEvaluationForm')
					});
				});

				// Update the topbar title with the chart title ON MOBILE
				Commands.execute('update:topbar', {
					title: {
						text: 'System ' + that.model.get('systemId')
					}
				});

				that.showDetails();

				that.showInstallations();

				// Show the section header
				that.showHeader({
					title: 'System ' + that.model.get('systemId')
				});

				/* On-boarding tour */
				Commands.execute('init:onBoard', {
					page: 'installations'
				});
			});

			/* Fetch */
			this.model.url = function(){
				return Backbone.apiRoot.root + 'Installations/?systemId=' + this.id;
			};

			// Fetch the model data
			this.model.fetch();

			// Call the controller 'show' method
			this.show({
				loader: true,
				debug: false
			});
		},

		// Most recent installation
		showDetails: function() {
			var that = this;
			var systemView;

			// Get the current installation
			this.models.installation = this.collections.installations.first();

			/* View */

			// Check for a current installation
			if (this.models.installation) {

				// Create a new system view
				systemView = new SystemView({
					model: this.models.installation
				});

				/* Events */
				// Listen for save installation event
				systemView.on('save:installation', function(options) {
					// Save any changes made to the installation
					that.saveInstallation(that.models.installation, options);
				});
				// Listen for create installation event
				systemView.on('create:installation', function() {
					// Create a new installation
					that.createInstallation();
				});
			}
			else {
				console.log("catch 3")
				// Create a new system view
				systemView = new NoItemsView({
					itemName: 'installation',
					border: true,
					marginTop: true
				});
			}

			// Show the system view
			this.view.detail.show(systemView);
		},

		// Previous installations
		showInstallations: function() {

			var
			that = this,
			installationListView;

			/*
			Collection
			*/

			// Get all previous installations
			this.collections.prevInstallations = this.collections.installations.clone();

			// Remove the latest installation from the collection
			this.collections.prevInstallations.remove(this.models.installation);

			/*
			View
			*/

			// Create the installation list view
			installationListView = new InstallationListView({
				model: this.model,
				collection: this.collections.prevInstallations
			});

			/*
			Events
			*/

			// Listen for installation save event
			installationListView.on('itemview:save:installation', function(childView, msg) {
				// Save the installation model
				that.saveInstallation(childView.model);
			});

			// Show the installations list view
			this.view.installations.show(installationListView);
		},

		/**
		@method saveInstallation
		@param model {Object} The model to be saved
		@param ended {Boolean} Denotes a request to end the installation
		@param callback {Function} An optional callback function
		*/
		saveInstallation: function(model, options, callback) {
			var that = this;
			// Options is optional
			options = options || {};
			// Defaults
			_.defaults(options, {
				ended: false
			});
			//Save the installation

			// Validation
			if (model) {
				//required field for PATCH
				var entityData = model.changed;

				//always attach the installation timezone to changed installationstart or end
				if(entityData.installationStart || entityData.installationEnd){
					entityData.installationTimeZone = model.get('installationTimeZone');
				}

				// Save this model via crudder
				Crudder.creatingUpdating({
					entity: model,
					entityData: entityData,
					patch: false,
					successMessage: 'Installation has been saved',
					errorMessage: 'Unable to save'
				}, function(response) {
					// Has the request been successful?
					if (response.success) {
						// Has the installation been ended?
						if (options.ended) {
							// Is it an R6 with enhanced service?
							if (model.get('chainingAvailable')) {
								// Show the 'start new or end' popup
								Commands.execute('app:confirmModal', {
									message: 'Do you want to end the installation completely or start a new activity sequence?',
									confirmText: 'Start new',
									confirmCallback: function() {
										Commands.execute('app:printLightbox', {
											installationId: model.get('installationId'),
											callback: function() {
												Commands.execute('app:enhancedServiceQuestionsLightbox', {
													installationId: model.get('installationId'),
													callback: function() {
														// Create a replica of the installation
														var replicaModel = new ReplicaModel({
															_id: model.get('installationId')
														});

														Crudder.creatingUpdating({
															entity: replicaModel,
															patch: false
														}, function(response) {
															// Redirect to chart setup of new installation.
															Commands.execute('app:navigate', {
																route: 'systems/' + response.data.get('systemNum') + '/charts/' + response.data.get('installId') + '/edit'
															});
														});
													}
												});
											}
										});
									},
									cancelText: 'End install',
									cancelCallback: function() {
										Commands.execute('app:printLightbox', {
											installationId: model.get('installationId'),
											callback: function() {
												Commands.execute('app:outcomePredictionLightbox', {
													installationId: model.get('installationId')
												});
											}
										});
									}
								});
							} else {
								// Show the email form
								Commands.execute('app:printLightbox', {
									installationId: model.get('installationId')
								});
							}
						}
						var sysID = response.data.attributes.systemId;

                        // Evaluation form logic.
                        if (response.response.jcData.showEvaluation) {
                            Commands.execute('app:EvaluationLightbox', {
                                requestData: {
                                    installationId: response.response.jcData.installationId,
                                    disableClose: true
                                }
                            });
                        }
					}
					else {
						console.log('update failed');
					}
				});
			}
			else {
				console.warn('No installation model supplied');
				return;
			}
		},

		/**
		@method createInstallation
		*/
		createInstallation: function() {
			var
			that = this,
			installationModel = new InstallationModel();

			// Set the systemId
			installationModel.set({
				systemId: this.model.get('systemId')
			});

			//Create a new installation
			Crudder.creatingUpdating({
				entity: installationModel,
				successMessage: 'Installation has been created',
				errorMessage: 'Unable to create'
			}, function(options) {
				// Has the request been successful?
				if (options.success) {
					// Send the user to the chart edit view
					Commands.execute('app:navigate', {
						route: 'systems/' + that.model.get('systemId') + '/charts/' + options.response.jcData.installationId + '/edit'
					});
					var cacheclientId = that.model.get('clientId');
					//var url = options.response.jcData.evaluationUrl;

					// Store client id in local storage
					store.set('clientId', cacheclientId);

					// Evaluation form logic.
                    if (options.response.jcData.showEvaluation) {
                        Commands.execute('app:EvaluationLightbox', {
                            requestData: {
                                installationId: options.response.jcData.installationId,
                                disableClose: true,
                                greyAssessmentEnd: true
                            }
                        });
                    }
					//if (options.response.jcData.evaluationUrl !== '') {
					//	$('.iframecontainer').css("height", "90%");
                    //
					//	// Reset tab state to ensure incorrect tab isn't selected
					//	$('.reportbtn').removeClass('evaltabselected');
					//	$('.evalbtn').addClass('evaltabselected');
					//	$('.reportsavings').css("display", "none");
                    //
					//	// Stop body from scrolling when modal is being shown
					//	$('body').css('overflow', 'hidden');
					//	$('.iframecontainer').html('<iframe id="rootiframe" class="rootiframe" src="' + options.response.jcData.evaluationUrl + '"></iframe>');
					//	$('.modaldialog').css("display", "block");
					//	$('.overlay').css("display", "block");
                    //
					//	// Need to have someway of remembering what eval link is in use
					//	store.set('evalLink', url);
					//}
				}
				else {
					console.log('create failed');
				}
			});
		}
	});
});

