/**
Chart Application - Model
@module Chart
@submodule Chart.Model
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Chart Model
    * idAttribute
    * urlRoot
Author(s):
  * Just Checking
*/

define('app/modules/charts/models/charts.models.chart',[
  'backbone'
], function(Backbone) {
  'use strict';

  /**
  Chart Model
  @class ChartApp.Model
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property idAttribute
    @type String
    */
    idAttribute: '_id',

    /**
    @property urlRoot
    @type String
    */

    // This is the route to the api - this is where the request will go when backbone syncs the model with the server
    urlRoot: 'Charts',

    /**
    @property defaults
    @type Object
    */
    defaults: {
      chartTitle: 'No title',
      nightView: false
    }

  });

});

