/**
Systems Application
@module Systems
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Module method
		* API
			* Get item list
			* Get an item by id
		* Start Event
Author(s):
  * Just Checking
*/

define('app/modules/systems/systems.module',['require','marionette','app/app.vent','app/commands/commands.vent','app/modules/systems/systems.router','app/modules/systems/controllers/systems.controllers.list','app/modules/systems/controllers/systems.controllers.detail','app/app'],function (require) {

	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Router 				= require('app/modules/systems/systems.router'),
		SystemsController 	= require('app/modules/systems/controllers/systems.controllers.list'),
		SystemController 	= require('app/modules/systems/controllers/systems.controllers.detail');

		return {

			/**
			Module Initialize
			@method initialize
			*/
		    initialize: function() {

				var App = require('app/app');

				var SystemsApp = App.module('SystemsApp', function() {

						// prevent starting with parent
						this.startWithParent = false;

						var API = {

							getList: function() {
								SystemsApp.controller = new SystemsController();
							},

							getById: function(systemId) {
								SystemsApp.controller = new SystemController({
									systemId: parseInt(systemId, 10)
								});

							}
						};

						this.on('start', function(hash) {
							// Start the router for this sub app
							SystemsApp.router = new Router({
								controller: API
							});
						});

					});
					return SystemsApp;
			}
		};
});

