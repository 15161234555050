/**
Application Requests - Get Current Route
@module Requests
@submodule Requests.GetCurrentRoute
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get the current route
Author(s):
  * Just Checking
*/

define('app/requests/requests.getCurrentRoute',[

	'backbone',
	'app/requests/requests.vent'

], function(Backbone, Requests) {

	'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get the current route
			@event get:current:router
			@return {String} Current Backbone history fragment value
			*/
			return Requests.setHandler('get:current:route', function(options) {

				// 
				options = options || {};

				// 
				var frag = Backbone.history.fragment;

				// Is there a fragment?
				if (_.isEmpty(frag)) {

					return false;
				
				}
				else {

					// Remove the last section of the fragment?
					if (options.removeSection) {

						// Remove the last section of the fragment
						frag = frag.replace(frag.substr(frag.lastIndexOf('/')), '');

					}

					return frag;

				}

			});

    }

  };

});

