/**
Users Application - Model
@module UsersApp
@submodule UsersApp.Model
*/

/*
Requires:
  * Backbone
  * jQuery
  * Underscore/Lodash
Contents:
  * Users Model
		* urlRoot
		* idAttribute
		* defaults
Author(s):
  * Just Checking
*/

define('app/modules/users/settings.users.model',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Users Model
  @class UsersApp.Model
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    Point to the source of the json
    @property urlRoot
    @type String
    */
    urlRoot: 'PermissionAssignments',

    /**
    Define the id attribute of the model
    @property idAttribute
    @type Integer
    @default 'userId'
    */
    idAttribute: 'accessId',

    /**
    Model default values
    @property defaults
    @type Object
    */
    defaults: {

      'name': '',
      'email': ''

    }

  });

});
