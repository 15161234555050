define('app/modules/newcharts/collections/chart-rows.collection',[
    'backbone',
    'app/modules/newcharts/models/chart-row.model'
], function(Backbone, Model) {
    'use strict';

    return Backbone.Collection.extend({

        model: Model,

        comparator: 'rowCurrentPosition'
    });

});
