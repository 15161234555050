define('app/modules/newcharts/features/carerVisits/collections/carerVisits.collection',[
	'backbone',
	'app/modules/newcharts/features/carerVisits/models/carerVisit.model'
], function(Backbone, Model) {
	'use strict';

	return Backbone.Collection.extend({

		model: Model,

		url: function() {
			return 'api/v1/CarerVisits/' + this.models[0].id;
		}
	});

});
