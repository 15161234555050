define('app/modules/newcharts/features/carerVisits/models/carerVisit.model',['require','backbone','moment'],function (require) {
	'use strict';

	var Backbone = require('backbone'),
		Moment = require('moment');

	return Backbone.Model.extend({
		idAttribute: '_id',

		// url: function() {
		// 	if (!this.get('system_num')) {
		// 		return 'api/v1/CarerVisits/' + this.get('install_id') + '/default';
		// 	}
		// 	if (!this.get('event_id')) {
		// 		return 'api/v1/CarerVisits/' + this.get('install_id');
		// 	}
        //
		// 	return 'api/v1/CarerVisits/' + this.get('install_id') + '/' + this.get('event_id');
		// },
        //
		// isNew: function() {
		// 	return this.get('visit_id') === false || this.get('visit_id') == null;
		// },

		defaults: {
			start_time: null,
			end_time: null
		}
	});

});
