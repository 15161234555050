/**
System Detail Layout
@module Systems
@submodule Systems.Layout
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * System Detail Layout
    * template
    * regions
Author(s):
  * Just Checking
*/

define('app/modules/systems/layouts/systems.layouts.detail',[

  'marionette',
  'tpl!app/modules/systems/templates/systems.templates.detail.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  System Detail Layout
  @class Systems.Layout
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

      detail: '#system-detail',
      installations: '#system-installations'

    },

  });

});

