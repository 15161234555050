/**
AJAX related scripts
@module AJAX
*/

/*
Requires:
  * jQuery
Contents:
  * Intialise the AJAX Settings
		* Set a global auth function for all ajax requests
		* Global AJAX error checks
  * AJAX loader overlay
  * Return the module
Author(s):
  * Just Checking
*/

define('validation',[],function() {

  'use strict';

  var Validation = {

    /*
    Custom invalid method
    */

    /*
    Params:
    view - The view the request has come from
    target - Used to reference the validated element
    error - The error message to be displayed
    parent - The target element's parent attribute (For styling purposes)
    focus - Do we want to focus on the erroring element? (boolean)
    */

    customInvalid: function(options) {

      var
      targetEl = options.view.$(options.target),
      button = options.view.$('button[type="submit"]');

      // Are the elements already in an error state?
      if (!targetEl.hasClass('error')) {

        // Add the error class to the target element
        targetEl.addClass('error');

        // Insert an error message after the target element
        targetEl.after('<span class="error' + options.parent + '">' + options.error + '</span>');

      }

      // Do we want to focus on the erroring element?
      if (options.focus) {

        // Focus on the offending element
        targetEl.focus();

      }

      // Does the error message already exists in the DOM?
      if (!options.view.$('#form-error').length) {

        // Add a message before the button
        button.before('<p id="form-error" class="no-margin-top"><i class="icon icon-attention red"></i> Please correct all errors and try again</p>');

      }

      return false;

    },

    /*
    Custom invalid method
    */

    /*
    Params:
    view - The view the request has come from
    target - Used to reference the validated element
    parent - Reference to the parent element type
    */

    customValid: function(options) {

      var targetEl = options.view.$(options.target);

      // Are the elements already in an error state?
      if (targetEl.hasClass('error')) {

        // Remove the error class from the target element
        targetEl.removeClass('error');

        // Remove the error message after the target element
        targetEl.next('span.error' + options.parent + '').remove();

      }

      return false;

    }

  };

  return Validation;

});

