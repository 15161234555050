/**
Users Application - Controller
@module UsersApp
@submodule UsersApp.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
  * Vent
  * Commands
  * Crudder
  * Collection
  * Model
  * ListView
  * FormLayout
Contents:
  * Users App Controller
		* initialize
		* Get the list of items
		* Get an item by its id
		* Show the list of users
		* Show a user's details
		* Show the empty form
		* Check for an enabled user
		* Define the model based on id supplied
Author(s):
  * Just Checking
*/

define('app/modules/users/settings.users.controller',[

  'marionette',
  'app/app.vent',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
	'backbone.crudder',
  'app/modules/users/settings.users.collection',
  'app/modules/users/settings.users.model',
  'app/modules/users/settings.users.accessSelect.model',
  'app/modules/users/views/menu/settings.users.views.menu.list',
  'app/modules/users/form/settings.users.form.layout',
  'app/modules/users/form/views/settings.users.form.views.edit',
  'app/modules/users/form/views/settings.users.form.views.remove',
  'app/modules/users/form/settings.users.panels.layout'

], function(Marionette, Vent, Commands, Requests, Crudder, Collection, Model, AccessSelect, ListView, FormLayout, EditView, RemoveView, PanelLayout) {

  'use strict';

  /**
  Users App Controller
  @class UsersApp.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(installationId) {

       //create a new layout
      this.Panels = new PanelLayout();


      //check if installationId is set and is a number, if it is update the collection url
      if(! isNaN(installationId)){
        //Add the installation Id to the url route for the collection, otherwise this gets worked out in the collection...
        Collection.prototype.urlSource = 'PermissionAssignments/?installationId='+ installationId;
      }


      // Define our item collection
      this.collection = new Collection();



      // Define our user model
      this.model = new Model();


      var App = require('app/app');

      // inject the panel layout into the content region
      App.main.currentView.content.show(this.Panels);

    },

    /**
    Get the list of items
    @method getList
    @param callback {Function} The callback function supplied
    */
    getList: function(callback) {

      var that = this;

      /*
      You are here
      */

      // Highlight the correct menu item in the list
      Commands.execute('show:current:location');

      /*
      Fetch the collection
      */


      // Fetch the data via crudder
			Crudder.reading({

				entity: this.collection,
				errorMessage: 'Error accessing user data'

			}, function(options) {
				// Has the request been successful?
				if (options.success) {
                    that.Panels.chartVersion = options.response.jcData.chartVersion;

					// Show the returned collection
                    that.showList();

                    // Fire the callback
                    if (callback) {
                        callback();
                    }
				}
            });


    },

    /**
    Get an item by its id
    @method getById
    @param suppliedId {Integer} The id of the user to show
    */
    getById: function(suppliedId) {


      var that = this,
      id = parseInt(suppliedId, 10);

      // If there is no collection list (page refresh)
      if (this.collection.length === 0) {


        // Call the method to get the collection list
        this.getList(function() {

          // Define our model based on the id supplied
          that.defineModel(id);

          // Show the collection requested
          that.showDetails();

        });


      }
      else {

        // Define our model based on the id supplied
        this.defineModel(id);

        // Show the collection requested
        this.showDetails();

      }

    },

    /**
    Show the list of users
    @method showList
    */
    showList: function() {

      var
      that = this;

      /*
      Create the view
      */
      var userListView = new ListView({

        collection: this.collection

      });

      // Listen for the main layout's 'show' event
      userListView.on('show', function() {

        /*
        Top bar
        */

        // Split open the URL to get the system number
        var url = Requests.request('get:current:route', {});
        var path  = url.split('/');
        var systemNumber = path[1];

        // Update the topbar title with the chart title
        Commands.execute('update:topbar', {

          title: {

            text: 'Chart Users (' + systemNumber + ')'

          }

        });

        /*
        Header view
        */

        // Show the section header
        that.showHeader({

          title: 'Chart Users (' + systemNumber + ')'

        });

      });

      /*
      Show the view
      Send the collection list in the 'menu' region
      via the correct view
      */
      // App.main.currentView.firstPanel.show(userListView);

      //send the view to the panel region
      this.Panels.firstPanel.show(userListView);




        /* On-boarding tour */
        Commands.execute('init:onBoard', {

          page: 'chartUsers'

        });
    },

    /**
    Show a user's details
    @method showDetails
    */
    showDetails: function() {

      var
      that = this,
      App = require('app/app');

      /*
      Create the layout
      */
      var userFormLayout = new FormLayout();

      /*
      Layout events
      */

      // Subscribe to the show event of the view
      userFormLayout.on('show', function() {

        // Check for an enabled user in the collection
       // Vent.trigger('check:user');

        // Highlight the side-menu item
        Commands.execute('show:current:item');

        // Create the edit user view
        var editView = new EditView({

          model: that.model,
          collection: that.collection

        });

        /*
        Add a form wrapper view via request
        @param view {Object} The view to wrap in the form
        @param options {Object} Any configuration required
        */

        var formView = Requests.request('get:form:wrapper', editView);
        //PREVENT MODAL FIRING AND BLOCKING REST OF APP
        /*
        // Subscribe to the show event of the view
          formView.on('show', function() {

  					// Subscribe to any form change events
  					Commands.execute('set:change:true', {

  						view: this

  					});
          });
        */
        formView.on('show', function() {

          // Bind validation to the form view
          Backbone.Validation.bind(this);
          Commands.execute('set:change:true', {view:this});

        });

        // Create the remove user view
        var removeView = new RemoveView({

          model: that.model

        });

        // Show the edit user form
        userFormLayout.edit.show(formView);

        // If this is a new model
        if (!that.model.isNew()) {

					// Show the remove user form
					userFormLayout.remove.show(removeView);

				}

      });

      /*
      Show the layout
      */

      // Send the requested user to be displayed in the 'secondPanel'
      // region via the correct view
      // App.main.currentView.secondPanel.show(userFormLayout);
      this.Panels.secondPanel.show(userFormLayout);

      // 3rd panel isn't used, so make sure we reset it
      // App.main.currentView.thirdPanel.reset();

    },

    /**
    Show the empty form
    @method showEmptyForm
    */
    showEmptyForm: function(systemNumber, installationId) {

      var that = this;

      // Call the method to get the collection list
      this.getList(function() {

        // Create a new model 'new user'
        that.model = new Model();

        // Show the first item by default
        that.showDetails();

      }, installationId);

    },

    /**
    Define the model based on id supplied
    @method defineModel
    @param id {Integer} The id of the requested model
    */
    defineModel: function(id) {

			// Has an id been supplied?
      if (id === 0) {

        // Create a new model 'new user'
        this.model = new Model();

      }
      else {

        // Get the right model from the collection
        this.model = this.collection.get(id);

        // Check that the model exists
        if (!this.model) {

					// Create a new model 'new user'
					this.model = new Model();

        }

      }

    }

	});

});

