define('app/modules/newcharts/layouts/main.layout',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/main.template.tmpl',
    'd3'

], function(Marionette, Vent, Requests, Template, D3) {

    'use strict';


    return Marionette.Layout.extend({

        template: Template,

        regions: {
            header: '#chart-header',
            body: '#chart-body'
        },

        initialize: function() {
            this.render();
        }
    });

});
