define('app/models/printLightbox.model',[
	'backbone',
	'app/commands/commands.vent'
], function(Backbone, Commands) {
	'use strict';

	Backbone.emulateHTTP = true;

	return Backbone.Model.extend({

		idAttribute: '_id',

		url: function() {
			return 'api/v1/PrintInstall/' + this.id
		},

		defaults: {
			email: ''
		},

		isNew: function() {
			return true;
		}

	});
});

