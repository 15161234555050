/*
Application Utilities
@module Utils
*/

/*
Requires:
	* jQuery
  * Moment
Contents:
  * Detect window width
  * Detect window height
  * Check if a string is numeric
  * Check if a number is the correct length
  * Check for a valid email address
  * Check for a valid URL
  * Check for an odd number
  * Generate Random Number
  * Convert to fuzzy time
  * Escape Characters
Author(s):
  * Just Checking
*/

define('utils',[],function() {

  'use strict';

  var Utils = {

		/*
		Detect window width
		*/

    getWindowWidth: function() {

      return $(window).width();

    },

    /*
    Detect window height
    */

    getWindowHeight: function() {

      return $(window).height();

    },

		/*
		Check if a string is numeric
    */

    isNumber: function(string) {

      return !isNaN(parseFloat(string)) && isFinite(string);

		},

    /*
    Check if a number is the correct length
    */

    isCorrectLength: function(string, length) {

			var isCorrect = false;

			// Does the string length and length match?
			if (string.length === length) {

				// Set to truthy
				isCorrect = true;

			}

      return isCorrect;

    },

    /*
    Check for a valid email address
    */

    isValidEmail: function(email) {

      var pattern = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

      // Does the email not match our pattern?
      if (!email.toString().match(pattern)) {

        return false;

      }
      else {

        return true;

      }

    },

    /*
    Check for a valid URL
    */

    isValidUrl: function(url) {

			var pattern = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

			// Does the email not match our pattern?
      if (!url.toString().match(pattern)) {

        return false;

      }
      else {

        return true;

      }

    },

    /*
    Check for an odd number
    */

    isOdd: function(num) {

			return num % 2;

		},

    /*
    Clean up a phone number
    Removes anything non-numerical apart from a + 
    */

    cleanPhone: function(phone) {

      phone = phone.replace(/[^\d\+]/g,'');

      if (phone.substr(0, 1) == "+") {

        phone = "+" + phone.replace(/[^\d]/g,'');

      }
      else {

        phone = phone.replace(/[^\d]/g,'');

      }

      return phone;

    },

		/*
		Generate Random Number
		*/

		generateRandomNumber: function() {

			return Math.floor(Math.random() * 1000000000);

		},

		/*
		Convert to user friendly date format
		@param date {DateTime} in YYYY-MM-DD HH:mm Z format
		@param format {String} The request format of the returned value
		Requires momentjs
		*/

		convertToFriendlyDate: function(options) {

			// Set the defaults
			_.defaults(options, {

				date: moment(),
				format: 'ddd Do MMM YYYY, HH:mm'

			});

			// Have a date and format been supplied?
			if (options.date && options.format) {

				return moment(options.date).format(options.format);

			}
			else {

				return;

			}

		},

		/*
		Convert to fuzzy time
		@param date {DateTime} in YYYY-MM-DD HH:mm Z format
		Requires momentjs
		*/

		convertToFuzzyTime: function(date) {

			var
			now = moment(),
			serverTime = moment(date),
			clampedMoment = serverTime.max(now);

			// Has a date been supplied?
			if (date) {

				return moment(clampedMoment).fromNow();

			}
			else {

				return;

			}

		},

		/*
		Escape Characters
		*/

		// Escape dodgy characters from a json object
		escapeJsonString: function(string) {

      if (string) {
        // This removes the dodgy characters from a string so it becomes valid json content
        return JSON.stringify(string);
      }
      else {
        // No string provided so send back the quote marks to make json valid
        return '""';
      }

    },

    // Escape newline chars for being transmitted with JSON over the wire
    escapeNewLineChars: function(value) {

      if (value !== null && value !== '') {
        return value.replace(/\n/g, '\\n');
      } else {
        return value;
      }

    },

    // Converts \n newline chars into <br> chars s.t. they are visible inside HTML
    convertToHTMLVisibleNewline: function(value) {

      if (value !== null && value !== '') {
        return value.replace(/\n/g, '<br>');
      } else {
        return value;
      }

    },

    // Converts <br> chars into \n newline chars s.t. they are visible inside text edit boxes
    convertToTextVisibleNewLine: function(value) {

      if (value !== null && value !== '') {
        return value.replace(/<br\>/g, '\n');
      } else {
        return value;
      }

    }

	};

	return Utils;

});

