/**
Application Layout - Header Topbar Controller
@module Layout
@submodule Layout.Controllers.Header.Menu.Topbar
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Topbar Controller
    * Show title
    * Show button
Author(s):
  * Just Checking
*/

define('app/layout/controllers/layout.controllers.header.menu.topbar',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/layout/layouts/layout.layouts.header.menu.topbar',
  'app/layout/models/layout.models.header.menu.topbar.title',
  'app/layout/models/layout.models.header.menu.topbar.button',
  'app/layout/views/layout.views.header.menu.topbar.title',
  'app/layout/views/layout.views.header.menu.topbar.button'

], function(Marionette, Commands, Requests, TopbarLayout, TitleModel, ButtonModel, TitleView, ButtonView) {

  'use strict';

  /**
  Topbar Controller
  @class MainApp.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var
      that = this,
      App = require('app/app');

      // Define the topbar region
      this.region = App.header.currentView.topBar;

      // Define the controller layout
      this.layout = new TopbarLayout();

      // Fire this event when the layout is shown
      this.layout.on('show', function() {

        /*
        Default Title
        */

        // Show the default title
        that.showTitle(this.title);

        /*
        Default Buttons
        */

        // Back button
        that.showButton(this.buttonLeft, {

          icon: 'icon-left-open'

        });

        // Menu Button
        that.showButton(this.buttonRight, {

          icon: 'icon-menu',
          className: 'right',
          command: 'show:panel'

        });

        // Optional Button (Not shown by default)
        that.showButton(this.buttonOptional, {

          visible: false

        });

      });

      // Show the layout
      this.show();

    },

    /**
    @method showTitle
    @param region {Region} The region to show the title in
    */
    showTitle: function(region) {

      var
      titleModel = new TitleModel(),
      titleView = new TitleView({

        model: titleModel

      });

      // Show the title view
      region.show(titleView);

    },

    /**
    @method showButton
    @param region {Region} The region to show the button in
    @param options {Object} Any options for the model 
    */
    showButton: function(region, options) {

      var
      buttonModel = new ButtonModel(options),
      buttonView = new ButtonView({

        model: buttonModel

      });

      // Show the back button view
      region.show(buttonView);

    }

  });

});


// When the menu button is shown
        // this.buttonRight.on('show', function() {

        //   // Attach panel events to the right elements
        //   Requests.request('get:state:data').get('panels').registerEventListener({

        //     view: this.currentView.$el

        //   });

        // });

// Show the mobile menu
        // App.header.currentView.topBar.show(topBarView);
            
        // });
