/**
Chart Application - Edit Buttons
@module Chart
@submodule Chart.Views.Edit.Buttons
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Edit Buttons
    * template
    * triggers
    * events
    * cancelChanges
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.edit.buttons',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'tpl!app/modules/charts/templates/charts.templates.edit.buttons.tmpl'

], function(Marionette, Commands, Requests, Template) {

  'use strict';

  /**
  Edit Buttons
  @class Chart.Views.Edit.Buttons
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property triggers
    @type Object
    */
    triggers: {

      'click button[type="submit"]': 'save:changes'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click button[type="cancel"]': 'cancelChanges'

    },

    /**
    @method cancelChanges
    */
    cancelChanges: function(e) {
      // Get the chart details route
      // var route = Requests.request('get:current:route', {

      //   removeSection: true

      // });

      // Go back to the chart
      Commands.execute('app:navigate:back');

      // Turn off change listner for unsaved changes popup
      Commands.execute('set:change:false', {view:this});
      if (e) { e.preventDefault(); }
    }
  });
});
