define('app/commands/commands.7dayLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/modules/newcharts/7day/layouts/main.layout','app/app'],function (require) {
    'use strict';

    var Commands = require('app/commands/commands.vent'),
        Requests = require('app/requests/requests.vent'),
        Lightbox = require('app/modules/newcharts/7day/layouts/main.layout');

    return {
        initialize: function() {

            return Commands.setHandler('app:7DayLightbox', function(options) {
                var App = require('app/app');

                App.lightbox.show(new Lightbox({
                    model: options.model
                }));
            });
        }
    };
});




