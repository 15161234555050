/**
Application Commands - Notifications
@module Commands
@submodule Commands.ShowNotifications
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
		* Show Notifications
Author(s):
  * Just Checking
*/

define('app/commands/commands.showNotifications',[

	'backbone',
	'app/commands/commands.vent',
	'app/components/notifications/components.notifications.collection',
	'app/components/notifications/views/components.notifications.views.list'

], function(Backbone, Commands, NotificationsCollection, NotificationsView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Show Notifications
			@event show:notifications
			*/
			return Commands.setHandler('show:notifications', function(options) {

				var
				App = require('app/app'),
				// Extract the global notifications
				globalData = _.where(options.data, {

					global: true

				}),
				// Extract the section notifications
				sectionData = _.where(options.data, {

					global: false

				}),
				// New section collection
				sectionCollection = new NotificationsCollection(sectionData),
				// New global collection
				globalCollection = new NotificationsCollection(globalData);

				// Check for notifications
				if (sectionCollection.length > 0) {

					// Section collection view
					var sectionView = new NotificationsView({

						collection: sectionCollection

					});

					// Show the view
          App.main.currentView.notifications.show(sectionView);

				}
				else {

					// Clear the region
					App.main.currentView.notifications.reset();

				}

				// Check for notifications
				if (globalCollection.length > 0) {

					// Global collection view
					var globalView = new NotificationsView({

						collection: globalCollection

					});

					// Show the notification layout
          App.notifications.show(globalView);
					
				}
				else {

					// Clear the region
					App.notifications.reset();

				}

			});

    }

  };

});

