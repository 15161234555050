define('app/modules/newcharts/rectMap',[],function() {

	var instance = null;

	function RectMap() {
		this.rowMap = [];
		this.rectMap = [];
		this.lastBlipId = null;

		this.addRow = function(lineId, rowWrapper) {
			if (!this.rowMap[lineId]) {
				this.rowMap[lineId] = [];
			}

			this.rowMap[lineId] = {
				start: rowWrapper.parent().position().top,
				end: rowWrapper.parent().position().top + 30
			};
		};

		this.reset = function() {
			this.rowMap = [];
			this.rectMap = [];
			this.lastBlipId = null;
		};

		this.addItem = function(lineId, x, w, element) {
			// console.log(lineId, x,w)
			// console.log(element)
			if (!this.rectMap[lineId]) {
				this.rectMap[lineId] = [];
			}

			for(var i = Math.floor(x); i <= x + w; i++) {
				this.rectMap[lineId][i] = element.get(0);
			}
		};

		this.getChartLine = function(chart, y) {
			var date = chart.prop('id').replace('chart-', '');

			for (var row in this.rowMap) {
				if (
					row.split('-')[0] == date
					&& y >= this.rowMap[row].start
					&& y <= this.rowMap[row].end
				) {
					return row;
				}
			}
		};

		this.hideTooltips = function(chart) {
			chart.find('#chart-rows').find('.popover').remove();
			this.lastBlipId = null;
		};

		this.triggerChartBlip = function(chart, x, y) {
			var lineId = this.getChartLine(chart, y);

			if (!lineId || !this.rectMap[lineId] || !this.rectMap[lineId][Math.floor(x)]) {
				this.hideTooltips(chart);
				return;
			}

			var $blip = $(this.rectMap[lineId][Math.floor(x)]);
			
            var blipId = lineId + '-' + $blip.attr('x');

			if (blipId != this.lastBlipId) {
				this.hideTooltips(chart);

				$blip.trigger('mouseenter');
				this.lastBlipId = blipId;
			}
		};

		this.recalculate = function() {
			// Recalculate each row position.
			var chart, lineId, rowWrapper;
			for(var key in this.rowMap) {
				if (this.rowMap.hasOwnProperty(key)) {
					chart = key.substr(0, key.indexOf('-'));
					lineId = key.substr(key.indexOf('-') + 1);

					rowWrapper = $('#chart-' + chart).find('li[data-id=' + lineId + ']').find('.chart-data-wrapper');
					if (rowWrapper.length > 0) {
						this.rowMap[chart + '-' + lineId] = {
							start: rowWrapper.position().top,
							end: rowWrapper.position().top + 30
						};
					}
				}
			}
		};
	}
	RectMap.getInstance = function() {
		if(instance === null){
			instance = new RectMap();
		}

		return instance;
	};

	return RectMap.getInstance();
});
