define('app/commands/commands.confirmModal',[
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modal/templates/confirmModal.tmpl'

], function(Commands, Requests, ModalTemplate) {
    'use strict';

    return {

        initialize: function() {

            var defaults = {
                message: 'No Message',
                confirmText: 'Yes',
                cancelText: 'No',
                confirmCallback: null,
                cancelCallback: null
            };

            function confirm(e) {
                if (e.data.confirmCallback) {
                    e.data.confirmCallback();
                }

                $('#confirmModal').remove();
            }

            function cancel(e) {
                if (e.data.cancelCallback) {
                    e.data.cancelCallback();
                }

                $('#confirmModal').remove();
            }

            return Commands.setHandler('app:confirmModal', function(options) {
                options = _.extend(defaults, options);

                $('body').append(ModalTemplate(options));

                var modal = $('#confirmModal');

                // Events
                modal
                    .find('#confirmBtn')
                    .unbind()
                    .click(options, confirm);
                modal
                    .find('#cancelBtn')
                    .unbind()
                    .click(options, cancel);
            });
        }
    };
});
