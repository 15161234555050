define('app/modules/newcharts/features/carerVisits/views/carerVisit.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment',
	'tpl!app/modules/newcharts/features/carerVisits/templates/carerVisit.template.tmpl',
	'app/modules/newcharts/state'

], function(Marionette, Vent, Commands, Requests, Moment, Template, State) {

	return Marionette.ItemView.extend({

		tagName: 'tr',

		template: Template,

		ui: {
			viewBtn: '.view-btn'
		},

		events: {
			'click @ui.viewBtn': 'viewVisit'
		},

		viewVisit: function(e) {
			var date = moment.utc($(e.toElement).data('date'));

			date.subtract(10, 'minutes');

			State.setType('chart', this.model.get('installId'), {
				date: date,
				zoom: 10
			});

			// Click chart image.
			$('#feature-chart-icon').click();
		}

	});

});
