/**
Users Application - User Remove View
@module UsersApp
@submodule UsersApp.Views.Form.Remove
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Template
	* Commands
	* Requests
Contents:
  * User Item View
		* template
		* ui
		* events
		* initialize
		* removeUser
Author(s):
  * Just Checking
*/

define('app/modules/users/form/views/settings.users.form.views.remove',[

  'marionette',
  'app/app.vent',
  'tpl!app/modules/users/templates/usersFormRemove.tmpl',
  'backbone.crudder',
  'app/commands/commands.vent',
  'app/requests/requests.vent'

], function(Marionette, Vent, Template, Crudder, Commands, Requests) {

  'use strict';

  /**
  User Item View
  @class UsersApp.Views.Form.Remove
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    The template for this view (index.html > templates > users)
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      removeButton: '#user-form-remove-button',
      removeButtonMobile: '#user-form-remove-button-mobile',
      removeUser: '#user-form-remove'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click #user-form-remove-button': 'removeUser',
      'click #user-form-remove-button-mobile': 'removeUser'

    },

    /**
    @method initialize
    */
    initialize: function() {

      // Bind this model to the form validation
      // NOTE: this has been disabled due to conflicts with
      // backbone relational
      // Backbone.Validation.bind(this);

      // Bind to any model change events
      // this.listenTo(this.model, 'change', this.render, this);
    },

    /**
    @method removeUser
    @param e {Object} Event information
    */
    removeUser: function(e) {

      e.preventDefault();

      var
      that = this,
      currentFragment,
      currentSection;

      /*
      Where are we?
      */

      // Get the current fragment
      currentFragment = Backbone.history.fragment;

      // Fire the function to get a fragment part in global.js
      currentSection = Requests.request('get:fragment:section', {

				currentFragment: currentFragment,
				limit: 1,
				removeLastChar: true

			});

      // Wait for confirmation from the user
      if (window.confirm('Are you sure? If you remove this user, they can no longer log in.')) {

        //console.log(this.model);

        // Remove this model via crudder
				Crudder.deleting({

					entity: this.model,
					successMessage: this.model.get('name') + ' has been removed',
					errorMessage: 'Unable to remove ' + this.model.get('name')

				}, function(options) {

					// Has the request beed successful?
					if (options.success) {

						// Trigger the new:user event in the controller
            Vent.trigger('new:user');

            // Are we in 'mobile' mode? (set in offcanvas.js)
            if (window.AppMode === 1) {

              // Set the form changes variable to false
              Commands.execute('reset:panel');

            }

            // Set the form changes variable to false
            Commands.execute('set:change:false');

					}

				});

      }

    }

  });

});

