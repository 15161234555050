define('app/modules/newcharts/models/feature-preview.model',['require','backbone'],function (require) {
	'use strict';

	var Backbone = require('backbone');

	return Backbone.Model.extend({

		idAttribute: '_id',

		defaults: {
			feature: '',
			rating: 0,
			comments: ''
		},

		url: function() {
			return 'api/v1/FeaturePreview/' + this.id;
		},

		isNew: function() {
			return true;
		}
	});

});

