/**
Application Components - Notifications Collection
@module Components
@submodule Components.Notifications.Collection
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Notifications Collection
Author(s):
  * Just Checking  
*/

define('app/components/notifications/components.notifications.collection',[

  'backbone',
  'app/components/notifications/components.notifications.model'

], function(Backbone, Model) {

  'use strict';

  /**
  Notifications Collection
  @class NotificationsApp.Collection
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    @property model
    @type Object
    */
    model: Model

  });

});
