define('app/views/views.lightboxView',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/modules/charts/templates/charts.templates.lightbox.tmpl','app/views/view.bathroomView','app/views/view.roomoccupancyView','app/app','app/app'],function (require) {
	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		LightboxTemplate 	= require('tpl!app/modules/charts/templates/charts.templates.lightbox.tmpl'),
		bathroomView		= require('app/views/view.bathroomView'),
		roomoccupancyView 	= require('app/views/view.roomoccupancyView');

		return Marionette.ItemView.extend({

			template: LightboxTemplate,

			ui: {
				bathroomVists: '.bathroomvists',
				roomOccupancy: '.roomoccupancy',
				overlayclick : '.overlay',
				exitclick : '.exitform',
			},

			events: {
				'click @ui.bathroomVists' : 'bathroomshow',
				'click @ui.roomOccupancy' : 'roomoccupancyshow',
				'click @ui.overlayclick' : 'closelightbox',
				'click @ui.exitclick' : 'closelightbox'
			},

			initialize:function () {
			},

			bathroomshow: function () {
				var App = require('app/app');

				// override url stuff and appent query string for bathroom visits
				this.model.url = function() {
				  return 'api/v1/' + this.urlRoot + '/' + this.id + '?type=bathroomVisits';
				};

				this.model.fetch({
					success: function (data){
						App.lightbox.show(new bathroomView({model: data}));
					}
				});

			},

			roomoccupancyshow: function () {
				var App = require('app/app');

				// override url stuff and appent query string for room occupancy
				this.model.url = function() {
				  return 'api/v1/' + this.urlRoot + '/' + this.id + '?type=roomOccupancy';
				};

				this.model.fetch({
					success: function (data){
						App.lightbox.show(new roomoccupancyView({model: data}));
					}
				});
			},

			closelightbox:function() {
				$('.overlay').hide();
				$('#lightboxcontainer').hide();
			},

			onRender:function () {
				$('#lightboxcontainer').html(LightboxTemplate);
				$('.overlay').show();
				$('#lightboxcontainer').show();
			}

		});

});

