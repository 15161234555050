/**
Application Commands - Stop Module
@module Commands
@submodule Commands.StopModule
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
  	* Stop Module
Author(s):
  * Just Checking
*/

define('app/commands/commands.stopModule',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Stop Module
			@event stop:module
			@param module {String} The module name
			*/
			return Commands.setHandler('stop:module', function(options) {

				var App = require('app/app');

				// Has an array been supplied?
				if (_.isArray(options)) {

					// Loop through each item
					_.each(options, function(object, index) {

						// Has a module name been supplied?
						if (_.isString(object.module)) {

							// Stop the module
							App.module(object.module).stop();
							
						}
						else {

							console.warn('An invalid module has been supplied to stop');

							return;

						}

					});

				}
				else {

					// Has a module name been supplied?
					if (_.isString(options.module)) {

						// Stop the module
						App.module(options.module).stop();
						
					}
					else {

						console.warn('No valid module supplied to stop');

						return;

					}

				}

			});

    }

  };

});

