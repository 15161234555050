/**
Application Commands - App Navigate Back
@module Commands
@submodule Commands.AppNavigateBack
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
Contents:
  * Module Initialize
		* App Navigation Back
Author(s):
  * Just Checking
*/

define('app/commands/commands.appNavigateBack',[

  'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			App Navigation Back
			@event app:navigate:back
			*/
			return Commands.setHandler('app:navigate:back', function(options) {

				// Options are not required, so...
				options = options || {};

				// Set any options defaults
				_.defaults(options, {

					trigger: true

				});

				// Back to the previous page
				Backbone.history.back(options.trigger);

			});

    }

  };

});

