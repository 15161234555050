define('app/modules/newcharts/views/main-header.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/main.header.tmpl',
	'tpl!app/modules/newcharts/templates/feature-icon.template.tmpl',
    'moment',
    'app/modules/newcharts/state',
	'app/behaviors/behaviors.tooltip'

], function(Marionette, Vent, Commands, Requests, Template, FeatureIconTemplate, Moment, State, Tooltip) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            featureBar: '#feature-bar',
            featureImg: '#feature-bar img'
        },

        events: {
            'click @ui.featureImg': 'switchFeature'
        },

		behaviors: {
			Tooltip: {
				behaviorClass: Tooltip
			}
		},

        featureIcons: function() {
			var defaultFeatures = [
				{
					type: 'chart',
					image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/chart.png',
					imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/chart_hover.png',
					imageBounce: '',
					tooltip: 'Chart View',
					bounce: false
				},
				// {
				// 	type: 'bathroom',
				// 	image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/bathroom.png',
				// 	imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/bathroom_hover.png',
				// 	imageBounce: '',
				// 	tooltip: 'Bathroom Visits',
				// 	bounce: false
				// },
				{
					type: 'multi',
					image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/multi.png',
                    imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/multi_hover.png',
					imageBounce: '',
					tooltip: 'Multiday View',
					bounce: false
                }
            ];

			if(this.model.get('chartMenu').canAccessDoorActivity) {
				defaultFeatures.push(
					{
						type: 'doorActivity',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/doorActivity.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/doorActivity_hover.png',
						imageBounce: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/doorActivity_bounce.png',
						tooltip: 'Door Activity',
						bounce: this.model.get('chartMeta').bounceDoorActivity
					}
				)
			} else if(this.model.get('chartMenu').doorActivityPreview != null && new Moment(this.model.get('chartMenu').doorActivityPreview).isAfter(new Moment())) {
				defaultFeatures.push(
					{
						type: 'doorActivity',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/doorActivity_beta.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/doorActivity_beta_hover.png',
						imageBounce: '',
						tooltip: 'Door Activity',
						bounce: false
					}
				)
			}

			if (this.model.get('chartMenu').notificationsPreview != null && new Moment(this.model.get('chartMenu').notificationsPreview).isAfter(new Moment())) {
				defaultFeatures.push(
					{
						type: 'notifications',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications_beta.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications_beta_hover.png',
						imageBounce: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications_beta.png',
						tooltip: 'Notifications',
						bounce: this.model.get('chartMeta').bounceNotifications
					}
				);
			} else if (this.model.get('chartMenu').canAccessAlerts) {
				defaultFeatures.push(
					{
						type: 'notifications',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications_hover.png',
						imageBounce: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications.png',
						tooltip: 'Notifications',
						bounce: this.model.get('chartMeta').bounceNotifications
					}
				);
			} else if (this.model.get('chartMenu').canAccessFamilyAlerts) {
				defaultFeatures.push(
					{
						type: 'notifications_family',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications_hover.png',
						imageBounce: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/notifications.png',
						tooltip: 'Notifications',
						bounce: this.model.get('chartMeta').bounceNotifications
					}
				);
			}

			if (this.model.get('chartMeta').moonshot || this.model.get('chartMeta').services.enhanced_sequences.enabled) {
				defaultFeatures.push(
					{
						type: 'postassessment',
						image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/postassessment.png',
						imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/postassessment_hover.png',
						imageBounce: '',
						tooltip: 'Post Assessment',
						bounce: false
					}
				);
			}

			if (this.model.get('chartMenu').canAccessEvents) {
				defaultFeatures.push({
					type: 'events',
					image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/events.png',
					imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/events_hover.png',
					imageBounce: '',
					tooltip: 'Sequences',
					bounce: false
				});
			}

			if (this.model.get('chartMenu').canAccessCarerVisits) {
				defaultFeatures.push({
					type: 'carerVisits',
					image: 'http://via.placeholder.com/573x573',
					imageHover: 'http://via.placeholder.com/573x573?text=Hover+Image',
					imageBounce: '',
					tooltip: 'Carer Visits',
					bounce: this.model.get('chartMeta').bounceCarerVisits
				})
			}

			if (this.model.get('chartMenu').canAccessHeatLight) {
                defaultFeatures.push({
                    type: 'heatLight',
                    image: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/heatLight.png',
                    imageHover: window.location.protocol + '//' + window.location.host + '/assets/images/png/featurebar/heatLight_hover.png',
                    imageBounce: '',
                    tooltip: 'Heat and light',
                    bounce: false
                })
            }

            return defaultFeatures;
		},

		onRender: function() {
            this.renderFeatureIcons(this.model.get('type'));
        },

        renderFeatureIcons: function(feature) {
            var buttonsArr = [],
				$html = $('html'),
				isMobile = $html.hasClass('touch') && !$html.hasClass('mouse');

            _.each(this.featureIcons(), function(iconObj) {
            	iconObj.isMobile = isMobile;
				iconObj.activeFeature = feature;
				buttonsArr.push(FeatureIconTemplate(iconObj));
				/*
                if (iconObj.type == feature || (iconObj.type == 'doorActivity' && feature == 'nonOccupancy')) {
                    buttonsArr.push("<img id=\"feature-" + iconObj.type + "-icon\" class=\"active\" src=\"" + iconObj.imageHover + "\" alt=\"\" data-type=\"" + iconObj.type + "\" data-tooltip=\"" + (isMobile ? 'false' : 'true') + "\" data-tooltip-text=\"" + iconObj.tooltip + "\" data-tooltip-placement=\"top\">");
                } else {
                    buttonsArr.push("<img id=\"feature-" + iconObj.type + "-icon\" class=\"" + bounceClass + "\" src=\"" + iconObj.image + "\" alt=\"\" data-type=\"" + iconObj.type + "\" data-tooltip=\"" + (isMobile ? 'false' : 'true') + "\" data-tooltip-text=\"" + iconObj.tooltip + "\" data-tooltip-placement=\"top\">");
                }
                */
            }.bind(this));

            this.ui.featureBar.html(buttonsArr.join(''));
        },

        switchFeature: function(e) {
            function doIt() {
                var newType = $(e.target).data('type');

                if (newType != this.model.get('type')) {
                    // Turn off old events
                    Vent.off('newchart:multi-change-dates');
                    Vent.off('newchart:datepicker');

                    // Check State for doorActivity
                    switch(newType) {
                        case 'doorActivity':
                            var lastTab = State.getLastDoorActivityTab(this.model.get('chartMeta').installationId, 'doorActivity');

                            if (lastTab != 'doorActivity') {
                                newType = lastTab;
                            }
                            break;
                    }

                    Commands.execute('app:navigate', {
                        route: 'systems/' +
                        this.model.get('chartMeta').systemNo +
                        '/newcharts/' +
                        this.model.get('chartMeta').installationId +
                        '/' + newType
                    });
                } else if(newType == 'notifications' || newType == 'notifications_family') {
                	Vent.trigger('newchart:notification-list');
				} else if(newType == 'events') {
					Vent.trigger('newchart:event-list');
				}
            }

            // Check note?
            if (this.model.get('type') == 'chart') {
                Vent.trigger('newchart:check-note', doIt.bind(this));
                return;
            }

            doIt.bind(this)();
        }

    });

});
