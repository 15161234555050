/**
Chart Application - Form View
@module Chart
@submodule Chart.Views.Edit.Form
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Form View
    * template
    * ui
    * events
    * initialize
    * onRender
    * formSubmitted
    * enableRow
    * removeSensorOption
    * addSensorOptions
    * addSensorOption
    * resetCombo
    * save
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.edit.form',[

  'marionette',
  'app/app.vent',
  'backbone.crudder',
  'backbone.syphon',
  'mobiscroll',
	'app/commands/commands.vent',
  'tpl!app/modules/charts/templates/charts.templates.edit.form.tmpl',
  'app/requests/requests.vent',
  'switcher',
  'utils',
  'app/modules/newcharts/features/notifications/views/notification-setup.view',
	'app/behaviors/behaviors.calendar',
	'app/behaviors/behaviors.showCalendar'

], function(Marionette, Vent, Crudder, Syphon, Mobiscroll, Commands, Template, Requests, Switcher, Utils, NotificationSetupView, Calendar, ShowCalendar) {

  'use strict';

  /**
  Form View
  @class Chart.Views.Edit.Form
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      sensorCombo: '#chart-sensor-list',
      cancelButton: '#chart-edit-cancel',
      saveButton: '#chart-edit-save',
      occupancySwitchContainer: '#chart-edit-occupancy-container',
      occupancySwitch: '#chart-edit-occupancy',
	  familyAlertsSwitchContainer: '#chart-edit-family-alerts-container',
      familyAlertsSwitch: '#chart-edit-family-alerts',
      sensorList: '#chart-sensor-list',
      sensorListIndicator: '#chart-sensor-list-indicator',
      chartTitle: '#chart-edit-title',
      chartReference: '#chart-edit-reference',
      chartNonOccupancy: '#chart-edit-occupancy',
      addAllMotionDoorBtn: '#add-all-motion-door',
      removeAllMotionDoorBtn: '#remove-all-motion-door',
      addAllActivityBtn: '#add-all-activity-sensors',
      removeAllActivityBtn: '#remove-all-activity-sensors',

	  // Moonshot
	  chartReasonDropdown: '[name="moonshotData[chartReasonDropdown]"]',
	  chartReasonOtherWrapper: '#other-field',
	  chartReason: '#chart-edit-reason',

	  moonshotDateLabel: '.moonshot-date-label',

	  // Services
	  toggleServiceBtn: '.toggleService'
    },

    /**
    @property events
    @type Object
    */
    events: {

    	'change @ui.sensorCombo': 'enableRow',
      	'submit form': 'formSubmitted',
		'change @ui.familyAlertsSwitch': 'familyAlertsModal',
		'change @ui.chartReasonDropdown': 'changeChartReasonDropdown',
		'click @ui.toggleServiceBtn': 'toggleService',
        'click @ui.addAllMotionDoorBtn': 'addAllMotionDoor',
        'click @ui.removeAllMotionDoorBtn': 'removeAllMotionDoor',
        'click @ui.addAllActivityBtn': 'addAllActivity',
        'click @ui.removeAllActivityBtn': 'removeAllActivity',
    },

	templateHelpers: {
    	currentService: function() {
    		for(var service in this.services) {
    			if(this.services[service].enabled) {
    				return this.services[service];
				}
			}

			return null;
		}
	},

  /**
   @property behaviors
   @type Object
   */
  behaviors: {
	  ShowCalendar: {
		  behaviorClass: ShowCalendar
	  },

	  Calendar: {
		  behaviorClass: Calendar,
		  showDate: true,
		  showTime: true,
		  showNow: true,
		  onSelect: function(valueText, inst, view, modelAttribute) {
		  	view.ui.moonshotDateLabel.text(moment(inst.val).format('DD/MM/YYYY HH:mm'));
		  }
	  }
  },

    /**
    @method initialize
    */
    initialize: function() {

      // Listen to add events on the collection
      this.listenTo(this.collection, 'add', this.addSensorOption);

      // Listen to remove events on the collection
      this.listenTo(this.collection, 'remove', this.removeSensorOption);

    },

    /**
    @method onRender
    */
    onRender: function() {

      /*
      Add Sensors to Combo
      */

      // Are there any rows?
      if (this.collection.length > 0) {

        // Add the sensor options
        this.addSensorOptions();

      }

      /*
      Non-occupancy Switch
      */

      // Are SMS alerts enabled for this user?
      if (this.model.get('nonOccupancy')) {
        // Make sure the sms checkbox is checked
        this.ui.occupancySwitch.attr('checked', 'checked');
      }
	  if (this.model.get('familyAlerts')) {
		  this.ui.familyAlertsSwitch.attr('checked', 'checked');
	  }

      // Enable the switch
      Switcher.init({

        view: this.ui.occupancySwitchContainer

      });
	  Switcher.init({

		view: this.ui.familyAlertsSwitchContainer

	  });

      /*
      Initial combo state
      */

      this.resetCombo();

    },

    /**
    @method formSubmitted
    */
    formSubmitted: function(e) {
      e.preventDefault();

      // Get the form data
      var data = Backbone.Syphon.serialize(this);

      // Map report not required checkbox
      if (data['moonshotData']) {
          data['moonshotData']['callback_report_required'] = !data['moonshotData']['report_not_required'];
          delete data['moonshotData']['report_not_required'];
      }

	  // Make sure date picker timezone is correct
	  if(data['moonshotData'] && data['moonshotData']['callback_date']) {
	  	data['moonshotData']['callback_date'] = moment(data['moonshotData']['callback_date']).utc().toISOString();
	  }

	  // Update the relevant service
	  data['services'] = this.model.get('services');
	  for(var serviceName in data['services']) {
	  	if (data['services'][serviceName].enabled) {
			data['services'][serviceName].data = data['moonshotData'];
			break;
		}
	  }

      // Set the model
      this.model.set(data, {

        silent: true

      });

    },

    /**
    @method enableRow
    */
    enableRow: function(e) {

      var
      id = this.ui.sensorCombo.find(':selected').val(),
      model = this.collection.get(id);

      // Trigger the enable row event
      this.trigger('enable:row', model);

      e.preventDefault();

    },

    /**
    @method removeSensorOption
    */
    removeSensorOption: function(options) {

      // Remove the option
      this.ui.sensorCombo.find(':selected').remove();

      // Make sure the first option is selected
      this.resetCombo();

    },

    /**
    @method addSensorOptions
    */
    addSensorOptions: function() {

      var that = this;

      // Loop through each disabled row
      _.each(this.collection.models, function(model) {

        // Append an option to the combo
        that.addSensorOption({

          model: model

        });

      });

    },

    /**
    @method addSensorOption
    */
    addSensorOption: function(options) {

      // If no model is supplied, use the latest added to the collection
      _.defaults(options, {

        model: this.collection.last()

      });

      // Append an option to the combo
      this.ui.sensorCombo.append(new Option(options.model.get('chartLineLabel'), options.model.get('chartLineId')));

      // Reset the combo
      this.resetCombo();

    },

    /**
    @method resetCombo
    */
    resetCombo: function() {

      // Make sure the first option is selected
      this.ui.sensorList.find(':first-child').prop('selected', true);

      // If the collection is empty
      if (this.collection.length === 0) {

        // Disable the combo
        this.ui.sensorCombo.prop('disabled', true);

        // Hide the sensor list indicator
        this.ui.sensorListIndicator.hide();

      }
      else {

        // Enable the combo
        this.ui.sensorCombo.prop('disabled', false);

        // Show the sensor list indicator
        this.ui.sensorListIndicator.show();

        // Show the collection length via the sensor list indicator
        this.ui.sensorListIndicator.text(this.collection.length);

      }

    },

    familyAlertsModal: function(e) {
		var App = require('app/app'),
			target = $(e.target).closest('input');

		if (target.is(':checked')) {
			Commands.execute('app:confirmModal', {
				message: "By turning on family notifications for this system, you are allowing users set up as family to create, view and edit notifications on this installation.\n<br />\n<br />\nFamily members are not able to set up notifications on behalf of a professional user.",
				confirmText: 'I understand, please proceed',
				cancelText: 'Turn off family notifications',
				cancelCallback: function() {
					this.ui.familyAlertsSwitchContainer.find('.switch-off').click();
				}.bind(this)
			});
			// App.lightbox.show(new NotificationSetupView({
			// 	accept: function() {
			// 		return true;
			// 	},
			// 	reject: function() {
			// 		this.ui.familyAlertsSwitchContainer.find('.switch-off').click();
            //
			// 		return true;
			// 	}.bind(this)
			// }));
		}
	},

	  changeChartReasonDropdown: function(e) {
		var elem = $(e.target);

	  	if (elem.val() == 'other') {
	  		this.ui.chartReason.val('Other');
			//this.ui.chartReasonOtherWrapper.show();
		} else {
			this.ui.chartReasonOtherWrapper.hide();
			this.ui.chartReason.val(elem.val());
		}
	  },

	  toggleMoonshot: function(e) {
		  e.preventDefault();

		  $.ajax({
			  url: 'api/v1/ToggleMoonshot/' + this.model.id,
			  success: function() {
				  location.reload();
			  },
			  error: function() {
				  alert('could not toggle moonshot')
			  }
		  });
	  },

	  toggleService: function(e) {
	      e.preventDefault();

		  var $target = $(e.target),
			  service = $target.data('service');

		  $target.attr('disabled', 'disabled');

		  service = service.charAt(0).toUpperCase() + service.slice(1);

		  if (service.indexOf('_') > -1) {
		  	var serviceParts = service.split('_');

		  	service = serviceParts[0].charAt(0).toUpperCase() + serviceParts[0].slice(1);
		  	service += serviceParts[1].charAt(0).toUpperCase() + serviceParts[1].slice(1);
		  }

		  var confirm = window.confirm('Are you sure you wish to toggle ' + service + ' Service?');

		  if (confirm) {
		  	// Confirmed, let's do it!
			  $.ajax({
				  url: 'api/v1/Reading/' + this.model.id + '/' + service + '/toggle',
				  success: function() {
					  location.reload();
				  },
				  error: function() {
					  alert('Could not toggle ' + service + ' Service.');
				  }
			  });
		  } else {
		  	$target.removeAttr('disabled');
		  }
	  },

      addAllMotionDoor: function(e) {
        e.preventDefault();

        this.ui.addAllMotionDoorBtn.addClass('pure-button-disabled').attr('disabled', 'disabled');
        this.trigger('enable:all:motion-door');
        this.ui.addAllMotionDoorBtn.removeClass('pure-button-disabled').removeAttr('disabled');
      },

      removeAllMotionDoor: function(e) {
        e.preventDefault();

        this.ui.removeAllMotionDoorBtn.addClass('pure-button-disabled').attr('disabled', 'disabled');
        this.trigger('disable:all:motion-door');
        this.ui.removeAllMotionDoorBtn.removeClass('pure-button-disabled').removeAttr('disabled');
      },

      addAllActivity: function(e) {
        e.preventDefault();

        this.ui.addAllActivityBtn.addClass('pure-button-disabled').attr('disabled', 'disabled');
        this.trigger('enable:all:activity');
        this.ui.addAllActivityBtn.removeClass('pure-button-disabled').removeAttr('disabled');
      },

      removeAllActivity: function(e) {
        e.preventDefault();

        this.ui.removeAllActivityBtn.addClass('pure-button-disabled').attr('disabled', 'disabled');
        this.trigger('disable:all:activity');
        this.ui.removeAllActivityBtn.removeClass('pure-button-disabled').removeAttr('disabled');
      },

  });

});

