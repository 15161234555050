define('app/views/views.outcomePredictionLightbox',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/templates/outcomePredictionLightbox.template.tmpl','backbone.crudder'],function (require) {
	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		LightboxTemplate 	= require('tpl!app/templates/outcomePredictionLightbox.template.tmpl'),
		Crudder				= require('backbone.crudder');

	return Marionette.ItemView.extend({

		template: LightboxTemplate,

		ui: {
			exitclick : '.exitform',
			submitBtn: '#submit',
			outcomes: '#outcomePrediction',
			outcomesOther: '#outcomes-other'
		},

		events: {
			'click @ui.exitclick' : 'closelightbox',
			'click @ui.submitBtn': 'submit',
			'change @ui.outcomes': 'toggleOther'
		},

		initialize:function () {
			$('.overlay').unbind().click(this.closelightbox.bind(this));
		},

		closelightbox:function() {
			$('.overlay').hide();
			$('#lightboxcontainer').hide();

			$('.emailPopup').unbind();

			if (typeof this.options.closeCallback === 'function') {
				this.options.closeCallback();
			}
		},

		onRender:function () {
			$('#lightboxcontainer').html(LightboxTemplate(this.model.toJSON()));
			$('.overlay').show();
			$('#lightboxcontainer').show();
		},

		onShow: function() {
			$('.emailPopup').animate({scrollTop: 0}, 600);
		},

		submit: function(e) {
			e.preventDefault();

			this.model.set({
				outcomePrediction: this.ui.outcomes.val() === 'other' ? this.ui.outcomesOther.val() : this.ui.outcomes.val()
			});

			// Crudder.
			Crudder.creatingUpdating({
				entity: this.model,
				patch: false,
				successMessage: 'Outcome Prediction saved successfully.'
			}, function(response) {
				// Do nothing.
				if (response.success) {
					this.closelightbox();
				}
			}.bind(this));
		},

		toggleOther: function(e) {
			if ($(e.target).val() === 'other') {
				// Show other box
				this.ui.outcomesOther.show();
			} else {
				// Clear & Hide other box
				this.ui.outcomesOther.val('').hide();
			}
		}

	});

});

