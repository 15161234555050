/**
Chart Application - Collection
@module ChartApp
@submodule ChartApp.Collection
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
Contents:
  * Chart Collection
  * Collection default values
Author(s):
  * Just Checking
*/

define('app/modules/users/settings.users.collection',[

  'backbone',
  'app/modules/users/settings.users.model',
  'app/modules/users/settings.users.accessSelect.model'

], function(Backbone, Model, AccessSelect) {

  'use strict';


  /**
  Chart Collection
  @class ChartApp.Collection
  @constructor
  @extends Backbone.Collection
  */

  return Backbone.Collection.extend({

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model,

    /**
    Point to the source of the json
    @property urlSource
    @type String
    */
    //users/+installationID
    urlSource: 'PermissionAssignments/?installationId='+ window.location.hash.substr(window.location.hash.lastIndexOf('/charts/') + 8, window.location.hash.lastIndexOf('/users') - (window.location.hash.lastIndexOf('/charts/') + 8)),
    //urlSource: 'PermissionAssignments',
    /**
    Parse the response from the API
    @method parse
    @param response {Object} The response from the server
    */
    parse: function(response) {
      //redirect the user to the carerplus family user pages if its a family kit
      if(response.jcData.redirectToCarerPlus === true){
        //set a cookie so we know where to come back to after handing off to the cp pages
        //$.removeCookie("interface");
	    if (typeof $.cookie('interface') === 'undefined') {
			$.cookie("interface", window.location.href.substr(0, window.location.href.lastIndexOf('/users')));
		}
        window.location.href = "/justupandabout.com/api/wwwroot/load.php?go=home&country=GB&referer=https%3A%2F%2Fapp.justchecking.com%2Finterface&iid="+ window.location.hash.substr(window.location.hash.lastIndexOf('/charts/') + 8, window.location.hash.lastIndexOf('/users') - (window.location.hash.lastIndexOf('/charts/') + 8));
      }

      if(response.jcData._addUserSpecification){
        //Create a new model to hold the access level assignments
        var accessSelect = new AccessSelect();
        accessSelect.set("userType", response.jcData._addUserSpecification.userType);
        accessSelect.set("scope", response.jcData._addUserSpecification.scope);
        accessSelect.set("actions", response.jcData._addUserSpecification.actions);

        //Hackily bind the accessModel as an attribute on the collection
        this.accessSelectModel = accessSelect;

      }



      // Return the data to create the collection
      return response.jcData.users;

    }
  });

});


// {
//     "success": true,
//     "message": "Data retrieved successfully",
//     "data": [
//         {
//             "userId": 1056,
//             "name": "Customer Support",
//             "mobileNumber": "07747792311",
//             "email": "support2@justchecking.co.uk",
//             "enableSmsAlerts": false,
//             "enableEmailAlerts": false,
//             "carerPlusEnabled": true,
//             "userCanMakeChanges": true
//         },
//         {
//             "userId": 15360,
//             "name": "Joel Price",
//             "mobileNumber": "077411111300",
//             "email": "joe.pric2e@justchecking.co.uk",
//             "enableSmsAlerts": false,
//             "enableEmailAlerts": true,
//             "carerPlusEnabled": true,
//             "userCanMakeChanges": false
//         },
//         {
//             "userId": 15539,
//             "name": "Rajni",
//             "mobileNumber": "074400448440",
//             "email": "test@jc.com",
//             "enableSmsAlerts": true,
//             "enableEmailAlerts": true,
//             "carerPlusEnabled": true,
//             "userCanMakeChanges": true
//         },
//         {
//             "userId": 15540,
//             "name": "Rajni DOEL",
//             "mobileNumber": "074400999880",
//             "email": "123456@JC.COM",
//             "enableSmsAlerts": true,
//             "enableEmailAlerts": false,
//             "carerPlusEnabled": true,
//             "userCanMakeChanges": false
//         }
//     ]
// }




// Collection validation
    // validation: {

    //   name: {

    //     required: true,
    //     msg: 'Please enter the user\'s name'

    //   },

    //   email: {

    //     required: true,
    //     pattern: 'email',
    //     msg: 'Please enter a valid email address e.g. joe@gmail.com'

    //   }

    // }
;
