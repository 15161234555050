/**
Application Layout - Header Layout
@module Layout
@submodule Layout.Layouts.Header
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
Contents:
  * Layout Header View
		* Template
		* UI
		* Events
		* Regions
		* onRender
		* chartNavigate
Author(s):
  * Just Checking
*/

define('app/layout/layouts/layout.layouts.header',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'tpl!app/layout/templates/layout.templates.header.tmpl'

], function(Marionette, Commands, Requests, Template) {

  'use strict';

  /**
  Layout Header View
  @class Layout.Layout.Header
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {

      chartLink: '.back-to-chart'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click .back-to-chart': 'chartNavigate'

    },

    /**
    @property regions
    @type Object
    */
    regions: {

      menu: '#page-header-menu',
      topBar: '#page-header-topbar',
      notifications: '#page-notifications'

    },

    /**
    @method onRender
    */
    onRender: function() {

    },

    /**
    @method chartNavigate
    */
    chartNavigate: function(e) {

			e.preventDefault();

			// Get the href of the target link
			var href = $(e.target).attr('data-hash');

			// Form / Model changes check and check what mode we are in
			if (Requests.request('get:change:status')) {

				// Show the message warning changes have been made
				// Commands.execute('app:modal', {

				//  template: TemplateModalFormChanges,
				//  route: href,
				//  trigger: false

				// });

			}
			else {

				// Send the user to the chart
				window.location = href;

			}

    }

  });

});

