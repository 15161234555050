/**
Application Commands - Update Top Bar
@module Commands
@submodule Commands.WhenFetched
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
    * Update Top Bar
Author(s):
  * Just Checking
*/

/*
Usage:
Commands.execute('update:topbar', {

  title: {
  
    text: that.models.meta.get('chartTitle')

  },
  buttonLeft: {

    text: 'Chart'

  },
  buttonRight: {

    text: 'Chart'

  },
  buttonOptional: {

    visible: true,
    icon: 'icon-menu',
    text: 'Menu'

  },
  reset: false

});
*/

define('app/commands/commands.updateTopbar',[

	'backbone',
	'app/commands/commands.vent'

], function(Backbone, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Update Top Bar
			@event update:topbar
			*/
			return Commands.setHandler('update:topbar', function(options) {

				var App = require('app/app');

        // We require a header view
        if (App.header.currentView) { 

          var
          topbarView = App.header.currentView.topBar.currentView,
          titleModel = topbarView.title.currentView.model,
          buttonLeftModel = topbarView.buttonLeft.currentView.model,
          buttonRightModel = topbarView.buttonRight.currentView.model,
          buttonOptionalModel = topbarView.buttonOptional.currentView.model;

          // Set the default options
          _.defaults(options, {

            reset: false,
            title: false,
            buttonLeft: false,
            buttonRight: false,
            buttonOptional: false

          });

          // Has a reset been requested?
          if (options.reset) {

            // Reset the topbar models
            // titleModel.reset();
            buttonLeftModel.reset();
            buttonRightModel.reset();
            buttonOptionalModel.reset();

          }
          else {

            // Does the title need updating?
            if (options.title) {
         
              // Update the model
             titleModel.set(options.title);

            }

            // Does the left button need updating?
            if (options.buttonLeft) {
          
              // Update the model
              buttonLeftModel.set(options.buttonLeft);

            }

            // Does the right button need updating?
            if (options.buttonRight) {
          
              // Update the model
              buttonRightModel.set(options.buttonRight);

            }

            // Does the optional button need updating?
            if (options.buttonOptional) {
          
              // Update the model
              buttonOptionalModel.set(options.buttonOptional);

            }

          }

        }
        else {

          return;

        }
      
      });

    }

  };

});

