/**
Application Controllers - Buttons
@module Controllers
@submodule Controllers.Abstract.Buttons
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Buttons Controller
		* initialize
		* getFormButtons
		* getDefaultButtons
Author(s):
  * Just Checking
*/

define('app/controllers/abstract/controllers.abstract.buttons',[

  'marionette',
  'app/collections/abstract/collections.abstract.buttons'

], function(Marionette, ButtonCollection) {

  'use strict';

  /**
  Buttons Controller
  @class Controllers.Abstract.Buttons
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {

			// Create our new button collection
			this.collection = new ButtonCollection();

    },

    /**
    @method getFormButtons
    */
    getFormButtons: function(buttons, model) {

      var
      array = [],
      buttonCollection;
      
      // Get the button defaults
      buttons = this.getDefaultButtons(buttons, model);

      // Is a primary button required?
      if (buttons.primary !== false) {
        
        // Push prumary button settings into the array
        array.push({
          
          type: 'primary',
          // className: 'button small radius',
          reference: buttons.reference,
          text: buttons.primary,
          buttonType: 'submit',
          placement: buttons.placement
        
        });
      
      }
      
      // Is a cancel button required?
      if (buttons.cancel !== false) {
        
        // Push cancel button settings into the array
        array.push({
          
          type: 'cancel',
          className: (buttons.placement === 'right') ? 'margin-right' : 'margin-left',
          text: buttons.cancel,
          buttonType: 'button',
          placement: buttons.placement
        
        });
      
      }

      // Add the array of buttons to our collection
      this.collection.add(array);
      
      // Set the placement value on the collection
      this.collection.placement = buttons.placement;
      
      return this.collection;
    
    },

    /**
    @method getDefaultButtons
    */
    getDefaultButtons: function(buttons, model) {

			// Return the button defaults if they don't exist
      return _.defaults(buttons, {
        
        primary: 'Submit',
        cancel: false,
        placement: 'right'
      
      });
    
    }

	});

});

