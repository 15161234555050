/**
Application Components - Loader Controller
@module Components
@submodule Components.loader.Controller
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
Author(s):
  * Just Checking
*/

define('app/components/loader/components.loader.controller',[

  'marionette',
  'app/commands/commands.vent',
  'app/components/loader/components.loader.view'

], function(Marionette, Commands, LoaderView) {

  'use strict';

  /**
  Main App Controller
  @class MainApp.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var
      App = require('app/app'),
      loaderView,
      originalView;

      _.defaults(options, {

        loadingType: 'spinner'

      });

      // Debug
      if (options.debug) {

        console.log('loader initialize');
        console.log('options:');
        console.log(options);

      }

      // Has a spinner loader been requested?
      if (options.loadingType === 'spinner') {

        // Get a new loader view
        loaderView = this.getLoaderView(options);

        // Show the loader in the target region
        options.region.show(loaderView);

      }
      else {

        // Remember the original view
        originalView = options.region.currentView;

        // Has a wrapper for the default target el been requested?
        if (options.defaultOpacityTarget) {

          // Add opacity wrapper to main region's current view
          App.main.currentView.addOpacityWrapper({

            add: true

          });

        }
        else {

          // Add opacity wrapper to the target region's current view
          options.region.currentView.addOpacityWrapper({

            add: true

          });

        }

      }

      // Do we need to close the section header region?
      if (options.closeSectionHeader) {

        // Close the section header
        this.closeSectionHeader(options);

      }

      // Show the real view
      this.showRealView(options, loaderView, originalView);

    },

    /**
    @method showRealView
    */
    showRealView: function(options, loaderView, originalView) {

      var that = this;

      // debug mode
      if (options.debug) {

        console.log('loader show real view');
        console.log('loader view:');
        console.log(loaderView);
        console.log('original view:');
        console.log(originalView);

      }

      // Listen for the entities being fetched
      Commands.execute('when:fetched', options.entities, function() {

        // debug mode
        if (options.debug) {

          console.log('entities have been fetched');

        }

        // Has a spinner loader been requested?
        if (options.loadingType === 'spinner') {

          // Is the current region view the loader view we created?
          if (options.region.currentView === loaderView) {
          
            // Show the view in the main region
            options.region.show(options.view);

          }
          else {

            // Manually close the view (and controller)
            options.view.close();
          
          }

        }
        else {

          // Has a wrapper for the default target el been requested?
          if (options.defaultOpacityTarget) {

            // Remove opacity wrapper to main region's current view
            App.main.currentView.addOpacityWrapper({

              add: false

            });

          }
          else {

            // Remove opacity wrapper to the target region's current view
            options.region.currentView.addOpacityWrapper({

              add: false

            });

          }

          // Is the current region view the loader view we created?
          if (options.region.currentView === originalView) {
          
            // Show the view in the main region
            options.region.show(options.view);

          }
          else {

            // Manually close the view (and controller)
            options.view.close();
          
          }

        }

        // Close this controller
        that.close();

      }, false);

    },

    /**
    @method getLoaderView
    */
    getLoaderView: function(options) {

      return new LoaderView(options);

    },

    /**
    @method closeSectionHeader
    */
    closeSectionHeader: function(options) {

      // Close the section header
      Commands.execute('close:section:header', {

        debug: options.debug,
        opacity: (options.loadingType === 'opacity')

      });

    }

  });

});
