/**
Chart Application - Controls Model
@module Chart
@submodule Chart.Models.Controls
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Controls Model
    * defaults
Author(s):
  * Just Checking
*/

/*
Example Data:
  "navData": "2014-01-06 00:00:00",
  "navLabel": "2014-01-06 00:00:00",
  "active": true
*/

define('app/modules/charts/models/charts.models.controls.item',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Chart Controls Model
  @class Chart.Models.Controls
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property defaults
    @type Object
    */
    defaults: {

      active: false

    }

  });

});
