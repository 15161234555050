/**
AJAX related scripts
@module AJAX
*/

/*
Requires:
  * jQuery
Contents:
  * Intialise the AJAX Settings
		* Set a global auth function for all ajax requests
		* Global AJAX error checks
  * AJAX loader overlay
  * Return the module
Author(s):
  * Just Checking
*/

define('switcher',[

  'jquery.hammer'

], function() {

  'use strict';

  var Switcher = {

    /**
    @method init
    */
    init: function(options) {

      var
      that = this,
      container = options.view.find('.switch-option'),
      switches = container.find('ul'),
      onSwitch = switches.find('#switch-on'),
      offSwitch = switches.find('#switch-off'),
      checkbox = container.find('input[type="checkbox"]');

      // console.log(container);
      // console.log(switches);
      // console.log(onSwitch);
      // console.log(offSwitch);
      // console.log(checkbox);

      /*
      Delegated event listener
      */

      switches.children('li').unbind();
      switches.on('click', 'li', function(e) {
      	e.preventDefault();
      	e.stopPropagation();
      	if (!options.view.hasClass('switch-disabled')) {
			// Switch states
			that.switchStates({
				switches: switches,
				checkbox: checkbox,
				trigger: true
			});
		}

        return false;

      });

      /*
      Initial UI Settings
      */

      // This is all based on the checked state of the checkbox supplied in the view
      if (checkbox.is(':checked')) {

        // Set switch to on
        this.switchOn({

					trigger: false,
					switches: switches,
					onSwitch: onSwitch,
					offSwitch: offSwitch,
					checkbox: checkbox

        });

      }
      else {

        // Set switch to off
        this.switchOff({

					trigger: false,
					switches: switches,
					onSwitch: onSwitch,
					offSwitch: offSwitch,
					checkbox: checkbox

        });

      }

      return false;

    },

    /**
    @method switchStates
    */
    switchStates: function(options) {

      var
      currentOnSwitch = options.switches.find('.switch-on'),
      currentOffSwitch = options.switches.find('.switch-off');

      // Switch classes
      currentOnSwitch.removeClass('switch-on');
      currentOnSwitch.addClass('switch-off');

      currentOffSwitch.removeClass('switch-off');
      currentOffSwitch.addClass('switch-on');

      // Is the checkbox not checked?
      if (!options.checkbox.is(':checked')) {

        // Set the checkbox to checked
        options.checkbox.prop('checked', true);

      }
      else {

        // Uncheck the checkbox
        options.checkbox.prop('checked', false);

      }

      // Has a change event been requested
      if (options.trigger) {

        // Trigger a change event
        options.checkbox.trigger('change');

      }

    },

    /**
    @method switchOn
    */
    switchOn: function(options) {

      // Change the on switch class
      options.onSwitch.attr('class', 'switch-on');

      // Change the off switch class
      options.offSwitch.attr('class', 'switch-off');

      // Set the checkbox to checked
      options.checkbox.prop('checked', true);

      // Enabled swipe event on the button
      this.enableSwipe({

				switches: options.switches

      });

      // Has a change event been requested
      if (options.trigger) {

        // Trigger a change event
        options.checkbox.trigger('click');

      }

    },

    /**
    @method switchOff
    */
    switchOff: function(options) {

      // Change the on switch class
      options.onSwitch.attr('class', 'switch-off');

      // Change the off switch class
      options.offSwitch.attr('class', 'switch-on');

      // Uncheck the checkbox
      options.checkbox.prop('checked', false);

      // Enabled swipe event on the button
      this.enableSwipe({

				switches: options.switches

      });

      // Has a change event been requested
      if (options.trigger) {

        // Trigger a change event
        options.checkbox.trigger('click');

      }

    },

    /**
    @method enableSwipe
    */
    enableSwipe: function(options) {

      var that = this;

      /*
      Hammer Time!
      */

      // Bind hammer to the switches
      options.switches.hammer().on('swipe', function(ev) {

        var
        container = $(ev.target).parent().parent(),
        switches = container.find('ul'),
        checkbox = container.find('input[type="checkbox"]');

        // Is this a swipe right?
        if (ev.direction === 'right') {

          // Switch the current state of the switch
          that.switchStates({

						switches: switches,
						checkbox: checkbox,
						trigger: true

					});

        }
        // Is this a swipe right?
        else if (ev.direction === 'left') {

          // Switch the current state of the switch
          that.switchStates({

						switches: switches,
						checkbox: checkbox,
						trigger: true

					});

        }

      });

    }

  };

  return Switcher;

});

