/**
Application Logins - Logins List View
@module Logins
@submodule Logins.Views.List
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* ItemView
Contents:
  * Logins List View
    * template
    * itemView
    * itemViewContainer
    * emptyView
Author(s):
  * Just Checking
*/

define('app/modules/logins/views/logins.views.list',[

  'marionette',
  'tpl!app/modules/logins/templates/logins.templates.list.tmpl',
  'app/modules/logins/views/logins.views.item',
  'app/views/views.noItems'

], function(Marionette, Template, ItemView, NoItemsView) {

  'use strict';

  /**
  Logins List View
  @class Logins.Views.List
  @constructor
  @extends Marionette.CompositeView
  */
  return Marionette.CompositeView.extend({

		/**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#logins-list',

    /**
    @property emptyView
    @type Function
    */
    emptyView: function() {

      return new NoItemsView({

        itemName: 'logins',
        border: true,
        marginTop: true

      });

    }

  });

});

