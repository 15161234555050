/**
Login Application - Form View
@module Login
@submodule Login.Views.Form
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Login Form View
    * template
Author(s):
  * Just Checking
*/

define('app/modules/login/views/login.views.form',[

  'marionette',
  'tpl!app/modules/login/templates/login.templates.form.tmpl',
  'app/commands/commands.vent',
  'app/modules/newcharts/state',


], function(Marionette, Template, Commands, State) {

  'use strict';

  /**
  Login Form View
  @class Login.Views.Form
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    initialize: function(){
      // bind all keydown events to the keydown method
      $(document).on('keydown', this.keydown);
    },

    keydown: function(event){
      var isvisible = $('#forgotten-password-form').is(":visible");
      if(event.keyCode === 13 && !isvisible) {
        $("#login-button").click();
      }
      else if (event.keyCode === 13 && isvisible) {
      	$('.forgot-button').click();
      }
    },

    /**
    @property template
    @type String
    */
    template: Template,

    events: {
      "click #login-button": "loginUser",
      "click #forgot-password": "showForgottenPasswordForm"
    },

    ui: {
      inputEmail: "#login-email",
      inputPassword: "#login-password",
      rememberMe: ".rememberCheck"
    },

    loginUser: function(e){

	if (window.external) {
		  //force ie autocomplete message for javascript form submits
	      if(typeof window.external.AutoCompleteSaveForm != 'undefined'){
	        var form = document.getElementById('login-form');
	        window.external.AutoCompleteSaveForm(form);
	      }
	}

     if( $( '#login-form' ).parsley({messages: {required: "This field is required"}}).validate()){

      var url = '/api/v1/Account/session',

      formValues = {
        email: this.ui.inputEmail.val(),
        password: this.ui.inputPassword.val(),
        rememberMe: this.ui.rememberMe.is(':checked') ? 1 : 0
      };

      // console.log($(".rememberCheck").is(':checked') ? 1 : 0);

         var email = this.ui.inputEmail.val().trim();

      $.ajax({
        url:url,
        type:'POST',
        dataType:"json",
        data: formValues,
        success: function(response){
          //TODO: navigate to the last page being viewed before 401 was thrown
            // Set the state
            State.rememberEmail(email);
            State.requestToken(email, formValues.password)
              .then(function() {
                console.log("oauth token set")
                console.log(State.getBearerObject())
                //reload the app from the start
                window.location.href = './';
              })
              .catch(function(err) {
                // Handle any errors that occurred while requesting the token
                console.log("Error attempting to set bearer")
                console.warn(err)
                alert('We cannot retrieve a bearer token from these credentials');
              });

         
        },
        error: function(error){
          //Display an error message to notify the user of the error
          console.warn('CANNOT LOGIN USER!!');
          console.warn(error);
          $('body').trigger('not:okey:dokey');
        }
      });


      }
       e.preventDefault();
    },

    showForgottenPasswordForm: function(e){
      e.preventDefault();
      $( '#forgotten-password-form' ).slideToggle('fast');
      $('#forgot-email').focus();
    },

    /* What to do when the view is rendered */
    onShow: function(){
      $( '#forgotten-password-form' ).hide();
      /* change parsley default text */
     // $( '#login-form' ).parsley({messages: {required: "This field is required"}});
      $( '#forgotten-password-form' ).parsley({messages: {required: "This field is required"}});
    }

  });

});

