/**
Application Components - Panels ItemView
@module Components
@submodule Components.Panels.ItemView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* Template
	* Commands
Contents:
  * Panel Header View
    * template
    * className
    * events
    * ui
    * serializeData
    * closePanel
Author(s):
  * Just Checking
*/

define('app/components/panels/views/panels.views.header',[

  'marionette',
  'app/requests/requests.vent',
  'tpl!app/components/panels/templates/panels.templates.header.tmpl'

], function(Marionette, Requests, Template) {

  'use strict';

  /**
  Panel Header View
  @class Menu.ItemView
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type Template
    */
    template: Template,

    /**
    @property className
    @type String
    */
    className: function() {

      // Has a symbol been requested?
      if (this.options.symbol) {

        return 'bg-symbol extra-padding-left';

      }
      else {

        return;
        
      }

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click .panel-close': 'closePanel'

    },

    /**
    @property ui
    @type Object
    */
    ui: {

      panelTitle: '.panel-title'

    },

    /**
    @method serializeData
    */
    serializeData: function() {

      return {
    
        panelReference: this.options.panelReference,
        title: this.options.title,
        close: this.options.close
      
      };

    },

    /**
    @method closePanel
    */
    closePanel: function(e) {

      // Show the panel via the jQuery plugin
      Requests.request('get:state:data').get('panels').clickEventHandler({

        panelReference: this.options.panelReference

      });

      e.preventDefault();

    }

  });

});

