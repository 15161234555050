/**
Initialise all App requests
@module Requests
@submodule Requests.Init
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
  * Module Initialize
		* Initialise all the required requests
Author(s):
  * Just Checking
*/

define('app/requests/requests.init',[

	'backbone',
	'app/requests/requests.getChangeStatus',
	'app/requests/requests.getCurrentRoute',
	'app/requests/requests.getCurrentLevel',
	'app/requests/requests.getCurrentUser',
	'app/requests/requests.getFragmentItem',
	'app/requests/requests.getFragmentSection',
	'app/requests/requests.getAlertEnabled',
	'app/requests/requests.getAuthToken',
	'app/requests/requests.getFormWrapper',
	'app/requests/requests.getFormButtons',
	'app/requests/requests.getFormattedDate',
	'app/requests/requests.getStateData',
	'app/requests/requests.getFuzzyTime',
	'app/requests/requests.getView'

], function(Backbone, GetChangeStatus, GetCurrentRoute, GetCurrentLevel, GetCurrentUser, GetFragmentItem, GetFragmentSection, GetAlertEnabled, GetAuthToken, GetFormWrapper, GetFormButtons, GetFormattedDate, GetStateData, GetFuzzyTime, GetView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

			/*
			Initialise all the required requests
			*/
			GetChangeStatus.initialize();
			GetCurrentRoute.initialize();
			GetCurrentLevel.initialize();
			GetCurrentUser.initialize();
			GetFragmentItem.initialize();
			GetFragmentSection.initialize();
			GetAlertEnabled.initialize();
			GetAuthToken.initialize();
			GetFormWrapper.initialize();
			GetFormButtons.initialize();
			GetFormattedDate.initialize();
			GetStateData.initialize();
			GetFuzzyTime.initialize();
			GetView.initialize();

    }

  };

});
