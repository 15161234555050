/**
Base Marionette Controller
@module Base
@submodule Base.Marionette.Controller
*/

/*
Requires:
	* Backbone
  * jQuery
  * Underscore
  * Marionette
  * Crudder
Contents:
  * initialize
  * show
  * save
  * close
  * showHeader
Author(s):
  * Just Checking
*/

define('app/base/controllers/marionette/base.controllers.marionette.controller',[

	'marionette',
	'app/commands/commands.vent',
	'app/components/loader/components.loader.controller',
  'app/layout/models/layout.models.main.header',
  'backbone.crudder'

], function(Marionette, Commands, LoaderController, HeaderModel, Crudder) {

  'use strict';

  return _.extend(Marionette.Controller.prototype, {

    /**
    @method initialize
    @param route {String} Route for the 'homepage'
    */
    initialize: function(options) {

      // Add a unique id to the controller
      this._instance_id = _.uniqueId('controller');

      // Register the controller
			Commands.execute('register:instance', this, this._instance_id);

    },

    /**
    @method show
    */
    show: function(options) {

      var App = require('app/app');

      // options are not required
      options = options || {};
     
      // Defaults
      _.defaults(options, {

        entities: undefined,
        loader: false,
        closeSectionHeader: true,
        defaultOpacityTarget: false

      });

      var
      view = options.view || this.layout || this.view,
      region = options.region || this.region || App.main,
      entities = (options.entities) ? options.entities : this.collection || this.model;

      // debug mode
      if (options.debug) {

        console.log('view:');
        console.log(view);
        console.log('region:');
        console.log(region);
        console.log('entities:');
        console.log(entities);

      }

      // Listen to the main layout 'close' event
      this.listenTo(view, 'close', function() {

        // debug mode
				if (options.debug) {
        
         console.log('controller closed');

        }
        
        // Close the controller of the closed view
        this.close();

      });

      // if loader is requested
      if (options.loader) {

        // Show the loader
        var loaderController = new LoaderController({

          view: view,
          region: region,
          entities: entities,
          loadingType: options.loadingType,
          closeSectionHeader: options.closeSectionHeader,
          defaultOpacityTarget: options.defaultOpacityTarget,
          debug: false

        });

     }
     else {

        // Show the view in the main region
        region.show(view);
       
      }

    },

    /**
    @method save
    */
    save: function(options) {

      var App = require('app/app');

      // options are not required
      options = options || {};

      // Defaults
      _.defaults(options, {

        entities: undefined,
        loader: true,
        closeSectionHeader: true,
        defaultOpacityTarget: false

      });

      var
      view = options.view || this.layout || this.view,
      region = options.region || this.region || App.main,
      entities = (options.entities) ? options.entities : this.collection || this.model;

      // debug mode
      if (options.debug) {

        console.log('view:');
        console.log(view);
        console.log('region:');
        console.log(region);
        console.log('entities:');
        console.log(entities);

      }

      // if loader is requested
      if (options.loader) {

        // Show the loader
        var loaderController = new LoaderController({

          view: view,
          region: region,
          entities: entities,
          loadingType: 'opacity',
          defaultOpacityTarget: true,
          debug: false

        });

      }
      else {

        // Show the view in the main region
        return;
        
      }

    },

    /**
    @method close
    */
    close: function() {

     // Delete the controller region
      delete this.region;

      // Delete the controller layout
      delete this.layout;

      // Delete the controller view
      delete this.view;

      // Delete the controller options
      delete this.options;

      /*
			No Super :-(
      */

      this.stopListening();
      this.triggerMethod('close');
      this.unbind();
      
      // Unregister the controller
      Commands.execute('unregister:instance', this, this._instance_id);

    },

    /**
    Show the header
    @method showHeader
    @param title {String} The section title
    @param logo {Boolean} Show the JC logo?
    @param subtitle {String} Sub title view
    */
    showHeader: function(options) {

      // options are not required
      options = options || {};

      _.defaults(options, {

        title: '',
        logo: false,
        subtitle: undefined,
        controls: undefined

      });

      // New header model
      var headerModel = new HeaderModel({

        title: options.title,
        logo: options.logo,
        subtitle: options.subtitle,
        controls: options.controls

      });

      // Execute the section header command
      Commands.execute('show:section:header', {

        model: headerModel

      });

    }

  });

});
