define('app/modules/evaluation/views/views.summaryView',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','d3','tpl!app/modules/evaluation/templates/evaluation.templates.lightbox.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.summary.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.outcomes.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.summary.pdf.tmpl','tpl!app/modules/evaluation/templates/evaluation.templates.outcomes.pdf.tmpl','app/app','app/modules/evaluation/views/views.lightboxView','app/modules/evaluation/models/evaluation.models.home'],function (require) {
    'use strict';

    var Marionette 			    = require('marionette'),
        Vent 				    = require('app/app.vent'),
        Commands 			    = require('app/commands/commands.vent'),
        Requests 			    = require('app/requests/requests.vent'),
        d3                      = require('d3'),
        LightboxTemplate 	    = require('tpl!app/modules/evaluation/templates/evaluation.templates.lightbox.tmpl'),
        SummaryTemplate	        = require('tpl!app/modules/evaluation/templates/evaluation.templates.summary.tmpl'),
        OutcomesTemplate	    = require('tpl!app/modules/evaluation/templates/evaluation.templates.outcomes.tmpl'),
        SummaryPDFTemplate      = require('tpl!app/modules/evaluation/templates/evaluation.templates.summary.pdf.tmpl'),
        OutcomesPDFTemplate      = require('tpl!app/modules/evaluation/templates/evaluation.templates.outcomes.pdf.tmpl');

        return Marionette.ItemView.extend({

            /**
             * Animation speed for the Pie chart fade in/out
             */
            pieAnimationSpeed: 250,

            /**
             * Boolean to store whether the user is on Outcomes or Summary.
             */
            isOnOutcomes: false,

            /**
             * Backbone base template.
             */
            template: LightboxTemplate,

            /**
             * Called when the user closes the lightbox.
             */
            closelightbox:function() {
                $('.overlay').hide();
                $('#lightboxcontainer').hide();
            },

            /**
             * Called when the user clicks the "Go Back" button.
             */
            goBack: function() {
                var App = require('app/app'),
                    HomeView = require('app/modules/evaluation/views/views.lightboxView'),
                    HomeModel = require('app/modules/evaluation/models/evaluation.models.home');

                App.lightbox.show(new HomeView({
                    model: new HomeModel({
                        _id: this.model.get('installationId')
                    })
                }));
            },

            /**
             * Sets up the go back button
             */
            setupGoBack: function(){
                $('#go-back').unbind().click(this.goBack.bind(this));
            },

            /**
             * Called automatically when the base template is rendered.
             */
            onRender: function() {
                $('#lightboxcontainer').html(LightboxTemplate).show();
            },

            /**
             * Called automatically when the base template is shown.
             */
            onShow: function() {
                $('.printcontainer').unbind().click(this.printPDF.bind(this)).show();
                this.loadSummaryReport(true);
            },

            /**
             * Load the summary report.
             * @param fetch (Boolean) whether to fetch data from the server or not.
             */
            loadSummaryReport: function(fetch) {
                var loaderContainer = $('.loader-container'),
                    evaluationContainer = $('#evaluationcontainer');

                $('.charthighlights > h3').text("Summary Report");
                this.isOnOutcomes = false;
                loaderContainer.spin();

                if (fetch == true) {
                    this.model.fetch({
                        success: function() {
                            loaderContainer.spin(false);
                            evaluationContainer.html(SummaryTemplate);

                            this.renderSummary();
                        }.bind(this)
                    });
                } else {
                    loaderContainer.spin(false);
                    evaluationContainer.html(SummaryTemplate);

                    this.renderSummary();
                }
            },

            /**
             * Load the outcomes report.
             * @param fetch (Boolean) whether to fetch data from the server or not.
             */
            loadOutcomesReport: function(fetch) {
                var loaderContainer = $('.loader-container'),
                    evaluationContainer = $('#evaluationcontainer');

                $('.charthighlights > h3').text("Outcomes Report");
                this.isOnOutcomes = true;
                loaderContainer.spin();

                if (fetch == true) {
                    this.model.fetch({
                        success: function() {
                            loaderContainer.spin(false);
                            evaluationContainer.html(OutcomesTemplate);

                            this.renderOutcomes();
                        }.bind(this)
                    });
                } else {
                    loaderContainer.spin(false);
                    evaluationContainer.html(OutcomesTemplate);

                    this.renderOutcomes();
                }
            },

            /**
             * Render the summary report.
             */
            renderSummary: function() {
                this.renderDropdown();
                this.renderPies();
                this.renderCalculations();
                this.renderButtons();
                this.setupGoBack();

                $('#text-report').unbind().click(function(){
                    this.loadOutcomesReport(false);
                }.bind(this));
                $('.exitform,.overlay').unbind().click(this.closelightbox);
            },

            /**
             * Render the outcomes report.
             */
            renderOutcomes: function() {
                this.renderDropdown();
                this.renderOutcomesText();
                this.setupGoBack();

                $('#summary-report').unbind().click(function(){
                    this.loadSummaryReport(false);
                }.bind(this));
                $('.exitform,.overlay').unbind().click(this.closelightbox);
            },

            /**
             * Render the dropdown
             */
            renderDropdown: function () {
                /**
                 * Generate a dropdown option.
                 * @param id        ID for the item
                 * @param scope     Scope for the item.
                 * @param text      Text to display on the dropdown item.
                 * @param indent    Indenting to add to the dropdown item.
                 * @param selected  Whether the item is selected or not.
                 * @returns {string} HTML for the dropdown option.
                 */
                function generateOption(id, scope, text, indent, selected) {
                    var html = "<option " +
                        "value='" + id + "' " +
                        "data-scope='" + scope + "'";

                    if (selected) {
                        html += " selected";
                    }

                    return html += ">" + indent + text + "</option>";
                }

                var dropdown = $('#scope-dropdown'),
                    html = "";

                // Reset and remove events
                dropdown.unbind().html('<option value="">Loading...</option>');

                // Add "All Assessments" option
                html += generateOption(
                    this.model.get('clientId'),
                    'client',
                    'All assessments',
                    '',
                    (this.model.get('scope') == "client")
                );

                // Render dropdown items.
                $.each(this.model.get('optGroupItems'), function(index, system) {
                    html += generateOption(
                        system.id,
                        'system',
                        system.title,
                        '',
                        (this.model.get('id') == system.id && this.model.get('scope') == "system")
                    );

                    $.each(system.installations, function(index, installation) {
                        html += generateOption(
                            installation.id,
                            'installation',
                            installation.title,
                            '&nbsp;&nbsp;',
                            (this.model.get('id') == installation.id && this.model.get('scope') == "installation")
                        );

                        $.each(installation.individuals, function(index, individual) {
                            html += generateOption(
                                individual.id,
                                'individual',
                                individual.title,
                                '&nbsp;&nbsp;&nbsp;&nbsp;',
                                (this.model.get('id') == individual.id && this.model.get('scope') == "individual")
                            );
                        }.bind(this));
                    }.bind(this));
                }.bind(this));

                dropdown.html(html);
                dropdown.change(this.changeScope.bind(this));
            },

            /**
             * Fired when the dropdown has been changed.
             * @param e JavaScript Event object.
             */
            changeScope: function (e) {
                var optionSelected = $(e.target).find("option:selected");

                this.model.set('id', optionSelected.val());
                this.model.set('scope', optionSelected.attr("data-scope"));

                this.onRender();
                if (this.isOnOutcomes) {
                    this.loadOutcomesReport(true);
                } else {
                    this.loadSummaryReport(true);
                }

                $('.printcontainer').unbind().click(this.printPDF.bind(this)).show();
            },

            /**
             * Render the pie charts.
             */
            renderPies: function () {
                this.loadPie('chartReasonsForInstalling', this.model.get('chartReasonsForInstalling'));
                this.loadPie('chartOutcomesAchieved', this.model.get('chartOutcomesAchieved'));
            },

            /**
             * Load pie chart.
             * @param elem  Element to load it in.
             * @param data  Pie chart data.
             */
            loadPie: function(elem, data) {
                var elem = $('#' + elem),
                    colors = [];

                elem.html("");

                // Set colors
                $.each(data, function(index, item) {
                    colors.push(item.colour);
                });

                setTimeout(function() {
                    var width = 360,
                        height = 360,
                        radius = Math.min(width, height) / 2,
                        //donutWidth = 65,
                        legendRectSize = 18,
                        legendSpacing = 4,
                        strokeColor = "#000",
                        strokeWidth = 0.3;

                    var color = d3.scale.ordinal().range(colors);

                    var svg = d3.select(elem[0])
                        .append('svg')
                        .attr('width', width * 2)
                        .attr('height', height)
                        .append('g')
                        .attr('transform', 'translate(' + (width / 2) + ',' + (height/2) + ')');

                    var arc = d3.svg.arc()
                        .outerRadius(radius);

                    var pie = d3.layout.pie()
                        .value(function(d) { return d.size; })
                        .sort(null);

                    var path = svg.selectAll('path')
                        .data(pie(data))
                        .enter()
                        .append('path')
                        .attr('d', arc)
                        .attr('fill', function(d, i) {
                            return color(d.data.title);
                        })
                        .attr('stroke', strokeColor)
                        .attr('stroke-width', strokeWidth);

                    var legend = svg.selectAll('.legend')
                        .data(color.domain())
                        .enter()
                        .append('g')
                        .attr('class', 'legend')
                        .attr('transform', function(d, i) {
                            var height = legendRectSize + legendSpacing;
                            var offset = height * color.domain().length / 2;
                            var horz = 5 * (legendRectSize + 25);
                            var vert = i * height - offset;

                            return 'translate(' + horz + ',' + vert + ')';
                        });

                    legend.append('rect')
                        .attr('width', legendRectSize)
                        .attr('height', legendRectSize)
                        .style('fill', color)
                        .style('stroke', strokeColor)
                        .style('stroke-width', strokeWidth + 0.4);

                    legend.append('text')
                        .attr('x', legendRectSize - legendSpacing + 10)
                        .attr('y', legendRectSize - legendSpacing)
                        .text(function(d) { return d; });
                }.bind(this));
            },

            /**
             * Switch to the "Reasons for Installing" pie chart.
             */
            switchReasonsForInstalling: function() {
                $('#chartReasonsForInstalling-button').attr('disabled', 'disabled');

                $('#chartOutcomesAchieved').fadeOut(this.pieAnimationSpeed, function(){
                    $('#chartReasonsForInstalling').fadeIn(this.pieAnimationSpeed, function(){
                        $('#chartOutcomesAchieved-button').attr('disabled', false);
                        $('#pietitle').text("Reasons for installing Just Checking");
                    });
                }.bind(this));
            },

            /**
             * Switch to the "Outcomes Achieved" pie chart.
             */
            switchOutcomesAchieved: function() {
                $('#chartOutcomesAchieved-button').attr('disabled', 'disabled');

                $('#chartReasonsForInstalling').fadeOut(this.pieAnimationSpeed, function() {
                    $('#chartOutcomesAchieved').fadeIn(this.pieAnimationSpeed, function(){
                        $('#chartReasonsForInstalling-button').attr('disabled', false);
                        $('#pietitle').text("Outcomes achieved using Just Checking");
                    });
                }.bind(this));
            },

            /**
             * Render the calculations footer.
             * @todo Refactor into template.
             */
            renderCalculations: function() {
                var elem = $('div#calculations'),
                    html = "";

                // Title
                var title = _.template("<div class=\"row\">\n    <div class=\"column large-12\">\n        <h4 class=\"title\">Outcomes for <%= title %> (<%= totalAssessments %>) <span class=\"aside\">*<%= outcomesSource %></span></h4>\n    </div>\n</div>");
                html += title({
                    title: $.trim(this.getSelectedName()),
                    totalAssessments: this.model.get('totalAssessments'),
                    outcomesSource: this.model.get('outcomeCalculationSourceData')
                });

                // Calculation body
                var body = _.template("<div class=\"row\">\n    <div class=\"column large-4\">\n        <h3>Daytime care hours</h3>\n        <h4>Monthly <%= daytimeMonthly %></h4>\n        <h4>Annualised <%= daytimeAnnual %></h4>\n    </div>\n    <div class=\"column large-4\">\n        <h3>Night time care hours</h3>\n        <h4>Monthly <%= nighttimeMonthly %></h4>\n        <h4>Annualised <%= nighttimeAnnual %></h4>\n    </div>\n    <div class=\"column large-4\">\n        <h3>Residential care</h3>\n        <h4>Monthly <%= residentialMonthly %></h4>\n        <h4>Annualised <%= residentialAnnual %></h4>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"column large-12\">\n        <p>negative values = cost savings</p>\n    </div>\n</div>");
                html += body({
                    daytimeMonthly: this.model.get('dayTimeCareMonth'),
                    daytimeAnnual: this.model.get('dayTimeCareYear'),
                    nighttimeMonthly: this.model.get('nightTimeCareMonth'),
                    nighttimeAnnual: this.model.get('nightTimeCareYear'),
                    residentialMonthly: this.model.get('residentialMonth'),
                    residentialAnnual: this.model.get('residentialYear')
                });

                elem.html(html);
            },

            /**
             * Get the name of the selected dropdown item.
             * @returns {string}
             */
            getSelectedName: function () {
                var text = $('#scope-dropdown').find("option:selected").text();

                if (text == "All assessments") {
                    text = "all assessments";
                }

                return text.replace("&nbsp;", "");
            },

            /**
             * Render the pie chart switch buttons.
             */
            renderButtons: function() {
                $('#chartReasonsForInstalling-button').click(function(e) {
                    this.switchReasonsForInstalling();
                }.bind(this));
                $('#chartOutcomesAchieved-button').click(function(e) {
                    this.switchOutcomesAchieved();
                }.bind(this));
            },

            /**
             * Render the text for the outcomes report.
             */
            renderOutcomesText: function() {
                $('#outcomes-text').html(this.model.get('reportSummary'));
            },

            /**
             * Print PDF button handler.
             * @param e JavaScript Event object.
             */
            printPDF: function(e) {
                _.mixin({
                    capitalize: function(string) {
                        return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
                    }
                });

                var url = '/api/v1/Generate/PDF',
                    html,
                    data = {
                        mainCSS: document.querySelector("link[href*='.css']").href,
                        justcheckingLogo: "/assets/images/png/just_checking_logo.png",
                        generatedText: this.generatePDFFooter()
                    };

                if (this.isOnOutcomes) {
                    html = OutcomesPDFTemplate;
                    url = url + "?orientation=portrait";
                    data.outcomesText = this.model.get('reportSummary');
                } else {
                    html = SummaryPDFTemplate;
                    data.left = {
                        title: "Reasons for installing Just Checking",
                        pie: $('#chartReasonsForInstalling').html()
                    };
                    data.right = {
                        title: "Outcomes achieved using Just Checking",
                        pie: $('#chartOutcomesAchieved').html()
                    };
                    data.calculations = $('#calculations').html()
                }

                html = html(data);
                console.log("print2")
                console.log(html)
                // $("<form action=\"" + url + "\" method=\"post\" style=\"display:none;\"><input type=\"hidden\" name=\"html\" value=\"" + encodeURI(html) + "\" /></form>").appendTo("body").submit().remove();
                e.preventDefault();
            },

            generatePDFFooter: function() {
                var html,
                    scope = this.model.get('scope'),
                    selectedName = this.getSelectedName(),
                    dropdownItems = this.model.get('optGroupItems');

                html = "Generated: " + moment().format('LLL') + " | ";

                switch(scope) {
                    case "individual":
                        // Work out what System and Installation the individual came from in the dropdown list.
                        var selectedSystem = "",
                            selectedInstallation = "";

                        try {
                            $.each(dropdownItems, function (index, system) {
                                selectedSystem = system.title;

                                $.each(system.installations, function (index, installation) {
                                    selectedInstallation = installation.title;

                                    $.each(installation.individuals, function (index, individual) {
                                        if ($.trim(individual.title) == $.trim(selectedName)) {
                                            throw "Found";
                                        }
                                    });
                                });
                            });
                        } catch (e) {
                            selectedSystem = $.trim(selectedSystem);
                            selectedInstallation = $.trim(selectedInstallation);

                            html += "System: " + selectedSystem.replace("System ", "") + " | ";
                            html += "Installation: " + selectedInstallation + " | ";
                        }


                    default:
                        var text = $.trim(selectedName);

                        if (scope == "system") {
                            text = text.replace("System ", "");
                        }

                        html += _.capitalize(scope) + ": " + text;
                        break;
                }

                return html;
            }
        });
});

