/**
Chart Application - Rows Collection
@module Chart
@submodule Chart.Collections.Rows
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Rows Collection
    * model
    * initialize
Author(s):
  * Just Checking
*/

define('app/modules/charts/collections/charts.collections.rows',[

  'backbone',
  'backbone.memento',
  'app/modules/charts/models/charts.models.row'

], function(Backbone, Momento, Model) {

  'use strict';

  /**
  Rows Collection
  @class ChartApp.Collections.Rows
  @constructor
  @extends Backbone.Collection
  */
  return Backbone.Collection.extend({

    /**
    Point to the model that this collection contains
    @property model
    @type Object
    */
    model: Model,

    /**
    @method initialize
    */
    initialize: function() {

      // Create an instance of backbone momento
      var memento = new Backbone.Memento(this);

      // Extend momento directly onto the collection
      _.extend(this, memento);

    }

  });

});
