/**
Application Commands - Extract fragment section
@module Requests
@submodule Requests.ExtractFragmentSection
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* App Navigation
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFragmentSection',[

	'app/requests/requests.vent'

], function(Requests) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Extract fragment section
			@event get:fragment:section
			@param currentFragment {String} The fragment you wish to manipulate
			@param limit {Integer} How many sections to extract from the array
			@param removeLastChar {Boolean} You may need to remove the last character
			@return {String} A section of the backbone fragment
			*/
			return Requests.setHandler('get:fragment:section', function(options) {

				// Set defaults
				_.defaults(options, {

					currentFragment: Backbone.history.fragment,
					removeLastChar: false

				});

				// Split the supplied fragment into an array
				var
				fragmentArray = options.currentFragment.split('/'),
				newFragment = '';

				// Loop through the array
				_.each(fragmentArray, function(fragment, index) {

					// If this fragment's index is less or equal to the limit set
					if (index <= options.limit) {

						// Add this fragment to the new fragment
						newFragment += fragment + '/';

					}

				});

				// Has the removal of the last character been requested?
				if (options.removeLastChar) {

					// Remove the last character
					newFragment = newFragment.substr(0, newFragment.length - 1);

				}

				return newFragment;

			});

    }

  };

});

