/**
Application Commands - App Modal
@module Commands
@submodule Commands.AppModal
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
  * Requests
Contents:
	* Module Initialize
		* Modal Display
Author(s):
  * Just Checking
*/

define('app/commands/commands.modal',[
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	// 'app/modal/modal.region',
	// 'app/modal/modal.model',
	// 'app/modal/modal.view'

], function(Commands, Requests, Region, Model, View) {
  'use strict';
  return {
		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Modal Display
			@event app:modal
			@param template {String} The template to show in the view
			@param route {String} The route to navigate the application to
			@param trigger {Boolean} Whether to call the route function
			*/
			return Commands.setHandler('app:modal', function(options) {
				var msg = 'You are leaving the page with unsaved changes, do you wish to continue?';
				if (confirm(msg)) {
					Backbone.history.navigate(options.route, {
						trigger: options.trigger
					});
					Commands.execute('set:change:false', {view:this});
				}
			});
    }
  };
});
