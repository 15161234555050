/**
Users Application - Users Edit View
@module UsersApp
@submodule UsersApp.Views.Form.Remove
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Syphon
	* Vent
	* Template
	* Crudder
	* Switcher
	* PhoneNumber
	* Placeholder
	* Validation
	* Utils
	* Commands
	* Requests
Contents:
  * User Item View
		* className
		* template
		* ui
		* events
		* initialize
		* onRender
		* saveUser
		* validateUser
		* updateName
		* updateEmail
		* showCountryList
		* processPhone
		* smsValidation
		* turnSmsSwitchOff
Author(s):
  * Just Checking
*/

define('app/modules/users/form/views/settings.users.form.views.edit',[

  'marionette',
  'backbone.syphon',
  'app/app.vent',
  'tpl!app/modules/users/templates/usersFormEdit.tmpl',
  //' tpl!app/modal/templates/modalRequireTelephone.tmpl',
  'backbone.crudder',
 // 'mobiscroll',
  //'switcher',
  //'phonenumber',
  'placeholder',
  'validation',
  'utils',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'chained'

], function(Marionette, Syphon, Vent, Template, /*TemplateModalRequireTelephone,*/ Crudder,/* Mobiscroll, Switcher, PhoneNumber,*/ Placeholder, Validation, Utils, Commands, Requests) {

  'use strict';

  /**
  User Item View
  @class UsersApp.Views.Form.Remove
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    The template for this view (index.html > templates > users)
    @property template
    @type String
    */
    template: Template,

    /*
    @property form
    @type Object
    */
    form: {
		focusFirstInput: true,
		buttons: {
			primary: 'Add',
			cancel: 'Cancel',
			placement: 'right'
		}
    },

    /**
    @property ui
    @type Object
    */
    ui: {

      headerTitle: '#user-form-header',
      headerDescription: '#user-form-description',
      emailCheckbox: '#user-form-email-alerts',
      emailCheckboxContainer: '#user-form-email-container',
      nameTextField: '#user-form-name',
      emailTextField: '#user-form-email',
     // submitButtonText: '#user-form-submit-text',
      userType: '#userType',
      scope: '#scope',
      actions: '#actions',
      resendPwd: '#resendPwdWrapper',
      team: '#user-form-team',
      teamSpecialisation: '#user-form-teamSpecialisation',
      staffFields: '#staffFields'
    },

    /**
    @property events
    @type Object
    */
    events: {

      'keyup #user-form-name': 'updateName',
      'keyup #user-form-email': 'updateEmail',
      'change #user-form-email': 'updateEmail',
      'change @ui.userType': 'updateUserType',
      'change #actions': 'updateAccess',
      'click #resendPwd': 'resendPassword'

    },

    /**
    @method initialize
    */
    initialize: function() {

      // Bind this model to the form validation
      // NOTE: this has been disabled due to conflicts with
      // backbone relational
      // Backbone.Validation.bind(this);

      // Bind to any model change events
      // this.listenTo(this.model, 'change', this.render, this);

    },

    /**
    @method onRender
    */
    onRender: function() {

      var
      that = this,
      isMatch = false;

      /*
      Reset
      */

      // Set the valid number switch
 //     this.phoneNumberValid = false;

      /*
      UI Settings
      */

      // If this is a new model (blank form)
      if (this.model.isNew()) {

        // Set the title of the blank form
        this.ui.headerTitle.text('Add a new user');

        // Show the new user form description
        this.ui.headerDescription.show();

        // Remove the 'disabled' attribute from the email input
        this.ui.emailTextField.removeAttr('disabled');

        // Show the required *
        this.$('label[for="' + this.ui.emailTextField.attr('id') + '"] span').removeClass('hide');


        //as this is a blank form, add a blank entry to the first select box
        this.ui.userType.append('<option value="choose">Choose...</option>');
        //this.ui.scope.append('<option value="">--</option>');
        //this.ui.actions.append('<option value="">--</option>');

        //loop through each item in the accessSelectModel
        _.each(this.collection.accessSelectModel.get('userType'), function(val, i){

          //render the access level select box values
          that.ui.userType.append('<option value="'+ val.value +'">'+ val.label +'</option>');

        });

        _.each(this.collection.accessSelectModel.get('scope'), function(val, i){

          //render the access level select box values
          that.ui.scope.append('<option value="'+ val.value +'" class="'+ val.class +'">'+ val.label +'</option>');

        });

        _.each(this.collection.accessSelectModel.get('actions'), function(val, i){

          //render the access level select box values
          that.ui.actions.append('<option value="'+ val.value +'" class="'+ val.class +'">'+ val.label +'</option>');

        });



        // Activate the chained select box plugin
        this.ui.scope.chained(this.ui.userType);
        //this.ui.actions.chained(this.ui.scope);
        this.ui.actions.chained([this.ui.userType, this.ui.scope]);

      }
      else { //this is an existing user


        // Does this user have a name?
        if (!this.model.get('name')) {

          // Set the name to unknown
          this.ui.headerTitle.text('No name supplied');

		}

	    this.$('label[for="' + this.ui.emailTextField.attr('id') + '"] span').removeClass('hide');

        //Populate the select dropdowns
        // this.ui.userType.append('<option>'+ this.model.get('userType') +'</option>');
        // this.ui.scope.append('<option>'+ this.model.get('scope') +'</option>');
        // this.ui.actions.append('<option>'+ this.model.get('actions') +'</option>');
		_.each(this.collection.accessSelectModel.get('userType'), function(val, i){
		  //render the access level select box value
			var html = '';

			if(that.model.get('userType') == val.value) {
				html = '<option value="'+ val.value +'" selected>'+ val.label +'</option>';
			} else {
				html = '<option value="'+ val.value +'">'+ val.label +'</option>';
			}

            that.ui.userType.append(html);
		});

		_.each(this.collection.accessSelectModel.get('scope'), function(val, i){
		  //render the access level select box values
			var html = '',
				scope = that.model.get('scope').replace('this ', '');

			if (scope == val.value) {
				html = '<option value="'+ val.value +'" class="'+ val.class +'" selected>'+ val.label +'</option>';
			} else {
				html = '<option value="'+ val.value +'" class="'+ val.class +'">'+ val.label +'</option>';
			}

            that.ui.scope.append(html);
		});

		_.each(this.collection.accessSelectModel.get('actions'), function(val, i){
		  //render the access level select box values
			var html = '';

			if (that.model.get('roleId') == val.value) {
				html = '<option value="'+ val.value +'" class="'+ val.class +'" selected>'+ val.label +'</option>';
			} else {
				html = '<option value="'+ val.value +'" class="'+ val.class +'">'+ val.label +'</option>';
			}

            that.ui.actions.append(html);
		});

        //show the resend password button
        this.ui.resendPwd.show();

        if (this.model.get('userType') == 'staff') {
            // Show and pre-populate the team fields
            this.ui.team.val(this.model.get('team'));
            this.ui.teamSpecialisation
                .val(this.model.get('teamSpecialisation'));
            this.ui.staffFields.show();
        }

        // Change the submit button text
        $('button[data-form-button=primary]').first().text('Save ');
        $('button[data-form-button=cancel]').hide();

		// Activate the chained select box plugin
	  	this.ui.scope.chained(this.ui.userType);
		//this.ui.actions.chained(this.ui.scope);
		this.ui.actions.chained([this.ui.userType, this.ui.scope]);

	    this.updateAccess();
      }


      /*
      Initiate Plugins
      */

      // Initiate the placeholder plugin
      this.$('input, textarea').placeholder();
      this.ui.team.autocomplete({
          source: [
              'Review Team',
              'Transition Team',
              'Mental Health',
              'Safeguarding',
              'Hospital Discharge',
              'NHS Reablement',
              'Social care',
              'Community Mental Health',
              'Continuing care'
          ]
      });

    },

    /**
    @method save
    @param data {Object} Data syphoned from the submitted form
    @param callback {Function} A callback function
    */
    save: function(data, callback) {

      var
      that = this,
      validatedData = this.validateUser(data);

      /*
      Test for validity
      */

      if (validatedData.isValid) {

				// Send true back to form wrapper
        if (callback) {

					callback(true);

        }

        /*
        Save the model
        */

				// Save this model via crudder
				Crudder.creatingUpdating({

					entity: this.model,
					entityData: validatedData.data,
					successMessage: validatedData.data.name + ' has been saved',
					errorMessage: 'Unable to save ' + validatedData.data.name

				}, function(options) {

					// Has the request beed successful?
					if (options.success) {

						var
						model = options.data,
						accessId;

            // Set the form changes variable to false
            Commands.execute('set:change:false');

            // Is the model new (no id)?
            // if (model.isNew()) {

              // Set the user id to what was returned from the server
              accessId = model.get('accessId');


              // Attach the returned id to the model
              model.set({

                accessId: accessId

              },
              {

                silent: true

              });

              // Add the new model to the collection
              that.collection.add(model);

              // Show the new user
							Vent.trigger('show:user', {

								id: accessId

							});

            // }
            // else {
            //
            //   /* HACKY way to show the newly added user! */
            //   window.location.href += ('/'+ model.get('accessId'));
            //   window.location.reload();
            //
				// 			// Manually re-render the view
				// 			that.render();
            //
            // }

					}
					else {

						var responseText = options.response.responseText;

            // Has there has been a server response?
            if (responseText) {

              var response = $.parseJSON(responseText);

              // User needs to submit a mobile number
              if (response.code === 100001) {

                // Show a custom validation error
                Validation.customInvalid({

									view: that,
									target: that.ui.mobileTextField,
									error: 'Please enter a valid mobile number',
									parent: '',
									focus: false

								});

                return false;

              }

              // Email already exists in the database
              if (response.code === 100101) {

                // Show a custom validation error
                Validation.customInvalid({

									view: that,
									target: that.ui.emailTextField,
									error: 'Email address already in use',
									parent: '',
									focus: false

								});

                return false;

              }

            }

					}

				});

      }
      else {

        // Send false back to form wrapper
        if (callback) {

					callback(false);

        }

        return;

      }

    },

    /**
    @method validateUser
    */
    validateUser: function(data) {

        console.log('validate', data);
      var
        isValid = true,
        name = this.ui.nameTextField.val(),
        email = this.ui.emailTextField.val(),
        userType = this.ui.userType.val();

      /*
      User name
      */

      if ('choose' == userType) {
        Validation.customInvalid({
          view: this,
          target: this.ui.userType,
          error: 'Please select a user type.',
          parent: '',
          focus: false
        });
        isValid = false;
      } else {
        Validation.customValid({
          view: this,
          target: this.ui.userType,
          parent: ''
        });
      }

      // We require a name
      if (!data.name) {

        // Show a custom validation error
        Validation.customInvalid({

					view: this,
					target: this.ui.nameTextField,
					error: 'Please enter the user\'s name',
					parent: '',
					focus: false

				});

        // Set the validation boolean to false
        isValid = false;

      }
      else {

        // Remove any previous error styling
        Validation.customValid({

					view: this,
					target: this.ui.nameTextField,
					parent: ''

				});

      }

      /*
      Email Address
      */

      // We require a valid email address
      if (!data.email || !Utils.isValidEmail(data.email)) {

        // Show a custom validation error
        Validation.customInvalid({

					view: this,
					target: this.ui.emailTextField,
					error: 'Please enter a valid email address',
					parent: '',
					focus: false

				});

        // Set the validation boolean to false
        isValid = false;

      }
      else {

        // Remove any previous error styling
        Validation.customValid({

					view: this,
					target: this.ui.emailTextField,
					parent: ''

				});

      }

      return {

        isValid: isValid,
        data: data

      };

    },

    /**
    @method updateName
    */
    updateName: function() {

      var name = this.ui.nameTextField.val();

      // We require a name
      if (name) {

        // Remove any previous error styling
        Validation.customValid({

					view: this,
					target: this.ui.nameTextField,
					parent: ''

				});

      }

    },

    /**
    @method updateEmail
    */
    updateEmail: function() {

      var email = this.ui.emailTextField.val();

      // We require a name
      if (email && Utils.isValidEmail(email)) {

        // Remove any previous error styling
        Validation.customValid({

					view: this,
					target: this.ui.emailTextField,
					parent: ''

				});

      }

    },

    updateUserType: function() {
        var userType = this.ui.userType.val();

        if (userType == 'staff') {
            this.ui.staffFields.show();
        } else {
            this.ui.staffFields.hide();
        }
    },

    updateAccess: function(){

      this.model.set('roleId', this.ui.actions.val());
      this.model.set('installationId', window.location.hash.substr(window.location.hash.lastIndexOf('/charts/') + 8, window.location.hash.lastIndexOf('/users') - (window.location.hash.lastIndexOf('/charts/') + 8)));

    },

    resendPassword: function(e){
      var url = '/api/v1/PermissionAssignments/'+ this.model.get('accessId') +'/sendPasswordReminder';

      $.ajax({
        url:url,
        type:'GET',
        //dataType:"json",
        //data: JSON.stringify({"accessId": this.model.get('accessId')}),
        success: function(){
          //TODO: navigate to the last page being viewed before 401 was thrown
          //reload the app from the start
          alert('This users password has been resent');
        },
        error: function(error){
          alert('There was an error resending this users password');
        }
      });

      e.preventDefault();
    }

});

});

