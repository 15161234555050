/**
Application Components - Loader View
@module Components
@submodule Components.Loader.View
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
  * Template
Contents:

Author(s):
  * Just Checking
*/

define('app/components/loader/components.loader.view',[

  'marionette',
  'jquery.spin',
  'tpl!app/components/loader/templates/components.loader.templates.loader.tmpl'

], function(Marionette, Spin, Template) {

  'use strict';

  /**
  Paging View
  @class Paging.View
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    template: Template,

    className: 'loader-container',

    onShow: function() {

      // Attach the spinner to the view
      this.$el.spin();

      // debug mode
      if (this.options.debug) {

        console.log('loader view show event');

      }

    },

    onClose: function() {

      // Close it down
      this.$el.spin(false);

      // debug mode
      if (this.options.debug) {

        console.log('loader view close event');

      }

    }

  });

});
