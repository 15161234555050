/**
Application Commands - PagingShow
@module Commands
@submodule Commands.PagingShow
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
	* Module Initialize
		* Paging Controller Handler
Author(s):
  * Just Checking
*/

define('app/commands/commands.pagingShow',[

	'app/commands/commands.vent',
  'app/components/paging/components.paging.controller'

], function(Commands, PagingController) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Paging Controller Handler
			@event paging:show
			@param pagingData {Object} Model JSON data sent in via the collection
			@param view {Backbone View} The view to input the paging into
			*/
			return Commands.setHandler('paging:show', function(pagingData, view) {

				// Create a new controller
				var controller = new PagingController();

				// Call the start method on the controller
				controller.start(pagingData, view);

			});

    }

  };

});

