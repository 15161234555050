define('app/modules/newcharts/features/doorActivity/views/doorActivity-table.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/features/doorActivity/templates/doorActivity-table.template.tmpl',
    'moment',
    'app/modules/newcharts/state'

], function(Marionette, Vent, Requests, Template, Moment, State) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            date: '.date'
        },

        events: {
            'click @ui.date': 'loadChart'
        },

        templateHelpers: {
            pillLimit: function() {
                var browserWidth = $(window).width(),
                    formula = browserWidth / 100,
                    result = Math.floor(formula),
                    difference = formula - result,
                    pillLimit = 10;

                if (difference <= 0.5) {
                    result -= 1;
                }

                // Upper cap.
                if (result > pillLimit) {
                    result = pillLimit;
                }

                return result;
            },

            mobileDate: function(date) {
                date = date.split(" ");
                date[1] = date[1].replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');

                date = new Moment(date[1] + ' ' + date[2] + ' ' + date[3], 'D MMMM YYYY');

                return date.format('Do MMM YYYY');
            },

            activityWidth: function(activity) {
                var limit = 100;

                return (activity * limit) / limit;
            },

            activityText: function(width) {
                var limit = 100;

                if (width > limit) {
                    return limit.toString() + '+';
                }

                return width.toString();
            },

            activityLimit: function() {
                return 100;
            }
        },

        initialize: function() {
            this.render();
        },

        loadChart: function(e) {
            var date = e.toElement.text;

            date = date.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
            date = new Moment(date, 'dddd D MMMM YYYY');

            // Set chart state
            State.setType('chart', this.model.get('installationId'), {
                date: date,
                zoom: 120
            });

            // Click chart image.
            $('#feature-chart-icon').click();
        }

    });

});
