/**
Help Application - Item View
@module Help
@submodule Help.Views.Item
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Help Item View
Author(s):
  * Just Checking
*/

define('app/modules/help/help.view',[

  'marionette',
  'tpl!app/modules/help/help.template.tmpl',
  'app/commands/commands.vent'

], function(Marionette, Template, Commands) {

  'use strict';

  /**
  Help Item View
  @class Help.Views.Item
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template

  });

});

