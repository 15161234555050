define('app/modules/newcharts/features/notifications/views/notification-menu.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'tpl!app/modules/newcharts/features/notifications/templates/notification-menu.template.tmpl',
	'moment',
	'switcher',
	'backbone.crudder'

], function(Marionette, Vent, Requests, Template, Moment, Switcher, Crudder) {
	'use strict';

	return Marionette.ItemView.extend({

		template: Template,

		ui: {
			notificationHistoryBtn: '#notification-history-btn'
		},

		events: {
			'click @ui.notificationHistoryBtn': 'notificationHistory'
		},

		notificationHistory: function(e) {
			e.preventDefault();

			Vent.trigger('newchart:notification-history', {});
		}

	});

});
