/*
Backbone Datasource v0.1.0
Extends Backbone Model and Collection and sets the url via a pre-defined dataSource root
*/

/*
Requires:
  * Backbone
  * Underscore
Contents:
  * Extend Backbone Model
  * Extend Backbone Collection
Author(s):
  * Just Checking
*/

define('backbone.apiRoot',[

	'backbone'

], function(Backbone) {

  'use strict';

	return {

		/**
		Initialize this module
		@method initialize
		*/
    initialize: function() {

    	var that = this;

			// Attach a url reference to the model prototype
		  Backbone.Model.prototype.urlReference = Backbone.Model.prototype.url;

			/*
		  Extend Backbone Model
		  @extends Backbone.Model.prototype
		  */
		  _.extend(Backbone.Model.prototype, {

		    url: function() {

		    	var dataType = (Backbone.apiRoot.dataType) ? '.' + Backbone.apiRoot.dataType : '';

		    	// Set the api root defaults
					_.defaults(Backbone.apiRoot, {

						root: '/',
		    		dataType: ''

		    	});
					// helpful for debugging
					//console.log(Backbone.apiRoot.root + this.urlReference() + dataType);

					// Return the url based on the apiRoot and model's url
					return Backbone.apiRoot.root + this.urlReference() + dataType;

		    }

		  });

		  /*
		  Extend Backbone Collection
		  @extends Backbone.Collection.prototype
		  */
		  _.extend(Backbone.Collection.prototype, {

		    url: function() {

					var
					proto = this.constructor.prototype,
					urlSource = (_.isFunction(proto.urlSource)) ? proto.urlSource() : proto.urlSource,
					dataType = (Backbone.apiRoot.dataType) ? '.' + Backbone.apiRoot.dataType : '';

					// Return the url based on the apiRoot and collection's urlSource property
					return Backbone.apiRoot.root + urlSource + dataType;

		    }

		  });

		}

	};

});

