define('app/modules/newcharts/models/chart.model',[
    'backbone',
    'app/commands/commands.vent',
    'app/modules/newcharts/collections/chart-rows.collection',
    'app/modules/newcharts/models/chart-row.model'
], function(Backbone, Commands, ChartRowsCollection, ChartRow) {
    'use strict';

    return Backbone.Model.extend({

        idAttribute: '_id',

        urlRoot: 'Charts',

        defaults: {
            chartTitle: 'No title',
            chartRows: new ChartRowsCollection()
        },

        parse: function(resp) {
            var attr, model, models, collection, options;

            resp = resp.jcData;

            for (var prop in resp) {
                if (this.defaults && this.defaults[prop]) {
                    attr = this.defaults[prop];
                    if (attr instanceof Backbone.Model) {
                        model = attr.clone();
                        model.set(resp[prop]);
                        resp[prop] = model;
                    } else if (attr instanceof Backbone.Collection) {
                        models = attr.map(function (model) { return model.clone(); });
                        options = _.clone(attr.options);
                        collection = new attr.constructor(models, options);
                        collection.add(resp[prop]);
                        resp[prop] = collection;
                    }
                }
            }
            
            return resp;
        }

    });
});

