/**
System Item
@module Systems
@submodule Systems.Views.Item
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * System Item
    * template
    * tagName
    * className
    * events
    * bindings
    * behaviors
    * navigateToChart
    * navigate
Author(s):
  * Just Checking
*/

define('app/modules/systems/views/systems.views.item',[

  'marionette',
  'tpl!app/modules/systems/templates/systems.templates.item.tmpl',
  'app/commands/commands.vent',
  'app/behaviors/behaviors.tooltip'

], function(Marionette, Template, Commands, Tooltip) {

  'use strict';

  /**
  System Item View
  @class Systems.Views.Item
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property tagName
    @type String
    */
    tagName: 'tr',

	templateHelpers: function() {
		var collection = this.options.collection.clone();

		return _.extend({
			showLastNotification: function() {
				collection = collection.filter(function(item) {
					return item.get('lastNotification') != null;
				});

				return collection.length > 0;
			},
			batteryStatusClass: function() {
				return this.lowBattery ? 'icon-battery-1 battery-low' : 'icon-battery-4 battery-ok';
			},
			batteryTooltip: function() {
				return this.lowBattery ? 'Battery Low<br />call our support team on 01564 785100' : 'Battery OK';
			}
		}, Marionette.ItemView.prototype.templateHelpers.call(this));
	},

    /**
    @property className
    @type String
    */
    className: function() {

      var classes = '';

      // Is this the previously viewed system?
      if (this.options.systemId === this.model.get('systemId')) {

        classes += 'selected-row';

      }

      // Can the user access this system?
      if (!this.model.get('p_viewSystemSummary')) {

        classes += ' cursor-auto';

      }

      // Set subscription status to subActive, subLapsed, subExpired
      var subStatus = this.model.get('subscriptionStatus');
      classes += ' sub' + subStatus.charAt(0).toUpperCase() + subStatus.slice(1);

      return classes;

    },

    /**
    @property ui
    @type Object
    */
    ui: {

      statusSymbol: '.systems-list-status',
	  batteryLow: '.battery-low',
	  batteryOk: '.battery-ok'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click button': 'navigateToChart',
      'click': 'navigate'

    },

    /**
    @property behaviors
    @type Object
    */
    behaviors: {

      Tooltip: {

        behaviorClass: Tooltip

      }

    },

    onShow: function() {
    	setTimeout(function() {
			Commands.execute('init:popover', {
				element: this.$el.find('.battery-low'),
				data: {
					headline: 'Battery Low',
					text: 'Call our support team on 01564 785100'
				}
			});

			Commands.execute('init:popover', {
				element: this.$el.find('.battery-ok'),
				data: {
					text: 'Battery OK'
				}
			});
		}.bind(this));
	},

    /**
    @method navigate
    */
    navigate: function(e) {

	  if (this.model.get('subscriptionStatus') == 'expired') {
	    return;
	  }

      // Navigate the app to the route
      Commands.execute('app:navigate', {

        route: 'systems/' + this.model.id

      });

      e.preventDefault();

    },

    /**
    @method navigateToChart
    */
    navigateToChart: function(e) {

		if (this.model.get('subscriptionStatus') == 'expired') {
			return;
		}

        if (this.model.get('chartVersion') == 2) {
            Commands.execute('app:navigate', {
                route: 'systems/' + this.model.id + '/newcharts/' + this.model.get('installationId')
            });
        } else {
            Commands.execute('app:navigate', {
                route: 'systems/' + this.model.id + '/charts/' + this.model.get('installationId')
            });
        }

        e.stopPropagation();
        e.preventDefault();
    }

  });

});

