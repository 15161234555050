/**
Application Commands - App Navigate
@module Commands
@submodule Commands.AppNavigate
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
	* Requests
Contents:
  * Module Initialize
		* App Navigation
Author(s):
  * Just Checking
*/

define('app/commands/commands.appNavigate',[

  'backbone',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'utils'

], function(Backbone, Commands, Requests, Utils) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			App Navigation
			@event app:navigate
			@param route {String} The route to navigate the application to
			@param trigger {Boolean} Whether to call the route function
			@param callback {Callback} A callback function
			*/
			return Commands.setHandler('app:navigate', function(options, callback) {
				// Options are not required, so...
				options = options || {};
				// Set defaults
				_.defaults(options, {
					trigger: true
				});

				// We require a route
				if (!options.route) { return; }

				// Reset state
				if (options.route == 'systems') {
					var oAuthBearerObject = store.get('oAuth_bearer_object')
                    store.clear();
                    setTimeout(function(){
                        store.set('oAuth_bearer_object', oAuthBearerObject)
                    },200)
				}

                // Hide What's New and System Totals
                if (options.route != "systems") {
                    $('#feature-promotion').remove();
                    $('#system-totals').remove();
                }
                if (options.route == "login") {
                    $('#portrait-overlay').addClass('stay-hidden');
                } else {
                    $('#portrait-overlay').removeClass('stay-hidden');
                }

				// Hide Bathroom Visits notification
				var reg = new RegExp("systems\/\d{5}\/charts\/\d{5}");
				if (!reg.test(options.route)) {
					$('#bathroom-visits-notification').remove();
				}

				// Form / Model changes check and check what mode we are in
				if (Requests.request('get:change:status')) {
					//Show the message warning changes have been made
					Commands.execute('app:modal', {
						template: options.template,
						route: options.route,
						trigger: options.trigger
					});
					// Has a callback been supplied?
					if (callback) {
						// Send falsey back
						callback(false);
					}
				}
				else {
					// Check for a full valid url
					var isURL = Utils.isValidUrl(options.route);
					// Has a full (external) url been supplied?
					if (isURL) {
						// Send the user to the url
						window.location = options.route;
					}
					else {
						// All ok, so allow navigation via the 'global router'
						Backbone.history.navigate(options.route, {
							trigger: options.trigger
						});
						// Close the right panel
						Commands.execute('close:panel');
						// Has a callback been supplied?
						if (callback) {
							// Send truthy back
							callback(true);
						}
					}
				}
			});
    }
  };
});
