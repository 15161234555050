/**
Main Application - Module
@module Application
@submodule Application.Controller
*/

/*
Requires:
  * jQuery
  * Backbone
  * Underscore
  * Marionette
Contents:
  * Main App Controller
		* initialize
    * showHeader
    * showMain
    * showPanels
Author(s):
  * Just Checking
*/

define('app/app.controller',[

  'marionette',
  'backbone.crudder',
  'app/commands/commands.vent',
  'app/components/panels/panels.controller',
  'app/layout/controllers/layout.controllers.header',
  'app/layout/controllers/layout.controllers.main'

], function(Marionette, Crudder, Commands, PanelsController, HeaderController, MainController) {

  'use strict';

  /**
  Main App Controller
  @class MainApp.Controller
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function() {
      // Start the main layout controllers
      this.showHeader();
      this.showMain();
      this.showPanels();
    },


    /**
    @method showHeader
    */
    showHeader: function() {

      // New header controller
      var headerController = new HeaderController();

    },

    /**
    @method showMain
    */
    showMain: function() {

      // New main controller
      var mainController = new MainController();

    },

    /**
    @method showPanels
    */
    showPanels: function(headerLayout) {

      // New panels controller
      var panelsController = new PanelsController();

    }

  });

});

