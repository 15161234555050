/**
Base Backbone Collection
@module Base
@submodule Base.Backbone.Collection
*/

/*
Requires:
  * Backbone
  * jQuery
  * Underscore
Contents:
  * perPage
  * parse
  * save
Author(s):
  * Just Checking
*/

define('app/base/collections/backbone/base.collections.backbone.collection',[

  'backbone',
  'app/commands/commands.vent',
  'backbone.crudder'

], function(Backbone, Commands, Crudder) {

  'use strict';

  return _.extend(Backbone.Collection.prototype, {

    /**
    @property perPage
    @type Integer
    Used for pagination records per page
    */
    perPage: 12,

    /**
    @method parse
    @param response {Object} The response from the server
    */
    parse: function(response) {

      // Is this an official API response?
      if (response.jcData) {

        // Any settings?
        if (response.jcSettings) {

          // Any notifications?
          if (response.jcSettings.notifications) {

            // Execute the show:notifications command
            Commands.execute('show:notifications', {

              data: response.jcSettings.notifications

            });

          }

        }

        // Return the API data
        return response.jcData;

      }
      else {

        // Return the raw json
        return response;
        
      }

    },

    /**
    @method save
    */
    save: function(callback) {

      // Loop through the models
      _.each(this.models, function(model) {

        // Only save the models if they have changed and there have been no failures
        if (model.hasChanged()) {

          // Save this model via crudder
          Crudder.creatingUpdating({

            entity: model,
            entityData: model.attributes,
            wait: true

          });

        }
      
      });

      // Has a callback been supplied?
      if (callback) {

        // Call it
        callback();

      }

    }

	});

});
