/**
Application Commands - When Fetched
@module Commands
@submodule Commands.WhenFetched
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
  	* Toggle Content Class
Author(s):
  * Just Checking
*/

define('app/commands/commands.registerInstance',[

	'backbone',
	'app/commands/commands.vent'

], function(Backbone, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Toggle Content Class
			@event toggle:content:class
			*/
			return Commands.setHandler('register:instance', function(instance, id) {

				var App = require('app/app');
     
       // 
        if (App.environment === 'development') {

          return App.register(instance, id);
        
        }
      
      });

    }

  };

});

