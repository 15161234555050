/**
Chart Application - Edit Layout
@module Chart
@submodule Chart.Layouts.Edit
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
	* templates
Contents:
  * Edit Layout
    * template
    * regions
Author(s):
  * Just Checking
*/

define('app/modules/charts/layouts/charts.layouts.edit',[

  'marionette',
  'app/app.vent',
  'app/requests/requests.vent',
  'tpl!app/modules/charts/templates/charts.templates.edit.tmpl'

], function(Marionette, Vent, Requests, Template) {

  'use strict';

  /**
  Edit Layout
  @class Chart.Layouts.Edit
  @constructor
  @extends Marionette.Layout
  */
  return Marionette.Layout.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {

		form: '#chart-edit-form',
		rows: '#chart-edit-rows',
		accelerometers: '#chart-edit-accelerometers',
		carers: '#chart-edit-carers',
		buttons: '#chart-edit-buttons'

    }

  });

});

