/**
Application Behaviors - Show Password
@module Behaviors
@submodule Behaviors.ShowPassword
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
Contents:
  * Show password
    * events
    * showPasswords
    * processInputs
    * toggleInputs
Author(s):
  * Just Checking
*/

define('app/behaviors/behaviors.showPasswords',[

	'marionette'

], function(Marionette) {

  'use strict';

  /**
	Show password
	*/
	return Marionette.Behavior.extend({

    /**
		@property events
		*/
    events: {
       
       'click .form-show-passwords': 'showPasswords'
    
    },

    /**
		@method showPasswords
		*/
    showPasswords: function() {

      var targetInputs = this.options.targetInputs;

      // Any target inputs supplied?
      if (targetInputs) {

        // Process each the found input
        var foundInputs = this.processInputs(targetInputs);

        // Process each the found input
        this.toggleInputs(foundInputs);

      }
      else {

        // Detect all password inputs
        targetInputs = this.view.$el.find(':password');

        // Any current inputs of type password?
        if (targetInputs.length > 0) {

          // Process each password input
          this.toggleInputs(targetInputs);
          
        }
        else {

          // Find any inputs that were previously of type password
          targetInputs = this.view.$el.find('[data-type="password"]');

          // Process each password input
          this.toggleInputs(targetInputs);

        }


      }
    
    },

    /**
    @method processInputs
    */
    processInputs: function(targetInputs) {

      var
      that = this,
      foundInputs = [];

      // Are we dealing with an array?
      if (_.isArray(targetInputs)) {

        // Loop through each id
        _.each(targetInputs, function(value, index) {

          // Detect the input
          var foundInput = that.view.$el.find('#' + value)[0];

          // Push it to our new array
          foundInputs.push(foundInput);
        
        });

      }
      // Are we dealing with a string?
      else if (_.isString(targetInputs)) {

        // Detect the input
        var foundInput = that.view.$el.find('#' + targetInputs)[0];

        // Push it to our new array
        foundInputs.push(foundInput);

      }
      else {

        return;

      }

      return foundInputs;

    },

    /**
    @method toggleInputs
    */
    toggleInputs: function(targetInputs) {

      // We require input!!
      if (targetInputs) {

        // Loop through each password input
        _.each(targetInputs, function(input) {

          var
          $input = $(input),
          isShown = ($input.attr('data-type') === 'password');

          // Are we currently showing this password?
          if (isShown) {

            // Add data attributes
            $input.removeAttr('data-type');
          
            // Change input to type password
            $input.prop('type', 'password');

          }
          else {

            // Add data attributes
            $input.attr('data-type', 'password');
          
            // Change input to type text
            $input.prop('type', 'text');

          }          
        
        });

      }
      else {

        return;

      }

    }

	});

});

