define('app/modules/charts/models/charts.models.note',['require','backbone'],function (require) {
    'use strict';

    var Backbone = require('backbone');

    return Backbone.Model.extend({

        idAttribute: '_id',

        defaults: {
            date: "",
            subject: "",
            message: ""
        },

        url: function() {
            if (this.get('id') == null) {
                return 'api/v1/ChartNotes';
            } else {
                return 'api/v1/ChartNotes/' + this.get('id');
            }
        }

    });

});

