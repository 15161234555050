/**
Base Backbone Model
@module Base
@submodule Base.Backbone.Model
*/

/*
Requires:
  * Backbone
  * jQuery
  * Underscore
Contents:
  * initialize
  * parse
  * _setOriginalAttributes
  * reset
Author(s):
  * Just Checking
*/

define('app/base/models/backbone/base.models.backbone.model',[

  'backbone',
  'app/commands/commands.vent'

], function(Backbone, Commands) {

  'use strict';

  return _.extend(Backbone.Model.prototype, {

    /**
    @method initialize
    */
    initialize: function() {

      // Set the original model attributes
      this._setOriginalAttributes();

      return this;

    },

    /**
    @method parse
    @param response {Object} The response from the server
    */
    parse: function(response) {

     // Is this an official API response?
     if (response.jcData) {

        // Have any settings been sent?
        if (response.jcSettings) {

         // Any notifications?
         if (response.jcSettings.notifications) {

           // Execute the show:notifications command
           Commands.execute('show:notifications', {

              data: response.jcSettings.notifications

            });

         }

        }

        // Return the API data
        return response.jcData;

     }
     else {

       // Return the raw json
       return response;
       
     }

    },

    /**
    @method _setOriginalAttributes
    */
    _setOriginalAttributes: function() {

      // Set the original model attributes to use in reset method
      this._originalAttributes = this.toJSON();

      return this;

    },

    /**
    @method reset
    */
    reset: function() {
      
      // Reset the model attributes to their initial value
      this.set(this._originalAttributes);

      return this;
    
    }

	});

});
