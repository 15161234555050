/**
Application Commands - Start Module
@module Commands
@submodule Commands.StartModule
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
  	* Start Module
Author(s):
  * Just Checking
*/

define('app/commands/commands.startModule',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Start Module
			@event start:module
			@param module {Object} The module reference
			@param name {String} The module name
			*/
			return Commands.setHandler('start:module', function(options) {

				var App = require('app/app');

				// Has an array been supplied?
				if (_.isArray(options)) {

					// Loop through each item
					_.each(options, function(object, index) {

						// Have valid options been supplied?
						if (_.isString(object.name) && _.isObject(object.module)) {

							// Initialise the module
							object.module.initialize();

							// Start the module
							App.module(object.name).start();
							
						}
						else {

							console.warn('An invalid module was supplied to start');

							return;

						}
					
					});

				}
				else {

					// Have valid options been supplied?
					if (_.isString(options.name) && _.isObject(options.module)) {

						// Initialise the module
						options.module.initialize();

						// Start the module
						App.module(options.name).start();
						
					}
					else {

						console.warn('No valid module supplied to start');

						return;

					}

				}

			});

    }

  };

});

