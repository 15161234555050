/**
Application Commands - Form Changes True
@module Commands
@submodule Commands.FormChangesTrue
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
		* Form Changes True
Author(s):
  * Just Checking
*/

define('app/commands/commands.setChangeTrue',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Form Changes True
			@event set:change:true
			@param view {Backbone.View} The view the request has come from
			*/
			return Commands.setHandler('set:change:true', function(options) {

				var App = require('app/app');

				if (options.view) {

					// Detect any changes to form elements
					options.view.$el.find('input, select, textarea').on('keypress change paste', function() {

						// Set changes detected to true
						App.state.changeDetected = true;

					});

				}
				else {

					// Set changes detected to true
					App.state.changeDetected = true;

				}

			});

    }

  };

});

