/**
Help Application - Router
@module Help
@submodule Help.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/help/help.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  /**
  Router
  @class Help.Router
  @constructor
  @extends Marionette.AppRouter
  */
  return Marionette.AppRouter.extend({

		/**
		@property appRoutes
		*/
    appRoutes: {

      'help': 'getHelp'

    }

  });

});

