define('app/base/views/emailPopup.view',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','tpl!app/templates/sequencesReportLightbox.template.tmpl','backbone.crudder'],function (require) {
	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		LightboxTemplate 	= require('tpl!app/templates/sequencesReportLightbox.template.tmpl'),
		Crudder				= require('backbone.crudder');

	return Marionette.ItemView.extend({

		// The `template` key is defined by the children.

		ui: {
			exitclick : '.exitform',
			submitBtn: '#submit',
			email: '#email',
			comboBtn: '#combo-btn',
			comboIcon: '#combo-btn > i'
		},

		events: {
			'click @ui.exitclick' : 'closelightbox',
			'click @ui.submitBtn': 'submit',
			'focus @ui.email': 'openCombo',
			'keyup @ui.email': 'openCombo',
			'blur @ui.email': 'closeCombo',
			'click @ui.comboBtn': 'toggleCombo',
			'click @ui.comboIcon': 'toggleCombo'
		},

		comboOpen: false,

		initialize:function () {
			$('.overlay').unbind().click(this.closelightbox.bind(this));
		},

		closelightbox:function() {
			$('.overlay').hide();
			$('#lightboxcontainer').hide();

			this.closeCombo();
			$('.emailPopup').unbind();
			this.ui.email.unbind();

			if (typeof this.options.closeCallback === 'function') {
				this.options.closeCallback();
			}
		},

		onRender:function () {
			$('#lightboxcontainer').html(LightboxTemplate(this.model.toJSON()));
			$('.overlay').show();
			$('#lightboxcontainer').show();

			$('body').click(function(e) {
				this.unfocusCombo(e);
			}.bind(this));

			this.createCombo();
		},

		onShow: function() {
			$('.emailPopup').animate({scrollTop: 0}, 600);
		},

		submit: function(e) {
			e.preventDefault();

			var emailField = this.ui.email,
				email = emailField.val();

			emailField.removeClass('error');

			if (email.indexOf('@') == -1) {
				emailField.addClass('error');
			} else {
				this.model.set({
					email: email
				});

				// Crudder.
				Crudder.creatingUpdating({
					entity: this.model,
					patch: false,
					successMessage: 'PDF queued successfully.'
				}, function(response) {
					// Do nothing.
					if (response.success) {
						this.closelightbox();
					}
				}.bind(this));
			}
		},

		createCombo: function() {
			var source = _.map(this.model.get('autocomplete'), function(item) {
				return {
					label: item.email + ' (' + item.name + ')',
					value: item.email
				};
			});

			this.ui.email.autocomplete({
				source: source,
				autoFocus: false
			});

			this.ui.email.unbind();
		},

		openCombo: function(e) {
			e.stopPropagation();

			this.ui.email.autocomplete("search", " ");
			this.ui.comboBtn.html('<i class="icon-up-open"></i>');
			$('.ui-menu-item')
				.unbind('click', this.closeCombo.bind(this))
				.click(this.closeCombo.bind(this));
		},

		toggleCombo: function(e) {
			e.stopPropagation();
			if ($('.ui-autocomplete.ui-front.ui-menu').css('display') != 'none') {
				// Close
				this.closeCombo();
			} else {
				// Open
				this.openCombo(e);
			}
		},

		closeCombo: function(e) {
			this.ui.email.autocomplete("close");
			this.ui.comboBtn.html('<i class="icon-down-open"></i>');
		}

	});

});

