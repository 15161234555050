define('app/modules/newcharts/views/spinner.view',[

	'marionette',
	'app/app.vent',
	'app/requests/requests.vent',
	'moment'

], function(Marionette, Vent, Requests, Moment) {
	'use strict';

	return Marionette.ItemView.extend({

		template: _.template("<div class='loader-container'></div>"),

		ui: {
			loader: '.loader-container'
		},

		onShow: function() {
			this.ui.loader.spin(true);
		}

	});

});
