define('app/modules/newcharts/layouts/multi.layout',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/templates/multi.template.tmpl',
    'tpl!app/modules/newcharts/templates/multi.pdf.template.tmpl',
    'app/modules/newcharts/models/chart.model',
    'app/modules/newcharts/views/chart.view',
    'app/modules/newcharts/collections/chart-rows.collection',
    'moment',
    'app/modules/newcharts/state'

], function(Marionette, Vent, Requests, Template, PDFTemplate, ChartModel, ChartView, RowCollection, Moment, State) {

    'use strict';

    return Marionette.Layout.extend({

        /** Amount of charts to load **/
        slots: function() {
            if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                return 70;
            }

            return 14;
        },

        template: Template,

        models: [],

        templateHelpers: {
            isTouch: function() {
                var $html = $('html');
                return $html.hasClass('touch') && !$html.hasClass('mouse');
            },

            /** Amount of charts to load **/
            slots: function() {
                if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                    return 70;
                }

                return 14;
            }
        },

        regions: function() {
            var slot = 1,
                regions = {};

            while(slot <= this.slots()) {
                regions['chart' + slot] = '#chart' + slot;

                slot++;
            }

            return regions;
        },

        initialize: function() {
            Vent.off('newchart:multi-navigate');
            Vent.on('newchart:multi-navigate', this.navigate.bind(this));

            Vent.off('newchart:multi-reset-zoom');
            Vent.on('newchart:multi-reset-zoom', this.resetZoom.bind(this));

            Vent.off('newchart:multi-print');
            Vent.on('newchart:multi-print', this.print.bind(this));

            Vent.off('newchart:day-view');
            Vent.on('newchart:day-view', this.loadDayView.bind(this));

            Vent.off('newchart:night-view');
            Vent.on('newchart:night-view', this.loadNightView.bind(this));
        },

        onBeforeShow: function() {
            this.loadCharts(this.options.nightView);

            var backToTop = $('#back-to-top');

            if (backToTop.length == 0) {
                $('body').append('<button id="back-to-top" type="button" class="pure-button pure-button-custom button-with-text">\n    <i class="icon-up"></i>\n    <br>\n    Back to top\n</button>');
            }

            backToTop = $(backToTop.selector).unbind().click(function() {
                $('html, body').animate({scrollTop: 0});
            });
            $(window).scroll(function() {
                if ($(this).scrollTop() > 220) {
                    backToTop.fadeIn();
                } else {
                    backToTop.fadeOut();
                }
            });
        },

        loadCharts: function(nightView) {
            var slot = 1,
                models = [],
                model,
                currentDate = new Moment(this.options.dates.from.toISOString()),
                endDate = new Moment(this.options.dates.to.toISOString()),
                fetched = 0;

            this.model.set('chartMeta', _.extend(this.model.get('chartMeta'), {
                zoom: this.options.zoom
            }));
            this.model.set('startDate', new Moment(currentDate.toISOString()));
            this.model.set('endDate', new Moment(endDate.toISOString()));
            this.model.set('nightView', nightView == true);

            while(endDate.diff(currentDate, 'days') > -1) {
                model = new ChartModel({
                    _id: this.options.installId,
                    slot: slot,
                    multi: true,
                    nightView: (nightView == true)
                });
                model.fetch({
                    data: {
                        date: currentDate.format('YYYY-MM-DDTHH:mm:ss'),
                        zoom: this.options.zoom,
                        minimal: 1
                    },
                    success: function(model) {
                        fetched++;

                        try {
                            this['chart' + model.get('slot')].show(new ChartView({
                                model: model,
                                collection: model.get('chartRows')
                            }));
                        } catch(e) {
                            // Do nothing.
                        }

                        if (fetched == models.length) {
                            fetched = 0;
                            setTimeout(function() {
                                var state = State.load(this.model);

                                var rowSwitch;
                                _.each(state.hiddenSensors, function(sensorId) {
                                    rowSwitch = $('#switchChartRow' + sensorId);

                                    if (rowSwitch.is(':checked')) {
                                        rowSwitch.parent().find('li#switch-on').click();
                                    } else {
                                        // Off and on again.
                                        rowSwitch.parent().find('li#switch-on').click();
                                        rowSwitch.parent().find('li#switch-on').click();
                                    }
                                });

                                var html = $('html');
                                if (html.hasClass('touch')) {
                                    state.hideNotes = true;
                                }

                                var hideNotes = $('#switchHideNotes'),
                                    hideNotesWrap = $('#switchHideNotes-wrapper').find('.switch-on');
                                if (state.hideNotes) {
                                    if (hideNotes.is(':checked')) {
                                        hideNotesWrap.click();
                                    } else {
                                        // Off and on again.
                                        hideNotesWrap.click();
                                        hideNotesWrap.click();
                                    }
                                } else {
                                    $('#switch-icon-notes').addClass('on');
                                }

                                var nightView = $('#switchNightView'),
                                    nightViewWrap = $('#switchNightView-wrapper').find('.switch-on');
                                if (state.nightView) {
                                    if (!nightView.is(':checked')) {
                                        nightViewWrap.click();
                                    } else {
                                        $('#switch-icon-night-view').addClass('on');
                                    }
                                } else {
                                    $('#switch-icon-night-view').removeClass('on');
                                }

                                State.save(this.model);

								this['chart' + model.get('slot')].currentView.renderExpands();
                            }.bind(this));
                        }
                    }.bind(this)
                });
                models.push(new ChartModel());

                slot++;
                currentDate.add(1, 'days');
            }
        },

        navigate: function(options) {
            options = _.extend({
                nightView: false,
                resetZoom: false
            }, options);

            var noteBoxes = this.$el.find('.notebox'),
                chart,
                totalCharts = noteBoxes.length,
                successfulCharts = 0;

            if (this.model.get('chartMenu').canAccessNote) {
                for (var i = 0; i < noteBoxes.length; i++) {
                    chart = noteBoxes[i].id.replace('notebox-', '');

                    Vent.trigger('newchart:multi-check-note-' + chart, function () {
                        successfulCharts++;

                        if (totalCharts == successfulCharts) {
                            this.options.zoom = options.zoom;

                            if (options.resetZoom) {
                                if (State.hasTimeJump(this.model.get('chartMeta').installationId)) {
                                    State.removeTimeJump(this.model.get('chartMeta').installationId);
                                    this.options.dates.from.subtract(1, 'day');
                                    this.options.dates.to.subtract(1, 'day');
                                }

                                this.options.dates.from = new Moment(this.options.dates.from).startOf('day');
                                this.options.dates.to = new Moment(this.options.dates.to).startOf('day');

                                if (options.nightView) {
                                    this.options.dates.from.add(12, 'hours');
                                    this.options.dates.to.add(12, 'hours');
                                }
                            } else {
                                // Update the time for the start date in the options.
                                if (options.nightView && options.date.hour() < 12) {
                                    // Let's do the time warp again.
                                    this.options.dates.from.add(1, 'day');
                                    this.options.dates.to.add(1, 'day');
                                    State.rememberTimeJump(this.model.get('chartMeta').installationId);
                                }

                                this.options.dates.from = new Moment(this.options.dates.from)
                                    .hour(options.date.hour())
                                    .minute(options.date.minute())
                                    .second(options.date.second());
                                this.options.dates.to = new Moment(this.options.dates.to)
                                    .hour(options.date.hour())
                                    .minute(options.date.minute())
                                    .second(options.date.second());
                            }

                            this.loadCharts(options.nightView);
                        }
                    }.bind(this));
                }
            } else {
                this.options.zoom = options.zoom;

                if (options.resetZoom) {
                    if (State.hasTimeJump(this.model.get('chartMeta').installationId)) {
                        State.removeTimeJump(this.model.get('chartMeta').installationId);
                        this.options.dates.from.subtract(1, 'day');
                        this.options.dates.to.subtract(1, 'day');
                    }

                    this.options.dates.from = new Moment(this.options.dates.from).startOf('day');
                    this.options.dates.to = new Moment(this.options.dates.to).startOf('day');

                    if (options.nightView) {
                        this.options.dates.from.add(12, 'hours');
                        this.options.dates.to.add(12, 'hours');
                    }
                } else {
                    // Update the time for the start date in the options.
                    if (options.nightView && options.date.hour() < 12) {
                        // Let's do the time warp again.
                        this.options.dates.from.add(1, 'day');
                        this.options.dates.to.add(1, 'day');
                        State.rememberTimeJump(this.model.get('chartMeta').installationId);
                    }

                    this.options.dates.from = new Moment(this.options.dates.from)
                        .hour(options.date.hour())
                        .minute(options.date.minute())
                        .second(options.date.second());
                    this.options.dates.to = new Moment(this.options.dates.to)
                        .hour(options.date.hour())
                        .minute(options.date.minute())
                        .second(options.date.second());
                }

                this.loadCharts(options.nightView);
            }
        },

        resetZoom: function(options) {
            options.resetZoom = true;
            options.nightView = this.model.get('nightView') == true;
            this.options.dates.from = new Moment(this.options.dates.from).startOf('day');
            this.options.dates.to = new Moment(this.options.dates.to).startOf('day');

            this.navigate(options);
        },

        print: function(options) {
            var charts = [],
                html = '',
                noteHtml = '',
                first = true;

            $('#charts').children().each(function(index, elem) {
                try {
                    // Ask the chart to generate its own HTML.
                    html = this[elem.id].currentView.multiPrint();

                    // Wrap the HTML in a proper page.
                    var page = $('#page-main').clone();
                    page.find('#charts').html(html);

                    // Move the print note box into the right place.
                    if (page.find('#printnote').length > 0) {
                        noteHtml = page.find('#printnote')[0].outerHTML;
                        page.find('#printnote').remove();
                    }

                    if (!first) {
                        page.prepend('<div class="page-break"></div>');
                        page.find('#chart-header').remove();
                    }

                    page = page.html();

                    if (noteHtml && noteHtml != '') {
                        page += noteHtml;
                        noteHtml = '';
                    }

                    charts.push(page);
                    first = false;
                } catch (e) {
                    // Skip!
                }
            }.bind(this));

            var storedArrayString = sessionStorage.getItem('printCSSClasses');
            var storedArray = '';
            var classString = '';
            if(storedArrayString){
                storedArray = JSON.parse(storedArrayString);
                // Iterate over the storedArray and concatenate the classNames
                for (var i = 0; i < storedArray.length; i++) {
                    var classStr = storedArray[i].class;
                    classString += classStr + '\n';
                }
            }
        
            // Add the finishing touches
            html = PDFTemplate({
                mainCSS: document.querySelector("link[href*='.css']").href,
                chartLineCSS: classString,
                html: charts.join(),
                generated: new Moment().format('LLL'),
                chartTitle: this.model.get('chartMeta').chartTitle,
                systemNum: this.model.get('chartMeta').systemNo
            });

            // Call the callback
            options.callback(html);
        },

        loadDayView: function() {
            this.navigate({
                zoom: 120,
                date: new Moment().startOf('day')
            });
        },

        loadNightView: function() {
            this.navigate({
                zoom: 120,
                date: $('html').hasClass('touch') ? new Moment().startOf('day').hour(18) : new Moment().startOf('day').hour(12),
                nightView: true
            });
        }
    });

});
