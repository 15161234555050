/**
Users Application - Form Layout
@module UsersApp
@submodule UsersApp.Form.Layout
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore/Lodash
	* Template
Contents:
  * User Item View
		* className
		* template
		* regions
Author(s):
  * Just Checking
*/

define('app/modules/users/form/settings.users.form.layout',[

  'marionette',
  'tpl!app/modules/users/templates/usersForm.tmpl'

], function(Marionette, Template) {

  'use strict';

  /**
  User Form Layout
  @class UsersApp.Form.Layout
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.Layout.extend({

    /**
    @property className
    @type String
    */
    className: 'large-8 columns right extra-padding padding-top dashed-border-left',

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property regions
    @type Object
    */
    regions: {
			
			edit: '#users-form-edit',
			remove: '#users-form-remove'
		
		}

  });

});

