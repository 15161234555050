define('app/modules/newcharts/features/doorActivity/views/no-doorActivity.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'tpl!app/modules/newcharts/features/doorActivity/templates/no-doorActivity.template.tmpl',
    'moment'

], function(Marionette, Vent, Requests, Template, Moment) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template

    });

});
