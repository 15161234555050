/**
Application Commands - When Fetched
@module Commands
@submodule Commands.WhenFetched
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
Author(s):
  * Just Checking
*/

define('app/commands/commands.unregisterInstance',[

	'backbone',
	'app/commands/commands.vent'

], function(Backbone, Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Unregister instance
			@event unregister:instance
			*/
			return Commands.setHandler('unregister:instance', function(instance, id) {

				var App = require('app/app');

				// 
        if (App.environment === 'development') {

          return App.unregister(instance, id);
        
        }
      
      });

    }

  };

});

