/**
Application Models - State
@module Models
@submodule Models.State
*/

/*
Requires:
  * Backbone
Contents:
  * Button Model
    * defaults
Author(s):
  * Just Checking
*/

define('app/models/models.state',[

  'backbone'

], function(Backbone) {

  'use strict';

  /**
  Button Model
  @class Models.State
  @constructor
  @extends Backbone.Model
  */
  return Backbone.Model.extend({

    /**
    @property defaults
    @type Object
    */
    defaults: {

      currentApp: '',
      currentLevel: 0,
      dateFormat: 'ddd D MMM YYYY',
      timeFormat: 'H:hh',
      dateTimeFormat: 'ddd D MMM YYYY, HH:mm',
      utcFormat: 'YYYY-MM-DDTHH:mm:ss',
      rootRoute: 'systems',
      previousParams: {},
      currentParams: {}

    }

  });

});

