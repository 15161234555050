/**
Application Config - AJAX
@module Config
@submodule Config.AJAX
*/

/*
Requires:
  * jQuery
Contents:
  * Intialise the AJAX Settings
		* Set a global auth function for all ajax requests
		* Global AJAX error checks (Disabled)
Author(s):
  * Just Checking
*/

define('ajax.init',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/*
		Intialise the AJAX Settings
    */
    initialize: function() {

    		//$.support.cors = true;

			/*
			Set a global auth function for all ajax requests
			*/
			$.ajaxSetup({

				xhrFields: {
			       withCredentials: true
			    },
			    crossDomain: false

			});

			/*
			Global AJAX error checks
			*/
			$(document).ajaxError(function(e, xhr, settings) {

				// console.log(e);
				// console.log(xhr);
				// console.log(settings);
				// console.log(exception);

				var
				status = xhr.status,
				statusText = xhr.statusText,
				responseText = xhr.responseText,
				paragraphs = [],
				buttons = [];

				/*
				Page not found?
				*/
				if (status === 404) {

					/*
					Overlay message


					// Execute a modal window and define which template to use
					Commands.execute('app:modal', {

						template: TemplateModalError404

					});*/

					return false;

				}

				/*
				Unauthorized or not logged in?
				*/
				else if (status === 401) {

					/*
					Overlay message


					// Execute a modal window and define which template to use
					Commands.execute('app:modal', {

						template: TemplateModalError401

					});*/

					return false;

				}

				/*
				Server error etc
				*/

				else {

					/*
					Overlay message


					// Execute a modal window and define which template to use
					Commands.execute('app:modal', {

						template: TemplateModalError

					});*/

					return false;

				}

			});

		}

  };

});

