/**
Application Layout - Menu CompositeView
@module Layout
@submodule Layout.Views.CompositeView
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Menu List View
		* tagName
		* itemView
		* itemViewContainer
    * ui
		* onRender
Author(s):
  * Just Checking
*/

define('app/layout/views/layout.views.header.menu.list',[

  'marionette',
  'tpl!app/layout/templates/layout.templates.header.menu.list.tmpl',
  'bootstrap.dropdown',
  'app/layout/views/layout.views.header.menu.item'

], function(Marionette, Template, Dropdown, ItemView) {

  'use strict';

  /**
  Menu CompositeView
  @class Layout.Views.CompositeView
  @constructor
  @extends Marionette.CompositeView
  */
  return Marionette.CompositeView.extend({

		/**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#top-menu-list',

    /**
    @property ui
    @type Object
    */
    ui: {

     contactDetails: '#top-menu-contact'

    },

		/**
    @method onRender
    */
		onRender: function() {

			var that = this;

			// Loop through each model
			_.each(this.collection.models, function(model) {
			
				// Detect the contact item
				if (model.get('reference') === 'contact') {

					// Loop through each sub menu item
					_.each(model.get('subMenu'), function(value) {
					
						// Add list items for tel and email
						that.ui.contactDetails.append('<li><a href="' + value.url + '"><i class="' + value.icon + '"></i> ' + value.text + '</a></li>');
					
					});


				}
			
			});

		}

  });

});

