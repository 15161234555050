/**
Login Application - Router
@module Login
@submodule Login.Router
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Router
		* appRoutes
Author(s):
  * Just Checking
*/

define('app/modules/logout/logout.router',[

  'marionette'

], function(Marionette) {

  'use strict';

  /**
  Router
  @class Login.Router
  @constructor
  @extends Marionette.AppRouter
  */
  return Marionette.AppRouter.extend({

		/**
		@property appRoutes
		*/
    appRoutes: {

      'logout': 'logout'

    }

  });

});

