define('app/modules/newcharts/features/heatLight/models/heatLight.model',['require','backbone','moment'],function (require) {
    'use strict';

    var Backbone = require('backbone'),
        Moment = require('moment');

    return Backbone.Model.extend({
        idAttribute: '_id',

        url: function() {
            return 'api/v1/HeatLight/'
                + this.get('mode')
                + '/'
                + this.id
                + '/'
                + this.get('sensorId')
                + '?dateFrom='
                + this.get('dateFrom')
                + '&dateTo='
                + this.get('dateTo');
        },

        defaults: {
            mode: 'heat',
            sensorId: null,
            dateFrom: new Moment().startOf('day').format('YYYY-MM-DD'),
            dateTo: new Moment().startOf('day').add(1, 'days').format('YYYY-MM-DD')
        }
    });
});
