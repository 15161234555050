/**
Application Commands - Close Panel
@module Commands
@submodule Commands.ClosePanel
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
	* Commands
	* Requests
	* Crudder
Contents:
  * Module Initialize
		* Close Panel
Author(s):
  * Just Checking  
*/

define('app/commands/commands.closePanel',[

	'backbone',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'backbone.crudder',
	'app/components/panels/views/panels.views.header'

], function(Backbone, Commands, Requests, Crudder, HeaderView) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Close Panel
			@event close:panel
			@param targetPanel {String}
			*/
			return Commands.setHandler('close:panel', function(options) {

				// Make options an empty object if not supplied
				options = options || {};

				// Set the option defaults
				_.defaults(options, {

					requestedPanel: $('.panel-right'),
          panelReference: 'right',
					panelType: 'horizontal',
          reset: true

				});

				var
				App = require('app/app'),
				appPanels = Requests.request('get:state:data').get('panels');
		if(appPanels){
				// Close the panel via the jQuery plugin
        appPanels.closePanel(options);
    	}

			});

    }

  };

});

