define('app/modules/newcharts/features/events/views/events.view',[

	'marionette',
	'app/app.vent',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment',
	'app/modules/newcharts/features/events/views/event.view',
	'tpl!app/modules/newcharts/features/events/templates/events.template.tmpl',
	'app/modules/newcharts/features/events/models/event.model',
	'app/views/views.noItems',
	'app/modules/newcharts/features/events/collections/events.collection',
	'app/modules/newcharts/features/events/models/sensor.model'

], function(Marionette, Vent, Commands, Requests, Moment, EventView, Template, EventModel, NoItemsView, EventsCollection, EventSensor) {
	'use strict';

	return Marionette.CompositeView.extend({

		itemView: EventView,

		itemViewContainer: 'tbody',

		template: Template,

		emptyView: function() {
			return new NoItemsView({
				itemName: 'sequences',
				border: true,
				marginTop: true
			});
		},

		ui: {
			createBtn: '.create-btn'
		},

		events: {
			'click @ui.createBtn': 'createEvent'
		},

		createEvent: function(e) {
			e.preventDefault();

			var model = new EventModel({
				install_id: this.options.install_id
			});

			model.fetch({
				success: function() {
					Vent.trigger('newchart:event-form', {
						model: model
					});
				}
			});
		}

	})

});
