(function(a,k){var m={type:"",text:"",top:0,left:0,angle:45,size:50,distance:50,template:'<div class="grumble" style="display:none;filter:progid:DXImageTransform.Microsoft.Matrix(sizingMethod=\'auto expand\')">&#160;</div>',textTemplate:'<div class="grumble-text" style="display:none;"><div class="outer"><div class="inner">{text}</div></div></div>',context:null};k.GrumbleBubble=function(d){this.options=a.extend({},m,d);this.context=a(this.options.context||a("body"));this.css={};this.create()};k.GrumbleBubble.prototype=
{create:function(){var d=k.GrumbleBubble.prototype.tmpl;this.bubble=a(d(this.options.template));this.text=a(d(this.options.textTemplate,{text:this.options.text}));this.prepare()},setBubbleRotation:function(){this.rotateDeg=this.options.angle-45;this.rotateDeg<0&&(this.rotateDeg+=360)},prepare:function(){var d=this.bubble.get(0).parentNode;this.setBubbleRotation();this.applyStyles();d!==this.context&&this.append();this.rotate()},applyStyles:function(){this.setPosition();this.css.width=this.options.size;
this.css.height=this.options.size;this.text.css(this.css).addClass("grumble-text"+this.options.size);this.bubble.css(this.css).addClass(this.options.type+"grumble"+this.options.size);this.realLeft=this.css.left;this.realTop=this.css.top},setPosition:function(){var d=this.options.angle/-360,a=this.options.size/2,j=this.options.size*this.options.size,j=Math.sqrt(j+j)/2,f=this.options.left-a-Math.sin(d*2*Math.PI)*(this.options.distance+j);this.css.top=this.options.top+a-Math.cos(d*2*Math.PI)*(this.options.distance+
j)-this.options.size;this.css.left=f},append:function(){var d=this.context;this.bubble.appendTo(d);this.text.appendTo(d)},rotate:function(){navigator.appName==="Microsoft Internet Explorer"&&k.document.documentMode<10?this.ieRotate():this.cssRotate()},cssRotate:function(){this.bubble.css({"-moz-transform":"rotate("+this.rotateDeg+"deg)","-webkit-transform":"rotate("+this.rotateDeg+"deg)","-o-transform":"rotate("+this.rotateDeg+"deg)",transform:"rotate("+this.rotateDeg+"deg)"})},ieRotate:function(){var d=
this.rotateDeg*(Math.PI*2/360),a=Math.cos(d),d=Math.sin(d),j=this.bubble.get(0);j.filters.item(0).M11=a;j.filters.item(0).M12=-d;j.filters.item(0).M21=d;j.filters.item(0).M22=a;a=this.bubble.width();d=this.bubble.height();this.bubble.css({left:this.css.left-(a-this.options.size)/2,top:this.css.top-(d-this.options.size)/2})},adjust:function(d){a.extend(this.options,d);this.prepare()},tmpl:function(a,h,j){for(var f in h)h[f]===null&&(h[f]=""),typeof h[f]==="object"&&h[f].length&&(h[f]=h[f].join(", ")),
a=a.replace(RegExp("{"+f+"}","g"),j?escape(h[f]):h[f]);return a}}})($,window);(function(a,k){function m(a,d){var g,c;d?(c=Number(a.css("margin-top").replace("px",""))||0,g=a.position(),g.top+=c+d.scrollTop()+a.height()):(g=a.offset(),g.top+=a.height());g.left+=a.width()/2;return g}function d(f,h,g){var c=a('<div style="position:absolute;visibility:hidden;width:'+f+'px;">'+g+"</div>").appendTo(a(document.body)),j=c.outerHeight()*2+f*0.2,b=a.inArray(f,h);c.remove();return j>=f&&h[++b]?d(h[b],h,g):f}var h=[],j=navigator.appName==="Microsoft Internet Explorer"&&window.document.documentMode<
10;a.fn.grumble=function(f,p){return typeof f==="string"?(this.trigger({type:f+".bubble",adjustments:p}),this):this.each(function(){var g=a(this),c=a.extend({},a.fn.grumble.defaults,f,g.data("grumble")||{}),l=d(c.size,c.sizeRange,c.text),b,e,i,o,n;c.useRelativePositioning&&(n=g.offsetParent());o=m(g,n);c.top=o.top;c.left=o.left;if(a.data(this,"hazGrumble"))return g.grumble("adjust",f),g.grumble("show"),!0;else a.data(this,"hazGrumble",!0);i={init:function(){b=new k({text:c.text,top:c.top,left:c.left,
angle:c.angle,size:l,distance:c.distance,type:c.type,context:n});c.hasHideButton&&this.addButton();h.push({grumble:b,button:e,onHide:function(){i.isVisible=!1;a(document.body).unbind("click.bubble");i.doOnBeginHideCallback();i.doOnHideCallback()}});this.showBubble();this.prepareEvents()},addButton:function(){var q=k.prototype.tmpl;e=a(q(c.buttonTemplate,{hideText:c.buttonHideText})).css({left:b.realLeft+l-10,top:b.realTop+l-10}).insertAfter(b.text)},rePositionButton:function(){e&&e.css({left:b.realLeft+
l-10,top:b.realTop+l-10})},createFxQueue:function(){b.bubble.queue("fx");b.text.queue("fx");b.bubble.delay(c.showAfter);b.text.delay(c.showAfter);e&&e.delay(c.showAfter)},showBubble:function(){i.isVisible!=!0&&(c.showAfter&&i.createFxQueue(),j?(b.bubble.queue("fx",function(a){b.bubble.show();a()}),b.text.queue("fx",function(a){b.text.show();a()}),e&&e.queue("fx",function(a){e.show();a()})):(b.bubble.fadeTo("fast",1),b.text.fadeTo("fast",1),e&&e.fadeTo("fast",1)),b.bubble.queue("fx",function(a){i.isVisible=
!0;(c.hideOnClick||c.hasHideButton)&&i.hideOnClick();i.doOnShowCallback();a()}),c.hideAfter&&i.hideBubble())},hideBubble:function(){b.bubble.delay(c.hideAfter);b.text.delay(c.hideAfter);b.bubble.queue("fx",function(a){i.doOnBeginHideCallback();a()});j?(b.bubble.queue("fx",function(a){b.bubble.hide();a()}),b.bubble.queue("fx",function(a){b.text.hide();a()}),e&&e.queue("fx",function(a){e.hide();a()})):(b.bubble.fadeOut(),b.text.fadeOut(),e&&e.fadeOut());b.bubble.queue("fx",function(a){i.isVisible=!1;
i.doOnHideCallback();a()})},doOnBeginHideCallback:function(){c.onBeginHide(b,e)},doOnHideCallback:function(){c.onHide(b,e)},doOnShowCallback:function(){c.onShow(b,e)},hideOnClick:function(){setTimeout(function(){var c=function(){i.hideBubble(b,e);a(document.body).unbind("click.bubble",c)};a(document.body).bind("click.bubble",c)},1E3)},prepareEvents:function(){a(window).bind("resize.bubble",function(){var a;a=m(g,n);b.adjust({top:a.top,left:a.left});i.rePositionButton()});g.bind("hide.bubble",function(){i.hideBubble(b,
e)});g.bind("adjust.bubble",function(a){a.adjustments&&typeof a.adjustments==="object"&&b.adjust(a.adjustments)});g.bind("show.bubble",function(){i.showBubble(b,e)});g.bind("delete.bubble",function(){b.bubble.hide().remove();b.text.hide().remove();e&&e.hide().remove();for(var a=h.length,c=0;c<a;c++)if(b===h[c].grumble){h.splice(c,1);break}g.removeData("hazGrumble")})}};i.init()})};a.fn.grumble.defaults={text:"",angle:45,size:50,sizeRange:[50,100,150,200],distance:0,type:"",useRelativePositioning:!1,
showAfter:0,hideAfter:!1,hideOnClick:!1,hasHideButton:!1,buttonTemplate:'<div class="grumble-button" style="display:none" title="{hideText}">x</div>',buttonHideText:"Hide",onHide:function(){},onShow:function(){},onBeginHide:function(){}};a(document).bind("keyup.bubble",function(d){d.keyCode===27&&a.each(h,function(a,d){d.grumble.bubble.clearQueue().hide();d.grumble.text.clearQueue().hide();d.button&&d.button.clearQueue().hide();d.onHide()})})})(jQuery,GrumbleBubble);

define("grumble", ["jquery"], function(){});

