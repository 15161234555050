/**
Application Requests - Get fuzzy time
@module Requests
@submodule Requests.GetFuzzyTime
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Requests
Contents:
	* Module Initialize
		* Get session data
Author(s):
  * Just Checking
*/

define('app/requests/requests.getFuzzyTime',[

	'underscore',
	'app/commands/commands.vent',
	'app/requests/requests.vent',
	'moment'

], function(_, Commands, Requests, Moment) {

  'use strict';

	return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Get formatted date
			@event get:session:data
			@param date {Date}
			@return fuzzy time {String} The formatted date
			*/
			return Requests.setHandler('get:fuzzy:time', function(options) {

				options = options || {};

				var
				now = new Moment(),
				serverTime = new Moment(options.date),
				clampedMoment = serverTime.max(now);

				// Has a date been supplied?
				if (options.date) {

					return new Moment(clampedMoment).fromNow();

				}
				else {

					return;

				}

			});

    }

  };

});

