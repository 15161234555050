/**
Application Commands - Set State Value
@module Commands
@submodule Commands.FormChangesTrue
*/

/*
Requires:
	* jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
  * Module Initialize
  	* Set state data
Author(s):
  * Just Checking
*/

define('app/commands/commands.setStateData',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Set state data
			@event set:state:data
			@param properties {Object} The properties to be updated or set
			*/
			return Commands.setHandler('set:state:data', function(options) {

				var App = require('app/app');

				App.state.set(options, {

					silent: true

				});

				// Has the required param been supplied?
				// if (options.properties) {

				//  // Do we have an object?
				//  if (_.isObject(options.properties)) {

				//    // Loop through each property
				//    _.each(options.properties, function(value, key) {

				//      // Update or create the property
				//      App.state[key] = value;

				//    });

				//  }
				//  else {

				//    return;

				//  }

				// }
				// else {

				//  return;

				// }

			});

    }

  };

});

