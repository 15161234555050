define('app/views/views.sequencesReportLightbox',['require','marionette','app/app.vent','app/commands/commands.vent','app/requests/requests.vent','app/base/views/emailPopup.view','tpl!app/templates/sequencesReportLightbox.template.tmpl','backbone.crudder'],function (require) {
	'use strict';

	var Marionette 			= require('marionette'),
		Vent 				= require('app/app.vent'),
		Commands 			= require('app/commands/commands.vent'),
		Requests 			= require('app/requests/requests.vent'),
		EmailPopup			= require('app/base/views/emailPopup.view'),
		LightboxTemplate 	= require('tpl!app/templates/sequencesReportLightbox.template.tmpl'),
		Crudder				= require('backbone.crudder');

		return EmailPopup.extend({

			template: LightboxTemplate,

			onRender:function () {
				$('#lightboxcontainer').html(LightboxTemplate(this.model.toJSON()));
				$('.overlay').show();
				$('#lightboxcontainer').show();

				this.createCombo();
			}

		});

});

