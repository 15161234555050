/**
Chart Application - Menu View
@module Chart
@submodule Chart.Views.Menu
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Chart Menu View
    * template
    * ui
    * events
    * behaviors
    * navigate
    * setup
    * share
    * refreshChart
    * changeViewType
    * showLogins
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.menu',[
  'marionette',
  'app/app.vent',
  'app/commands/commands.vent',
  'mobiscroll',
  'tpl!app/modules/charts/templates/charts.templates.menu.tmpl',
  'app/requests/requests.vent',
  'bootstrap.dropdown',
  'moment',
  'app/behaviors/behaviors.calendar',
  'app/behaviors/behaviors.showCalendar'
], function(Marionette, Vent, Commands, Mobiscroll, Template, Requests, Dropdown, Moment, Calendar, ShowCalendar) {

  'use strict';

  /**
  Menu View
  @class ChartApp.Views.Menu
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.ItemView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property ui
    @type Object
    */
    ui: {
      //chartHighlights: '#chart-highlights',
      datePickerInput: '#chart-date-input',
	  datePickerButtons: '.chart-datepicker-button',
      refreshButton: '#chart-refresh-button',
      loginsButton: '#chart-logins-button',
      views: '#views',
      viewButtons: '.chart-views-button',
      sevenDay: '#chart-views-7day',
      sevenNight: '#chart-views-7night',
      navigateButtons: '[data-hash]',
      setupButton: '#chart-settings-edit',
      shareButton: '.chart-share-button',
      printButton: '#chart-print',
      mainMenuButtons: '#chart-menu-main',
      updateTime: '#updateTime',
      roomOccupancy: '#room-occupancy',
      bathroomVisits: '#bathroom-visits',
      noteButton: '.show-note'
    },

    /**
    @property events
    @type Object
    */
    events: {

			// 'click @ui.datePickerButtons': 'showDatePicker',
      //'click @ui.chartHighlights' : 'chartHighlights',
      'click @ui.noteButton': 'showNote',
      'click @ui.refreshButton': 'refreshChart',
      'click @ui.loginsButton': 'showLogins',
      'click @ui.viewButtons': 'changeViewType',
      'click @ui.navigateButtons': 'navigate',
      'click @ui.setupButton': 'setup',
      'click @ui.shareButton': 'share',
      'click @ui.printButton': 'printPDF',
      'click @ui.sevenDay': 'open7Day',
      'click @ui.sevenNight': 'open7Night',
      'click @ui.roomOccupancy': 'roomOccupancy',
      'click @ui.bathroomVisits': 'bathroomVisits'
    },

    /**
    @property behaviors
    @type Object
    */
    behaviors: {

      ShowCalendar: {

        behaviorClass: ShowCalendar

      },

      Calendar: {
        behaviorClass: Calendar,
        showNow: true,
        onSelect: function(valueText) {

          var UTCFormat = Requests.request('get:state:data').get('utcFormat');

          // Navigate the chart app
          Vent.trigger('chart:navigate', {

            date: new Moment(valueText).format(UTCFormat),
            userSelected: 1

          });

        },
	  	onMonthLoaded: function(year, month, inst) {
			var notes = JSON.parse(store.get('calendar.markers')),
				marked = [];

			if (notes != null) {
				$.each(notes, function(index, noteObj) {
					var date = new Moment(noteObj.date).toDate(),
						text = noteObj.subject;

					marked.push({d: date, text: text});
				});

				inst.settings.marked = marked;
				inst.refresh();
			}
		}
      }
    },

    navigate: function(e) {

      var
      hash = $(e.currentTarget).attr('data-hash'),
      frag = Requests.request('get:fragment:section', {

        limit: 3

      });

      //check if the user pressed the alerts button and redirect them to the alerts app if so
      if(hash === "alerts"){
        $.removeCookie("interface");
        $.cookie("interface", window.location.href);
        window.location.href = "/justupandabout.com/api/wwwroot/load.php?go=settings&country=GB&referer=https%3A%2F%2Fapp.justchecking.com%2Finterface&iid="+ this.options.meta.get('installationId');
       // window.location.href = "http://justupandabout.com/api/wwwroot/load.php?go=settings&country=GB&referer=https%3A%2F%2Fapp.justchecking.com%2Finterface&iid="+ this.options.meta.get('installationId') +"&uid=17416"
      }else{

        //if we want to navigate to the installations page we need to remove nearly everything from the url.
        if(hash === "installations"){
          frag = frag.replace(frag.substring(frag.indexOf('/charts')), '');
          hash = '';
        }

        // Navigate the app to the route
        Commands.execute('app:navigate', {

          route: frag + hash

        });
      }

      e.preventDefault();
    },

    setup: function(e) {
      // Show the chart edit state
      Vent.trigger('chart:edit', {

        systemId: this.options.meta.get('systemId'),
        installationId: this.options.meta.get('installationId'),
        date: this.options.meta.get('chartDate'),
        zoom: this.options.meta.get('zoom'),
        model: this.options.chartModel

      });

      e.preventDefault();
    },

    share: function(e) {
      console.log('share it');
      console.log($(e.currentTarget).attr('data-share-type'));
      var shareType = $(e.currentTarget).attr('data-share-type');
      e.preventDefault();
    },

    printPDF: function(e) {
      	var url = '/api/v1/Generate/PDF',
		  	note = this.options.chartModel.get('note'),
			rawHTML = "<!DOCTYPE html><html><head><link rel=\"stylesheet\" href=\""+ document.querySelector("link[href*='.css']").href +"\" type=\"text/css\" /><style>html{background:none !important;} .bg-logo{background: url(\"https://app.justchecking.com/assets/images/svg/jc-logo.svg\") no-repeat 100% 0;} #chart-day-menu li{top:-11px;} /* Make sure chart axis and rows are at zero to render full 24 hours */.chart-data, #chart-timeline-hours{left:0px !important;} #printnote {max-width: 940px; margin: 2em auto; padding: 2em 1em; background-color: white; border: 2px solid #F58026; border-radius: 5px;}</style></head><body>"+
				document.getElementById('page-main').innerHTML;

		if (note != null && (note.subject || note.message)) {
			rawHTML += "<div id='printnote'>";

			if (note.subject) {
				rawHTML += "<h3>" + note.subject + "</h3>";
			}
			if (note.message) {
				rawHTML += "<p>" + note.message + "</p>";
			}

			rawHTML += "</div>";
		}

		rawHTML += "</body></html>";

	    // $("<form action=\"" + url + "\" method=\"post\" style=\"display:none;\"><input type=\"hidden\" name=\"html\" value=\"" + encodeURI(rawHTML) + "\" /></form>").appendTo("body").submit().remove();
      console.log("print1")
      console.log(rawHTML)
      e.preventDefault();
    },

    chartHighlights: function (type) {
		Commands.execute('app:Lightbox', {
			requestData: {
                chartTitle: this.options.meta.get('chartTitle'),
                systemNum: this.options.meta.get('systemNo'),
          		installationId: this.options.meta.get('installationId'),
                type: type
        	}
		});
    },

    roomOccupancy: function () {
        this.chartHighlights('roomOccupancy');
    },

    bathroomVisits: function () {
        this.chartHighlights('bathroomVisits');
    },

    /**
    @method refreshChart
    */
    refreshChart: function(e) {
      // Navigate the chart app and set zoom back to default
      Vent.trigger('chart:navigate', {
        refresh: true
      });
      e.preventDefault();
    },

    changeViewType: function(e) {
      var viewType = $(e.currentTarget).attr('data-view-type');
       // Navigate the chart app
        Vent.trigger('chart:navigate', {
          date: $(e.currentTarget).attr('data-date')
        });
      e.preventDefault();
    },

    showLogins: function(e) {
      var loginListView = Requests.request('get:view', {
        request: 'logins:get:list',
        panelView: true
      });

      // Show logins in the bottom panel
      Commands.execute('show:panel', {
        targetPanel: 'bottom',
        requestData: {
          installationId: this.options.meta.get('installationId')
        },
        view: loginListView,
        title: 'Logins'
      });
      e.preventDefault();
    },

    onRender: function() {
		// Bathroom visits notification
		if (
			typeof this.options.meta.get('showBathroomVisitsPrompt') != 'undefined' &&
			this.options.meta.get('showBathroomVisitsPrompt') == true
		) {
			$('#page-main-content-notifications')
				.append('<div id="bathroom-visits-notification" class="notification notification-inline">Why not take a look at the new Night-time bathroom visits feature? You can find this under \'View\' (<i class="icon-eye"></i> icon below).</div>');
		}

      // Are we showing today and last update is also today?
      if (this.options.state.get('lastUpdateToday')) {
        // Show the update time
        this.ui.updateTime.show();
      }
      else {
        // Show the update time
        this.ui.updateTime.hide();
      }
      /*
      Hide the night/day view picker on zoom
      */
      if(this.options.meta.get('zoom') == '10'){
        this.ui.views.attr('style', 'display: none !important');
      }
      else {
        this.ui.views.attr('style', 'display: inline-block !important');
      }

	  store.set('calendar.markers', JSON.stringify(this.options.chartModel.get('calendarNotes')));
	},

    open7Day: function(){
	  this.openSevenPopup('day');
    },

    open7Night: function(){
		this.openSevenPopup('night');
    },

    openSevenPopup: function(type) {
		$.ajax({
			url: '/api/v1/SevenDayUrl/' + this.model.get('installationId'),
			type: 'get',
			data: {
				chartDate: this.model.get('chartDate'),
				type: type
			},
			success: function(response) {
				window.open(response.jcData.url, '_blank', 'width=1024, height=768, resizable=yes, status=no, titlebar=no, menubar=no, location=no, scrollbars=yes');
			}
		});
	},

    showNote: function() {
		$.ajax({
			url: '/api/v1/ChartNotesTracking/' + this.options.meta.get('installationId')
		});

        Commands.execute('app:NoteLightbox', {
            requestData: {
                chartTitle: this.options.meta.get('chartTitle'),
                systemNum: this.options.meta.get('systemNo'),
                installationId: this.options.meta.get('installationId'),
                date: this.model.get('chartDate'),
                type: 'showNote',
                chartModel: this.options.chartModel
            }
        });
	}
  });
});

