/**
Chart Application - Controls Button CompositeView
@module Chart
@submodule Chart.Views.Controls.Buttons
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Controls Button CompositeView
    * template
    * itemView
    * itemViewContainer
    * itemViewOptions
    * ui
    * events
    * serializeData
    * onRender
    * navigate
Author(s):
  * Just Checking
*/

define('app/modules/charts/views/charts.views.controls.list',[

  'marionette',
  'app/app.vent',
  'moment',
  'tpl!app/modules/charts/templates/charts.templates.controls.tmpl',
  'app/modules/charts/views/charts.views.controls.item',
  'app/commands/commands.vent'

], function(Marionette, Vent, Moment, Template, ItemView, Commands) {

  'use strict';

  /**
  Controls Button CompositeView
  @class Chart.Views.Controls.Buttons
  @constructor
  @extends Marionette.ItemView
  */
  return Marionette.CompositeView.extend({

    /**
    @property template
    @type String
    */
    template: Template,

    /**
    @property itemView
    @type Class
    */
    itemView: ItemView,

    /**
    @property itemViewContainer
    @type String
    */
    itemViewContainer: '#chart-day-menu',

    /**
    @property itemView
    @type Object
    */
    itemViewOptions: function() {

      // Pass the current zoom level into the item view
      return {

        zoom: this.model.get('zoom')

      };

    },

    /**
    @property ui
    @type Object
    */
    ui: {

      date: '#chart-day-date'

    },

    /**
    @property events
    @type Object
    */
    events: {

      'click a': 'navigate'

    },

    /**
    @method serializeData
    */
    serializeData: function() {

      // Merge the state and meta objects
      var data = _.extend(this.model.attributes, this.options.meta.attributes);

      return data;

    },

    /**
    @method onRender
    */
    onRender: function() {

      /*
      Date Alignment
      */

      // Are we in the day view?
      if (this.model.get('view') === 'day') {

        // Add the class to right align the text
        this.ui.date.addClass('right-text');

      }
      else {

        // Remove the class
        this.ui.date.removeClass('right-text');

      }

		window.switchToNewChart = function() {
			Commands.execute('app:navigate', {
				route: 'systems/' + this.model.get('systemId') + '/newcharts/' + this.model.get('installationId'),
				trigger: true
			});
		}.bind(this);

    },

    /**
    @method navigate
    */
    navigate: function(e) {

      e.preventDefault();

      var date = $(e.currentTarget).attr('data-date');

      // Do we have a view type?
      if (date && this.model.get('zoom') === 120) {

        // Navigate the chart app
        Vent.trigger('chart:navigate', {

          date: date

        });

      }
      else {

        return;

      }

    }

  });

});

