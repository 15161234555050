/**
Application Commands - Panel Reset
@module Commands
@submodule Commands.PanelReset
*/

/*
Requires:
  * jQuery
  * Backbone
  * Marionette
  * Underscore
  * Commands
Contents:
	* Module Initialize
		* Panel Reset
Author(s):
  * Just Checking
*/

define('app/commands/commands.resetPanel',[

	'app/commands/commands.vent'

], function(Commands) {

  'use strict';

  return {

		/**
		Module Initialize
		@method initialize
		*/
    initialize: function() {

      /**
			Panel Reset
			@event reset:panel
			*/
			return Commands.setHandler('reset:panel', function() {

				// Send the panel back to 0
				$('#main').css('left', 0);

				// Hide the mobile back button
				$('.mobile-back').addClass('hide');

				// Close the menu
				$('.top-bar').removeClass('expanded');

			});

    }

  };

});

