define('app/modules/newcharts/features/heatLight/views/heatLight.view',[

    'marionette',
    'app/app.vent',
    'app/commands/commands.vent',
    'app/requests/requests.vent',
	'app/modules/newcharts/features/heatLight/models/heatLight.model',
    'tpl!app/modules/newcharts/features/heatLight/templates/heatLight.template.tmpl',
    'd3',
    'app/modules/newcharts/state',
    'app/behaviors/behaviors.calendar',
    'app/behaviors/behaviors.showCalendar',
    'moment'
], function(Marionette, Vent, Commands, Requests, Model, Template, D3, State, Calendar, ShowCalendar, Moment) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            heatBtn: '#heat-summary',
            lightBtn: '#light-summary',
            sensorSelect: '#sensor-select',
            datePickerInput: '#chart-date-input',
            datePickerButtons: '.chart-datepicker-button',
        },

        templateHelpers: function(param1) {
            return {
                sensorList: function () {
                    var sensors = [], sensorId, field;
                    for (var sensor in this.options.settingsModel.attributes) {
                        sensorId = parseInt(sensor, 10);
                        sensor = this.options.settingsModel.attributes[sensor];

                        if (typeof sensor === 'object') {
                            field = null;
                            for (field in sensor.fields) {
                                field = sensor.fields[field];

                                if (
                                    (
                                        this.model.get('mode') === 'heat'
                                        && field.FieldTypeName === 'Temperature'
                                    )
                                    ||
                                    (
                                        this.model.get('mode') === 'light'
                                        && field.FieldTypeName === 'Light'
                                    )
                                ) {
                                    break;
                                } else {
                                    field = null;
                                }
                            }
                            if (null !== field) {
                                // Sensor supports current mode
                                sensors.push({
                                    id: sensorId,
                                    label: sensor.chartTitle,
                                    selected: parseInt(this.model.get('sensorId'), 10) === sensorId
                                });
                            }
                        }
                    }

                    return sensors;
                }.bind(this),
                highest: function () {
                    var data = this.getData();

                    return _.reduce(data, function(highestVal, item) {
                        var value = Math.round(item.Value);

                        return value > highestVal ? value : highestVal;
                    }, 0);
                }.bind(this),
                lowest: function () {
                    var data = this.getData(),
                        lowest = Infinity;

                    lowest = _.reduce(data, function(lowestVal, item) {
                        var value = Math.round(item.Value);

                        return value < lowestVal ? value : lowestVal;
                    }, lowest);

                    if (lowest === Infinity) {
                        return 0;
                    }

                    return lowest;
                }.bind(this),
				showModeToggle: function() {
					var heatSupported = false,
						lightSupported = false,
						sensorId,
						sensor,
						field;

					outer_loop:
					for (var sensor in this.options.settingsModel.attributes) {
                        sensorId = parseInt(sensor, 10);
                        sensor = this.options.settingsModel.attributes[sensor];

                        if (typeof sensor === 'object') {
                            field = null;
                            for (field in sensor.fields) {
                                field = sensor.fields[field];

								if (field.FieldTypeName === 'Temperature') {
									heatSupported = true;
								}
								if (field.FieldTypeName === 'Light') {
									lightSupported = true;
								}

								if (heatSupported && lightSupported) {
									// No point carrying on this loop
									break outer_loop;
								}
                            }
                        }
                    }

					return heatSupported && lightSupported;
				}.bind(this),
                minDate: function() {
                    return new Moment(this.options.chartMeta.installationStart).startOf('day');
                }.bind(this),
                maxDate: function() {
                    var today = new Moment().startOf('day'),
                        installEnd = this.options.chartMeta.installationEnd;
                    if (null === installEnd){
                        return today;
                    }

                    installEnd = new Moment(installEnd).startOf('day');

                    return today.isBefore(installEnd) ? today : installEnd;
                }.bind(this)
            };
        },

        behaviors: {
            ShowCalendar: {
                behaviorClass: ShowCalendar
            },

            Calendar: {
                behaviorClass: Calendar,
                showNow: true,
                onSelect: function(valueText, inst, view) {
                    view.model.set({
                        dateFrom: new Moment(valueText).startOf('day').format('YYYY-MM-DD'),
                        dateTo: new Moment(valueText).startOf('day').add(1, 'day').format('YYYY-MM-DD')
                    });
                    Vent.trigger('newchart:heatLight-reload', {
                        model: view.model
                    });
                },
            }
        },

        events: {
            'click @ui.heatBtn': 'loadHeatSummary',
            'click @ui.lightBtn': 'loadLightSummary',
            'change @ui.sensorSelect': 'changeSensor'
        },

        loadLightSummary: function() {
            this.model.set('mode', 'light');

            Vent.trigger('newchart:heatLight-reload', {
                model: this.model
            });
        },

        loadHeatSummary: function() {
            this.model.set('mode', 'heat');

            Vent.trigger('newchart:heatLight-reload', {
                model: this.model
            });
        },

        changeSensor: function() {
            var val = parseInt(this.ui.sensorSelect.val(), 10);

            if (this.model.get('sensorId') === val) {
                // Already on the same value
                return;
            }

            this.model.set({
                sensorId: val
            });
			State.save(this.model);
            Vent.trigger('newchart:heatLight-reload', {
                model: this.model
            });
        },

        onRender: function() {
            var settings = this.options.settingsModel,
                container = this.$el.find('#chart');

            // Calculate vertical timeline markers
            var field;
            for (field in settings.get(this.model.get('sensorId')).fields) {
                field = settings.get(this.model.get('sensorId')).fields[field];

                if (
                    (
                        this.model.get('mode') === 'heat'
                        && field.FieldTypeName === 'Temperature'
                    )
                    ||
                    (
                        this.model.get('mode') === 'light'
                        && field.FieldTypeName === 'Light'
                    )
                ) {
                    break;
                } else {
                    field = null;
                }
            }
            if (null === field) {
                // Sensor doesn't support temperature or light!
                return;
            }

            var scaleMax = field.MaxScale,
                scaleMin = field.MinScale,
                current = scaleMax,
                verticalTimelineMarkers = [],
                scaleRate = 5;

            function generateVerticalTimelineMarkersArray() {
                var markers = [];
                current = scaleMax;
                while (current >= scaleMin) {
                    markers.push(current);

                    current -= scaleRate;
                }

                return markers;
            }
            var iterationSafety = 10;
            do {
                verticalTimelineMarkers = generateVerticalTimelineMarkersArray();
                iterationSafety--;
                scaleRate--;
            } while (iterationSafety > 0 && verticalTimelineMarkers.length < 5);

            var lastVerticalY = 0, lastHorizontalX = 0, topPadding = 20;
            var horizontalTimelineMarkers = _.range(0, 24, 2);
            horizontalTimelineMarkers.push(0);

            // Start SVG
            var svg = D3.select(this.$el.find('#chart')[0])
                .append('svg')
                .attr('width', '85%')
                .attr('height', 50*verticalTimelineMarkers.length);

			svg
                .append('g')
				.attr('class', 'vertical-timeline')
                .attr('fill', '#888');

            // Render vertical timeline markers
            for (var i = 0; i < verticalTimelineMarkers.length; i++) {
                svg
                    .append('text')
                    .text(verticalTimelineMarkers[i] + (this.model.get('mode') === 'heat' ? '°' : ''))
                    .attr('fill', '#888')
                    .attr('x', 0)
                    .attr('y', lastVerticalY = topPadding + 10 + (50*i));
            }

            // Render Horizontal timeline markers
            for (i = 0; i < horizontalTimelineMarkers.length; i++) {
                svg
                    .append('text')
                    .text(horizontalTimelineMarkers[i].toString().padStart(2, '0'))
                    .attr('fill', '#888')
                    .attr('x', lastHorizontalX = 20 + (60*i))
                    .attr('y', topPadding + (50*(verticalTimelineMarkers.length - 1) + 30));
            }

            // Render timeline lines
            svg
                .append('line')
                .attr('stroke', '#888')
                .attr('x1', 25)
                .attr('x2', 25)
                .attr('y1', topPadding)
                .attr('y2', lastVerticalY);
            svg
                .append('line')
                .attr('stroke', '#888')
                .attr('x1', 25)
                .attr('x2', lastHorizontalX + 10)
                .attr('y1', topPadding + (50*(verticalTimelineMarkers.length - 1) + 10))
                .attr('y2', topPadding + (50*(verticalTimelineMarkers.length - 1) + 10));

            // Work out the pixel height of each chart unit in the scale
            var totalHeight = lastVerticalY,
                unitHeight = totalHeight / (scaleMax - scaleMin); // Height of 1 unit in the scale

			// Create hidden rectangle
			svg
				.insert('rect', 'g.vertical-timeline')
				.attr('class', 'hidden-rect')
				.attr('fill', 'white')
				.attr('x', 0)
				.attr('y', totalHeight)
				.attr('width', '100%')
				.attr('height', 50);

            // Render data
            var data = this.getData();

            current = new Moment(this.model.get('dateFrom'));
            var end = new Moment(this.model.get('dateTo'));

            i = 0;
            var rawValue, value, unitValue, barHeight, barY, g, dataItem;
            while (current < end) {
                // Find the right data item
                dataItem = _.find(data, function(item) {
                    return item.ActDateTimeIso === current.format('YYYY-MM-DD HH:mm:ss');
                });
                if (!dataItem) {
                    // We do not have data for this day, so skip rendering this bar
                    lastHorizontalX = 20 + (60*i);
                    current = current.add(1, 'hour');
                    i++;
                    continue;
                }

                // Render single bar
                rawValue = Math.round(dataItem.Value);
                value = Math.min(Math.max(rawValue, 0.5), scaleMax);
                unitValue = value - scaleMin;
                barHeight = unitHeight * unitValue;
                barY = totalHeight - barHeight;

                g = svg
                    .insert('g', 'rect.hidden-rect')
                    .attr('title', rawValue + (this.model.get('mode') === 'heat' ? '°' : ''))
                    .each(function() {
                        Commands.execute('init:popover', {
                            container: container,
                            element: $(this),
                            data: {
                                placement: 'top',
                                text: $(this).attr('title')
                            },
                            html: true
                        })
                    });

                var className = '';
                if (this.model.get('mode') === 'heat') {
                    className = 'temp-' + value;
                } else {
                    className = value <= 6 ? 'lux-grey' : 'lux-blue';
                }

                g
                    .append('circle')
                    .attr('class', className)
                    .attr('cx', 40 + (30 * i))
                    .attr('cy', barY + topPadding)
                    .attr('r', 13);

                g
                    .append('rect')
                    .attr('class', className)
                    .attr('width', 26)
                    .attr('height', Math.max(barHeight - topPadding, 0))
                    .attr('x', 27 + (30 * i))
                    .attr('y', barY + topPadding);

                current = current.add(1, 'hour');
                i++;
            }
        },

        getData: function() {
            var data = [], dataItem;
            for (dataItem in this.model.attributes) {
                dataItem = this.model.attributes[dataItem];

                if (typeof dataItem === 'object' && !moment.isMoment(dataItem)) {
                    data.push(dataItem);
                }
            }

            return data;
        }

    });
});

