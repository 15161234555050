define('app/commands/commands.noteLightbox',['require','app/commands/commands.vent','app/requests/requests.vent','app/views/views.lightboxView','app/modules/charts/models/charts.models.insights','app/views/view.bathroomView','app/views/view.roomoccupancyView','app/modules/charts/models/charts.models.note','app/modules/charts/views/charts.views.notewindow','app/app'],function (require) {
	'use strict';

	var Commands            = require('app/commands/commands.vent'),
		Requests            = require('app/requests/requests.vent'),
		Lightbox            = require('app/views/views.lightboxView'),
		Model 	            = require('app/modules/charts/models/charts.models.insights'),
		bathroomView		= require('app/views/view.bathroomView'),
		roomoccupancyView 	= require('app/views/view.roomoccupancyView'),
		NoteModel           = require('app/modules/charts/models/charts.models.note'),
		noteView            = require('app/modules/charts/views/charts.views.notewindow');

	return {
		initialize: function() {
			return Commands.setHandler('app:NoteLightbox', function(options) {
				var App = require('app/app'),
					cacheinstall = options.requestData.installationId,
					type = options.requestData.type,
					model;

				var note = options.requestData.chartModel.get('note');
				if (typeof note != "object") {
					var date = moment(options.requestData.date);

					model = new NoteModel({
						installationId: cacheinstall,
						date: date.format("YYYY-MM-DD")
					});
				} else {
					model = new NoteModel(note);
				}

				App.lightbox.show(new Lightbox({
					model: model,
					type: type
				}));
				App.lightbox.show(new noteView({model: model, chartModel: options.requestData.chartModel}));
			});
		}
	};
});




