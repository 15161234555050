/**
Application Layout - Header Menu Controller
@module Layout
@submodule Layout.Controllers.Header.Menu
*/

/*
Requires:
  * Backbone
  * Marionette
  * jQuery
  * Underscore
Contents:
  * Main Header Menu Controller
    * initialize
Author(s):
  * Just Checking
*/

define('app/layout/controllers/layout.controllers.header.menu',[

  'marionette',
  'app/commands/commands.vent',
  'app/requests/requests.vent',
  'app/layout/layouts/layout.layouts.main',
  'app/layout/models/layout.models.header.menu.model',
  'app/layout/collections/layout.collections.header.menu.collection',
  'app/layout/views/layout.views.header.menu.list',
  'app/components/panels/layouts/panels.layouts.panels',
  'app/components/panels/layouts/panels.layouts.panel',
  'app/components/panels/views/panels.views.header',
  'app/layout/controllers/layout.controllers.header.menu.topbar'

], function(Marionette, Commands, Requests, MainLayout, MenuModel, MenuCollection, MenuListView, PanelsLayout, PanelLayout, PanelHeaderView, TopbarController) {

  'use strict';

  /**
  Main Header Menu Controller
  @class Layout.Controllers.Header.Menu
  @constructor
  @extends Marionette.Controller
  */
  return Marionette.Controller.extend({

		/**
    @method initialize
    */
    initialize: function(options) {

      var
      that = this,
      App = require('app/app');

      // Create the menu collection
      var menuCollection = new MenuCollection();
      
      // Fetch the menu data
      menuCollection.fetch();

      // Bind to the menu collection fetched event
      Commands.execute('when:fetched', menuCollection, function() {

        /*
        Main menu
        */

        // Create the menu views
        var menuView = new MenuListView({

          collection: menuCollection

        }),
        menuViewClone = new MenuListView({

          collection: menuCollection

        }),
        panelLayout = new PanelLayout();

        // When the right panel is shown
        App.panels.currentView.panelRight.on('show', function() {

          // New panel header view
          var panelHeaderView = new PanelHeaderView({

            panelReference: 'right',
            title: 'Just Checking',
            symbol: true,
            close: true

          });

          // Listen for the panel content show event
          panelLayout.content.on('show', function() {

            var
            menu = this.currentView.$el.find('#top-menu-list'),
            menuItems = menu.children();

            /* 
            Adjust styling to suit vertical menu
            */

            // Remove the 'inline-list' class
            menu.removeClass('inline-list');

            // Add the 'vertical-menu-list' class
            menu.addClass('vertical-menu-list');

            // Remove any floating classes
            menuItems.removeClass('left right');

            // Add the right icon
            menuItems.find('a').append('<i class="icon-right-open"></i>');

          });

          // Show the cloned menu view in the menus panel content region
          panelLayout.content.show(menuViewClone);

          // Show the panel header view in the header region
          panelLayout.header.show(panelHeaderView);

        });

        // Show the panel layout in the right panel region
        App.panels.currentView.panelRight.show(panelLayout);

        // Show the desktop menu
        App.header.currentView.menu.show(menuView);

      });

      /*
      Top Bar
      */

      // New topbar controller
      var topbarController = new TopbarController();

    }

  });

});
