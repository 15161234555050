define('app/modules/newcharts/views/multi-menu.view',[

    'marionette',
    'app/app.vent',
    'app/requests/requests.vent',
    'app/commands/commands.vent',
    'tpl!app/modules/newcharts/templates/multi-menu.template.tmpl',
    'moment',
    'app/behaviors/behaviors.calendar',
    'app/behaviors/behaviors.showCalendar',
    'switcher',
    'app/modules/newcharts/state'

], function(Marionette, Vent, Requests, Commands, Template, Moment, Calendar, ShowCalendar, Switcher, State) {
    'use strict';

    return Marionette.ItemView.extend({

        template: Template,

        ui: {
            fromDateInput: '#chart-from-input',
            toDateInput: '#chart-to-input',
            goBtn: '#goBtn',
            dateError: '#multi-date-selection .error',
            sensorList: '#sensor-list',

            hideNotesBtn: '#switchHideNotes',
            nightViewBtn: '#switchNightView',

            datesExpand: '#dates-expand',
            datesCollapse: '#dates-collapse',
            sensorsExpand: '#sensors-expand',
            sensorsCollapse: '#sensors-collapse',
            extrasExpand: '#extras-expand',
            extrasCollapse: '#extras-collapse',

            dates: '.date',
            innerSensorList: '#inner-sensor-list',
            extras: '.extra',

            allSensorsCheck: '#switchAllSensors'
        },

        events: {
            'click @ui.goBtn': 'selectDates',

            'change @ui.hideNotesBtn': 'toggleNotes',
            'change @ui.nightViewBtn': 'toggleNightView',

            'click @ui.datesExpand': 'expandDates',
            'click @ui.datesCollapse': 'collapseDates',
            'click @ui.sensorsExpand': 'expandSensors',
            'click @ui.sensorsCollapse': 'collapseSensors',
            'click @ui.extrasExpand': 'expandExtras',
            'click @ui.extrasCollapse': 'collapseExtras',

            'click #sevenDay-view': function(e) {
                // Get the start date and attach it to the model
                var model = this.model.clone(),
                    date = this.ui.fromDateInput.val();

                if (date && date != '') {
                    model.set('startDate', new Moment(date));
                }

                Commands.execute('app:7DayLightbox', {
                    model: model
                });

                e.preventDefault();
            }
        },

        templateHelpers: {
            installationStart: function() {
                return new Moment(this.chartMeta.installationStart).format('YYYY-MM-DD');
            },
            installationEnd: function() {
                return this.chartMeta.installationEnd == null ? '' : new Moment(this.chartMeta.installationEnd).format('YYYY-MM-DD');
            },
            maxDate: function() {
                var slots = 14;
                if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                    slots = 70;
                }

				var maxDate = new Moment(this.options.dates.from).add(slots-1, 'days'),
					installEnd = this.chartMeta.installationEnd == null ? null : new Moment(this.chartMeta.installationEnd);

				if (installEnd != null && maxDate.isAfter(installEnd)) {
					maxDate = installEnd.clone();
				}

                return maxDate.format('YYYY-MM-DD');
            }
        },

        behaviors: {

            ShowCalendar: {

                behaviorClass: ShowCalendar

            },

            Calendar: {
                behaviorClass: Calendar,
                showNow: true,
                onShow: function(elem) {
                    while(elem.find('.dwbw.dwb-n').length > 1) {
                        elem.find('.dwbw.dwb-n').last().remove();
                    }
                },
                onSelect: function(valueText, inst, view, model, elem) {
                    if (elem[0].id == 'chart-from-input') {
                        var valueDate = new Moment(valueText);
                        $('#from-date').text(valueDate.format('DD/MM/YYYY'));

                        // Get slots amount
                        var slots = 14;
                        if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                            slots = 70;
                        }

                        // Bind the second date picker.
                        var toElem = $('#chart-to-input'),
                            toValue = new Moment(toElem.mobiscroll('getDate')),
                            minDate = new Moment(valueDate),
                            maxDate = new Moment(valueDate).add(slots-1, 'days'),
							installEnd = view.model.get('chartMeta').installationEnd == null ? null : new Moment(view.model.get('chartMeta').installationEnd);

						if (installEnd != null && maxDate.isAfter(installEnd)) {
							maxDate = installEnd.clone();
						}

                        toElem.mobiscroll('option', {
                            minDate: minDate.toDate(),
                            maxDate: maxDate.toDate()
                        });

                        // Check if the selected date is outside of the bounds
                        // If so, set to the maximum
                        if (!this.isBetween(toValue, minDate, maxDate)) {
                            toElem.mobiscroll('setDate', maxDate.toDate(), true);
                            $('#to-date').text(maxDate.format('DD/MM/YYYY'));
                        }
                    } else if (elem[0].id == 'chart-to-input') {
                        $('#to-date').text(new Moment(valueText).format('DD/MM/YYYY'));
                    }

                    view.ui.dateError.fadeOut();
                },
                onMonthLoaded: function(year, month, inst) {
                    var notes = JSON.parse(store.get('calendar.markers')),
                        marked = [];

                    if (notes != null) {
                        $.each(notes, function(index, noteObj) {
                            var date = new Moment(noteObj.date).toDate(),
                                text = noteObj.subject;

                            marked.push({d: date, text: text});
                        });

                        inst.settings.marked = marked;
                        inst.refresh();
                    }
                },
                isBetween: function(date, min, max) {
                    date = new Moment(date);
                    min = new Moment(min);

                    if (date.isSame(min) || date.isSame(max)) {
                        return true;
                    }

                    while(min.isBefore(date)) {
                        min.add(1, 'day');

                        if (min.isSame(max)) {
                            break;
                        }
                    }

                    return min.isSame(date);
                }
            }
        },

        onRender: function() {
            // Enable the switches
            setTimeout(function() {
                if (this.model.get('chartMenu').canAccessNote) {
                    setTimeout(function() {
                        Switcher.init({
                            view: $('#switchHideNotes-wrapper')
                        });
                    });
                }
                setTimeout(function() {
                    Switcher.init({
                        view: $('#switchNightView-wrapper')
                    });
                });

                if (this.model.get('chartRows').length <= 5) {
                    // Hide the scroll on the show / hide sensors box.
                    this.ui.sensorList.find('.scrollable').css('overflow', 'hidden');
                }

                this.model.get('chartRows').forEach(function(row) {
                    if (row.get('rowInUse')) {
                        Switcher.init({
                            view: $('#switchChartRow' + row.get('chartLineId') + '-wrapper')
                        });
                        $('#switchChartRow' + row.get('chartLineId')).change(function(e) {
                            var id = e.target.id.replace('switchChartRow', '');

                            if (e.target.checked) {
                                Vent.trigger('newchart:show-row', {
                                    id: id
                                });
                            } else {
                                Vent.trigger('newchart:hide-row', {
                                    id: id
                                });
                            }

                            this.refreshAllSensorsSwitch();
                            State.save(this.model);
                        }.bind(this));
                    }
                }.bind(this));


                // All sensors switch
                setTimeout(function() {
                    Switcher.init({
                        view: $('#switchAllSensors-wrapper')
                    });
                    this.refreshAllSensorsSwitch();
                }.bind(this));
            }.bind(this));
        },

        refreshAllSensorsSwitch: function() {
            this.ui.allSensorsCheck.unbind();

            var allSensorsWrapper = $('#switchAllSensors-wrapper'),
                onSwitch = allSensorsWrapper.find('.switch-on'),
                atLeastOneShowing = false;

            this.model.get('chartRows').forEach(function(row) {
                if (row.get('rowInUse') && $('#switchChartRow' + row.get('chartLineId')).is(':checked')) {
                    atLeastOneShowing = true;
                }
            }.bind(this));

            if (
                atLeastOneShowing && !this.ui.allSensorsCheck.is(':checked')
                || !atLeastOneShowing && this.ui.allSensorsCheck.is(':checked')
            ) {
                onSwitch.click();
            }

            this.ui.allSensorsCheck.change(function(e) {
                var elem = $(e.target);

                if (elem.is(':checked')) {
                    // Show all sensors.
                    this.model.get('chartRows').forEach(function(row){
                        var $rowSwitch = $('#switchChartRow' + row.get('chartLineId')),
                            $rowSwitchWrapper = $('#switchChartRow' + row.get('chartLineId') + '-wrapper');

                        if (!$rowSwitch.is(':checked')) {
                            $rowSwitchWrapper.find('.switch-on').click();
                        }
                    });
                } else {
                    // Hide all sensors.
                    this.model.get('chartRows').forEach(function(row){
                        var $rowSwitch = $('#switchChartRow' + row.get('chartLineId')),
                            $rowSwitchWrapper = $('#switchChartRow' + row.get('chartLineId') + '-wrapper');

                        if ($rowSwitch.is(':checked')) {
                            $rowSwitchWrapper.find('.switch-off').click();
                        }
                    });
                }
            }.bind(this));
        },

        selectDates: function() {
            var fromDate = new Moment(this.ui.fromDateInput.val()),
                toDate = new Moment(this.ui.toDateInput.val()),
                tempDate,
                days = 14;

            if (State.getEmail().toLowerCase().indexOf('justchecking.co.uk') !== -1) {
                days = 70;
            }

            if(Math.abs(fromDate.diff(toDate, 'days')) >= days) {
                // Too many days! - Blink error text.
                this.ui.dateError.fadeIn().fadeOut().fadeIn().fadeOut().fadeIn().fadeOut().fadeIn();
                return;
            }

            // Make sure the from and to dates are the correct way around.
            if (fromDate.diff(toDate, 'days') > 0) {
                // Wrong way.
                tempDate = fromDate;
                fromDate = toDate;
                toDate = tempDate;
            }

            Vent.trigger('newchart:multi-change-dates', {
                from: fromDate,
                to: toDate
            });
        },

        serializeData: function() {
            var data = Backbone.Marionette.ItemView.prototype.serializeData.apply(this, arguments);

            data.options = this.options;

            return data;
        },

        toggleNotes: function(e) {
            if (e.target.checked) {
                $('#switch-icon-notes').addClass('on');
                Vent.trigger('newchart:show-all-notes');
            } else {
                $('#switch-icon-notes').removeClass('on');
                Vent.trigger('newchart:hide-all-notes');
            }

            State.save(this.model);
        },

        toggleNightView: function(e) {
            var noteBoxes = $('#charts').find('.notebox'),
                chart,
                totalCharts = noteBoxes.length,
                successfulCharts = 0;

            if (this.model.get('chartMenu').canAccessNote) {
                for(var i = 0; i < noteBoxes.length; i++) {
                    chart = noteBoxes[i].id.replace('notebox-', '');

                    Vent.trigger('newchart:multi-check-note-' + chart, function() {
                        successfulCharts++;

                        if (totalCharts == successfulCharts) {
                            if (e.target.checked) {
                                $('#switch-icon-night-view').addClass('on');
                                Vent.trigger('newchart:night-view');
                            } else {
                                $('#switch-icon-night-view').removeClass('on');
                                Vent.trigger('newchart:day-view');
                            }

                            State.save(this.model);
                        }
                    }.bind(this));
                }
            } else {
                if (e.target.checked) {
                    $('#switch-icon-night-view').addClass('on');
                    Vent.trigger('newchart:night-view');
                } else {
                    $('#switch-icon-night-view').removeClass('on');
                    Vent.trigger('newchart:day-view');
                }

                State.save(this.model);
            }
        },

        expandDates: function() {
            this.ui.datesExpand.hide();
            this.ui.datesCollapse.show();

            this.ui.dates.show();
        },

        collapseDates: function() {
            this.ui.datesCollapse.hide();
            this.ui.datesExpand.show();

            this.ui.dates.hide();
        },

        expandSensors: function() {
            this.ui.sensorsExpand.hide();
            this.ui.sensorsCollapse.show();

            this.ui.innerSensorList.show();
        },

        collapseSensors: function() {
            this.ui.sensorsCollapse.hide();
            this.ui.sensorsExpand.show();

            this.ui.innerSensorList.hide();
        },

        expandExtras: function() {
            this.ui.extrasExpand.hide();
            this.ui.extrasCollapse.show();

            this.ui.extras.show();
        },

        collapseExtras: function() {
            this.ui.extrasCollapse.hide();
            this.ui.extrasExpand.show();

            this.ui.extras.hide();
        }

    });

});
